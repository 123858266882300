<div class="content">
    <div class="header">
        <ng-container *ngIf="view === 'default' || view === 'details'">
            <h3>Offer Letter</h3>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <h3>Compensation</h3>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <h3>Review {{ form.value?.full_name }}’s Offer Letter</h3>
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <h3>Options</h3>
        </ng-container>
        <ng-container *ngIf="view === 'payroll-settings'">
            <h3>Payroll Settings</h3>
        </ng-container>
        <button class="close-btn btn" *ngIf="view !== 'preview'" (click)="onHideOfferModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="form-content-section" [formGroup]="form">
        <ng-container *ngIf="view === 'default'">
            <div class="form-content-holder">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Full Name</mat-label>
                                    <input type="text" autocomplete="off" formControlName="full_name" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.full_name.valid && form.controls.full_name.touched"
                                    >
                                        Full Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Identity Number</mat-label>
                                    <input type="text" autocomplete="off" formControlName="identity_number" matInput />
                                    <mat-error
                                        *ngIf="
                                            !form.controls.identity_number.valid &&
                                            form.controls.identity_number.touched
                                        "
                                    >
                                        Identity Number is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Job Grade</mat-label>
                                    <input type="text" autocomplete="off" formControlName="job_grade" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.job_grade.valid && form.controls.job_grade.touched"
                                    >
                                        Job Grade is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Job title</mat-label>
                                    <input type="text" autocomplete="off" formControlName="designation" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.designation.valid && form.controls.designation.touched"
                                    >
                                        Job title is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="noticePeriodTypeOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Notice Period"
                                    [control]="form.controls.notice_period"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.notice_period.valid && form.controls.notice_period.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="annualLeaveTypeOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Annual Leave"
                                    [control]="form.controls.time_off"
                                    [ngClass]="{
                                        invalid: !form.controls.time_off.valid && form.controls.time_off.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="form.get('start_time')">
                        <div class="separator-line"></div>
                        <h4>Hours of Work</h4>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="startTimeOptions"
                                        [dropdownValueUpdated]="dropdownValueUpdated"
                                        [newStyle]="true"
                                        placeholder="Start Time"
                                        [control]="form.controls.start_time"
                                        [ngClass]="{
                                            invalid: !form.controls.start_time.valid && form.controls.start_time.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="endTimeOptions"
                                        [dropdownValueUpdated]="dropdownValueUpdated"
                                        [newStyle]="true"
                                        placeholder="End Time"
                                        [control]="form.controls.end_time"
                                        [ngClass]="{
                                            invalid: !form.controls.end_time.valid && form.controls.end_time.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="lunchBreakOptions"
                                        [dropdownValueUpdated]="dropdownValueUpdated"
                                        [newStyle]="true"
                                        placeholder="Lunch Break"
                                        [control]="form.controls.lunch_break"
                                        [ngClass]="{
                                            invalid:
                                                !form.controls.lunch_break.valid && form.controls.lunch_break.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="separator-line"></div>
                    <h4>Dates</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                    <mat-label>Start Date</mat-label>
                                    <input
                                        formControlName="start_date"
                                        matInput
                                        [matDatepicker]="picker1"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error
                                        *ngIf="!form.controls.start_date.valid && form.controls.start_date.touched"
                                    >
                                        Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block" *ngIf="form.value.details?.offer_type === 'fixed'">
                                <mat-form-field appearance="outline" (click)="picker2.open()">
                                    <mat-label>End Date</mat-label>
                                    <input
                                        formControlName="end_date"
                                        matInput
                                        [matDatepicker]="picker2"
                                        [min]="minEndDate"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error *ngIf="!form.controls.end_date.valid && form.controls.end_date.touched">
                                        End Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>Reporting Structure</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [newStyle]="true"
                                    placeholder="
                                        Hiring Executive
                                    "
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="form.controls.hiring_executive"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hiring_executive.valid &&
                                            form.controls.hiring_executive.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        form.controls.hiring_executive.touched && !form.controls.hiring_executive.valid
                                    "
                                >
                                    Hiring Executive is required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="hrBusinessOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="HR Business Partner"
                                    [control]="form.controls.hr_business_partner"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hr_business_partner.valid &&
                                            form.controls.hr_business_partner.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block" *ngIf="usersOptions?.length">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Hiring Manager"
                                    [control]="form.controls.manager"
                                    [ngClass]="{
                                        invalid: !form.controls.manager.valid && form.controls.manager.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <div class="form-content-holder">
                <div class="form-block">
                    <ng-container formGroupName="salary">
                        <div class="form-block-columns mb10">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span class="input-prefix" *ngIf="form.value.salary.salary">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field class="right-label-align" appearance="outline">
                                        <mat-label>Salary Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                allowNegative: false,
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="salary"
                                            matInput
                                        />
                                        <mat-error
                                            *ngIf="
                                                !form.get('salary').controls.salary.valid &&
                                                form.get('salary').controls.salary.touched
                                            "
                                        >
                                            Salary Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="frequencyOptions"
                                        [newStyle]="true"
                                        [control]="form.get('salary').controls.period"
                                        [placeholder]="'Frequency'"
                                        [ngClass]="{
                                            invalid:
                                                form.get('salary').controls.period.touched &&
                                                !form.get('salary').controls.period.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            form.get('salary').controls.period.touched &&
                                            !form.get('salary').controls.period.valid
                                        "
                                    >
                                        Period is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="bonuses" *ngIf="form.get('bonuses')['controls'].length">
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('bonuses')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="bonusTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Bonus Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Bonus Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block" *ngIf="item.get('type').value === '13th cheque'">
                                    <span class="input-prefix" *ngIf="item.value.amount">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Salary Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                allowNegative: false,
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="input-block" *ngIf="item.get('type').value === 'management'">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Bonus Percentage</mat-label>
                                        <input
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                allowNegative: false,
                                                prefix: '',
                                                thousands: ',',
                                                precision: 1,
                                                align: 'left'
                                            }"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Bonus is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="allowances" *ngIf="form.get('allowances')['controls'].length">
                        <div class="separator-line"></div>
                        <h4>Allowances</h4>
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('allowances')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="allowancesTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Allowance Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                        (onChange)="onChangeAllowancesTypeOptions(i)"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Allowance Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div
                                    class="input-block"
                                    *ngIf="
                                        item.get('type').value !== 'medical aid' &&
                                        item.get('type').value !== 'group life cover' &&
                                        item.get('type').value !== 'free goods'
                                    "
                                >
                                    <span class="input-prefix" *ngIf="item.value.amount">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Allowance Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                allowNegative: false,
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="input-block" *ngIf="item.get('type').value === 'medical aid'">
                                    <app-auto-dropdown
                                        [options]="coverTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.amount"
                                        placeholder="Cover"
                                        [ngClass]="{
                                            invalid: item.controls.amount.touched && !item.controls.amount.valid
                                        }"
                                        (onChange)="onChangeAllowancesTypeOptions(i)"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.amount.touched && !item.controls.amount.valid"
                                    >
                                        Cover is required
                                    </div>
                                </div>
                                <div class="input-block" *ngIf="item.get('type').value === 'group life cover'">
                                    <app-auto-dropdown
                                        [options]="multipleTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.amount"
                                        placeholder="Group Life Cover"
                                        [ngClass]="{
                                            invalid: item.controls.amount.touched && !item.controls.amount.valid
                                        }"
                                        (onChange)="onChangeAllowancesTypeOptions(i)"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.amount.touched && !item.controls.amount.valid"
                                    >
                                        Group Life Cover is required
                                    </div>
                                </div>
                                <div class="input-block" *ngIf="item.get('type').value == 'free goods'">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Value</mat-label>
                                        <input
                                            class="align-left"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                allowNegative: false,
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            placeholder="Unlimited (Default)"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <div class="preview-holder">
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Personal Details</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Full Name</div>
                            <span>{{ form.value.full_name }}</span>
                        </div>
                        <div class="preview-item">
                            <div>ID Number</div>
                            <span>{{ form.value.identity_number }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Job Grade</div>
                            <span>{{ form.value.job_grade }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Job Title</div>
                            <span>{{ form.value.designation }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.notice_period">
                            <div>Notice Period</div>
                            <span>{{ form.value.notice_period }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.time_off">
                            <div>Annual Leave</div>
                            <span>{{ form.value.time_off }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Start Date</div>
                            <span *ngIf="previewData.start_date">{{
                                previewData.start_date | amDateFormat: 'DD MMM YYYY'
                            }}</span>
                        </div>
                        <div class="preview-item" *ngIf="previewData.end_date">
                            <div>End Date</div>
                            <span>{{ previewData.end_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.start_time">
                            <div>Start Time</div>
                            <span>{{ form.value.start_time }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.end_time">
                            <div>End Time</div>
                            <span>{{ form.value.end_time }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.lunch_break">
                            <div>Lunch Break</div>
                            <span>{{ form.value.lunch_break }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Reporting Structure</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Dealer Principle</div>
                            <span>{{ getUserName(form.value.hiring_executive) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>HR Business Partner</div>
                            <span>{{ getUserName(form.value.hr_business_partner) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Hiring Manager</div>
                            <span>{{ getUserName(form.value.manager) }}</span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.payroll_business_partner">
                            <div>Payroll Business Partner</div>
                            <span>{{ getUserName(form.value.payroll_business_partner) }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-compensation">
                    <div class="preview-title">
                        <strong>Compensation</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper preview-allowances">
                        <div class="preview-item">
                            <div>Salary Amount</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.salary | number: '.2-2' }}
                                <ng-container [ngSwitch]="form.value.salary.period">
                                    <ng-container *ngSwitchDefault>{{ form.value.salary.period }}</ng-container>
                                    <ng-container *ngSwitchCase="'hourly'">per hour</ng-container>
                                    <ng-container *ngSwitchCase="'dayly'">per day</ng-container>
                                    <ng-container *ngSwitchCase="'weekly'">per week</ng-container>
                                    <ng-container *ngSwitchCase="'monthly'">per month</ng-container>
                                    <ng-container *ngSwitchCase="'yearly'">per year</ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.commission_amount">
                            <div>Commission Amount</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.commission_amount | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.commission?.amount">
                            <div>Variable Pay / Commission</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.commission.amount | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngFor="let bonus of form.value.bonuses">
                            <div>Bonus</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ bonus.amount | number: '.2-2' }}
                                ({{ bonus.type | titlecase }})</span
                            >
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-allowances" *ngIf="form.value?.allowances.length">
                    <div class="preview-title">
                        <strong>Allowances</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item" *ngFor="let item of form.value.allowances">
                            <ng-container *ngIf="item.type === 'funeral cover'">
                                <div>Funeral Cover</div>
                                <span>Yes</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'mobile phone'">
                                <div>Mobile Phone</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number: '.2-2' }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'travel'">
                                <div>Travel</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number: '.2-2' }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'medical aid'">
                                <div>MedicalAid</div>
                                <span> {{ item.amount }}</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'group life cover'">
                                <div>Group Life Cover</div>
                                <span> {{ item.amount }}</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'free goods'">
                                <div>Free Goods</div>
                                <span>
                                    <ng-container *ngIf="item.amount">{{ form.value.salary.currency }}</ng-container>
                                    {{ item.amount || 'Unlimited' }}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-payroll-settings last" *ngIf="form.value.payroll_settings">
                    <div class="preview-title">
                        <strong>Payroll Settings</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper" style="border-bottom: none;">
                        <div class="preview-item">
                            <div>Company Rule</div>
                            <span> {{ form.value.payroll_settings.company_rule }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Payment Run Def</div>
                            <span> {{ form.value.payroll_settings.payment_run_def }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Leave Policy</div>
                            <span> {{ form.value.payroll_settings.leave_policy }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Nature of Contract</div>
                            <span> {{ form.value.payroll_settings.nature_of_contract }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Paypoint</div>
                            <span> {{ form.value.payroll_settings.pay_point }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Department</div>
                            <span> {{ form.value.payroll_settings.department }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Division</div>
                            <span> {{ form.value.payroll_settings.division }}</span>
                        </div>
                        <div class="preview-item">
                            <div>ESS_level</div>
                            <span> {{ form.value.payroll_settings.ess_level }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <div class="form-content-holder ftc_reasons-holder" formArrayName="ftc_reasons">
                <p>
                    Please provide a reason for offering this candidate a Fixed Term contract.
                </p>
                <p
                    *ngFor="let item of form.get('ftc_reasons')['controls']; let i = index; let last = last"
                    [formGroupName]="i"
                >
                    <ng-container *ngIf="!last">
                        <mat-checkbox [disabled]="item.value.disabled" formControlName="checked">
                            {{ item.value.title }}
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="last">
                        <mat-checkbox formControlName="checked"> </mat-checkbox>
                        <div class="form-block">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <textarea
                                        matInput
                                        formControlName="title"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="1"
                                        resize="vertical"
                                    ></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'details'" formArrayName="details">
            <div class="form-details-section">
                <ng-container *ngIf="form.value.details.offer_type === 'permanent'">
                    <p class="text">Please select a <strong>company template</strong>?</p>
                    <div class="input-block" style="max-width: 300px;">
                        <app-auto-dropdown
                            [options]="companyTypeOptions"
                            [newStyle]="true"
                            placeholder="Company"
                            [dropdownValueUpdated]="dropdownValueUpdated"
                            [control]="form.controls.details.controls.company"
                        >
                        </app-auto-dropdown>
                    </div>
                </ng-container>
                <p class="text">
                    Is this offer for a <strong>Permanent Employee</strong> or a <strong>Fixed Term Contractor</strong>?
                </p>
                <mat-radio-group formControlName="offer_type" (change)="onChangeOfferType($event)">
                    <mat-radio-button value="permanent">
                        <p>Permanent Employee</p>
                    </mat-radio-button>
                    <mat-radio-button value="fixed">
                        <p>Fixed Term Contractor</p>
                    </mat-radio-button>
                </mat-radio-group>
                <div class="work-days-wrapper mt20 mb20" *ngIf="form.value.details.offer_type === 'permanent'">
                    <mat-checkbox formControlName="send_onboarding_workflow">
                        Send Onboarding Workflow after signing
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'payroll-settings'">
            <div class="form-content-holder">
                <div class="form-block">
                    <ng-container formGroupName="payroll_settings">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="companyRuleOptions"
                                    [newStyle]="true"
                                    [control]="payroll.company_rule"
                                    placeholder="Company Rule"
                                    [ngClass]="{
                                        invalid: payroll.company_rule.touched && !payroll.company_rule.valid
                                    }"
                                    (onChange)="onChangeCompanyRule($event)"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.company_rule.touched && !payroll.company_rule.valid"
                                >
                                    Company Rule is required
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="paymentRunDefIDOptions"
                                    [newStyle]="true"
                                    [control]="payroll.payment_run_def"
                                    placeholder="Payment Run Def"
                                    [ngClass]="{
                                        invalid: payroll.payment_run_def.touched && !payroll.payment_run_def.valid
                                    }"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.payment_run_def.touched && !payroll.payment_run_def.valid"
                                >
                                    Payment Run Def is required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="leavePolicyIDOptions"
                                    [newStyle]="true"
                                    [control]="payroll.leave_policy"
                                    placeholder="Leave Policy"
                                    [ngClass]="{
                                        invalid: payroll.leave_policy.touched && !payroll.leave_policy.valid
                                    }"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.leave_policy.touched && !payroll.leave_policy.valid"
                                >
                                    Leave Policy is required
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="natureOfContractOptions"
                                    [newStyle]="true"
                                    [control]="payroll.nature_of_contract"
                                    placeholder="Nature Of Contract"
                                    [ngClass]="{
                                        invalid: payroll.nature_of_contract.touched && !payroll.nature_of_contract.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.nature_of_contract.touched && !payroll.nature_of_contract.valid"
                                >
                                    Nature Of Contract is required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="payPointOptions"
                                    [newStyle]="true"
                                    [control]="payroll.pay_point"
                                    placeholder="Paypoint"
                                    [ngClass]="{
                                        invalid: payroll.pay_point.touched && !payroll.pay_point.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.pay_point.touched && !payroll.pay_point.valid"
                                >
                                    Paypoint is required
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="departmentOptions"
                                    [newStyle]="true"
                                    [control]="payroll.department"
                                    placeholder="Department"
                                    [ngClass]="{
                                        invalid: payroll.department.touched && !payroll.department.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.department.touched && !payroll.department.valid"
                                >
                                    Department is required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="divisionOptions"
                                    [newStyle]="true"
                                    [control]="payroll.division"
                                    placeholder="Division"
                                    [ngClass]="{
                                        invalid: payroll.division.touched && !payroll.division.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div class="error-message" *ngIf="payroll.division.touched && !payroll.division.valid">
                                    Division is required
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="essLevelOptions"
                                    [newStyle]="true"
                                    [control]="payroll.ess_level"
                                    placeholder="ESS Level"
                                    [ngClass]="{
                                        invalid: payroll.ess_level.touched && !payroll.ess_level.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="payroll.ess_level.touched && !payroll.ess_level.valid"
                                >
                                    ESS Level is required
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <ng-container *ngIf="view === 'default'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w140" *ngIf="form.value.end_date" (click)="onNext()">Options</button>
            <button class="btn btn-primary w140" *ngIf="!form.value.end_date" (click)="onNext()">Compensation</button>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <button class="btn btn-primary  mb10" (click)="onPreviewContract()">
                Preview Contract
            </button>
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn btn-primary" (click)="onSendOffer()">
                Send for Approval
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'payroll-settings'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary" (click)="onPreview()">
                Preview
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <ng-container *ngIf="form.value.details.offer_type === 'permanent'" (click)="onPreview()">
                <button class="btn btn-primary" (click)="onPreview()">
                    Preview
                </button>
            </ng-container>
            <!-- <button class="btn btn-primary" (click)="onPayroll()">
                Payroll Settings
            </button> -->
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w140" (click)="onCompensation()">Compensation</button>
        </ng-container>
        <ng-container *ngIf="view === 'details'">
            <button class="btn btn-primary w72" (click)="onAddDetails()">Details</button>
        </ng-container>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideOfferModal()"></div>

<div class="offer-preview-modal" *ngIf="previewContractLink">
    <div class="overlay"></div>
    <button class="close-btn btn" (click)="onHideOfferPreview()">
        <img src="/assets/images/icons/icons-8-delete.svg" />
    </button>
    <pdf-viewer
        *ngIf="previewContractLink"
        [src]="previewContractLink"
        [render-text]="true"
        [original-size]="true"
        [autoresize]="true"
        [zoom]="1"
        [render-text]="true"
        [show-borders]="false"
        style="display: block;max-width: 800px;"
    >
    </pdf-viewer>
</div>
