import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-candidate-resume',
    templateUrl: './candidate-resume.component.html',
    styleUrls: ['./candidate-resume.component.scss']
})
export class CandidateResumeComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() hideLanguagesBlock;
    @Input() candidate;
    @Output() scrollTabContentToTop = new EventEmitter<any>();

    constructor(private fb: FormBuilder) {}

    ngAfterViewInit(): void {
        this.scrollToTop();
    }

    ngOnInit() {}

    scrollToTop() {
        this.scrollTabContentToTop.emit();
    }

    ngOnDestroy() {
        this.form = this.fb.group({
            languages: this.fb.array([]),
            employment_summary: [''],
            certificates: this.fb.array([]),
            employment_history: this.fb.array([]),
            education: this.fb.array([])
        });
    }
}
