import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SdkJob } from '@app/core/models';
import { CandidateService, JobService } from '@app/core/services';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as fromStore from '../../store';
import * as fromStoreSelectors from '../../store/selectors/jobs.selector';

@Component({
    selector: 'app-not-hire-modal',
    templateUrl: './not-hire-modal.component.html',
    styleUrls: ['./not-hire-modal.component.scss']
})
export class DoNotHireModalComponent implements OnInit {
    @Input() candidate;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() mark: EventEmitter<any> = new EventEmitter();
    contentLoading = false;
    formControl: FormControl;
    constructor(private candidateService: CandidateService) {}

    ngOnInit(): void {}

    onHideModal() {
        this.close.emit();
    }

    onMark() {
        this.contentLoading = true;
        this.candidateService
            .markAsDoNotHire(this.candidate.id, { reason: this.formControl })
            .subscribe((response: any) => {
                // console.log('res', response);
                this.mark.emit(response.audit);
                this.contentLoading = false;
                this.candidate.do_not_hire = true;
                this.candidateService.setMarkedDoNotHire(this.candidate.id);
            });
    }
}
