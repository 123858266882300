<div class="overview-holder" #overviewDiv>
    <div
        class="matching"
        *ngIf="
            candidate &&
            candidate.matching &&
            showBlockMatch &&
            candidate.resume_file &&
            candidate.resume_file_new &&
            !isDeleted
        "
    >
        <div
            class="match-block  big-block"
            *ngIf="
                jobMatchText &&
                !doNotMatch &&
                candidate?.complianceRateClass === 'pending' &&
                job &&
                job.status !== 'closed' &&
                job.status !== 'hold'
            "
            [ngClass]="{
                'green-bg': jobMatchText === 'Good',
                'green-bg-ex': jobMatchText === 'Excellent',
                'orange-bg': jobMatchText === 'Average',
                'red-bg': jobMatchText === 'Poor'
            }"
        >
            <!-- <div>
                <div class="match-block__title">{{ jobMatchText }} job match</div>
                <div class="match-block__description">
                    AI MATCHING ENGINE
                </div>
            </div> -->

            <!-- <ng-container *ngIf="candidate?.complianceRateClass !== 'pending'">
                <div class="icon" *ngIf="jobMatchText === 'Good'">
                    <img src="/assets/images/icons/icons-8-checked.svg" alt="" />
                </div>

                <div class="icon" *ngIf="jobMatchText === 'Excellent'">
                    <img src="/assets/images/icons/icons-8-checked.svg" alt="" />
                </div>

                <div class="icon" *ngIf="jobMatchText === 'Average'">
                    <img src="/assets/images/icons/icons-8-checked-average.svg" alt="" />
                </div>

                <div class="icon" *ngIf="jobMatchText === 'Poor'">
                    <img src="/assets/images/icons/not-checked.svg" alt="" />
                </div>
            </ng-container> -->
            <ng-container>
                <div class="action-btns">
                    <span class="remove-link" (click)="onRemoveClick()">Remove</span>
                    <button class="btn add-btn" (click)="onAddToJob()">Add to job</button>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="matching" *ngIf="!this.candidate.resume_file && !this.candidate.resume_file_new">
        <div class="match-block resume-required-block  big-block orange-bg">
            <div>
                <div class="match-block__title">Candidate Resume Required</div>
                <div class="match-block__description">
                    THIS CANDIDATE APPLIED WITHOUT A RESUME
                </div>
            </div>

            <div class="icon">
                <img src="/assets/images/icons/no-resume.svg" alt="" />
            </div>
        </div>
    </div>
    <div class="content-block" *ngIf="candidate.summary">
        <h3>Our Summary</h3>
        <div [innerHTML]="candidate.summary"></div>

        <div
            class="summary-additional"
            *ngIf="candidate.avg_months_per_employer || (candidate.matching && candidate.matching[jobId])"
        >
            <div class="average summary-additional__block" *ngIf="candidate.avg_months_per_employer">
                <div class="icon">
                    <img src="/assets/images/icons/icons-8-time.svg" alt="" />
                </div>
                <div class="info">
                    <span class="info-title">Average time per employer</span>
                    <p class="info-value">
                        <span>{{ candidate.avg_months_per_employer }}</span>
                    </p>
                </div>
            </div>
            <div
                class="job-type summary-additional__block"
                *ngIf="
                    candidate.matchingScores &&
                    (candidate.matchingScores[jobId] || candidate.matchingScores[jobId] === 0)
                "
            >
                <div class="icon">
                    <img src="/assets/images/icons/icons-8-new-job.svg" alt="" />
                </div>
                <div class="info">
                    <span class="info-title">Job Type Indicator</span>
                    <div class="info-value">
                        <span *ngIf="candidate.matchingScores[jobId] < 50 || !candidate.matchingScores[jobId]"
                            >temp / contractor</span
                        >
                        <span *ngIf="candidate.matchingScores[jobId] >= 50">full-time / direct-hire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-block" *ngIf="candidate.management_story">
        <h3>Management Story</h3>
        <p [innerHTML]="candidate.management_story"></p>
    </div>
    <!-- <div class="content-block" *ngIf="candidate.skills && candidate.skills.length">
        <h3>Skills</h3>
        <div class="skills-list">
            <div
                class="skill-name"
                *ngFor="let skill of candidate.skills"
                [ngClass]="{
                    'green-bg': skill.percent_of_parent_taxonomy > 30,
                    'orange-bg': skill.percent_of_parent_taxonomy >= 15 && skill.percent_of_parent_taxonomy < 30
                }"
            >
                {{ skill.title | titlecase }}
            </div>
        </div>
    </div> -->
    <div class="content-block" *ngIf="candidate.candidate_skills && candidate.candidate_skills.length">
        <h3>Skills</h3>
        <div class="skills-list">
            <div
                class="skill-name"
                *ngFor="let skill of candidate.candidate_skills"
                [ngClass]="{
                    'green-bg': skill.type === 'current',
                    'orange-bg': skill.type === 'not-current'
                }"
            >
                {{ skill.skill | titlecase }}
            </div>
        </div>
    </div>
    <div
        #documentsDiv
        class="content-block documents-container"
        [ngClass]="{ 'top-block': isTopBlock(), 'upload-in-progress': uploadQueue && uploadQueue.length }"
        *ngIf="
            !candidate.resume_file ||
            (candidate.skills && candidate.skills.length && !disableFiles) ||
            (candidate.parsedSkills && candidate.parsedSkills.length && !disableFiles) ||
            (documents && documents.length && !disableFiles)
        "
    >
        <h3>Documents</h3>
        <app-loader *ngIf="documentsLoading" class="posa"></app-loader>
        <div class="existing-files" [ngClass]="{ 'empty-list': !documents.length }">
            <ng-container *ngFor="let file of documents">
                <div (click)="onDocumentPreview(file)" class="file-block-frame" *ngIf="file.link">
                    <span class="file-icon"
                        ><img
                            *ngIf="file.extension"
                            src="/assets/images/icons/files-icon-{{ utilitiesService.getExtension(file.name) }}.svg"
                            alt=""
                    /></span>
                    <div class="file-block-holder">
                        <span class="title">{{ getDocName(file.documentType || file.originalName) }} </span>
                        <div class="label-row">
                            <span class="file-size thin" *ngIf="file.size">
                                {{ utilitiesService.convertSize(file.size) }}
                            </span>
                            <span class="thin" *ngIf="file.uploadedAt && file.uploadedAt.toString().length === 13">{{
                                file.uploadedAt | date: 'd MMMM, y'
                            }}</span>
                            <span class="thin" *ngIf="file.uploadedAt && file.uploadedAt.toString().length === 10">{{
                                file.uploadedAt * 1000 | date: 'd MMMM, y'
                            }}</span>
                            <div class="upload-icon" (click)="onDownloadDocument($event, file)">
                                <img src="/assets/images/download.svg" height="14" alt="Upload Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="add-files">
            <div
                class="file-block-wrapper"
                *ngFor="let uploadItem of uploadQueue"
                [ngClass]="{ fadeout: uploadItem.fadeout }"
            >
                <div class="file-block-frame" *ngIf="uploadItem">
                    <span class="file-icon">
                        <img
                            *ngIf="uploadItem.extension"
                            src="/assets/images/icons/files-icon-{{ uploadItem.extension }}.svg"
                            alt=""
                        />
                    </span>
                    <div class="file-block-holder">
                        <span class="title"
                            >{{ uploadItem.text }}
                            <span class="thin" *ngIf="uploadItem.file.size">{{
                                convertSize(uploadItem.file.size)
                            }}</span></span
                        >
                        <div class="label-row">
                            <!-- <span class="label">{{ getDocType(uploadItem.type) }}</span> -->
                            <span class="thin">{{ today | date: 'MMM d, y' }}</span>
                        </div>
                    </div>
                </div>

                <app-progress-circle
                    class="mla progress-circle"
                    [progress]="uploadItem.progress"
                    *ngIf="uploadItem.uploadStarted && !uploadItem.uploadFinished"
                >
                </app-progress-circle>
                <div
                    class="status-circle mla"
                    [ngClass]="{
                        success: uploadItem.uploadFinished && uploadItem.success,
                        warning: uploadItem.uploadFinished && !uploadItem.success
                    }"
                >
                    <img class="success-img" src="/assets/images/check.svg" alt="" width="18" height="14" />
                    <img class="warning-img" src="/assets/images/exclamation-mark.svg" alt="" width="6" height="17" />
                </div>
            </div>
            <div class="upload-block">
                <input class="file hide-input" type="file" name="" id="uploader_file" (change)="onFileUpload($event)" />
                <div
                    class="upload-btn-block"
                    #uploadOptionsDropUp
                    [ngClass]="{ disabled: uploadQueue && uploadQueue.length }"
                >
                    <div class="label-button" (click)="onAddDocumentBtnClick()">
                        <svg-icon src="/assets/images/plus-icon.svg"></svg-icon>
                        <span>Add Document</span>
                    </div>
                    <div class="options-drop-up" [ngClass]="{ visible: showUploaderOptions }">
                        <label
                            *ngFor="let type of candidateDocumentsTypeOptions"
                            for="uploader_file"
                            class="upload-item"
                            (click)="onUploadItemClick(type.value)"
                        >
                            <span class="upload-item-text">{{ type.label }}</span>
                            <span class="upload-item-size">&lt; 5MB</span>
                        </label>
                        <!-- <label
                            for="uploader_file"
                            class="upload-item"
                            (click)="onUploadItemClick('resume')"
                            [ngClass]="{ disabled: resumeUploadDisabled }"
                        >
                            <span class="upload-item-icon">
                                <svg-icon src="/assets/images/icons-8-resume.svg"></svg-icon>
                            </span>
                            <span class="upload-item-text">Resume</span>
                            <span class="upload-item-size">&lt; 5MB</span>
                        </label>
                        <label for="uploader_file" class="upload-item" (click)="onUploadItemClick('letter')">
                            <span class="upload-item-icon">
                                <svg-icon src="/assets/images/icons-8-document.svg"></svg-icon>
                            </span>
                            <span class="upload-item-text">Cover Letter</span>
                            <span class="upload-item-size">&lt; 5MB</span>
                        </label>
                        <label for="uploader_file" class="upload-item" (click)="onUploadItemClick('portfolio')">
                            <span class="upload-item-icon">
                                <svg-icon src="/assets/images/icons-8-internet-folder.svg"></svg-icon>
                            </span>
                            <span class="upload-item-text">Portfolio</span>
                            <span class="upload-item-size">&lt; 20MB</span>
                        </label>
                        <label for="uploader_file" class="upload-item" (click)="onUploadItemClick('id')">
                            <span class="upload-item-icon">
                                <svg-icon src="/assets/images/icons-8-contact.svg"></svg-icon>
                            </span>
                            <span class="upload-item-text">ID / Passport</span>
                            <span class="upload-item-size">&lt; 5MB</span>
                        </label>
                        <label for="uploader_file" class="upload-item" (click)="onUploadItemClick('other')">
                            <span class="upload-item-icon">
                                <svg-icon src="/assets/images/icons-8-attach.svg"></svg-icon>
                            </span>
                            <span class="upload-item-text">Other</span>
                            <span class="upload-item-size">&lt; 20MB</span>
                        </label> -->
                    </div>
                </div>
                <div class="error-message" *ngIf="uploadError">{{ uploadError }}</div>
            </div>
        </div>
    </div>
</div>

<app-candidate-preview-document
    *ngIf="documentPreviewModal.show"
    (close)="documentPreviewModal.show = false"
    [candidate]="candidate"
    [file]="documentPreviewModal.file"
    [documentsTypeOptions]="documentsTypeOptions"
>
</app-candidate-preview-document>
<app-loader *ngIf="contentLoading"></app-loader>
