import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Candidate, User } from '@app/core/models';
import { Store, select } from '@ngrx/store';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';

import * as fromStore from '@app/store';
import * as fromSelectors from './../../../../../../store/selectors';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { EmployeesService } from '@app/core/services/employees.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
@Component({
    selector: 'app-termination-letter',
    templateUrl: './termination-letter.component.html',
    styleUrls: ['./termination-letter.component.scss']
})
export class TerminationLetterComponent implements OnInit {
    contentLoading = false;
    view = 'default';
    form: FormGroup;
    candidate: Candidate;
    companyTypeOptions: any[] = [
        { value: 'dimensiondata', label: 'Dimension Data' },
        { value: 'britehouse', label: 'Britehouse' }
    ];
    usersOptions: SelectItem[] = [];
    usersSubscription: Subscription;
    users: User[];
    @Input() data;
    @Output() onClose = new EventEmitter<boolean>();
    @Output() onApproval = new EventEmitter();
    previewData: any = {};
    fiscalTypeOptions = [
        { value: 2023, label: 'FY23' },
        { value: 2024, label: 'FY24' },
        { value: 2025, label: 'FY25' },
        { value: 2026, label: 'FY26' },
        { value: 2027, label: 'FY27' },
        { value: 2028, label: 'FY28' },
        { value: 2029, label: 'FY29' },
        { value: 2030, label: 'FY30' }
    ];
    previewContractLink: string = '';
    dropdownValueUpdated = false;
    constructor(
        private fb: FormBuilder,
        private store: Store<fromStore.State>,
        private formHelperService: FormHelperService,
        private employeeService: EmployeesService,
        private toaster: ToastrService
    ) {}

    ngOnInit(): void {
        this.candidate = this.data.candidate;
        const startDate = moment(this.candidate.start_date, 'DD-MM-YYYY').format();
        const terminationDate = moment(this.candidate.termination_date, 'DD-MM-YYYY').format();
        this.form = this.fb.group({
            generate_termination_letter: [true],
            annual_leave_transfer: [10],
            severance_pay: ['NTT DATA'],
            full_name: [
                `${this.candidate?.first_name} ${this.candidate?.last_name}`,
                [Validators.required, Validators.pattern('\\b\\w+\\b(?:.*?\\b\\w+\\b){1}')]
            ],
            hr_business_partner: ['', Validators.required],
            meeting_date: ['', Validators.required],
            previous_start_date: ['', Validators.required],
            start_date: [startDate || '', Validators.required],
            last_working_day: [terminationDate || '', Validators.required],
            new_start_date: ['', Validators.required],
            current_fiscal_year: ['', Validators.required],
            company: ['', Validators.required],
            provident_fund_name: ['', Validators.required]
        });
        if (this.candidate?.job_details?.reporting?.length) {
            const reporting = this.candidate.job_details.reporting;
            const hr_business_partner = reporting.find((r) => r.relationship === 'hr_business_partner');
            if (hr_business_partner) {
                this.form.get('hr_business_partner').patchValue(hr_business_partner.id);
                this.dropdownValueUpdated = true;
                setTimeout(() => {
                    this.dropdownValueUpdated = false;
                }, 100);
            }
        }

        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            console.log(users);
            this.users = users;
            users.sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            });
            this.usersOptions = users
                // .filter(
                //     (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                // )
                .filter((u) => u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
        });
    }

    onHideModal() {
        this.onClose.emit();
    }

    onAddDetails() {
        console.log(this.form.value, this.form.valid, this.form);
        if (!this.form.get('annual_leave_transfer').valid && !this.form.get('severance_pay').valid) {
            return;
        }
        this.view = 'details';
    }

    onNext() {
        console.log('onNext', this.form.value, this.form);
        const fields = ['full_name'];

        for (let field of fields) {
            if (!this.form.get(field).valid) {
                const group = this.form.get(field) as FormGroup;
                group.markAsTouched();
                return;
            }
        }
        this.view = 'compensation';
    }

    onBack() {
        if (this.view === 'details') {
            this.view = 'default';
        } else if (this.view === 'preview') {
            this.view = 'details';
        }
    }

    onHideContent(event) {
        (event.target as HTMLInputElement).closest('.preview-block').children[1].classList.toggle('hide');
    }

    getUserName(id) {
        const user = this.users.find((u) => u.id === id);
        if (user) {
            return user.full_name;
        } else {
            return id;
        }
    }

    onPreview() {
        // console.log('onPreview', this.form.value);
        if (!this.form.valid) {
            this.formHelperService.markFormGroupTouched(this.form);
            return;
        }
        this.view = 'preview';
    }

    onSend() {
        console.log('onSend', this.form.value);
        if (!this.form.valid) {
            this.formHelperService.markFormGroupTouched(this.form);
            return;
        }
        const data = this.prepareFormBeforeSend();
        this.contentLoading = true;
        this.employeeService.employeeTerminationApproval(this.candidate.id, data).subscribe(
            (response: any) => {
                console.log('employeeOfferApproval response', response);
                this.onApproval.emit({ candidateId: this.candidate.id });
                this.onClose.emit();
                this.contentLoading = false;
            },
            (err) => {
                console.log(err.error);
                this.toaster.error(err.error.message);
                this.contentLoading = false;
            }
        );
    }

    onPreviewContract() {
        const data = this.prepareFormBeforeSend();
        this.contentLoading = true;
        this.employeeService.employeeTerminationApproval(this.candidate.id, data, 'preview').subscribe(
            (res: any) => {
                console.log('employeeOfferAccepted', res);
                this.previewContractLink = res.previewContractLink;
                this.contentLoading = false;
            },
            (err) => {
                console.log(err);
                this.toaster.error('Preview Contract Error');
                this.contentLoading = false;
            }
        );
    }

    prepareFormBeforeSend() {
        const data = { ...this.form.value };
        data.meeting_date = moment(this.form.value.meeting_date).format('DD-MM-YYYY');
        data.previous_start_date = moment(this.form.value.previous_start_date).format('DD-MM-YYYY');
        data.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        data.last_working_day = moment(this.form.value.last_working_day).format('DD-MM-YYYY');
        data.new_start_date = moment(this.form.value.new_start_date).format('DD-MM-YYYY');
        return data;
    }

    onHideOfferPreview() {
        this.previewContractLink = null;
    }

    onFinish() {
        this.onClose.emit();
    }
}
