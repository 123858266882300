import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { User } from './../../../../../../core/models/user';
import { MsService } from './../../../../../../core/services/ms.service';

@Component({
    selector: 'app-interview-select-service',
    templateUrl: './interview-select-service.component.html',
    styleUrls: ['./interview-select-service.component.scss']
})
export class InterviewSelectServiceComponent implements OnInit, OnDestroy {
    @Output() selectService: EventEmitter<any> = new EventEmitter();
    @Input() user: User;
    nextEnabled = false;

    isMsalAuthenticated: Subscription;

    servicesList = [
        // {
        //     id: 'google',
        //     icon: '/assets/images/services-icons/google-calendar.png',
        //     title: 'Google Calendar',
        //     enabled: false
        // },
        {
            id: 'office',
            icon: '/assets/images/services-icons/office.png',
            title: 'Connect your Office 365 Calendar',
            enabled: true
        }
        // {
        //     id: 'exchange',
        //     icon: '/assets/images/services-icons/microsoft-exchange.png',
        //     title: 'Microsoft Exchange',
        //     enabled: false
        // }
    ];

    activeService;

    constructor(
        private msalService: MsService,
        private msal: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {}

    ngOnInit(): void {
        // this.msalService.logout();
    }

    selectScheduleService(service) {
        this.activeService = service;
        if (service === 'office') {
            // console.log('Redirect URL:', environment.msalRedirectUrl);
            this.isMsalAuthenticated = this.msalService.$isAuthenticated.subscribe((isAuthenticated) => {
                console.log('MSAL authenticated status:', isAuthenticated);
                this.nextEnabled = isAuthenticated;
                console.log('Next enabled:', this.nextEnabled);
                if (this.nextEnabled) {
                    this.goToNextStep();
                }

                if (!isAuthenticated) {
                    console.log('Sign in with popup');
                    this.msalService.authenticate(this.user);
                }
            });
        } else {
            console.log('Some other service');
            this.nextEnabled = true;
        }
    }

    goToNextStep() {
        this.selectService.emit(this.activeService);
    }

    ngOnDestroy(): void {
        if (this.isMsalAuthenticated) {
            this.isMsalAuthenticated.unsubscribe();
        }
    }
}
