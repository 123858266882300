import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/models';
import { EmployeesService } from '@app/core/services/employees.service';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { Store, select } from '@ngrx/store';
import * as fromSelectors from './../../../../../../../store/selectors';

import moment from 'moment';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import * as fromStore from '@app/store';

@Component({
    selector: 'app-termination-modal',
    templateUrl: './termination-modal.component.html',
    styleUrls: ['./termination-modal.component.scss']
})
export class TerminationModalComponent implements OnInit {
    @Input() workflows;
    @Input() employee;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    contentLoading = false;
    visible = false;
    form: FormGroup;
    terminationTypeOptions = [
        { value: 'resignation', label: 'Resignation' },
        { value: 'dismissal', label: 'Dismissal' },
        { value: 'end_of_contract', label: 'End of Contract' },
        { value: 'misconduct', label: 'Misconduct' },
        { value: 'mutual_separation', label: 'Mutual Separation' },
        { value: 'operational_requirements', label: 'Operational Requirements' },
        { value: 'performance', label: 'Performance' },
        { value: 'section_189', label: 'Section 189' },
        { value: 'section_197', label: 'Section 197' }
    ];
    workflowTypeOptions = [];
    dropdownValueUpdated = false;
    usersOptions: SelectItem[] = [];
    usersSubscription: Subscription;
    users: User[];
    constructor(
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private employeeService: EmployeesService,
        private store: Store<fromStore.State>
    ) {}

    ngOnInit(): void {
        this.workflowTypeOptions = this.workflows;
        this.visible = true;
        let date = moment().format();
        if (this.employee.termination_date) {
            date = moment(this.employee.termination_date, 'DD-MM-YYYY').format();
        }
        this.form = this.fb.group({
            termination_category: [this.employee.termination_category || '', Validators.required],
            termination_date: [date, Validators.required],
            employee_id: [this.employee.employee_id || '', Validators.required],
            hr_business_partner: ['', Validators.required],
            send_workflow: [false]
        });
        const reporting = this.employee.job_details?.reporting || [];
        const hr_business_partner = reporting.find((r) => r.relationship === 'hr_business_partner');
        if (hr_business_partner) {
            this.form.get('hr_business_partner').patchValue(hr_business_partner.id);
        }
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            this.users.sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            });
            this.usersOptions = this.users
                // .filter(
                //     (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                // )
                .filter((u) => u.email !== this.employee.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
            this.dropdownValueUpdated = false;
            setTimeout(() => {
                this.dropdownValueUpdated = true;
            }, 100);
        });
    }

    onHideModal() {
        this.visible = false;
        this.onClose.emit();
    }

    async onFinish() {
        console.log('form values', this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            return false;
        }
        this.form.value.termination_date = moment(this.form.get('termination_date').value).format('DD-MM-YYYY');
        this.contentLoading = true;
        await this.employeeService.updateEmployee(this.employee.id, {
            termination_category: this.form.value.termination_category,
            termination_date: this.form.value.termination_date,
            employee_id: this.form.value.employee_id,
            hr_business_partner: this.form.value.hr_business_partner
        });
        const reporting = this.employee.job_details?.reporting || [];
        const hr_business_partner = reporting.find((r) => r.relationship === 'hr_business_partner');
        if (!hr_business_partner || this.form.value.hr_business_partner !== hr_business_partner.id) {
            const usr: any = this.users.find((u) => u.id === this.form.value.hr_business_partner);
            if (hr_business_partner) {
                hr_business_partner.first_name = usr.first_name;
                hr_business_partner.last_name = usr.last_name;
                hr_business_partner.user_color = usr.user_color || '#ffd8be';
                hr_business_partner.id = usr.id;
                hr_business_partner.contact = usr.id;
                hr_business_partner.email = usr.email;
                hr_business_partner.relationship = 'hr_business_partner';
            } else {
                reporting.push({
                    first_name: usr.first_name,
                    last_name: usr.last_name,
                    user_color: usr.user_color || '#ffd8be',
                    id: usr.id,
                    contact: usr.id,
                    email: usr.email,
                    relationship: 'hr_business_partner'
                });
            }
            // console.log('report', reporting);
            this.employeeService.updateJobDetails(this.employee.id, { reporting });
        }

        this.form.value.reporting = reporting;

        if (this.form.value.workflow_id) {
            this.employeeService.addToWorkflow(this.employee.id, this.form.value.workflow_id).subscribe(
                () => {
                    this.onChange.emit(this.form.value);
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
        } else {
            this.onChange.emit(this.form.value);
        }
    }

    onCheckboxChange(e) {
        if (e.checked) {
            this.form.addControl('workflow_id', new FormControl('', Validators.required));
        } else {
            this.form.removeControl('workflow_id');
        }
    }
}
