<div class="left-col">
    <div class="inner">
        <pdf-viewer
            *ngIf="file"
            [src]="file.link"
            [render-text]="true"
            [original-size]="false"
            [autoresize]="true"
            style="display: block; max-width: 100%"
        >
        </pdf-viewer>
    </div>
</div>
<div class="right-col">
    <div class="inner">
        <div class="wrapper mb20">
            <h4></h4>
            <button class="close close-modal-btn" (click)="onClose()">
                <img src="/assets/images/icons/icons-8-delete.svg" />
            </button>
        </div>
        <a class="file-block-frame mb20" [href]="file.link" [title]="file.name"
            ><span class="file-icon">
                <img
                    *ngIf="file.name"
                    src="/assets/images/icons/files-icon-{{ utilitiesService.getExtension(file.name) }}.svg"
                    alt=""
                />
            </span>
            <div class="file-block-holder">
                <span class="title">
                    <strong>{{ file.original_name }}</strong>
                    <span class="thin" *ngIf="file.size">{{ utilitiesService.convertSize(file.size) }}</span>
                </span>
                <div class="label-row">
                    <span class="thin ml0" *ngIf="file.uploaded_at">{{ file.uploaded_at | date: 'd MMMM, y' }}</span>
                    <span class="document-status">
                        <div class="status-text success" *ngIf="file.status === 'approved'">
                            Approved
                        </div>
                        <div class="status-text error" *ngIf="file.status === 'rejected'">
                            Rejected
                        </div>
                        <div class="status-text warning" *ngIf="!file.status || file.status === 'pending'">
                            Pending
                        </div>
                    </span>
                </div>
            </div>
        </a>
        <div class="wrapper mb20" *ngIf="file.status !== 'rejected' && file.status !== 'approved'">
            <button class="btn btn-approve" (click)="onApproveDocument()">Approve Document</button>
            <button
                [disabled]="file?.action_type === 'multiple-signers'"
                class="btn btn-reject"
                (click)="onRejectDocument()"
            >
                Reject Document
            </button>
        </div>
        <div class="input-block" [formGroup]="form" *ngIf="showReason && file.status !== 'rejected'">
            <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <textarea
                    matInput
                    formControlName="reason"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="10"
                    resize="vertical"
                    (keyup.enter)="keyupReason($event)"
                ></textarea>
            </mat-form-field>
            <p>Type your reason and press enter. Please note that this reason is also sent to the employee.</p>
        </div>
        <div class="versions-block" *ngIf="file?.versions?.length">
            <h5>Previous Versions</h5>
            <a *ngFor="let item of file.versions" class="versions-file" [href]="item.link" [title]="item.name">
                <img
                    *ngIf="item.name"
                    src="/assets/images/icons/files-icon-{{ utilitiesService.getExtension(item.name) }}.svg"
                    alt=""
                />
                <span>{{ item.uploaded_at | date: 'd MMMM, y' }}</span>
            </a>
        </div>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>

<app-document-send-to-provider
    *ngIf="approveDocumentModal.show"
    (close)="onCloseModal()"
    (updated)="onDocumentUpdated($event)"
    [data]="approveDocumentModal"
>
</app-document-send-to-provider>
