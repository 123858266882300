<app-loader *ngIf="isFormLoading || contentLoading"></app-loader>
<button class="close-modal-btn" (click)="closeModal()">
    <img src="/assets/images/icons/icons-8-delete.svg" />
</button>
<div class="container fixed-container" [ngClass]="{ scrollable: !isFormLoading }">
    <div class="title-block">
        <div class="title-block-inner">
            <div class="title-actions">
                <h1 class="page-title">
                    <svg-icon src="/assets/images/icon-ampersand.svg"></svg-icon>
                    <span>
                        Edit Employee
                    </span>
                </h1>
                <div class="buttons-title" *ngIf="activeSection !== 'files'">
                    <button class="btn btn-primary mla" (click)="onSaveAll($event)">
                        Save
                    </button>
                </div>
            </div>
            <div class="sections-nav">
                <div
                    class="section-nav-link"
                    (click)="onChangeSection('job')"
                    [ngClass]="{ active: activeSection === 'job' }"
                >
                    Job
                </div>
                <div
                    class="section-nav-link"
                    (click)="onChangeSection('personal')"
                    [ngClass]="{ active: activeSection === 'personal' }"
                >
                    Personal
                </div>
                <div
                    class="section-nav-link"
                    (click)="onChangeSection('employment')"
                    [ngClass]="{ active: activeSection === 'employment' }"
                >
                    Employment & Education
                </div>
                <div
                    *ngIf="secureNavLinksVisible"
                    class="section-nav-link"
                    (click)="onChangeSection('compensation')"
                    [ngClass]="{ active: activeSection === 'compensation' }"
                >
                    Compensation
                </div>
                <div
                    *ngIf="secureNavLinksVisible"
                    class="section-nav-link"
                    (click)="onChangeSection('time_off')"
                    [ngClass]="{ active: activeSection === 'time_off' }"
                >
                    Time Off
                </div>
                <!-- <div
                    class="section-nav-link"
                    (click)="onChangeSection('files')"
                    [ngClass]="{ active: activeSection === 'files' }"
                >
                    Files
                </div> -->
            </div>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="content-section" id="job" *ngIf="activeSection === 'job'">
            <div class="form-content-section" [formGroup]="jobForm">
                <div class="form-content-holder">
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">General Information</h3>
                    </div>
                    <div class="form-block" formGroupName="personal">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input type="text" autocomplete="off" formControlName="first_name" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input type="text" autocomplete="off" formControlName="last_name" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Official Email Address</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="work_email"
                                            (input)="terms$.next($event.target.value)"
                                            matInput
                                        />
                                        <mat-error
                                            class="error-message"
                                            *ngIf="
                                                jobForm.get('personal').get('work_email').errors?.incorrect &&
                                                jobForm.get('personal').get('work_email').touched
                                            "
                                        >
                                            Duplicate Email Address
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Personal Email Address</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="personal_email"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block mb30">
                                    <label class="form-block-label pse-mat-label">Mobile Number</label>
                                    <app-input-phone [country]="initialCountry" formControlName="phone">
                                    </app-input-phone>
                                    <mat-error
                                        class="error-message"
                                        *ngIf="jobForm.get('personal').get('phone').errors?.incorrect_number"
                                    >
                                        Mobile Number is invalid
                                    </mat-error>
                                    <mat-error
                                        class="error-message"
                                        *ngIf="jobForm.get('personal').get('phone').errors?.phoneIsUniqueValidator"
                                    >
                                        User with this phone number is already exists
                                    </mat-error>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col"></div>
                        </div>
                    </div>
                    <div class="section-separator mb40 mt15"></div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Work Information</h3>
                    </div>
                    <div class="form-block">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Employee ID</mat-label>
                                        <input type="text" formControlName="employee_id" matInput autocomplete="off" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="employeeStatusOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.status"
                                        placeholder="Employee Status"
                                        [ngClass]="{
                                            invalid: !jobForm.controls.status.valid && jobForm.controls.status.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline" (click)="picker1.open()">
                                        <mat-label>Date of Joining</mat-label>
                                        <input
                                            formControlName="start_date"
                                            matInput
                                            [matDatepicker]="picker1"
                                            autocomplete="off"
                                        />
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error
                                            *ngIf="
                                                !jobForm.controls.start_date.valid &&
                                                jobForm.controls.start_date.touched
                                            "
                                        >
                                            Date of Joining is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="jobTypeOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.type"
                                        placeholder="Employee Type"
                                        [ngClass]="{
                                            invalid: !jobForm.controls.type.valid && jobForm.controls.type.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <ng-container *ngIf="jobDepartmentOptions.length">
                                        <app-auto-dropdown
                                            [options]="jobDepartmentOptions"
                                            [newStyle]="true"
                                            [control]="jobForm.controls.department"
                                            placeholder="Department"
                                            [ngClass]="{
                                                invalid:
                                                    !jobForm.controls.department.valid &&
                                                    jobForm.controls.department.touched
                                            }"
                                        >
                                        </app-auto-dropdown>
                                    </ng-container>
                                    <div class="input-block" *ngIf="!jobDepartmentOptions.length">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Department</mat-label>
                                            <input type="text" formControlName="department" matInput />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Designation or Title</mat-label>
                                        <input type="text" formControlName="designation" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="jobBusinessUnitOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.business_unit"
                                        placeholder="Business Unit"
                                        [ngClass]="{
                                            invalid:
                                                !jobForm.controls.business_unit.valid &&
                                                jobForm.controls.business_unit.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <!-- <mat-form-field appearance="outline">
                                        <mat-label>Business Unit</mat-label>
                                        <input type="text" formControlName="business_unit" matInput />
                                    </mat-form-field> -->
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block" *ngIf="jobLocationOptions.length">
                                    <app-auto-dropdown
                                        [options]="jobLocationOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.job_location"
                                        placeholder="Office Location"
                                        [ngClass]="{
                                            invalid:
                                                !jobForm.controls.job_location.valid &&
                                                jobForm.controls.job_location.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <!-- <app-auto-dropdown
                                        [options]="jobSubDepartmentOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.sub_department"
                                        placeholder="Sub Department"
                                        [ngClass]="{
                                            invalid:
                                                !jobForm.controls.sub_department.valid &&
                                                jobForm.controls.sub_department.touched
                                        }"
                                    >
                                    </app-auto-dropdown> -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>Sub Department</mat-label>
                                        <input type="text" formControlName="sub_department" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <!-- <app-auto-dropdown
                                        [options]="jobPrimaryTeamOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.primary_team"
                                        placeholder="Primary Team"
                                        [ngClass]="{
                                            invalid:
                                                !jobForm.controls.primary_team.valid &&
                                                jobForm.controls.primary_team.touched
                                        }"
                                    >
                                    </app-auto-dropdown> -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>Primary Team</mat-label>
                                        <input type="text" formControlName="primary_team" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Additional Teams</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="additional_teams"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="jobLevelOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.level"
                                        placeholder="Level"
                                        [ngClass]="{
                                            invalid: !jobForm.controls.level.valid && jobForm.controls.level.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="jobCostCentrOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.cost_centr"
                                        placeholder="Cost Centre"
                                        [ngClass]="{
                                            invalid:
                                                !jobForm.controls.cost_centr.valid &&
                                                jobForm.controls.cost_centr.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="userTypesOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.role"
                                        [disabled]="user.role !== 'account_owner' && user.role !== 'admin'"
                                        placeholder="Role"
                                        [ngClass]="{
                                            invalid: !jobForm.controls.role.valid && jobForm.controls.role.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-separator mb40 mt15"></div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Additional Work Information</h3>
                    </div>
                    <div class="form-block">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="jobShiftOptions"
                                        [newStyle]="true"
                                        [control]="jobForm.controls.shift"
                                        placeholder="Shift"
                                        [ngClass]="{
                                            invalid: !jobForm.controls.shift.valid && jobForm.controls.shift.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>ID Card Number</mat-label>
                                        <input type="text" autocomplete="off" formControlName="id_card" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline" (click)="picker2.open()">
                                        <mat-label>Probation Start Date</mat-label>
                                        <input
                                            formControlName="probation_start_date"
                                            matInput
                                            [matDatepicker]="picker2"
                                            autocomplete="off"
                                        />
                                        <mat-datepicker #picker2></mat-datepicker>
                                        <mat-error
                                            *ngIf="
                                                !jobForm.controls.probation_start_date.valid &&
                                                jobForm.controls.probation_start_date.touched
                                            "
                                        >
                                            Probation Start Date</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline" (click)="picker3.open()">
                                        <mat-label>Probation End Date</mat-label>
                                        <input
                                            formControlName="probation_end_date"
                                            matInput
                                            [matDatepicker]="picker3"
                                            autocomplete="off"
                                        />
                                        <mat-datepicker #picker3></mat-datepicker>
                                        <mat-error
                                            *ngIf="
                                                !jobForm.controls.probation_end_date.valid &&
                                                jobForm.controls.probation_end_date.touched
                                            "
                                        >
                                            Probation End Date</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Previous Employer</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="previous_company_name"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline" (click)="picker7.open()">
                                        <mat-label>Previous Start Date</mat-label>
                                        <input
                                            formControlName="previous_start_date"
                                            matInput
                                            [matDatepicker]="picker7"
                                            autocomplete="off"
                                        />
                                        <mat-datepicker #picker7></mat-datepicker>
                                        <mat-error
                                            *ngIf="
                                                !jobForm.controls.previous_start_date.valid &&
                                                jobForm.controls.previous_start_date.touched
                                            "
                                        >
                                            Previous Start Date</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-separator mb40 mt15"></div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Reporting Information</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="reporting">
                            <p-accordion
                                *ngFor="let report of jobForm.get('reporting')['controls']; let i = index"
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="reportInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="report.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!report.valid && report.get('contact').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                                <div *ngIf="!report.valid && !report.get('contact').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="report.get('contact').value">
                                                        {{ report.get('first_name').value | titlecase }}
                                                        {{ report.get('last_name').value | titlecase }}
                                                    </h4>
                                                    <h4 class="position" *ngIf="!report.get('contact').value">
                                                        New Report
                                                    </h4>
                                                </div>
                                                <div class="second-row" *ngIf="report.get('relationship').value">
                                                    {{
                                                        utilitiesService.transformRoleToText(
                                                            report.get('relationship').value
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <div class="dropdown-container w-50 pr15">
                                            <app-auto-dropdown
                                                appearance="outline"
                                                *ngIf="contactsOptions?.length"
                                                [options]="contactsOptions"
                                                [newStyle]="true"
                                                [control]="jobForm.get('reporting').controls[i].controls.contact"
                                                placeholder="Contact"
                                                (onChange)="changeContactForm($event, i)"
                                                [ngClass]="{
                                                    invalid:
                                                        report.controls.contact.touched &&
                                                        !report.controls.contact.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    report.controls.contact.touched && !report.controls.contact.valid
                                                "
                                            >
                                                Contact is required
                                            </div>
                                        </div>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="relationshipOptions"
                                                [newStyle]="true"
                                                [control]="jobForm.get('reporting').controls[i].controls.relationship"
                                                placeholder="Relationship"
                                                [ngClass]="{
                                                    invalid:
                                                        jobForm.get('reporting').controls[i].controls.relationship
                                                            .touched &&
                                                        !jobForm.get('reporting').controls[i].controls.relationship
                                                            .valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    jobForm.get('reporting').controls[i].controls.relationship
                                                        .touched &&
                                                    !jobForm.get('reporting').controls[i].controls.relationship.valid
                                                "
                                            >
                                                Relationship is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onReportingSectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button
                            class="add-section-button"
                            (click)="onSectionAdd()"
                            *ngIf="jobForm.get('reporting')['controls']?.length < 2"
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Report</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section" id="personal" *ngIf="activeSection === 'personal'">
            <div class="form-content-section" [formGroup]="personalForm">
                <div class="form-content-holder">
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Profile Information</h3>
                    </div>
                    <div class="form-block">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="profile-holder">
                                    <div class="profile-image-block">
                                        <label for="image">
                                            <div class="profile-icon">
                                                <ng-container *ngIf="!personalForm.get('profile_image').value">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="47"
                                                        height="48"
                                                        viewBox="0 0 71 72"
                                                    >
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M-12 0h84v71h-84z" />
                                                            <path
                                                                fill="#71BF43"
                                                                fill-rule="nonzero"
                                                                d="M56.13 42.458c7.949 0 14.392 6.444 14.392 14.392 0 7.95-6.443 14.393-14.392 14.393S41.737 64.799 41.737 56.85c0-7.948 6.444-14.392 14.393-14.392zm-.071 7.196c-1.223 0-2.159.936-2.159 2.16v2.877H51.02c-1.223 0-2.158.936-2.158 2.16 0 1.223.935 2.158 2.158 2.158H53.9v2.879c0 1.223.936 2.159 2.159 2.159 1.223 0 2.159-.936 2.159-2.16V59.01h2.878c1.224 0 2.16-.935 2.16-2.159 0-1.223-.936-2.158-2.16-2.158h-2.878v-2.879c0-1.223-.936-2.159-2.16-2.159z"
                                                            />
                                                            <path
                                                                fill="#444B54"
                                                                fill-rule="nonzero"
                                                                d="M4.678 57.642a2.147 2.147 0 0 1-1.871 1.08c-.36 0-.72-.072-1.08-.288C.72 57.858.36 56.563.936 55.484 6.764 45.263 17.703 38.86 29.505 38.86c3.958 0 7.772.72 11.442 2.015 1.151.432 1.655 1.655 1.295 2.806-.36.864-1.223 1.44-2.087 1.44-.216 0-.504-.072-.72-.144-3.166-1.152-6.476-1.8-9.93-1.8-10.219 0-19.718 5.542-24.827 14.465zM29.551 33.103C20.412 33.103 13 25.69 13 16.55 13 7.412 20.412 0 29.551 0c9.14 0 16.552 7.412 16.552 16.551 0 9.14-7.412 16.552-16.552 16.552zm0-28.785A12.223 12.223 0 0 0 17.318 16.55c0 6.765 5.469 12.234 12.233 12.234 6.765 0 12.234-5.47 12.234-12.234S36.315 4.318 29.551 4.318z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </ng-container>
                                                <input
                                                    #image
                                                    type="file"
                                                    id="image"
                                                    accept="image/*"
                                                    (change)="onUploadProfileImage(image.files)"
                                                />
                                            </div>
                                            <div
                                                class="img-bg"
                                                *ngIf="personalForm.get('profile_image').value"
                                                [ngStyle]="{
                                                    'background-image':
                                                        'url(' + personalForm.get('profile_image').value + ')'
                                                }"
                                            ></div>
                                            <!-- <img
                                                [src]="sanitizeLink(personalForm.get('profile_image').value)"
                                                class="img-uploaded"
                                                height="200"
                                                *ngIf="personalForm.get('profile_image').value"
                                            /> -->
                                            <input formControlName="profile_image" type="hidden" />
                                        </label>
                                    </div>

                                    <div class="profile-text">
                                        <strong>Profile Image</strong>
                                        <p>
                                            I have consent to upload and use this profile image on behalf of the
                                            employee
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input type="text" autocomplete="off" formControlName="first_name" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input type="text" autocomplete="off" formControlName="last_name" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Personal Information</h3>
                    </div>
                    <div class="form-block">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="gendersOptions"
                                        [newStyle]="true"
                                        [control]="personalForm.controls.gender"
                                        placeholder="Gender"
                                        [ngClass]="{
                                            invalid:
                                                !personalForm.controls.gender.valid &&
                                                personalForm.controls.gender.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="ethnicityOptions"
                                        [newStyle]="true"
                                        [control]="personalForm.controls.ethnicity"
                                        placeholder="Ethnicity"
                                        [ngClass]="{
                                            invalid:
                                                !personalForm.controls.ethnicity.valid &&
                                                personalForm.controls.ethnicity.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline" (click)="picker4.open()">
                                        <mat-label>Date of Birth</mat-label>
                                        <input
                                            formControlName="date_of_birth"
                                            matInput
                                            [matDatepicker]="picker4"
                                            autocomplete="off"
                                        />
                                        <mat-datepicker #picker4></mat-datepicker>
                                        <mat-error
                                            *ngIf="
                                                !personalForm.controls.date_of_birth.valid &&
                                                personalForm.controls.date_of_birth.touched
                                            "
                                        >
                                            Date of Birth is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="maritalStatusOptions"
                                        [control]="personalForm.controls.marital_status"
                                        placeholder="Marital Status"
                                    >
                                    </app-auto-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="bloodGroupOptions"
                                    [control]="personalForm.controls.blood_group"
                                    placeholder="Blood Group"
                                >
                                </app-auto-dropdown>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-ac-chips-material
                                        *ngIf="languagesOptions.length"
                                        formControlName="languages"
                                        [suggestions]="languagesOptions"
                                        title="Languages Spoken"
                                        key="id"
                                        (onValueChange)="onLanguagesAdded($event)"
                                    ></app-ac-chips-material>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Identity Number</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="identity_number"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col"></div>
                        </div>
                        <div class="section-separator mb40 mt15"></div>
                        <div class="form-block-info">
                            <h3 class="form-block-info__title">Personal Contact Information</h3>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <h5>Residential Address</h5>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col residential-col">
                                <h5>Postal Address</h5>
                                <p-checkbox
                                    binary="true"
                                    label="Same as Residential"
                                    formControlName="same_as_residential"
                                    (onChange)="onCheckboxChange($event)"
                                >
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col" formGroupName="residential_address">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Street</mat-label>
                                        <input type="text" autocomplete="off" formControlName="street" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>City</mat-label>
                                        <input type="text" autocomplete="off" formControlName="city" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State / Province</mat-label>
                                        <input type="text" autocomplete="off" formControlName="state" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [newStyle]="true"
                                        [options]="countriesOptions"
                                        [control]="personalForm.get('residential_address').controls.country"
                                        placeholder="Country"
                                        [ngClass]="{
                                            invalid:
                                                !personalForm.get('residential_address').controls.country.valid &&
                                                personalForm.get('residential_address').controls.country.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Zip / Postal Code</mat-label>
                                        <input type="text" autocomplete="off" formControlName="code" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col" formGroupName="postal_address">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Street</mat-label>
                                        <input type="text" autocomplete="off" formControlName="street" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>City</mat-label>
                                        <input type="text" autocomplete="off" formControlName="city" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State / Province</mat-label>
                                        <input type="text" autocomplete="off" formControlName="state" matInput />
                                    </mat-form-field>
                                </div>
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [disabled]="personalForm.get('postal_address').controls.country.disabled"
                                        [newStyle]="true"
                                        [options]="countriesOptions"
                                        [control]="personalForm.get('postal_address').controls.country"
                                        placeholder="Country"
                                        [ngClass]="{
                                            invalid:
                                                !personalForm.get('postal_address').controls.country.valid &&
                                                personalForm.get('postal_address').controls.country.touched
                                        }"
                                    >
                                    </app-auto-dropdown>
                                </div>
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Zip / Postal Code</mat-label>
                                        <input type="text" autocomplete="off" formControlName="code" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="section-separator mb40 mt15"></div>
                        <div class="form-block-info">
                            <h3 class="form-block-info__title">Dependent Information</h3>
                        </div>
                        <div class="form-block">
                            <div class="accordion-array" formArrayName="dependents">
                                <p-accordion
                                    *ngFor="let item of personalForm.get('dependents')['controls']; let i = index"
                                    [formGroupName]="i"
                                >
                                    <p-accordionTab class="section-wrap" [selected]="dependentsInvalid[i]">
                                        <p-header>
                                            <div class="flex-row">
                                                <div class="mr10">
                                                    <div *ngIf="item.valid" class="success-icon-wrap">
                                                        <img
                                                            class="success-icon"
                                                            src="/assets/images/icons/check.svg"
                                                            alt=""
                                                            width="10"
                                                            height="10"
                                                        />
                                                    </div>
                                                    <div *ngIf="!item.valid && item.get('full_name').value">
                                                        <img
                                                            class="success-icon"
                                                            src="/assets/images/icons/warning.svg"
                                                            alt=""
                                                            width="15"
                                                            height="15"
                                                        />
                                                    </div>
                                                    <div *ngIf="!item.valid && !item.get('full_name').value">
                                                        <div class="grey-circle"></div>
                                                    </div>
                                                </div>
                                                <div class="accordion-header">
                                                    <div class="accordion-header-title">
                                                        <h4 class="position" *ngIf="item.get('full_name').value">
                                                            {{ item.get('full_name').value | titlecase }}
                                                        </h4>
                                                        <span *ngIf="item.get('relationship').value">
                                                            - {{ item.get('relationship').value | titlecase }}</span
                                                        >
                                                        <h4 class="position" *ngIf="!item.get('full_name').value">
                                                            New Dependent
                                                        </h4>
                                                    </div>
                                                    <div *ngIf="item.get('date_of_birth').value" class="date">
                                                        {{
                                                            item.get('date_of_birth').value
                                                                | amDateFormat: 'DD MMMM YYYY'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </p-header>
                                        <div class="flex-row">
                                            <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                                <mat-label>Full Name</mat-label>
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    formControlName="full_name"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="
                                                        item.controls.full_name.touched &&
                                                        !item.controls.full_name.valid
                                                    "
                                                >
                                                    Full Name is required
                                                </mat-error>
                                            </mat-form-field>
                                            <div class="dropdown-container w-50 pl15">
                                                <app-auto-dropdown
                                                    [options]="membershipOptions"
                                                    [newStyle]="true"
                                                    [control]="item.controls.relationship"
                                                    placeholder="Relationship"
                                                    [ngClass]="{
                                                        invalid:
                                                            item.controls.relationship.touched &&
                                                            !item.controls.relationship.valid
                                                    }"
                                                >
                                                </app-auto-dropdown>
                                                <div
                                                    class="error-message"
                                                    *ngIf="
                                                        item.controls.relationship.touched &&
                                                        !item.controls.relationship.valid
                                                    "
                                                >
                                                    Relationship is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-row">
                                            <div class="dropdown-container w-50 pr15">
                                                <app-auto-dropdown
                                                    [options]="gendersOptions"
                                                    [newStyle]="true"
                                                    [control]="item.controls.gender"
                                                    placeholder="Gender"
                                                    [ngClass]="{
                                                        invalid:
                                                            item.controls.gender.touched && !item.controls.gender.valid
                                                    }"
                                                >
                                                </app-auto-dropdown>
                                                <div
                                                    class="error-message"
                                                    *ngIf="item.controls.gender.touched && !item.controls.gender.valid"
                                                >
                                                    Gender is required
                                                </div>
                                            </div>
                                            <mat-form-field
                                                class="dropdown-container w-50 pl15"
                                                appearance="outline"
                                                (click)="picker5.open()"
                                            >
                                                <mat-label>Date of Birth</mat-label>
                                                <input
                                                    formControlName="date_of_birth"
                                                    matInput
                                                    [matDatepicker]="picker5"
                                                    autocomplete="off"
                                                />
                                                <mat-datepicker #picker5></mat-datepicker>
                                                <mat-error
                                                    *ngIf="
                                                        !item.controls.date_of_birth.valid &&
                                                        item.controls.date_of_birth.touched
                                                    "
                                                >
                                                    Date of Birth is required</mat-error
                                                >
                                            </mat-form-field>
                                        </div>
                                        <div class="close-icon-wrap">
                                            <span class="close-icon">
                                                <svg-icon
                                                    [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                    src="/assets/images/icons/icons-8-trash-can.svg"
                                                    (click)="onDependentSectionRemove(i)"
                                                ></svg-icon>
                                            </span>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                            <button class="add-section-button" (click)="onSectionAddDependent()">
                                <img src="/assets/images/plus-icon.svg" alt="" />
                                <span>Add Dependent</span>
                            </button>
                        </div>
                        <div class="section-separator mb30 mt40"></div>
                        <div class="form-block-info">
                            <h3 class="form-block-info__title">Emergency Contact</h3>
                        </div>
                        <div class="form-block">
                            <div class="accordion-array" formArrayName="emergency_contacts">
                                <p-accordion
                                    *ngFor="
                                        let item of personalForm.get('emergency_contacts')['controls'];
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                >
                                    <p-accordionTab class="section-wrap" [selected]="emergencyContactsInvalid[i]">
                                        <p-header>
                                            <div class="flex-row">
                                                <div class="mr10">
                                                    <div *ngIf="item.valid" class="success-icon-wrap">
                                                        <img
                                                            class="success-icon"
                                                            src="/assets/images/icons/check.svg"
                                                            alt=""
                                                            width="10"
                                                            height="10"
                                                        />
                                                    </div>
                                                    <div *ngIf="!item.valid && item.get('full_name').value">
                                                        <img
                                                            class="success-icon"
                                                            src="/assets/images/icons/warning.svg"
                                                            alt=""
                                                            width="15"
                                                            height="15"
                                                        />
                                                    </div>
                                                    <div *ngIf="!item.valid && !item.get('full_name').value">
                                                        <div class="grey-circle"></div>
                                                    </div>
                                                </div>
                                                <div class="accordion-header">
                                                    <div class="accordion-header-title">
                                                        <h4 class="position" *ngIf="item.get('full_name').value">
                                                            {{ item.get('full_name').value | titlecase }}
                                                        </h4>
                                                        <span *ngIf="item.get('relationship').value">
                                                            - {{ item.get('relationship').value | titlecase }}</span
                                                        >
                                                        <h4 class="position" *ngIf="!item.get('full_name').value">
                                                            New Dependent
                                                        </h4>
                                                    </div>
                                                    <div *ngIf="item.get('phone').value" class="date">
                                                        {{ item.get('phone').value }}
                                                        <span *ngIf="item.get('email').value"
                                                            >| {{ item.get('email').value }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-header>
                                        <div class="flex-row">
                                            <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                                <mat-label>Full Name</mat-label>
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    formControlName="full_name"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="
                                                        item.controls.full_name.touched &&
                                                        !item.controls.full_name.valid
                                                    "
                                                >
                                                    Full Name is required
                                                </mat-error>
                                            </mat-form-field>
                                            <div class="dropdown-container w-50 pl15">
                                                <app-auto-dropdown
                                                    [options]="membershipOptions"
                                                    [newStyle]="true"
                                                    [control]="item.controls.relationship"
                                                    placeholder="Relationship"
                                                    [ngClass]="{
                                                        invalid:
                                                            item.controls.relationship.touched &&
                                                            !item.controls.relationship.valid
                                                    }"
                                                >
                                                </app-auto-dropdown>
                                                <div
                                                    class="error-message"
                                                    *ngIf="
                                                        item.controls.relationship.touched &&
                                                        !item.controls.relationship.valid
                                                    "
                                                >
                                                    Relationship is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-row">
                                            <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                                <mat-label>Phone Number</mat-label>
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    formControlName="phone"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="item.controls.phone.touched && !item.controls.phone.valid"
                                                >
                                                    Phone Number is required
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                                <mat-label>Email Address</mat-label>
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    formControlName="email"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="item.controls.email.touched && !item.controls.email.valid"
                                                >
                                                    Email Address is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="close-icon-wrap">
                                            <span class="close-icon">
                                                <svg-icon
                                                    [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                    src="/assets/images/icons/icons-8-trash-can.svg"
                                                    (click)="onEmergencyContactSectionRemove(i)"
                                                ></svg-icon>
                                            </span>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                            <button class="add-section-button" (click)="onSectionAddEmergencyContact()">
                                <img src="/assets/images/plus-icon.svg" alt="" />
                                <span>Add Emergency Contact</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section" id="employment" *ngIf="activeSection === 'employment'">
            <div class="form-content-holder">
                <div class="form-block">
                    <app-candidate-resume [hideLanguagesBlock]="true" [form]="cv" [candidate]="employee">
                    </app-candidate-resume>
                </div>
            </div>
        </div>
        <div class="content-section" id="compensation" *ngIf="activeSection === 'compensation'">
            <div class="form-content-section" [formGroup]="compensationForm">
                <div class="form-content-holder">
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Salary</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="salary">
                            <p-accordion
                                *ngFor="
                                    let item of compensationForm.get('salary')['controls'];
                                    let i = index;
                                    let last = last
                                "
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="salaryInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="item.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!item.valid && !item.get('currency').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                                <div *ngIf="!item.valid && item.get('currency').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="item.get('currency').value">
                                                        {{ item.get('currency').value | uppercase }}
                                                        {{ item.get('salary').value | number: '.2-2' }} (Annualy)
                                                    </h4>
                                                    <h4 class="position" *ngIf="!item.get('currency').value">
                                                        Add Salary
                                                    </h4>
                                                </div>
                                                <div *ngIf="item.get('effective_date').value" class="date">
                                                    {{
                                                        item.get('effective_date').value | amDateFormat: 'DD MMMM YYYY'
                                                    }}
                                                    <ng-container *ngIf="last"
                                                        >-
                                                        <div class="current">
                                                            Current
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <mat-form-field
                                            class="dropdown-container w-50 pr15"
                                            appearance="outline"
                                            (click)="picker6.open()"
                                        >
                                            <mat-label>Effective Date</mat-label>
                                            <input
                                                formControlName="effective_date"
                                                matInput
                                                [matDatepicker]="picker6"
                                                autocomplete="off"
                                            />
                                            <mat-datepicker #picker6></mat-datepicker>
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.effective_date.valid &&
                                                    item.controls.effective_date.touched
                                                "
                                            >
                                                Effective Date is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="currencyOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.currency"
                                                placeholder="Currency"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.currency.touched && !item.controls.currency.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.currency.touched && !item.controls.currency.valid"
                                            >
                                                Currency is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row">
                                        <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                            <mat-label>Annual Salary</mat-label>
                                            <input type="number" autocomplete="off" formControlName="salary" matInput />
                                            <mat-error
                                                *ngIf="!item.controls.salary.valid && item.controls.salary.touched"
                                            >
                                                Annual Salary is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="reasonOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.reason"
                                                placeholder="Reason"
                                                [ngClass]="{
                                                    invalid: item.controls.reason.touched && !item.controls.reason.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.reason.touched && !item.controls.reason.valid"
                                            >
                                                Reason is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row mb20">
                                        <p-checkbox
                                            binary="true"
                                            label="Variable Pay / Commission"
                                            (onChange)="onCommissionCheckboxChange($event, i, item)"
                                            formControlName="variable_pay"
                                        >
                                        </p-checkbox>
                                    </div>
                                    <div *ngIf="item.get('commission')" formGroupName="commission">
                                        <div class="flex-row">
                                            <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                                <mat-label>Variable Pay Percentage (%)</mat-label>
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    formControlName="percentage"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="
                                                        item.controls.commission.controls.percentage.errors?.required &&
                                                        item.controls.commission.controls.percentage.touched
                                                    "
                                                >
                                                    Percentage or Amount is required
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="
                                                        item.controls.commission.controls.percentage.errors
                                                            ?.incorrect &&
                                                        item.controls.commission.controls.percentage.touched
                                                    "
                                                >
                                                    Incorrect value
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                                <mat-label>Variable Pay Amount</mat-label>
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    formControlName="amount"
                                                    matInput
                                                />
                                            </mat-form-field>
                                        </div>
                                        <div class="flex-row">
                                            <div class="dropdown-container w-50 pr15">
                                                <app-auto-dropdown
                                                    [options]="payScheduleOptions"
                                                    [newStyle]="true"
                                                    [control]="item.get('commission').controls.payout_frequency"
                                                    placeholder="Variable Pay Payout Frequency"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onSalarySectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button class="add-section-button" (click)="onAddSalarySection()">
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Salary</span>
                        </button>
                    </div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Bonuses</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="bonuses">
                            <p-accordion
                                *ngFor="let item of compensationForm.get('bonuses')['controls']; let i = index"
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="bonusesInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="item.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!item.valid && !item.get('currency').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                                <div *ngIf="!item.valid && item.get('currency').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="item.get('currency').value">
                                                        {{ item.get('currency').value | uppercase }}
                                                        {{ item.get('amount').value | number: '.2-2' }}
                                                        -
                                                        {{ item.get('type').value | titlecase }}
                                                    </h4>
                                                    <h4 class="position" *ngIf="!item.get('currency').value">
                                                        Add Bounus
                                                    </h4>
                                                </div>
                                                <div *ngIf="item.get('effective_date').value" class="date">
                                                    {{
                                                        item.get('effective_date').value | amDateFormat: 'DD MMMM YYYY'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <mat-form-field
                                            class="dropdown-container w-50 pr15"
                                            appearance="outline"
                                            (click)="picker6.open()"
                                        >
                                            <mat-label>Effective Date</mat-label>
                                            <input
                                                formControlName="effective_date"
                                                matInput
                                                [matDatepicker]="picker6"
                                                autocomplete="off"
                                            />
                                            <mat-datepicker #picker6></mat-datepicker>
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.effective_date.valid &&
                                                    item.controls.effective_date.touched
                                                "
                                            >
                                                Effective Date is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="bonusTypeOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.type"
                                                placeholder="Bonus Type"
                                                [ngClass]="{
                                                    invalid: item.controls.type.touched && !item.controls.type.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                            >
                                                Bonus Type is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row">
                                        <div class="dropdown-container w-50 pr15">
                                            <app-auto-dropdown
                                                [options]="currencyOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.currency"
                                                placeholder="Currency"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.currency.touched && !item.controls.currency.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.currency.touched && !item.controls.currency.valid"
                                            >
                                                Currency is required
                                            </div>
                                        </div>
                                        <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                            <mat-label>Amount</mat-label>
                                            <input type="number" autocomplete="off" formControlName="amount" matInput />
                                            <mat-error
                                                *ngIf="!item.controls.amount.valid && item.controls.amount.touched"
                                            >
                                                Amount is required</mat-error
                                            >
                                        </mat-form-field>
                                    </div>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onBonusSectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button
                            class="add-section-button"
                            *ngIf="
                                !compensationForm.get('bonuses')['controls'] ||
                                compensationForm.get('bonuses')['controls'].length < bonusTypeOptions?.length
                            "
                            (click)="onAddBonusSection()"
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Bonus</span>
                        </button>
                    </div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Allowances</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="allowances">
                            <p-accordion
                                *ngFor="let item of compensationForm.get('allowances')['controls']; let i = index"
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="allowancesInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="item.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!item.valid && !item.get('currency').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                                <div *ngIf="!item.valid && item.get('currency').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="item.get('type').value">
                                                        {{ item.get('currency').value | uppercase }}
                                                        {{ item.get('amount').value | number: '.2-2' }}
                                                        <ng-container *ngIf="item.get('amount').value">-</ng-container>
                                                        {{ item.get('type').value | titlecase }}
                                                    </h4>
                                                    <h4 class="position" *ngIf="!item.get('type').value">
                                                        Add Allowance
                                                    </h4>
                                                </div>
                                                <div *ngIf="item.get('effective_date').value" class="date">
                                                    {{
                                                        item.get('effective_date').value | amDateFormat: 'DD MMMM YYYY'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <mat-form-field
                                            class="dropdown-container w-50 pr15"
                                            appearance="outline"
                                            (click)="picker6.open()"
                                        >
                                            <mat-label>Effective Date</mat-label>
                                            <input
                                                formControlName="effective_date"
                                                matInput
                                                [matDatepicker]="picker6"
                                                autocomplete="off"
                                            />
                                            <mat-datepicker #picker6></mat-datepicker>
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.effective_date.valid &&
                                                    item.controls.effective_date.touched
                                                "
                                            >
                                                Effective Date is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="allowancesTypeOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.type"
                                                placeholder="Allowance Type"
                                                [ngClass]="{
                                                    invalid: item.controls.type.touched && !item.controls.type.valid
                                                }"
                                                (onChange)="onChangeAllowancesTypeOptions(i)"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                            >
                                                Allowance Type is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row" *ngIf="item.get('type').value !== 'funeral cover'">
                                        <div class="dropdown-container w-50 pr15">
                                            <app-auto-dropdown
                                                [options]="currencyOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.currency"
                                                placeholder="Currency"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.currency.touched && !item.controls.currency.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.currency.touched && !item.controls.currency.valid"
                                            >
                                                Currency is required
                                            </div>
                                        </div>
                                        <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                            <mat-label>Amount</mat-label>
                                            <input type="number" autocomplete="off" formControlName="amount" matInput />
                                            <mat-error
                                                *ngIf="!item.controls.amount.valid && item.controls.amount.touched"
                                            >
                                                Amount is required</mat-error
                                            >
                                        </mat-form-field>
                                    </div>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onAllowanceSectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button
                            class="add-section-button btn-allowance"
                            *ngIf="
                                !compensationForm.get('allowances')['controls'] ||
                                compensationForm.get('allowances')['controls'].length < allowancesTypeOptions?.length
                            "
                            (click)="onAddAllowanceSection()"
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Allowance</span>
                        </button>
                    </div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Bursaries</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="bursaries">
                            <p-accordion
                                *ngFor="
                                    let item of compensationForm.get('bursaries')['controls'];
                                    let i = index;
                                    let last = last
                                "
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="bursariesInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="item.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!item.valid && !item.get('currency').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                                <div *ngIf="!item.valid && item.get('currency').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="item.get('qualification_name').value">
                                                        {{ item.get('qualification_name').value | titlecase }}
                                                    </h4>
                                                    <h4 class="position" *ngIf="!item.get('qualification_name').value">
                                                        Add Bursary
                                                    </h4>
                                                </div>
                                                <div *ngIf="item.get('institution').value" class="date">
                                                    {{ item.get('institution').value }}
                                                    <ng-container *ngIf="last"
                                                        >-
                                                        <div class="current">
                                                            Current
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                            <mat-label>Qualification Name</mat-label>
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                formControlName="qualification_name"
                                                matInput
                                            />
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.qualification_name.valid &&
                                                    item.controls.qualification_name.touched
                                                "
                                            >
                                                Qualification Name is required</mat-error
                                            >
                                        </mat-form-field>
                                        <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                            <mat-label>Institution</mat-label>
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                formControlName="institution"
                                                matInput
                                            />
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.institution.valid &&
                                                    item.controls.institution.touched
                                                "
                                            >
                                                Institution is required</mat-error
                                            >
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-row">
                                        <div class="dropdown-container w-50 pr15">
                                            <app-auto-dropdown
                                                [options]="qualificationLengthOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.qualification_length"
                                                (onChange)="changeQualificationLength($event, i)"
                                                placeholder="Qualification Length"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.qualification_length.touched &&
                                                        !item.controls.qualification_length.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    item.controls.qualification_length.touched &&
                                                    !item.controls.qualification_length.valid
                                                "
                                            >
                                                Qualification Length is required
                                            </div>
                                        </div>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [disabled]="!item.value.qualification_length"
                                                [options]="currentYearOptions[i]"
                                                [newStyle]="true"
                                                [control]="item.controls.current_year"
                                                placeholder="Current Year"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.current_year.touched &&
                                                        !item.controls.current_year.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    item.controls.current_year.touched &&
                                                    !item.controls.current_year.valid
                                                "
                                            >
                                                Current Year is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row">
                                        <div class="dropdown-container w-50 pr15">
                                            <app-auto-dropdown
                                                [options]="currencyOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.currency"
                                                placeholder="Currency"
                                                [ngClass]="{
                                                    invalid:
                                                        item.controls.currency.touched && !item.controls.currency.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.currency.touched && !item.controls.currency.valid"
                                            >
                                                Currency is required
                                            </div>
                                        </div>
                                        <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                            <mat-label>Tuition Fee</mat-label>
                                            <input
                                                type="number"
                                                autocomplete="off"
                                                formControlName="tuition_fee"
                                                matInput
                                            />
                                            <mat-error
                                                *ngIf="
                                                    !item.controls.tuition_fee.valid &&
                                                    item.controls.tuition_fee.touched
                                                "
                                            >
                                                Tuition Fee is required</mat-error
                                            >
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-row mb20">
                                        <p-checkbox
                                            binary="true"
                                            label="Additional Allowances"
                                            (onChange)="onAdditionalAllowancesCheckboxChange($event, i, item)"
                                            formControlName="additional_allowances"
                                        >
                                        </p-checkbox>
                                    </div>
                                    <ng-container *ngIf="item.get('additional_allowances').value">
                                        <div class="flex-row">
                                            <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                                <mat-label>Accommodation Allowance</mat-label>
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    formControlName="accommodation_allowance"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="
                                                        !item.controls.accommodation_allowance.valid &&
                                                        item.controls.accommodation_allowance.touched
                                                    "
                                                >
                                                    Accommodation Allowance is required</mat-error
                                                >
                                            </mat-form-field>
                                            <mat-form-field class="dropdown-container w-50 pl15" appearance="outline">
                                                <mat-label>Book Allowance</mat-label>
                                                <input
                                                    type="number"
                                                    autocomplete="off"
                                                    formControlName="book_allowance"
                                                    matInput
                                                />
                                                <mat-error
                                                    *ngIf="
                                                        !item.controls.book_allowance.valid &&
                                                        item.controls.book_allowance.touched
                                                    "
                                                >
                                                    Book Allowance is required</mat-error
                                                >
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onBursarySectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button class="add-section-button btn-allowance" (click)="onAddBursary()">
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Bursary</span>
                        </button>
                    </div>

                    <div class="section-separator mb40 mt20"></div>
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Banking Details</h3>
                    </div>
                    <div class="form-block" formGroupName="banking_details">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="bankTypeOptions"
                                        [newStyle]="true"
                                        [control]="compensationForm.get('banking_details').controls.bank"
                                        placeholder="Bank Name"
                                        [ngClass]="{
                                            invalid:
                                                compensationForm.get('banking_details').controls.bank.touched &&
                                                !compensationForm.get('banking_details').controls.bank.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            compensationForm.get('banking_details').controls.bank.touched &&
                                            !compensationForm.get('banking_details').controls.bank.valid
                                        "
                                    >
                                        Bank Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Name Of Account Holder</mat-label>
                                        <input type="text" autocomplete="off" formControlName="name" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Branch Code</mat-label>
                                        <input type="text" autocomplete="off" formControlName="code" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Account Number</mat-label>
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            formControlName="account_number"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns">
                            <div
                                class="form-block-col"
                                *ngIf="compensationForm.get('banking_details').controls?.account_type"
                            >
                                <div class="input-block">
                                    <app-dropdown
                                        [options]="accountTypeOptions"
                                        [newStyle]="true"
                                        [control]="compensationForm.get('banking_details').controls.account_type"
                                        placeholder="Account Type"
                                        [ngClass]="{
                                            invalid:
                                                compensationForm.get('banking_details').controls.account_type.touched &&
                                                !compensationForm.get('banking_details').controls.account_type.valid
                                        }"
                                    >
                                    </app-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            compensationForm.get('banking_details').controls.account_type.touched &&
                                            !compensationForm.get('banking_details').controls.account_type.valid
                                        "
                                    >
                                        Account Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div
                                class="form-block-col"
                                *ngIf="compensationForm.get('banking_details').controls?.account_ownership"
                            >
                                <div class="input-block">
                                    <app-dropdown
                                        [options]="accountOwnershipTypeOptions"
                                        [newStyle]="true"
                                        [control]="compensationForm.get('banking_details').controls.account_ownership"
                                        placeholder="Account Ownership"
                                        [ngClass]="{
                                            invalid:
                                                compensationForm.get('banking_details').controls.account_ownership
                                                    .touched &&
                                                !compensationForm.get('banking_details').controls.account_ownership
                                                    .valid
                                        }"
                                    >
                                    </app-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            compensationForm.get('banking_details').controls.account_ownership
                                                .touched &&
                                            !compensationForm.get('banking_details').controls.account_ownership.valid
                                        "
                                    >
                                        Account Type is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p-checkbox
                            binary="true"
                            label="Bank account details match proof of banking letter provided by employee"
                            formControlName="match"
                        >
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="content-section" id="files" *ngIf="activeSection === 'files'">
            <div class="form-content-section">
                <div class="form-content-holder">
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Files</h3>
                    </div>
                    <div class="existing-files" *ngIf="employee.documents">
                        <div class="file-block-frame" *ngFor="let file of employee.documents">
                            <button class="btn btn-delete" (click)="onDeleteFile(file)">
                                <svg-icon
                                    class=""
                                    [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                                    src="/assets/images/icons/icons-8-trash-can.svg"
                                ></svg-icon>
                            </button>
                            <span class="file-icon">
                                <img
                                    *ngIf="file.name"
                                    src="/assets/images/icons/files-icon-{{
                                        utilitiesService.getExtension(file.name)
                                    }}.svg"
                                    alt=""
                                />
                            </span>
                            <div class="file-block-holder">
                                <span class="title">
                                    <strong>{{ file.original_name }}</strong>
                                    <span class="thin" *ngIf="file.size">{{
                                        utilitiesService.convertSize(file.size)
                                    }}</span>
                                </span>
                                <div class="label-row">
                                    <span class="thin ml0" *ngIf="file.uploaded_at">{{
                                        file.uploaded_at | date: 'd MMMM, y'
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="upload-documents-block">
                        <input
                            class="file hide-input"
                            type="file"
                            name=""
                            id="uploader_file"
                            (change)="onFileUpload($event)"
                        />
                        <div
                            class="upload-btn-block"
                            #uploadOptionsDropUp
                            [ngClass]="{ disabled: uploadQueue && uploadQueue.length }"
                        >
                            <div class="label-button" (click)="onAddDocumentBtnClick()">
                                <svg-icon src="/assets/images/plus-icon.svg"></svg-icon>
                                <span>Add Document</span>
                            </div>
                            <div class="options-drop-up" [ngClass]="{ visible: showUploaderOptions }">
                                <div class="documents-category" *ngFor="let category of uploadedTypesOptions">
                                    <span
                                        >{{ category.title }}
                                        <i class="pi pi-arrow-right"></i>
                                    </span>
                                    <div class="documents-category-lists">
                                        <ng-container *ngFor="let type of category.types">
                                            <label
                                                for="uploader_file"
                                                class="upload-item"
                                                (click)="onUploadItemClick(type.value, category.title)"
                                            >
                                                <span class="upload-item-text">{{ type.label }}</span>
                                                <span class="upload-item-size">&lt; 5MB</span>
                                            </label>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="uploadError">{{ uploadError }}</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="content-section" id="time_off" *ngIf="activeSection === 'time_off'">
            <div class="form-content-section" [formGroup]="timeOffForm">
                <div class="form-content-holder">
                    <div class="form-block-info">
                        <h3 class="form-block-info__title">Time Off</h3>
                    </div>
                    <div class="form-block">
                        <div class="accordion-array" formArrayName="time_off">
                            <p-accordion
                                *ngFor="let item of timeOffForm.get('time_off')['controls']; let i = index"
                                [formGroupName]="i"
                            >
                                <p-accordionTab class="section-wrap" [selected]="timeOffInvalid[i]">
                                    <p-header>
                                        <div class="flex-row">
                                            <div class="mr10">
                                                <div *ngIf="item.valid" class="success-icon-wrap">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/check.svg"
                                                        alt=""
                                                        width="10"
                                                        height="10"
                                                    />
                                                </div>
                                                <div *ngIf="!item.valid && !item.get('amount').value">
                                                    <div class="grey-circle"></div>
                                                </div>
                                                <div *ngIf="!item.valid && item.get('amount').value">
                                                    <img
                                                        class="success-icon"
                                                        src="/assets/images/icons/warning.svg"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            </div>
                                            <div class="accordion-header">
                                                <div class="accordion-header-title">
                                                    <h4 class="position" *ngIf="item.get('amount').value">
                                                        {{ item.get('amount').value }}
                                                        Day<ng-container *ngIf="item.get('amount').value > 1"
                                                            >s</ng-container
                                                        >
                                                    </h4>
                                                    <h4 class="position" *ngIf="!item.get('amount').value">
                                                        Add Time Off
                                                    </h4>
                                                </div>
                                                <div *ngIf="item.get('type').value" class="date">
                                                    {{ item.get('type').value | titlecase }}
                                                </div>
                                            </div>
                                        </div>
                                    </p-header>
                                    <div class="flex-row">
                                        <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                                            <mat-label>Number of Days</mat-label>
                                            <input type="number" autocomplete="off" formControlName="amount" matInput />
                                            <mat-error
                                                *ngIf="!item.controls.amount.valid && item.controls.amount.touched"
                                            >
                                                Number of Days is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="dropdown-container w-50 pl15">
                                            <app-auto-dropdown
                                                [options]="timeOffTypeOptions"
                                                [newStyle]="true"
                                                [control]="item.controls.type"
                                                placeholder="Time Off Type"
                                                [ngClass]="{
                                                    invalid: item.controls.type.touched && !item.controls.type.valid
                                                }"
                                            >
                                            </app-auto-dropdown>
                                            <div
                                                class="error-message"
                                                *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                            >
                                                Time Off Type is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="close-icon-wrap">
                                        <span class="close-icon">
                                            <svg-icon
                                                [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                                                src="/assets/images/icons/icons-8-trash-can.svg"
                                                (click)="onTimeOffSectionRemove(i)"
                                            ></svg-icon>
                                        </span>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <button
                            class="add-section-button btn-allowance"
                            *ngIf="
                                !timeOffForm.get('time_off')['controls'] ||
                                timeOffForm.get('time_off')['controls'].length < timeOffTypeOptions?.length
                            "
                            (click)="onAddTimeOffSection()"
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Time Off</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
