<p-dialog
    [(visible)]="visible"
    (onHide)="onHideModal()"
    [style]="{ width: '450px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
    [formGroup]="form"
>
    <div class="p-header p-header-new bg-green">
        Employee Termination
        <button class="close-btn" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new">
        <div class="form-content-section">
            <div class="form-content-holder">
                <div class="form-block mb">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="terminationTypeOptions"
                                    [control]="form.get('termination_category')"
                                    placeholder="Termination Category"
                                    [ngClass]="{
                                        invalid:
                                            !form.get('termination_category').valid &&
                                            form.get('termination_category').touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        !form.get('termination_category').valid &&
                                        form.get('termination_category').touched
                                    "
                                >
                                    Termination Category is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block mb0">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Employee ID</mat-label>
                                    <input type="text" autocomplete="off" formControlName="employee_id" matInput />
                                </mat-form-field>
                                <div
                                    class="error-message"
                                    *ngIf="!form.get('employee_id').valid && form.get('employee_id').touched"
                                >
                                    Employee Id is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block mb0">
                    <div class="input-block">
                        <mat-form-field appearance="outline" (click)="picker1.open()">
                            <mat-label>Termination Date</mat-label>
                            <input
                                formControlName="termination_date"
                                matInput
                                [matDatepicker]="picker1"
                                autocomplete="off"
                            />
                            <mat-icon matSuffix>
                                <svg-icon class="calendar-mat-icon" src="/assets/images/icons/calendar.svg"></svg-icon>
                            </mat-icon>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error
                                *ngIf="!form.controls.termination_date.valid && form.controls.termination_date.touched"
                            >
                                Date of Birth is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-block">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="usersOptions"
                            [dropdownValueUpdated]="dropdownValueUpdated"
                            [newStyle]="true"
                            placeholder="HR Business Partner"
                            [control]="form.controls.hr_business_partner"
                            [ngClass]="{
                                invalid:
                                    !form.controls.hr_business_partner.valid &&
                                    form.controls.hr_business_partner.touched
                            }"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
                <!-- <div class="form-block">
                    <div class="input-block">
                        <mat-form-field appearance="outline">
                            <mat-label>Reason</mat-label>
                            <textarea
                                matInput
                                formControlName="termination_reason"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="5"
                                resize="vertical"
                            ></textarea>
                            <mat-error
                                *ngIf="form.get('termination_reason').invalid && form.get('termination_reason').touched"
                            >
                                Reason is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="form-block">
                    <p-checkbox
                        name="send_workflow"
                        binary="true"
                        label="Send an off-boarding workflow to this employee"
                        formControlName="send_workflow"
                        inputId="send_workflow"
                        (onChange)="onCheckboxChange($event)"
                    ></p-checkbox>
                </div>
                <div class="form-block" *ngIf="form.get('workflow_id')">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="workflowTypeOptions"
                            [control]="form.get('workflow_id')"
                            placeholder="Workflow"
                            [ngClass]="{
                                invalid: !form.get('workflow_id').valid && form.get('workflow_id').touched
                            }"
                        >
                        </app-auto-dropdown>
                        <div
                            class="error-message"
                            *ngIf="!form.get('workflow_id').valid && form.get('workflow_id').touched"
                        >
                            Workflow is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-footer p-footer-new">
        <div class="action">
            <button class="btn add-workflow-btn" (click)="onFinish()">
                Finish
            </button>
        </div>
    </div>
</p-dialog>
<app-loader *ngIf="contentLoading"></app-loader>
