import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { EmployeesService } from '@app/core/services/employees.service';
import moment from 'moment';

@Component({
    selector: 'app-candidate-preview-document',
    templateUrl: './candidate-preview-document.component.html',
    styleUrls: ['./candidate-preview-document.component.scss']
})
export class CandidatePreviewDocumentComponent implements OnInit, OnDestroy {
    contentLoading = false;
    @Input('file') file;
    @Input('candidate') candidate;
    @Input('documentsTypeOptions') documentsTypeOptions;
    @Output() close: EventEmitter<any> = new EventEmitter();
    constructor(private employeeService: EmployeesService, private _renderer2: Renderer2) {}

    ngOnInit(): void {
        this._renderer2.addClass(document.body, 'hide-sidebar');
    }

    onClose() {
        this.close.emit();
    }

    onDonloadDocument() {
        let name = '';
        if (this.candidate.employee_id) {
            name = `${this.candidate.employee_id}_`;
        }
        let documentType = this.documentsTypeOptions.find((d) => d.value === this.file.documentType);
        const url = this.file.link;
        name += `${this.candidate.first_name}_${this.candidate.last_name}_${
            documentType ? documentType.slug : this.file.name
        }_${moment.unix(this.candidate.created_at).format('YYYYMMDD')}`;
        this.employeeService.downloadFile(url, name).subscribe();
    }

    ngOnDestroy() {
        this._renderer2.removeClass(document.body, 'hide-sidebar');
    }
}
