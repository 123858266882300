import { SelectItem } from 'primeng';

export const jobTypeOptions = [
    { label: 'Permanent', value: 'permanent' },
    { label: 'Temporary', value: 'temporary' },
    { label: 'Internship', value: 'internship' },
    { label: 'Fixed Term', value: 'fixed_term' },
    { label: 'Freelance', value: 'freelance' }
];

export const gendersTypeOptions: SelectItem[] = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
];

export const ethnicityTypeOptions: SelectItem[] = [
    { value: 'African', label: 'African' },
    { value: 'Indian', label: 'Indian' },
    { value: 'Coloured', label: 'Coloured' },
    { value: 'White', label: 'White' },
    { value: 'Other', label: 'Other' }
];

export const datePickerFormat = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const employeeStatusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
];

export const userTypesOptions = [
    {
        label: 'Employee',
        value: 'employee'
    },
    {
        label: 'Owner',
        value: 'account_owner'
    },
    {
        label: 'Administrator',
        value: 'admin'
    },
    {
        label: 'Recruiter',
        value: 'recruiter'
    },
    {
        label: 'HR Business Partner',
        value: 'hr_business_partner'
    },
    {
        label: 'Onboarding Business Partner',
        value: 'onboarding_business_partner'
    },
    {
        label: 'HR Admin',
        value: 'hr_admin'
    },
    {
        label: 'Payroll Business Partner',
        value: 'payroll_business_partner'
    }
];

export const jobDepartmentOptions = [
    { value: 'Intelligent workplace', label: 'Intelligent Workplace' },
    { value: 'Intelligent Business Applications', label: 'Intelligent Business applications' },
    { value: 'Intelligent Security', label: 'Intelligent Security' },
    { value: 'Intelligent Infrastructure', label: 'Intelligent Infrastructure' },
    { value: 'Intelligent Customer Experience', label: 'Intelligent Customer Experience' },
    { value: 'geography', label: 'Geography' },
    { value: 'cross go-to-market', label: 'Cross Go-To-Market' },
    { value: 'mea business services', label: 'MEA Business Services' },
    { value: 'services', label: 'Services' },
    { value: 'human resources', label: 'Human Resources' },
    { value: 'finance', label: 'Finance' },
    { value: 'i&t', label: 'I&T' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'risk', label: 'Risk' },
    { value: 'compliance & legal', label: 'Compliance & Legal' },
    { value: 'other', label: 'Other' }
];

export const jobDesignationOptions = [{ label: 'Regional Sales Manager', value: 'regional sales manager' }];

export const jobBusinessUnitOptions = [
    { label: 'Intelligent Business Applications', value: 'Intelligent Business Applications' }
];

export const jobLevelOptions: SelectItem[] = [{ label: '1', value: '1' }];

export const jobShiftOptions: SelectItem[] = [
    { label: 'Day Shift', value: 'day shift' },
    { label: 'Night Shift', value: 'night shift' }
];

export const relationshipOptions = [
    {
        label: 'Manager',
        value: 'manager'
    },
    {
        label: 'HR Business Partner',
        value: 'hr_business_partner'
    },
    {
        label: 'Onboarding Business Partner',
        value: 'onboarding_business_partner'
    },
    {
        label: 'HR Admin',
        value: 'hr_admin'
    },
    {
        label: 'Payroll Business Partner',
        value: 'payroll_business_partner'
    }
];

export const bloodGroupOptions: SelectItem[] = [
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' },
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' }
];
export const maritalStatusOptions: SelectItem[] = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
    { value: 'cohabiting', label: 'Cohabiting' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
    { value: 'civil partnership', label: 'Civil Partnership' },
    { value: 'prefer not to say', label: 'Prefer Not To Say' }
];
export const currencyOptions: SelectItem[] = [
    { label: 'ZAR - South African Rand', value: 'ZAR' },
    { label: 'USD - US Dollar', value: 'USD' },
    { label: 'EUR - Euro', value: 'EUR' },
    { label: 'KES - Kenyan Shilling', value: 'KES' },
    { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
    { label: 'UGX - Ugandan Shilling', value: 'UGX' },
    { label: 'NGN - Nigerian Naira', value: 'NGN' },
    { label: 'BWP - Botswanan Pula', value: 'BWP' },
    { label: 'ZMW - Zambian Kwacha', value: 'ZMW' },

    { label: 'AED - United Arab Emirates Dirham', value: 'AED' },
    { label: 'AFN - Afghan Afghani', value: 'AFN' },
    { label: 'ALL - Albanian Lek', value: 'ALL' },
    { label: 'AMD - Armenian Dram', value: 'AMD' },
    { label: 'ANG - Netherlands Antillean Guilder', value: 'ANG' },
    { label: 'AOA - Angolan Kwanza', value: 'AOA' },
    { label: 'ARS - Argentine Peso', value: 'ARS' },
    { label: 'AUD - Australian Dollar', value: 'AUD' },
    { label: 'AWG - Aruban Florin', value: 'AWG' },
    { label: 'AZN - Azerbaijani Manat', value: 'AZN' },
    { label: 'BAM - Bosnia-Herzegovina Convertible Mark', value: 'BAM' },
    { label: 'BBD - Barbadian Dollar', value: 'BBD' },
    { label: 'BDT - Bangladeshi Taka', value: 'BDT' },
    { label: 'BGN - Bulgarian Lev', value: 'BGN' },
    { label: 'BHD - Bahraini Dinar', value: 'BHD' },
    { label: 'BIF - Burundian Franc', value: 'BIF' },
    { label: 'BMD - Bermudan Dollar', value: 'BMD' },
    { label: 'BND - Brunei Dollar', value: 'BND' },
    { label: 'BOB - Bolivian Boliviano', value: 'BOB' },
    { label: 'BRL - Brazilian Real', value: 'BRL' },
    { label: 'BSD - Bahamian Dollar', value: 'BSD' },
    { label: 'BTC - Bitcoin', value: 'BTC' },
    { label: 'BTN - Bhutanese Ngultrum', value: 'BTN' },
    { label: 'BYN - Belarusian Ruble', value: 'BYN' },
    { label: 'BZD - Belize Dollar', value: 'BZD' },
    { label: 'CAD - Canadian Dollar', value: 'CAD' },
    { label: 'CDF - Congolese Franc', value: 'CDF' },
    { label: 'CHF - Swiss Franc', value: 'CHF' },
    { label: 'CLF - Chilean Unit of Account (UF)', value: 'CLF' },
    { label: 'CLP - Chilean Peso', value: 'CLP' },
    { label: 'CNH - Chinese Yuan (Offshore)', value: 'CNH' },
    { label: 'CNY - Chinese Yuan', value: 'CNY' },
    { label: 'COP - Colombian Peso', value: 'COP' },
    { label: 'CRC - Costa Rican Colón', value: 'CRC' },
    { label: 'CUC - Cuban Convertible Peso', value: 'CUC' },
    { label: 'CUP - Cuban Peso', value: 'CUP' },
    { label: 'CVE - Cape Verdean Escudo', value: 'CVE' },
    { label: 'CZK - Czech Republic Koruna', value: 'CZK' },
    { label: 'DJF - Djiboutian Franc', value: 'DJF' },
    { label: 'DKK - Danish Krone', value: 'DKK' },
    { label: 'DOP - Dominican Peso', value: 'DOP' },
    { label: 'DZD - Algerian Dinar', value: 'DZD' },
    { label: 'EGP - Egyptian Pound', value: 'EGP' },
    { label: 'ERN - Eritrean Nakfa', value: 'ERN' },
    { label: 'ETB - Ethiopian Birr', value: 'ETB' },
    { label: 'FJD - Fijian Dollar', value: 'FJD' },
    { label: 'FKP - Falkland Islands Pound', value: 'FKP' },
    { label: 'GBP - British Pound Sterling', value: 'GBP' },
    { label: 'GEL - Georgian Lari', value: 'GEL' },
    { label: 'GGP - Guernsey Pound', value: 'GGP' },
    { label: 'GHS - Ghanaian Cedi', value: 'GHS' },
    { label: 'GIP - Gibraltar Pound', value: 'GIP' },
    { label: 'GMD - Gambian Dalasi', value: 'GMD' },
    { label: 'GNF - Guinean Franc', value: 'GNF' },
    { label: 'GTQ - Guatemalan Quetzal', value: 'GTQ' },
    { label: 'GYD - Guyanaese Dollar', value: 'GYD' },
    { label: 'HKD - Hong Kong Dollar', value: 'HKD' },
    { label: 'HNL - Honduran Lempira', value: 'HNL' },
    { label: 'HRK - Croatian Kuna', value: 'HRK' },
    { label: 'HTG - Haitian Gourde', value: 'HTG' },
    { label: 'HUF - Hungarian Forint', value: 'HUF' },
    { label: 'IDR - Indonesian Rupiah', value: 'IDR' },
    { label: 'ILS - Israeli New Sheqel', value: 'ILS' },
    { label: 'IMP - Manx pound', value: 'IMP' },
    { label: 'INR - Indian Rupee', value: 'INR' },
    { label: 'IQD - Iraqi Dinar', value: 'IQD' },
    { label: 'IRR - Iranian Rial', value: 'IRR' },
    { label: 'ISK - Icelandic Króna', value: 'ISK' },
    { label: 'JEP - Jersey Pound', value: 'JEP' },
    { label: 'JMD - Jamaican Dollar', value: 'JMD' },
    { label: 'JOD - Jordanian Dinar', value: 'JOD' },
    { label: 'JPY - Japanese Yen', value: 'JPY' },
    { label: 'KGS - Kyrgystani Som', value: 'KGS' },
    { label: 'KHR - Cambodian Riel', value: 'KHR' },
    { label: 'KMF - Comorian Franc', value: 'KMF' },
    { label: 'KPW - North Korean Won', value: 'KPW' },
    { label: 'KRW - South Korean Won', value: 'KRW' },
    { label: 'KWD - Kuwaiti Dinar', value: 'KWD' },
    { label: 'KYD - Cayman Islands Dollar', value: 'KYD' },
    { label: 'KZT - Kazakhstani Tenge', value: 'KZT' },
    { label: 'LAK - Laotian Kip', value: 'LAK' },
    { label: 'LBP - Lebanese Pound', value: 'LBP' },
    { label: 'LKR - Sri Lankan Rupee', value: 'LKR' },
    { label: 'LRD - Liberian Dollar', value: 'LRD' },
    { label: 'LSL - Lesotho Loti', value: 'LSL' },
    { label: 'LYD - Libyan Dinar', value: 'LYD' },
    { label: 'MAD - Moroccan Dirham', value: 'MAD' },
    { label: 'MDL - Moldovan Leu', value: 'MDL' },
    { label: 'MGA - Malagasy Ariary', value: 'MGA' },
    { label: 'MKD - Macedonian Denar', value: 'MKD' },
    { label: 'MMK - Myanma Kyat', value: 'MMK' },
    { label: 'MNT - Mongolian Tugrik', value: 'MNT' },
    { label: 'MOP - Macanese Pataca', value: 'MOP' },
    { label: 'MRU - Mauritanian Ouguiya', value: 'MRU' },
    { label: 'MUR - Mauritian Rupee', value: 'MUR' },
    { label: 'MVR - Maldivian Rufiyaa', value: 'MVR' },
    { label: 'MWK - Malawian Kwacha', value: 'MWK' },
    { label: 'MXN - Mexican Peso', value: 'MXN' },
    { label: 'MYR - Malaysian Ringgit', value: 'MYR' },
    { label: 'MZN - Mozambican Metical', value: 'MZN' },
    { label: 'NAD - Namibian Dollar', value: 'NAD' },
    { label: 'NIO - Nicaraguan Córdoba', value: 'NIO' },
    { label: 'NOK - Norwegian Krone', value: 'NOK' },
    { label: 'NPR - Nepalese Rupee', value: 'NPR' },
    { label: 'NZD - New Zealand Dollar', value: 'NZD' },
    { label: 'OMR - Omani Rial', value: 'OMR' },
    { label: 'PAB - Panamanian Balboa', value: 'PAB' },
    { label: 'PEN - Peruvian Nuevo Sol', value: 'PEN' },
    { label: 'PGK - Papua New Guinean Kina', value: 'PGK' },
    { label: 'PHP - Philippine Peso', value: 'PHP' },
    { label: 'PKR - Pakistani Rupee', value: 'PKR' },
    { label: 'PLN - Polish Zloty', value: 'PLN' },
    { label: 'PYG - Paraguayan Guarani', value: 'PYG' },
    { label: 'QAR - Qatari Rial', value: 'QAR' },
    { label: 'RON - Romanian Leu', value: 'RON' },
    { label: 'RSD - Serbian Dinar', value: 'RSD' },
    { label: 'RUB - Russian Ruble', value: 'RUB' },
    { label: 'RWF - Rwandan Franc', value: 'RWF' },
    { label: 'SAR - Saudi Riyal', value: 'SAR' },
    { label: 'SBD - Solomon Islands Dollar', value: 'SBD' },
    { label: 'SCR - Seychellois Rupee', value: 'SCR' },
    { label: 'SDG - Sudanese Pound', value: 'SDG' },
    { label: 'SEK - Swedish Krona', value: 'SEK' },
    { label: 'SGD - Singapore Dollar', value: 'SGD' },
    { label: 'SHP - Saint Helena Pound', value: 'SHP' },
    { label: 'SLL - Sierra Leonean Leone', value: 'SLL' },
    { label: 'SOS - Somali Shilling', value: 'SOS' },
    { label: 'SRD - Surinamese Dollar', value: 'SRD' },
    { label: 'SSP - South Sudanese Pound', value: 'SSP' },
    { label: 'STD - São Tomé and Príncipe Dobra (pre-2018)', value: 'STD' },
    { label: 'STN - São Tomé and Príncipe Dobra', value: 'STN' },
    { label: 'SVC - Salvadoran Colón', value: 'SVC' },
    { label: 'SYP - Syrian Pound', value: 'SYP' },
    { label: 'SZL - Swazi Lilangeni', value: 'SZL' },
    { label: 'THB - Thai Baht', value: 'THB' },
    { label: 'TJS - Tajikistani Somoni', value: 'TJS' },
    { label: 'TMT - Turkmenistani Manat', value: 'TMT' },
    { label: 'TND - Tunisian Dinar', value: 'TND' },
    { label: "TOP - Tongan Pa'anga", value: 'TOP' },
    { label: 'TRY - Turkish Lira', value: 'TRY' },
    { label: 'TTD - Trinidad and Tobago Dollar', value: 'TTD' },
    { label: 'TWD - New Taiwan Dollar', value: 'TWD' },
    { label: 'UAH - Ukrainian Hryvnia', value: 'UAH' },
    { label: 'UYU - Uruguayan Peso', value: 'UYU' },
    { label: 'UZS - Uzbekistan Som', value: 'UZS' },
    { label: 'VEF - Venezuelan Bolívar Fuerte (Old)', value: 'VEF' },
    { label: 'VES - Venezuelan Bolívar Soberano', value: 'VES' },
    { label: 'VND - Vietnamese Dong', value: 'VND' },
    { label: 'VUV - Vanuatu Vatu', value: 'VUV' },
    { label: 'WST - Samoan Tala', value: 'WST' },
    { label: 'XAF - CFA Franc BEAC', value: 'XAF' },
    { label: 'XAG - Silver Ounce', value: 'XAG' },
    { label: 'XAU - Gold Ounce', value: 'XAU' },
    { label: 'XCD - East Caribbean Dollar', value: 'XCD' },
    { label: 'XDR - Special Drawing Rights', value: 'XDR' },
    { label: 'XOF - CFA Franc BCEAO', value: 'XOF' },
    { label: 'XPD - Palladium Ounce', value: 'XPD' },
    { label: 'XPF - CFP Franc', value: 'XPF' },
    { label: 'XPT - Platinum Ounce', value: 'XPT' },
    { label: 'YER - Yemeni Rial', value: 'YER' },
    { label: 'ZWL - Zimbabwean Dollar', value: 'ZWL' }
];
export const languagesOptions = [
    { name: 'Afar', id: 'Afar' },
    { name: 'Abkhazian', id: 'Abkhazian' },
    { name: 'Avestan', id: 'Avestan' },
    { name: 'Afrikaans', id: 'Afrikaans' },
    { name: 'Akan', id: 'Akan' },
    { name: 'Amharic', id: 'Amharic' },
    { name: 'Aragonese', id: 'Aragonese' },
    { name: 'Arabic', id: 'Arabic' },
    { name: 'Assamese', id: 'Assamese' },
    { name: 'Avaric', id: 'Avaric' },
    { name: 'Aymara', id: 'Aymara' },
    { name: 'Azerbaijani', id: 'Azerbaijani' },
    { name: 'Bashkir', id: 'Bashkir' },
    { name: 'Belarusian', id: 'Belarusian' },
    { name: 'Bulgarian', id: 'Bulgarian' },
    { name: 'Bihari languages', id: 'Bihari languages' },
    { name: 'Bislama', id: 'Bislama' },
    { name: 'Bambara', id: 'Bambara' },
    { name: 'Bengali', id: 'Bengali' },
    { name: 'Tibetan', id: 'Tibetan' },
    { name: 'Breton', id: 'Breton' },
    { name: 'Bosnian', id: 'Bosnian' },
    { name: 'Catalan; Valencian', id: 'Catalan; Valencian' },
    { name: 'Chechen', id: 'Chechen' },
    { name: 'Chamorro', id: 'Chamorro' },
    { name: 'Corsican', id: 'Corsican' },
    { name: 'Cree', id: 'Cree' },
    { name: 'Czech', id: 'Czech' },
    { name: 'Chuvash', id: 'Chuvash' },
    { name: 'Welsh', id: 'Welsh' },
    { name: 'Danish', id: 'Danish' },
    { name: 'German', id: 'German' },
    { name: 'Dzongkha', id: 'Dzongkha' },
    { name: 'Ewe', id: 'Ewe' },
    { name: 'English', id: 'English' },
    { name: 'Esperanto', id: 'Esperanto' },
    { name: 'Spanish; Castilian', id: 'Spanish; Castilian' },
    { name: 'Estonian', id: 'Estonian' },
    { name: 'Basque', id: 'Basque' },
    { name: 'Persian', id: 'Persian' },
    { name: 'Fulah', id: 'Fulah' },
    { name: 'Finnish', id: 'Finnish' },
    { name: 'Fijian', id: 'Fijian' },
    { name: 'Faroese', id: 'Faroese' },
    { name: 'French', id: 'French' },
    { name: 'Western Frisian', id: 'Western Frisian' },
    { name: 'Irish', id: 'Irish' },
    { name: 'Gaelic; Scottish Gaelic', id: 'Gaelic; Scottish Gaelic' },
    { name: 'Galician', id: 'Galician' },
    { name: 'Guarani', id: 'Guarani' },
    { name: 'Gujarati', id: 'Gujarati' },
    { name: 'Manx', id: 'Manx' },
    { name: 'Hausa', id: 'Hausa' },
    { name: 'Hebrew', id: 'Hebrew' },
    { name: 'Hindi', id: 'Hindi' },
    { name: 'Hiri Motu', id: 'Hiri Motu' },
    { name: 'Croatian', id: 'Croatian' },
    { name: 'Haitian; Haitian Creole', id: 'Haitian; Haitian Creole' },
    { name: 'Hungarian', id: 'Hungarian' },
    { name: 'Armenian', id: 'Armenian' },
    { name: 'Herero', id: 'Herero' },
    { name: 'Interlingue; Occidental', id: 'Interlingue; Occidental' },
    { name: 'Igbo', id: 'Igbo' },
    { name: 'Sichuan Yi; Nuosu', id: 'Sichuan Yi; Nuosu' },
    { name: 'Inupiaq', id: 'Inupiaq' },
    { name: 'Ido', id: 'Ido' },
    { name: 'Icelandic', id: 'Icelandic' },
    { name: 'Italian', id: 'Italian' },
    { name: 'Inuktitut', id: 'Inuktitut' },
    { name: 'Japanese', id: 'Japanese' },
    { name: 'Javanese', id: 'Javanese' },
    { name: 'Georgian', id: 'Georgian' },
    { name: 'Kongo', id: 'Kongo' },
    { name: 'Kikuyu; Gikuyu', id: 'Kikuyu; Gikuyu' },
    { name: 'Kuanyama; Kwanyama', id: 'Kuanyama; Kwanyama' },
    { name: 'Kazakh', id: 'Kazakh' },
    { name: 'Kalaallisut; Greenlandic', id: 'Kalaallisut; Greenlandic' },
    { name: 'Central Khmer', id: 'Central Khmer' },
    { name: 'Kannada', id: 'Kannada' },
    { name: 'Korean', id: 'Korean' },
    { name: 'Kanuri', id: 'Kanuri' },
    { name: 'Kashmiri', id: 'Kashmiri' },
    { name: 'Kurdish', id: 'Kurdish' },
    { name: 'Komi', id: 'Komi' },
    { name: 'Cornish', id: 'Cornish' },
    { name: 'Kirghiz; Kyrgyz', id: 'Kirghiz; Kyrgyz' },
    { name: 'Latin', id: 'Latin' },
    { name: 'Luxembourgish; Letzeburgesch', id: 'Luxembourgish; Letzeburgesch' },
    { name: 'Ganda', id: 'Ganda' },
    { name: 'Limburgan; Limburger; Limburgish', id: 'Limburgan; Limburger; Limburgish' },
    { name: 'Lingala', id: 'Lingala' },
    { name: 'Lao', id: 'Lao' },
    { name: 'Lithuanian', id: 'Lithuanian' },
    { name: 'Luba-Katanga', id: 'Luba-Katanga' },
    { name: 'Latvian', id: 'Latvian' },
    { name: 'Malagasy', id: 'Malagasy' },
    { name: 'Marshallese', id: 'Marshallese' },
    { name: 'Maori', id: 'Maori' },
    { name: 'Macedonian', id: 'Macedonian' },
    { name: 'Malayalam', id: 'Malayalam' },
    { name: 'Mongolian', id: 'Mongolian' },
    { name: 'Marathi', id: 'Marathi' },
    { name: 'Malay', id: 'Malay' },
    { name: 'Maltese', id: 'Maltese' },
    { name: 'Burmese', id: 'Burmese' },
    { name: 'Nauru', id: 'Nauru' },
    { name: 'Nepali', id: 'Nepali' },
    { name: 'Ndonga', id: 'Ndonga' },
    { name: 'Dutch; Flemish', id: 'Dutch; Flemish' },
    { name: 'Norwegian', id: 'Norwegian' },
    { name: 'Ndebele, South; South Ndebele', id: 'Ndebele, South; South Ndebele' },
    { name: 'Navajo; Navaho', id: 'Navajo; Navaho' },
    { name: 'Chichewa; Chewa; Nyanja', id: 'Chichewa; Chewa; Nyanja' },
    { name: 'Ojibwa', id: 'Ojibwa' },
    { name: 'Oromo', id: 'Oromo' },
    { name: 'Oriya', id: 'Oriya' },
    { name: 'Ossetian; Ossetic', id: 'Ossetian; Ossetic' },
    { name: 'Panjabi; Punjabi', id: 'Panjabi; Punjabi' },
    { name: 'Pali', id: 'Pali' },
    { name: 'Polish', id: 'Polish' },
    { name: 'Pushto; Pashto', id: 'Pushto; Pashto' },
    { name: 'Portuguese', id: 'Portuguese' },
    { name: 'Quechua', id: 'Quechua' },
    { name: 'Romansh', id: 'Romansh' },
    { name: 'Rundi', id: 'Rundi' },
    { name: 'Romanian; Moldavian; Moldovan', id: 'Romanian; Moldavian; Moldovan' },
    { name: 'Russian', id: 'Russian' },
    { name: 'Kinyarwanda', id: 'Kinyarwanda' },
    { name: 'Sanskrit', id: 'Sanskrit' },
    { name: 'Sardinian', id: 'Sardinian' },
    { name: 'Sindhi', id: 'Sindhi' },
    { name: 'Northern Sami', id: 'Northern Sami' },
    { name: 'Sango', id: 'Sango' },
    { name: 'Sinhala; Sinhalese', id: 'Sinhala; Sinhalese' },
    { name: 'Slovak', id: 'Slovak' },
    { name: 'Slovenian', id: 'Slovenian' },
    { name: 'Samoan', id: 'Samoan' },
    { name: 'Shona', id: 'Shona' },
    { name: 'Somali', id: 'Somali' },
    { name: 'Albanian', id: 'Albanian' },
    { name: 'Serbian', id: 'Serbian' },
    { name: 'Swati', id: 'Swati' },
    { name: 'Sotho, Southern', id: 'Sotho, Southern' },
    { name: 'Sundanese', id: 'Sundanese' },
    { name: 'Swedish', id: 'Swedish' },
    { name: 'Swahili', id: 'Swahili' },
    { name: 'Tamil', id: 'Tamil' },
    { name: 'Telugu', id: 'Telugu' },
    { name: 'Tajik', id: 'Tajik' },
    { name: 'Thai', id: 'Thai' },
    { name: 'Tigrinya', id: 'Tigrinya' },
    { name: 'Turkmen', id: 'Turkmen' },
    { name: 'Tagalog', id: 'Tagalog' },
    { name: 'Tswana', id: 'Tswana' },
    { name: 'Tonga (Tonga Islands)', id: 'Tonga (Tonga Islands)' },
    { name: 'Turkish', id: 'Turkish' },
    { name: 'Tsonga', id: 'Tsonga' },
    { name: 'Tatar', id: 'Tatar' },
    { name: 'Twi', id: 'Twi' },
    { name: 'Tahitian', id: 'Tahitian' },
    { name: 'Uighur; Uyghur', id: 'Uighur; Uyghur' },
    { name: 'Ukrainian', id: 'Ukrainian' },
    { name: 'Urdu', id: 'Urdu' },
    { name: 'Uzbek', id: 'Uzbek' },
    { name: 'Venda', id: 'Venda' },
    { name: 'Vietnamese', id: 'Vietnamese' },
    { name: 'Walloon', id: 'Walloon' },
    { name: 'Wolof', id: 'Wolof' },
    { name: 'Xhosa', id: 'Xhosa' },
    { name: 'Yiddish', id: 'Yiddish' },
    { name: 'Yoruba', id: 'Yoruba' },
    { name: 'Zhuang; Chuang', id: 'Zhuang; Chuang' },
    { name: 'Chinese', id: 'Chinese' },
    { name: 'Zulu', ida: 'Zulu' }
];
export const mapFieldOptions = [
    { value: 'employee.full_name', label: 'Employee Full Name' },
    { value: 'employee.first_name', label: 'Employee First Name' },
    { value: 'employee.last_name', label: 'Employee Last Name' },
    { value: 'employee.address', label: 'Contact address' },
    { value: 'employee.street', label: 'Street' },
    { value: 'employee.city', label: 'City' },
    { value: 'employee.state', label: 'Province' },
    { value: 'employee.country', label: 'Country' },
    { value: 'employee.zip_code', label: 'Postal Code' },
    { value: 'employee.email_private', label: 'Email(Private)' },
    { value: 'employee.email_work', label: 'Email(Work)' },
    { value: 'employee.mobile_phone', label: 'Mobile phone' },
    { value: 'employee.gender', label: 'Gender' },
    { value: 'employee.ethnicity', label: 'Ethnicity' },
    { value: 'employee.date_of_birth', label: 'Date of birth' },
    { value: 'employee.identity_number', label: 'Identity number' },
    { value: 'employee.marital_status', label: 'Marital status' },
    { value: 'employee.blood_group', label: 'Blood group' },
    { value: 'employee.residential_street', label: 'Residential street' },
    { value: 'employee.residential_city', label: 'Residential city' },
    { value: 'employee.residential_state', label: 'Residential state' },
    { value: 'employee.residential_country', label: 'Residential country' },
    { value: 'employee.residential_zip_code', label: 'Residential zip code' },
    { value: 'employee.annual_leave', label: 'Annual Leave' },

    { value: 'employee.postal_street', label: 'Postal street' },
    { value: 'employee.postal_city', label: 'Postal city' },
    { value: 'employee.postal_state', label: 'Postal state' },
    { value: 'employee.postal_country', label: 'Postal country' },
    { value: 'employee.postal_zip_code', label: 'Postal zip code' },

    { value: 'employee.partner_name', label: "Partner's name" },
    { value: 'employee.partner_gender', label: "Partner's gender" },
    { value: 'employee.partner_date_of_birth', label: "Partner's date of birth" },

    { value: 'employee.petrol_allowance', label: 'Petrol Allowance' },
    { value: 'employee.mobile_phone_allowance', label: 'Mobile Phone Allowance' },

    { value: 'offer.funeral_cover', label: 'Funeral Cover' },
    { value: 'offer.motor_vehicle_allowance', label: 'Motor Vehicle Allowance' },
    { value: 'offer.petrol_allowance', label: 'Petrol Allowance' },
    { value: 'offer.mobile_phone_allowance', label: 'Mobile Phone Allowance' },
    { value: 'offer.time_off', label: 'Time Off - Earned' },
    { value: 'offer.created_at', label: 'Created at' },
    { value: 'offer.effective_date', label: 'Effective Date' },
    { value: 'offer.currency', label: 'Currency' },
    { value: 'offer.annual_amount', label: 'Annual Salary' },
    { value: 'offer.bonus_amount', label: 'Bonus Amount' },
    { value: 'offer.commission_amount', label: 'Commission Amount' },
    { value: 'offer.hr_full_name', label: "HR partner's full name" },
    { value: 'offer.hr_first_name', label: "HR partner's first name" },
    { value: 'offer.hr_last_name', label: "HR partner's last name" },
    { value: 'offer.hr_email', label: "HR partner's email" },
    { value: 'offer.hr_designation', label: "HR partner's designation" },
    { value: 'offer.date_of_joining', label: 'Joining date' },
    { value: 'offer.monthly_amount', label: 'Monthly Amount' },
    { value: 'job.title', label: 'Job title' },
    { value: 'job.job_type', label: 'Job type' },
    { value: 'job.location', label: 'Job location' },
    { value: 'job.location_city', label: 'Job location city' },
    { value: 'job.location_short', label: 'Job location short' },
    { value: 'job.previous_company_name', label: 'Previous Employer' },
    { value: 'job.previous_start_date', label: 'Previous Start Date' },
    { value: 'recruiter.full_name', label: "Recruiter's full name" },
    { value: 'recruiter.first_name', label: "Recruiter's first name" },
    { value: 'recruiter.last_name', label: "Recruiter's last name" },
    { value: 'recruiter.email', label: "Recruiter's email" },
    { value: 'recruiter.title', label: "Recruiters's title" },
    { value: 'hiring_manager.full_name', label: "Hiring manager's full name" },
    { value: 'hiring_manager.first_name', label: "Hiring manager's first name" },
    { value: 'hiring_manager.last_name', label: "Hiring manager's last name" },
    { value: 'hiring_manager.email', label: "Hiring manager's email" },
    { value: 'hiring_manager.title', label: "Hiring manager's title" }
];
export const documentTypeOptions = [
    { value: 'Letter of Appointment', label: 'Letter of Appointment', name: 'LOA' },
    { value: 'Addendum to Letter of Appointment', label: 'Addendum to Letter of Appointment', name: 'Addendum' },
    { value: 'Benefit Letter', label: 'Benefit Letter', name: 'Benefit Letter' },
    { value: 'Sanlam GLI Option', label: 'Sanlam GLI Option', name: 'GLI' },
    { value: 'Sanlam Beneficiary nomination', label: 'Sanlam Beneficiary Nomination', name: 'Sanlam Beneficiary Nom' },
    { value: 'Sanlam Funeral Cover', label: 'Sanlam Funeral Cover', name: 'Funeral Cover' },
    { value: 'Funeral Nomination Form', label: 'Funeral Nomination Form', name: 'Funeral Nomination Form' },
    {
        value: 'Alexander Forbes Beneficiary Nomination',
        label: 'Alexander Forbes Beneficiary Nomination',
        name: 'AF Beneficiary Nom'
    },
    { value: 'Provident Fund New Member', label: 'Provident Fund New Member', name: 'PF New Member' },
    { value: 'Kaelo GAP Cover', label: 'Kaelo GAP Cover', name: 'GAP Cover' },
    { value: 'Kaelo Doctor Nomination', label: 'Kaelo Doctor Nomination', name: 'Kaelo Doctor Nom' },
    { value: 'Emergency Contact Form', label: 'Emergency Contact Form', name: 'Emergency Contact Form' },
    { value: 'New Employee Details', label: 'New Employee Details', name: 'Personal Info' },
    { value: 'Dept of Labour EEA1', label: 'Dept of Labour EEA1', name: 'EEA1' },
    { value: 'Payroll Giving', label: 'Payroll Giving', name: 'Payroll Giving' },
    { value: 'Passport', label: 'Passport', name: 'Passport' },
    { value: 'Identity Document', label: 'Identity Document', name: 'ID Copy' },
    { value: 'Marriage Certificate', label: 'Marriage Certificate', name: 'Marriage Cert' },
    {
        value: 'Beneficiary ID / Birth Certificates',
        label: 'Beneficiary ID / Birth Certificates',
        name: 'Beneficiary ID/BC'
    },
    { value: "Driver's License", label: "Driver's License", name: 'Drivers Lic' },
    { value: 'Bank Letter', label: 'Bank Letter', name: 'Bank Letter' },
    { value: 'Fixed Term Contract', label: 'Fixed Term Contract', name: 'FTC' },
    { value: 'Addendum to Fixed Term Contract', label: 'Addendum to Fixed Term Contract', name: 'Addendum to FTC' },
    { value: 'Discovery New Member Application', label: 'Discovery New Member Application', name: 'Discovery New' },
    { value: 'Discovery Membership Transfer', label: 'Discovery Membership Transfer', name: 'Discovery Transfer' },
    { value: 'Discovery Vitality Application', label: 'Discovery Vitality Application', name: 'Vitality New' },
    { value: 'Discovery Add Dependent', label: 'Discovery Add Dependent', name: 'Discovery Add Dep' },
    { value: 'Discovery Add New Born Baby', label: 'Discovery Add New Born Baby', name: 'Discovery Add Baby' },
    { value: 'Membership Certificate', label: 'Membership Certificate', name: 'Membership Cert' },
    { value: 'Keycare Application', label: 'KeyCare Application', name: 'KeyCare New' },
    { value: 'Keycare Income Verification', label: 'KeyCare Income Verification', name: 'KeyCare Inc Verification' },
    { value: 'Kaelo Underwriting', label: 'Kaelo Underwriting', name: 'Kaelo Underwriting' },
    { value: 'Kaelo Application', label: 'Kaelo Application', name: 'Kaelo New' },
    { value: 'Criminal Background Check', label: 'Criminal Background Check', name: 'Crim Checks' },
    { value: 'Mock Payslip', label: 'Mock Payslip', name: 'Mock Payslip' },
    { value: 'Previous Company Payslips', label: 'Previous Company Payslips', name: 'Previous Payslips' },
    { value: 'resume', label: 'Resume', name: 'CV' },
    { value: 'Offer Letter', label: 'Offer Letter', name: 'Offer Letter/Email' },
    { value: 'AOD Take on or Acknowledgement', label: 'AOD Take on or Acknowledgement', name: 'AOD Takeon' },
    { value: 'Tax number', label: 'Tax Number', name: 'Tax no' },
    { value: 'Labour Broker Agreement', label: 'Labour Broker Agreement', name: 'LB Agreement' },
    { value: 'Service Provider Agreement', label: 'Service Provider Agreement', name: 'SP Agreement' },
    {
        value: 'Independent Contractor Questionnaire',
        label: 'Independent Contractor Questionnaire',
        name: 'Ind Cont Questionnaire'
    },
    { value: 'Internship Agreement', label: 'Internship Agreement', name: 'Intern Agreement' },
    { value: 'Work Permit', label: 'Work Permit', name: 'Work permit' },
    { value: 'criminal-check-report', label: 'Criminal Check', name: 'Criminal Check' },
    { value: 'criminal-check-saps-report', label: 'SAPS Report', name: 'SAPS Report' }
];

export const agencyTypeOptions = [
    { value: 'Agency Terms of Service', label: 'Agency Terms of Service', name: 'Agency Terms of Service' }
];

export const uploadedTypesOptions: any[] = [
    {
        title: 'Onboarding Documents',
        types: documentTypeOptions
    }
    // {
    //     title: 'Employee Documents',
    //     types: [
    //         { value: 'Resume' },
    //         { value: 'Portfolio' },
    //         { value: 'Certificate' },
    //         { value: 'Cover Letter' },
    //         { value: 'Letter of Employment' },
    //         { value: 'Employment Contract' },
    //         { value: 'Other' }
    //     ]
    // },
    // {
    //     title: 'Personal Files',
    //     types: [
    //         { value: 'Identity Document', single: true },
    //         { value: 'Passport', single: true },
    //         { value: 'Work Permit / Visa', single: true },
    //         { value: 'Proof of Address' },
    //         { value: 'Proof of Bank Account' },
    //         { value: 'Marriage Certificate' },
    //         { value: 'Tax Registration Certificate' },
    //         { value: 'Other' }
    //     ]
    // },
];
export const icons: any[] = [
    { url: 'icons-8-smiling-face-with-heart.svg' },
    { url: 'icons-8-filled-like.svg' },
    { url: 'icons-8-wall-clock.svg' },
    { url: 'icons-8-property-time.svg' },
    { url: 'icons-8-heart-health.svg' },
    { url: 'icons-8-food.svg' },
    { url: 'icons-8-vegetarian-food.svg' },
    { url: 'icons-8-sun.svg' },
    { url: 'icons-8-car.svg' },
    { url: 'icons-8-bicycle.svg' },
    { url: 'icons-8-handshake.svg' },
    { url: 'icons-8-appointment-reminders.svg' },
    { url: 'icons-8-monitor.svg' },
    { url: 'icons-8-place-marker.svg' },
    { url: 'icons-8-christmas-star-2.svg' },
    { url: 'icons-8-lock-2.svg' },
    { url: 'icons-8-thumb-up.svg' },
    { url: 'icons-8-airport.svg' },
    { url: 'icons-8-money.svg' },
    { url: 'icons-8-shield.svg' },
    { url: 'icons-8-heart-with-pulse.svg' },
    { url: 'icons-8-dog.svg' },
    { url: 'icons-8-toggle-on.svg' },
    { url: 'icons-8-dumbbell.svg' }
];
export const fts_reasons: string[] = [
    'The Employee is replacing another employee who is temporarily absent from work;',
    'The Employee is employed on account of a temporary increase in the volume of work which is not expected to endure beyond 12 months;',
    'The Employee is a student or recent graduate who is employed for the purposes of being trained or gaining work experience in order to enter a job or profession;',
    'The Employee is employed to work exclusively on a specific project that has a limited/defined duration;',
    'The Employee is employed to perform seasonal work;',
    'The Employee is employed in a position which is funded by an external source for a limited period;',
    'The Employee has reached a normal or agreed retirement age applicable in the Company’s business.'
];

export const fts_reasons_extra: string =
    'The Employee is a non-South African citizen who has been granted permission to work in South Africa and/or for the Company for a defined period of time.';

export const ftsReasonsCfao: string[] = [
    'Temporary replacement for current employee',
    'Temporary increase of work volume',
    'To work on a specific project with limited duration',
    'Non-RSA citizen with a valid work permit & passport',
    'Limited duration Petrol / Diesel Apprentice MERSETA contract'
];

export const salaryOptions: SelectItem[] = [
    { label: 'per hour', value: 'hourly' },
    { label: 'per day', value: 'dayly' },
    { label: 'per week', value: 'weekly' },
    { label: 'per month', value: 'monthly' },
    { label: 'per year', value: 'yearly' }
];
