import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
    data: any;
    options: any;
    @Input() chartData;
    @Input() chartOptions;

    constructor() {}

    ngOnInit(): void {
        // console.log('BAR CHART', this.chartData, this.chartOptions);
        this.data = this.chartData;
        this.options = this.chartOptions;
    }
}
