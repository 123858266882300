<div class="modal-default-style">
    <div class="content">
        <div class="header">
            <h3>Mark {{ candidate.first_name }} {{ candidate.last_name }} as Do Not Hire?</h3>
            <button class="close-btn btn" (click)="onHideModal()">
                <img src="/assets/images/icons/icons-8-delete.svg" />
            </button>
        </div>
        <div class="form-content-section">
            <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <textarea
                    matInput
                    [(ngModel)]="formControl"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="4"
                    resize="vertical"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="footer">
            <button class="btn btn-primary mla" (click)="onMark()">Mark as Do Not Hire</button>
        </div>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideModal()"></div>
