<div class="left-col">
    <div class="inner">
        <pdf-viewer
            *ngIf="file"
            [src]="file.link"
            [render-text]="true"
            [original-size]="false"
            [autoresize]="true"
            style="display: block; max-width: 100%; height: 100%;"
        >
        </pdf-viewer>
    </div>
    <button (click)="onClose()" class="close close-modal-btn">
        <img src="/assets/images/icons/icons-8-delete.svg" />
    </button>
    <button class="btn upload-btn" (click)="onDonloadDocument()">
        <img src="/assets/images/download.svg" height="14" alt="Upload Icon" />
    </button>
</div>
<div class="right-col">
    <div class="inner"></div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
