<div [formGroup]="form">
    <div formArrayName="filters">
        <div *ngFor="let item of filters.controls; let i = index; let last = last" [formGroupName]="i">
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <p-dropdown
                            class="clear-drop"
                            [dropdownIcon]="'pi pi-chevron-down'"
                            [options]="dropdownOptions[item.value.type]"
                        ></p-dropdown>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col short">
                        <app-auto-dropdown
                            *ngIf="item.value.type !== 'cost' && item.value.type !== 'salary'"
                            [options]="conditionTypeOptions2"
                            [control]="item.get('condition')"
                        ></app-auto-dropdown>
                        <app-auto-dropdown
                            *ngIf="item.value.type === 'cost' || item.value.type === 'salary'"
                            [options]="conditionTypeOptions"
                            [control]="item.get('condition')"
                        ></app-auto-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="form-block-columns max-width">
                    <div class="form-block-col">
                        <input
                            *ngIf="item.value.type === 'cost' || item.value.type === 'salary'"
                            type="number"
                            class="form-input"
                            formControlName="value"
                        />
                        <div class="input-block" *ngIf="item.value.type === 'salary_start_date'">
                            <mat-form-field appearance="outline" (click)="picker2.open()">
                                <input formControlName="value" matInput [matDatepicker]="picker2" autocomplete="off" />
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-error *ngIf="!item.get('value').valid && item.get('value').touched">
                                    Salary Start Date is required</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div class="input-block" *ngIf="item.value.type === 'date_of_birth'">
                            <mat-form-field appearance="outline" (click)="picker3.open()">
                                <input formControlName="value" matInput [matDatepicker]="picker3" autocomplete="off" />
                                <mat-datepicker #picker3></mat-datepicker>
                                <mat-error *ngIf="!item.get('value').valid && item.get('value').touched">
                                    Date of birth is required</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div
                            class="input-block"
                            *ngIf="
                                item.value.type === 'type' ||
                                item.value.type === 'job_location' ||
                                item.value.type === 'gender' ||
                                item.value.type === 'ethnicity' ||
                                item.value.type === 'status' ||
                                item.value.type === 'department' ||
                                item.value.type === 'designation' ||
                                item.value.type === 'business_unit' ||
                                item.value.type === 'level' ||
                                item.value.type === 'shift' ||
                                item.value.type === 'marital_status' ||
                                item.value.type === 'blood_group' ||
                                item.value.type === 'languages'
                            "
                        >
                            <app-auto-dropdown
                                [disabled]="item.get('value').disabled"
                                [options]="filtersOptions[item.value.type]"
                                [control]="item.get('value')"
                                [ngClass]="{
                                    invalid:
                                        !item.get('value').valid &&
                                        item.get('value').touched &&
                                        !item.get('value').disabled
                                }"
                            >
                            </app-auto-dropdown>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'type' &&
                                    !item.get('value').disabled
                                "
                            >
                                Employee type is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'job_location' &&
                                    !item.get('value').disabled
                                "
                            >
                                Location is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'gender' &&
                                    !item.get('value').disabled
                                "
                            >
                                Gender is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'ethnicity' &&
                                    !item.get('value').disabled
                                "
                            >
                                Ethnicity is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'status' &&
                                    !item.get('value').disabled
                                "
                            >
                                Status is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'department'
                                "
                            >
                                Department is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'designation'
                                "
                            >
                                Designation is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'business_unit'
                                "
                            >
                                Business Unit is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid && item.get('value').touched && item.value.type === 'level'
                                "
                            >
                                Level is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid && item.get('value').touched && item.value.type === 'shift'
                                "
                            >
                                Shift is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'marital_status'
                                "
                            >
                                Marital Status is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'blood group'
                                "
                            >
                                Blood Group is required
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid &&
                                    item.get('value').touched &&
                                    item.value.type === 'languages'
                                "
                            >
                                Spoken Language is required
                            </div>
                        </div>

                        <div class="input-block" *ngIf="item.value.type === 'tags'">
                            <app-auto-dropdown
                                [disabled]="item.get('value').disabled"
                                [options]="filtersOptions[item.value.type]"
                                [control]="item.get('value')"
                                [ngClass]="{
                                    invalid:
                                        !item.get('value').valid &&
                                        item.get('value').touched &&
                                        !item.get('value').disabled
                                }"
                            >
                            </app-auto-dropdown>
                            <div
                                class="error-message"
                                *ngIf="
                                    !item.get('value').valid && item.get('value').touched && item.value.type === 'tags'
                                "
                            >
                                Tags is required
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-block-col-separator"
                        *ngIf="
                            filters.controls.length - 1 !== i ||
                            (filters.controls.length - 1 === i && entryCriteriaDropDown)
                        "
                    ></div>
                    <div
                        class="form-block-col shortest"
                        *ngIf="
                            filters.controls.length - 1 !== i ||
                            (filters.controls.length - 1 === i && entryCriteriaDropDown)
                        "
                    >
                        <app-auto-dropdown
                            [disabled]="item.get('compare').disabled"
                            [options]="compareTypeOptions"
                            [control]="item.get('compare')"
                            (onChange)="onChangeCompareOptions()"
                        ></app-auto-dropdown>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <button class="btn btn-grey minus" (click)="onDelete(i)"></button>
                    <button class="btn btn-grey plus" (click)="showEntryCriteriaDropDown(i)"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="form-block mb30">
        <div class="form-block-columns" *ngIf="entryCriteriaDropDown">
            <div class="form-block-col">
                <div class="input-block">
                    <p-dropdown
                        class="clear-drop"
                        [options]="entryRulesOptions"
                        placeholder="Select a criteria"
                        [dropdownIcon]="'pi pi-chevron-down'"
                        formControlName="entry_criteria"
                        (onChange)="onChangeValue($event)"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="info-circle">
                                {{ item?.label }}
                            </div>
                        </ng-template>
                        <ng-template let-new pTemplate="item">
                            <div class="info-circle" *ngIf="!new?.selected">
                                {{ new?.label }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div
                        class="error-message"
                        *ngIf="!form.controls.entry_criteria.valid && form.controls.entry_criteria.touched"
                    >
                        Criteria is required
                    </div>
                </div>
            </div>
            <div class="form-block-col-separator"></div>
            <div class="form-block-col short"></div>
        </div>
    </div>
</div>
<app-loader class="inner-loader" *ngIf="innerContentLoading"></app-loader>
<div class="users-matching-block">
    <ng-container *ngIf="filteredUsers.length">
        <h4>{{ filteredUsers.length }} employees match your criteria</h4>
        <div class="users-list">
            <div class="list-header">
                <div class="flex-50">Name</div>
                <div class="flex-50">Type</div>
            </div>
            <div class="list-content">
                <div class="list-item" *ngFor="let user of filteredUsers">
                    <div class="col flex-50">
                        <div class="user-avatar">
                            <span
                                *ngIf="user.profile_image"
                                [ngStyle]="{
                                    'background-image': 'url(' + user.profile_image + ')'
                                }"
                            ></span>
                            <span *ngIf="!user.profile_image">
                                {{ user.initials }}
                            </span>
                        </div>
                        <span>{{ user.name }}</span>
                    </div>
                    <div class="col flex-50">
                        <span>Employee</span>
                    </div>
                </div>
            </div>
            <!-- <div class="list-footer">
            And 2,839 employees like this
        </div> -->
        </div>
    </ng-container>
    <ng-container *ngIf="filteredUsersRequestsComplete && filteredUsers.length === 0">
        <div class="no-match-user">
            No users match these rules yet
        </div>
    </ng-container>
</div>
