<div class="container candidate-component">
    <div class="candidate-component__profile" *ngIf="candidate">
        <div class="candidate-votes">
            <ng-container *ngIf="candidate?.complianceRateClass !== 'pending' && !fromPeople && !offerAssessment">
                <button
                    class="like"
                    (click)="onShowModal()"
                    *ngIf="
                        user?.role !== 'recruitment_agency' &&
                        user?.role !== 'agency_user' &&
                        candidate?.stageClass !== 'deleted'
                    "
                >
                    <img src="/assets/images/icons/icons-8-thumbs-down.svg" alt="" />
                </button>
            </ng-container>
            <ng-container *ngIf="candidate?.complianceRateClass === 'pending' && !fromPeople">
                <button class="like" (click)="onSilentDecline()">
                    <img src="/assets/images/icons/icons-8-denied.svg" alt="" />
                </button>
            </ng-container>
            <div class="rating candidate-votes__dropdown" [ngClass]="{ show: showRatingDropdown }">
                <button [disabled]="!jobOwner" class="fdr aic" (click)="onRatingClick($event)">
                    <span>Rating</span>
                    <img class="button-icon" src="/assets/images/icons/icons-8-sort-down.svg" alt="" />
                </button>
                <div class="content-rating">
                    <app-candidate-item-feedback
                        [feedback]="candidate.feedback"
                        (feedbackUpdate)="recalculateRating($event, job)"
                        [currentRating]="currentRating"
                        [job]="job"
                        [jobId]="job.id"
                        [candidateId]="candidate.id"
                        *ngIf="job && candidate && showRatingDropdown"
                    >
                    </app-candidate-item-feedback>
                </div>
            </div>
            <div
                class="rating candidate-votes__dropdown quick-actions"
                [ngClass]="{ show: showQuickActionsDropdown }"
                *ngIf="user?.role !== 'recruitment_agency' && user?.role !== 'agency_user' && user.role !== 'employee'"
            >
                <button class="fdr aic" (click)="onQuickActionsClick($event)">
                    <span>Quick Actions</span>
                    <img class="button-icon" src="/assets/images/icons/icons-8-sort-down.svg" alt="" />
                </button>
                <div class="content-rating">
                    <ng-container *ngIf="!fromPeople">
                        <button
                            class="progress candidate-votes__dropdown"
                            (click)="onProgress()"
                            [disabled]="
                                user?.role === 'recruitment_agency' ||
                                user?.role === 'agency_user' ||
                                !isProgressable ||
                                !candidateIsActive ||
                                acceptedAnotherOffer ||
                                isDeleted
                            "
                            *ngIf="
                                candidate?.stageClass !== 'deleted' &&
                                candidate?.stageClass !== 'pending' &&
                                candidate?.stageClass !== 'yellow'
                            "
                        >
                            Progress
                        </button>
                    </ng-container>
                    <button
                        class="progress candidate-votes__dropdown"
                        (click)="onCriminalCheckClick()"
                        *ngIf="
                            user?.role !== 'recruitment_agency' &&
                            user?.role !== 'agency_user' &&
                            (!candidate.criminalCheckStatus || candidate.criminalCheckStatus === 'complete')
                        "
                    >
                        Criminal and ID Verification
                    </button>
                    <ng-container *ngIf="!fromPeople">
                        <button
                            class="progress candidate-votes__dropdown"
                            (click)="showUndeclineModal()"
                            *ngIf="
                                user?.role !== 'recruitment_agency' &&
                                user?.role !== 'agency_user' &&
                                candidate?.stageClass === 'deleted'
                            "
                        >
                            Un-decline
                        </button>
                        <button
                            class="progress candidate-votes__dropdown"
                            (click)="onAddCandidateToJob(candidate)"
                            *ngIf="
                                candidate?.stageClass === 'pending' &&
                                job &&
                                job.status !== 'closed' &&
                                job.status !== 'hold'
                            "
                        >
                            Add to Job
                        </button>
                    </ng-container>
                    <button
                        class="progress candidate-votes__dropdown"
                        (click)="onAddCandidateToRole(candidate)"
                        *ngIf="user?.role === 'admin' || user?.role === 'account_owner' || user?.role === 'recruiter'"
                    >
                        Add to Another Role
                    </button>
                    <button
                        class="progress candidate-votes__dropdown"
                        (click)="onGenerateResume()"
                        *ngIf="
                            candidate.resume_file && user?.role !== 'recruitment_agency' && user?.role !== 'agency_user'
                        "
                    >
                        Generate Branded Resume
                    </button>
                    <button
                        class="progress candidate-votes__dropdown"
                        (click)="onShowDoNotHireModal()"
                        *ngIf="!candidate?.do_not_hire"
                    >
                        Mark as Do Not Hire
                    </button>
                    <button
                        class="progress candidate-votes__dropdown"
                        (click)="onCareerPortalProfile()"
                        *ngIf="candidate?.hasUser && user.role && user.role !== 'employee'"
                    >
                        Career Portal Profile
                    </button>
                </div>
            </div>
        </div>
        <div class="candidate-main-info">
            <div class="img-block" *ngIf="candidate.profile_image_link">
                <img [src]="candidate.profile_image_link" alt="profile image" />
            </div>
            <strong class="name">{{ candidate.first_name | titlecase }} {{ candidate.last_name | titlecase }}</strong>
            <p class="position-block" *ngIf="candidate.employment_history && candidate.employment_history.length">
                <span class="position">{{ candidate.last_position | titlecase }} </span>
            </p>
            <div class="information-circles">
                <!-- Matching Score -->
                <!-- 0 to 19% = E (Red)
                20 to 39% = D (Red)
                40 to 59% = C (Orange)
                60 to 79% = B (Green)
                80% to 100% = A (Green) -->
                <div
                    class="info-circle matching"
                    [ngClass]="{
                        red: candidateScore < 40,
                        orange: candidateScore >= 40 && candidateScore < 60,
                        green: candidateScore >= 60
                    }"
                    *ngIf="candidateScoreDefined"
                    pTooltip="{{ candidateScore | number: '0.0-0' }}"
                    tooltipPosition="bottom"
                >
                    <span>
                        <ng-container *ngIf="candidateScore < 20">E</ng-container>
                        <ng-container *ngIf="candidateScore >= 20 && candidateScore < 40">D</ng-container>
                        <ng-container *ngIf="candidateScore >= 40 && candidateScore < 60">C</ng-container>
                        <ng-container *ngIf="candidateScore >= 60 && candidateScore < 80">B</ng-container>
                        <ng-container *ngIf="candidateScore >= 80">A</ng-container>
                    </span>
                </div>
                <!-- Enthnicity -->
                <div
                    class="info-circle"
                    *ngIf="candidate.ethnicity"
                    [pTooltip]="candidate.ethnicity"
                    tooltipPosition="bottom"
                >
                    {{ candidate.ethnicity | titlecase | slice: 0:1 }}
                </div>
                <!-- Gender -->
                <div
                    class="info-circle"
                    *ngIf="candidate.gender"
                    [pTooltip]="candidate.gender"
                    tooltipPosition="bottom"
                >
                    {{ candidate.gender | titlecase | slice: 0:1 }}
                </div>

                <!-- Relocate -->
                <div
                    class="info-circle relocate"
                    [ngClass]="{ ready: candidate.relocate, 'not-ready': !candidate.relocate }"
                    [pTooltip]="candidate.relocate ? 'Will relocate' : 'Will not relocate'"
                    tooltipPosition="bottom"
                    *ngIf="showRelocate"
                >
                    <img *ngIf="candidate.relocate" src="/assets/images/relocate.svg" alt="" />
                    <img *ngIf="!candidate.relocate" src="/assets/images/no-relocate.svg" alt="" />
                </div>

                <!-- Identity Verification -->
                <div
                    class="info-circle id-verification"
                    [ngClass]="{ verified: candidate.idVerified, 'not-verified': !candidate.idVerified }"
                    [pTooltip]="candidate.idVerified ? 'Identity is verified' : 'Identity is not verified'"
                    tooltipPosition="bottom"
                    *ngIf="showIdVerified"
                >
                    <img src="/assets/images/icons-8-face-id.svg" alt="" />
                </div>
                <!-- Criminal Record Verification -->
                <div
                    class="info-circle criminal-check"
                    [ngClass]="{ clear: candidate.criminalCheck === 'clear', hit: candidate.criminalCheck === 'hit' }"
                    [pTooltip]="candidate.criminalCheck === 'clear' ? 'Clear' : 'HIT'"
                    tooltipPosition="bottom"
                    *ngIf="showCriminalCheck && candidate.criminalCheck"
                >
                    <img src="/assets/images/icons-8-fingerprint.svg" alt="" />
                </div>
            </div>
            <div
                class="view-employee-profile"
                *ngIf="candidate?.employee && !candidate?.offer_pending"
                (click)="onNavigateToEmployeeProfile()"
            >
                <span>View Employee Profile</span>
                <svg-icon
                    [svgStyle]="{ 'width.px': 12, 'height.px': 12, fill: '#ffc107' }"
                    src="/assets/images/icons/icons-8-link.svg"
                >
                </svg-icon>
            </div>
            <div class="dnh-block" *ngIf="candidate?.do_not_hire">
                Do Not Hire
            </div>
            <ng-container *ngIf="currentRating && !contentLoading">
                <div class="candidate-rating-info flex-row jcc">
                    <svg
                        *ngFor="let key of currentRating | countIterable"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                    >
                        <path
                            fill="#F7B500"
                            d="M7.5 0L5.328 5.148 0 5.73 3.984 9.492 2.863 15 7.5 12.175 12.137 15 11.016 9.492 15 5.73 9.672 5.148z"
                            transform="translate(.438)"
                        />
                    </svg>
                </div>
            </ng-container>
        </div>
        <div class="candidate-profile-row tags">
            <div class="title">
                <span>Tags</span>
            </div>
            <div class="content">
                <app-candidate-tags
                    *ngIf="candidate"
                    [job]="job"
                    [candidate]="candidate"
                    [offerAudit]="offerAudit"
                    [tagChanged]="tagChanged"
                    (tagUpdated)="tagUpdated($event)"
                >
                </app-candidate-tags>
            </div>
        </div>
        <div class="candidate-profile-row-separator" *ngIf="appliedJobs.length"></div>
        <div class="candidate-profile-row applied-jobs" *ngIf="appliedJobs.length">
            <div class="title">
                <span>Applied Jobs</span>
            </div>
            <div class="content">
                <div class="applied-dropdown">
                    <p-dropdown
                        class="clear-drop"
                        [options]="appliedJobs"
                        [(ngModel)]="appliedJobs[appliedJobsId]"
                        [dropdownIcon]="'pi pi-chevron-down'"
                        (onChange)="selectAppliedJob($event)"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="title-drop apply-item">
                                <div class="position">{{ item?.label }}</div>
                                <div class="location">{{ item?.value?.location }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-new pTemplate="item">
                            <div class="apply-item title-drop in-content">
                                <div class="position">{{ new?.label }}</div>
                                <div class="location">{{ new?.value?.location }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div
                    class="applied-progress"
                    *ngIf="appliedJobs && appliedJobs[appliedJobsId] && appliedProgress?.length"
                >
                    <ol class="ProgressBar">
                        <li
                            class="ProgressBar-step"
                            [ngClass]="{ 'is-complete': dot.active && !dot.last, 'is-current': dot.active && dot.last }"
                            *ngFor="let dot of appliedProgress"
                        >
                            <div class="ProgressBar-icon"></div>
                            <div class="label-custom" *ngIf="dot.active && dot.last">
                                <span>{{ appliedJobs[appliedJobsId]?.value?.stage_label | titlecase }}</span>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="candidate-profile-row-separator" *ngIf="potentialOwners && potentialOwners?.length"></div>
        <div class="candidate-profile-row owner" *ngIf="potentialOwners && potentialOwners?.length">
            <div class="title">
                <span>Owner</span>
            </div>
            <div class="content">
                <p-dropdown
                    class="clear-drop"
                    [filter]="true"
                    filterBy="label"
                    [options]="potentialOwners"
                    [(ngModel)]="currentOwner"
                    filterPlaceholder="Search"
                    [dropdownIcon]="'pi pi-chevron-down'"
                    (onChange)="changeOwner($event)"
                    [disabled]="!changeOwnershipAllowed"
                >
                    <ng-template let-item pTemplate="selectedItem">
                        <div
                            *ngIf="currentOwner"
                            class="info-circle"
                            [ngStyle]="{ 'background-color': item?.value.color }"
                        >
                            {{ item?.label | titlecase | slice: 0:1 }}
                        </div>
                        <span *ngIf="currentOwner"
                            >{{ item?.label }} {{ user.id === item?.value.id ? ' (Me)' : '' }}</span
                        >
                        <span *ngIf="!currentOwner">Unassigned</span>
                    </ng-template>
                    <ng-template let-new pTemplate="item">
                        <div class="info-circle" [ngStyle]="{ 'background-color': new?.value.color }">
                            {{ new?.label | titlecase | slice: 0:1 }}
                        </div>
                        <span>{{ new?.label }} {{ user.id === new?.value.id ? ' (Me)' : '' }}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="candidate-profile-row contact-row" *ngIf="currentAgencyOwner?.label">
            <div class="title">
                <span>AGENCY</span>
            </div>
            <div class="content">
                <div class="detail-information">
                    <div class="grey-circle-bg">
                        <svg-icon
                            [svgStyle]="{ 'width.px': 12, 'height.px': 12, fill: '#637381' }"
                            src="/assets/images/link-chain-svgrepo-com.svg"
                        ></svg-icon>
                    </div>
                    <p>{{ currentAgencyOwner?.label }}</p>
                </div>
            </div>
        </div>
        <div class="candidate-profile-row-separator"></div>
        <div class="candidate-profile-row contact-row">
            <div class="title edited">
                <span>Contact</span>
                <button
                    class="btn btn-round"
                    *ngIf="changeOwnershipAllowed && candidate?.hasUser"
                    (click)="setCandidateProfileMode()"
                >
                    <img src="assets/images/edit-dark.svg" alt="Edit contact" width="14" height="14" />
                </button>
            </div>
            <div class="content">
                <div class="detail-information">
                    <img src="assets/images/email-send.svg" height="13px" alt="Email icon" />
                    <p>
                        <a href="mailto:{{ candidate.email }}">{{ candidate.email }}</a>
                    </p>
                </div>
                <div class="detail-information">
                    <img src="assets/images/phone.svg" height="13px" alt="Phone icon" />
                    <p class="text">{{ candidate.phone || 'Add a phone number' }}</p>
                </div>
                <div class="detail-information">
                    <img src="assets/images/location.svg" height="14px" alt="Location icon" />
                    <p>{{ candidate?.address?.locationShort || candidate.location || 'Unknown Location' }}</p>
                </div>
                <div class="detail-information" *ngIf="candidate.id_number">
                    <img src="assets/images/icons/passport-svgrepo-com.svg" height="14px" alt="Passport icon" />
                    <p
                        *ngIf="
                            user.role === 'admin' ||
                                user.role === 'account_owner' ||
                                user.role === 'recruiter' ||
                                jobOwner;
                            else obfuscated
                        "
                    >
                        {{ candidate.id_number }}
                    </p>
                    <ng-template #obfuscated>
                        <p>{{ candidate?.obfuscated_id_number }}</p>
                    </ng-template>
                </div>
                <div class="detail-information" *ngIf="candidate.website_url">
                    <img src="assets/images/web.svg" height="13px" width="13px" alt="web icon" />
                    <p class="text">
                        <a href="{{ candidate.website_url }}">{{ candidate.website_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.facebook_url">
                    <img src="assets/images/facebook.svg" alt="Facebook icon" />
                    <p class="text">
                        <a href="{{ candidate.facebook_url }}"> {{ candidate.facebook_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.instagram_url">
                    <img src="assets/images/instagram_new.svg" alt="Instagram icon" />
                    <p class="text">
                        <a href="{{ candidate.instagram_url }}"> {{ candidate.instagram_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.github_url">
                    <img src="assets/images/github.svg" alt="Github icon" />
                    <p class="text">
                        <a href="{{ candidate.github_url }}"> {{ candidate.github_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.linkedin_url">
                    <img src="assets/images/linkedin.svg" alt="Linkedin icon" />
                    <p class="text">
                        <a href="{{ candidate.linkedin_url }}"> {{ candidate.linkedin_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.twitter_url">
                    <img src="assets/images/twitter.svg" alt="Twitter icon" />
                    <p class="text">
                        <a href="{{ candidate.twitter_url }}"> {{ candidate.twitter_url }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="candidate-component__info">
        <div class="sections-nav">
            <button class="btn grey send-email" (click)="onShowEmailModal()">
                <img src="/assets/images/icons/icons-8-chat-bubble.svg" alt="" style="width: 14px; height: 11px;" />
            </button>
            <div
                class="section-nav-link"
                *ngFor="let section of sections"
                (click)="onChangeSection(section)"
                [ngClass]="{
                    active: activeSection === section,
                    danger: (section === 'questions' || section === 'assessments') && isKnockout(section) === 'danger',
                    warning: (section === 'questions' || section === 'assessments') && isKnockout(section) === 'warning'
                }"
            >
                <div class="circle warning"></div>
                <div class="circle danger"></div>
                <span>{{ section | titlecase }}</span>
            </div>
            <!-- <div class="offer-actions" *ngIf="offerAssessment">
                <button class="btn btn-danger-inversed" (click)="onOfferAccepted(false)">Declined</button>
                <button class="btn btn-primary-inversed" (click)="onOfferAccepted(true)">Accepted</button>
            </div> -->
        </div>
        <div class="candidate-content">
            <app-candidate-offer-tracking
                *ngIf="
                    candidate &&
                    job &&
                    (offerAssessment ||
                        (candidate['offer-approval'] && candidate['offer-approval'].status !== 'approve'))
                "
                [candidate]="candidate"
                [job]="job"
                [user]="user"
            >
            </app-candidate-offer-tracking>
            <!-- <app-candidate-offer-approval-reject
                *ngIf="candidate && candidate['offer-approval'] && candidate['offer-approval'].status !== 'approve'"
                [candidate]="candidate"
                [job]="job"
                [user]="user"
            >
            </app-candidate-offer-approval-reject>
            <app-candidate-offer-block
                *ngIf="
                    offerAssessment &&
                    job &&
                    candidate &&
                    candidate.assignments &&
                    ((!candidate['offer-approval'] && !candidate['offer-approval']?.status) ||
                        (candidate['offer-approval'] && candidate['offer-approval'].status === 'approve'))
                "
                [candidate]="candidate"
                [job]="job"
                [user]="user"
            >
            </app-candidate-offer-block> -->
            <!-- <div
                class="alert-block grey-bg"
                *ngIf="
                    !isSelfDeleted &&
                    !isDeleted &&
                    !acceptedAnotherOffer &&
                    offerAssessment &&
                    !offerAssessment.hasOwnProperty('offer_accepted')
                "
            >
                <div>
                    <div class="alert-block-title">Candidate is under offer on this job</div>
                    <div class="alert-block-description">
                        CANDIDATE ALERT
                    </div>
                </div>
                <div class="alert-btns">
                    <ng-container *ngIf="offerAssessment?.offer_documents">
                        <button class="btn resend-btn" (click)="onResendOffer(job.id, candidate.id)">
                            Resend Offer
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!offerAssessment?.offer_documents">
                        <button class="btn decline-btn" (click)="onOfferAccepted(false)">Offer Declined</button>
                        <button class="btn btn-primary-inversed" (click)="onOfferAccepted(true)">Offer Accepted</button>
                    </ng-container>
                </div>
            </div> -->
            <ng-container
                *ngIf="
                    false &&
                    !isSelfDeleted &&
                    !isDeleted &&
                    !acceptedAnotherOffer &&
                    offerAssessment &&
                    offerAssessment.hasOwnProperty('offer_accepted')
                "
            >
                <div
                    class="alert-block"
                    [ngClass]="{
                        'green-bg': offerAssessment.offer_accepted,
                        'red-bg': !offerAssessment.offer_accepted
                    }"
                >
                    <div>
                        <div class="alert-block-title" *ngIf="offerAssessment.offer_accepted">
                            Candidate has accepted the offer
                        </div>
                        <div class="alert-block-title" *ngIf="!offerAssessment.offer_accepted">
                            Candidate has declined the offer
                        </div>
                        <div class="alert-block-description">
                            CANDIDATE ALERT
                        </div>
                    </div>
                    <div class="icon" *ngIf="offerAssessment.offer_accepted">
                        <img src="/assets/images/icons/icons-8-checked.svg" alt="" />
                    </div>
                    <div class="icon" *ngIf="!offerAssessment.offer_accepted">
                        <img src="/assets/images/icons/not-checked.svg" alt="" />
                    </div>
                </div>
            </ng-container>

            <div class="alert-block red-bg" *ngIf="!isSelfDeleted && !isDeleted && acceptedAnotherOffer">
                <div>
                    <div class="alert-block-title">Candidate has accepted another job</div>
                    <div class="alert-block-description">
                        CANDIDATE ALERT
                    </div>
                </div>
                <div class="icon">
                    <img src="/assets/images/icons/not-checked.svg" alt="" />
                </div>
            </div>
            <div
                class="alert-block orange-bg"
                *ngIf="
                    !isSelfDeleted && !isDeleted && !acceptedAnotherOffer && underReviewOnAnotherJob && !offerAssessment
                "
            >
                <div>
                    <div class="alert-block-title">Candidate is under offer on another job</div>
                    <div class="alert-block-description">
                        CANDIDATE ALERT
                    </div>
                </div>
                <span class="dropdown-dots">
                    <span class="dots"><i></i><i></i><i></i></span>
                    <div class="dropdown">
                        <button class="btn btn-dropdown" (click)="onViewJobOffer()">
                            <svg-icon
                                [svgStyle]="{ 'width.px': 12, 'height.px': 12, fill: '#000000' }"
                                src="/assets/images/icons/icons-8-link.svg"
                            >
                            </svg-icon>
                            View Job Offer
                        </button>
                    </div>
                </span>
                <!-- <div class="icon">
                    <div class="status-icon warning">
                        <div class="holder">
                            <div class="bar1"></div>
                            <div class="bar2"></div>
                            <div class="bar3"></div>
                            <div class="fill"></div>
                            <div class="mark"></div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="alert-block red-bg" *ngIf="isSelfDeleted && (isDeleted || !isDeleted)">
                <div>
                    <div class="alert-block-title">Candidate withdrew their application</div>
                    <div class="alert-block-description">
                        CANDIDATE ALERT
                    </div>
                </div>
                <div class="icon">
                    <img src="/assets/images/icons/not-checked.svg" alt="" />
                </div>
            </div>
            <div class="alert-block red-bg" *ngIf="!isSelfDeleted && isDeleted">
                <div>
                    <div class="alert-block-title">Candidate has been declined</div>
                    <div class="alert-block-description">
                        CANDIDATE ALERT
                    </div>
                </div>
                <div class="icon">
                    <img src="/assets/images/icons/not-checked.svg" alt="" />
                </div>
            </div>
            <ng-container>
                <div class="content-section" *ngIf="activeSection === 'overview'">
                    <ng-container *ngIf="job">
                        <app-candidate-overview
                            *ngIf="job && candidate"
                            [offerAssessment]="offerAssessment"
                            [candidate]="candidate"
                            [resumeThreshold]="resumeThreshold"
                            [documents]="documents"
                            [documentsLoading]="documentsLoading"
                            [jobId]="job.id"
                            [job]="job"
                            [doNotMatch]="job.do_not_match"
                            [documentsTypeOptions]="documentsTypeOptions"
                            [isDeleted]="isDeleted"
                            (uploadFile)="uploadFile($event)"
                            (onAddCandidateToJob)="onAddCandidateToJob($event)"
                            (onSilentDecline)="onSilentDecline()"
                        >
                        </app-candidate-overview>
                    </ng-container>
                    <ng-container *ngIf="!job">
                        <app-candidate-overview
                            *ngIf="candidate"
                            [offerAssessment]="offerAssessment"
                            [candidate]="candidate"
                            [resumeThreshold]="resumeThreshold"
                            [documents]="documents"
                            [documentsLoading]="documentsLoading"
                            [documentsTypeOptions]="documentsTypeOptions"
                            [jobId]=""
                            [isDeleted]="isDeleted"
                            (uploadFile)="uploadFile($event)"
                            (onAddCandidateToJob)="onAddCandidateToJob($event)"
                            (onSilentDecline)="onSilentDecline()"
                        >
                        </app-candidate-overview>
                    </ng-container>
                </div>
                <div class="content-section" *ngIf="activeSection === 'details'">
                    <app-candidate-details [candidate]="candidate"></app-candidate-details>
                </div>
                <div class="content-section" *ngIf="activeSection === 'questions'">
                    <app-candidate-questions
                        *ngIf="(job || criminalCheckAss) && candidate && user"
                        [questionnaires]="questionnaires"
                        [candidate]="candidate"
                        [personality_assessment]="personality_assessment"
                        [logicTest]="logicTest"
                        [stagesData]="stagesData"
                        [radar_chart_data]="radar_chart_data"
                        [stageId]="stageId"
                        [job]="job"
                        [user]="user"
                        [videos]="videos"
                        [personalityProfileScores]="personalityProfileScores"
                        [criminalCheckAss]="criminalCheckAss"
                        (extendDeadline)="changeExtendDeadline($event)"
                    >
                    </app-candidate-questions>
                </div>
                <div class="content-section" *ngIf="activeSection === 'interviews'">
                    <app-candidate-interviews
                        *ngIf="job && candidate"
                        [job]="job"
                        [candidate]="candidate"
                        [candidateInterviews]="candidateInterviews"
                        [videos]="videos"
                        [questionnaires]="questionnaires"
                        [logicTest]="logicTest"
                        [stagesData]="stagesData"
                        [radar_chart_data]="radar_chart_data"
                        [personality_assessment]="personality_assessment"
                        [personalityProfileScores]="personalityProfileScores"
                    ></app-candidate-interviews>
                </div>
                <div class="content-section" *ngIf="activeSection === 'history'">
                    <app-candidate-item-timeline
                        *ngIf="candidate"
                        [job]="job"
                        [candidate]="candidate"
                        [offerAudit]="offerAudit"
                        [dataChanged]="dataChanged"
                    >
                    </app-candidate-item-timeline>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-loader *ngIf="contentLoading"></app-loader>
<p-dialog
    [(visible)]="declineModalVisible"
    (onHide)="onShowModal(false)"
    [style]="{ width: '460px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
    *ngIf="job"
    [formGroup]="declineForm"
>
    <div class="p-header">
        <svg
            width="19.4px"
            height="16.7px"
            viewBox="0 0 30 26"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <g id="Recruit-Setup" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Candidate-View---Details" transform="translate(-326.000000, -356.000000)">
                    <g id="icons8-error" transform="translate(326.000000, 356.000000)">
                        <path
                            d="M27.5675676,26 L1.62162162,26 C1.04027027,26 0.505135135,25.6888125 0.215675676,25.18425 C-0.072972973,24.6796875 -0.0713513514,24.0589375 0.221351351,23.556 L13.1943243,0.806 C13.4845946,0.307125 14.0181081,0 14.5945946,0 C15.1710811,0 15.7045946,0.307125 15.9956757,0.806 L28.9686486,23.556 C29.2613514,24.0589375 29.262973,24.6796875 28.9743243,25.18425 C28.6856757,25.6888125 28.1481081,26 27.5675676,26 Z"
                            id="Path"
                            fill="#FFC107"
                        ></path>
                        <path
                            d="M12.972973,21.240375 C12.972973,21.0218125 13.0110811,20.8219375 13.0889189,20.63425 C13.1651351,20.449 13.2745946,20.288125 13.4156757,20.15325 C13.5543243,20.01675 13.7254054,19.9103125 13.9216216,19.8339375 C14.1194595,19.7591875 14.3416216,19.719375 14.5897297,19.719375 C14.8378378,19.719375 15.0616216,19.7591875 15.2610811,19.8339375 C15.4637838,19.9103125 15.6348649,20.01675 15.7735135,20.15325 C15.9154054,20.288125 16.0248649,20.449 16.1002703,20.63425 C16.1781081,20.8219375 16.2162162,21.0218125 16.2162162,21.240375 C16.2162162,21.45975 16.1781081,21.659625 16.1002703,21.841625 C16.0240541,22.0244375 15.9145946,22.1820625 15.7735135,22.319375 C15.6348649,22.45425 15.4637838,22.559875 15.2610811,22.637875 C15.0616216,22.712625 14.8378378,22.75 14.5897297,22.75 C14.3408108,22.75 14.1194595,22.712625 13.9216216,22.6370625 C13.7254054,22.5590625 13.5543243,22.4534375 13.4156757,22.3185625 C13.2737838,22.18125 13.1643243,22.023625 13.0889189,21.8408125 C13.0110811,21.6588125 12.972973,21.4589375 12.972973,21.240375 Z M15.7945946,17.875 L13.3840541,17.875 L13.0427027,8.125 L16.1351351,8.125 L15.7945946,17.875 Z"
                            id="Shape"
                            fill="#fff"
                            fill-rule="nonzero"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
        Decline Candidates
    </div>
    <div class="p-body">
        <p>
            You are about to decline <b>1</b> candidate from <b>{{ job.title }}</b> role.
        </p>
        <p>Please provide a reason</p>
        <div style="position: relative">
            <textarea class="textarea" formControlName="reason"></textarea>
            <div class="error-message" *ngIf="!declineForm.get('reason').valid && declineForm.get('reason').touched">
                Reason is required
            </div>
        </div>
    </div>
    <div class="p-footer">
        <div class="action">
            <p-checkbox binary="true" label="Send decline notification email" formControlName="send_email">
            </p-checkbox>
            <button class="btn danger" (click)="onDecline()">
                Decline
            </button>
        </div>
    </div>
</p-dialog>
<app-email-modal
    *ngIf="candidate && job"
    [(visible)]="emailModalVisible"
    [jobId]="[job?.id]"
    [candidates]="[[candidate.id, candidateNameOrEmail]]"
    (onHide)="onShowEmailModal(false)"
>
</app-email-modal>

<p-dialog
    [(visible)]="undeclineModalVisible"
    (onHide)="onHideUndeclineModal()"
    [style]="{ width: '460px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
    *ngIf="job"
>
    <div class="p-header p-header-new bg-green">
        <p>Un-decline Candidate?</p>
        <button class="close-btn" (click)="onHideUndeclineModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new fz15">Are you sure you want to un-decline this candidate?</div>
    <div class="p-footer p-footer-new">
        <div class="action">
            <button class="btn" (click)="undeclineCandidate()">Un-decline</button>
        </div>
    </div>
</p-dialog>

<app-candidate-item-profile-edit-modal
    *ngIf="candidate && editCandidateProfileMode"
    [candidate]="candidate"
    (closeModal)="onCandidateProfileClosed($event)"
>
</app-candidate-item-profile-edit-modal>

<app-criminal-check-modal
    *ngIf="candidate && showCriminalCheckModal"
    [candidate]="candidate"
    [jobId]="job.id"
    (assignmentAdded)="onCriminalCheckAssAdded($event)"
    (closeModal)="onCriminalCheckModalClose($event)"
>
</app-criminal-check-modal>
<ng-container *ngIf="viewJobOfferModal.show">
    <div class="view-job-offer-modal">
        <div class="inner">
            <pdf-viewer
                *ngIf="viewJobOfferModal.url"
                [src]="viewJobOfferModal.url"
                [render-text]="true"
                [original-size]="false"
                [autoresize]="true"
                style="display: block; max-width: 100%; height: 100%;"
            >
            </pdf-viewer>
            <button class="close-btn" (click)="viewJobOfferModal.show = false">
                <img src="/assets/images/icons/icons-8-delete.svg" />
            </button>
        </div>
        <div class="overlay"></div>
    </div>
</ng-container>

<app-add-role-modal
    *ngIf="addCandidateToRoleModal.show"
    (close)="onCloseRoleModal()"
    [candidate]="addCandidateToRoleModal.candidate"
    [users]="users"
>
</app-add-role-modal>
<app-not-hire-modal
    *ngIf="showDoNotHireModal.show"
    (close)="onCloseDoNotHireModal()"
    (mark)="onMarkAsDoNotHire($event)"
    [candidate]="showDoNotHireModal.candidate"
>
</app-not-hire-modal>
