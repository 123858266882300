import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStagesData from '../reducers/general-information.reducer';

export const getGeneralState = createFeatureSelector<fromStagesData.StagesDataListState>('general');

export const getGeneralLoading = createSelector(
    getGeneralState,
    (state: fromStagesData.StagesDataListState) => state.loading
);

export const getDocumentTypesData = createSelector(
    getGeneralState,
    (state: fromStagesData.StagesDataListState) => state.document_types
);

export const getBusinessUnits = createSelector(
    getGeneralState,
    (state: fromStagesData.StagesDataListState) => state.business_units
);
