<div
    class="alert-block"
    [ngClass]="{
        'red-bg': offerAssessment?.retracted || offerAssessment?.offer_accepted === false,
        'green-bg':
            (offerAssessment || offerAssessment?.offer_accepted) &&
            !offerAssessment?.retracted &&
            offerAssessment?.offer_accepted !== false
    }"
    *ngIf="offerAssessment"
>
    <div class="img-wrapper">
        <img src="/assets/images/icons/icons-8-signing-a-document.svg" alt="" />
    </div>
    <div>
        <div
            class="alert-block-title"
            *ngIf="
                offerAssessment &&
                !offerAssessment?.retracted &&
                !offerAssessment?.offer_accepted &&
                offerAssessment?.offer_accepted !== false
            "
        >
            Candidate is under offer on this job
        </div>
        <div class="alert-block-title" *ngIf="offerAssessment?.retracted">Offer retracted by recruiter</div>
        <div class="alert-block-title" *ngIf="offerAssessment?.offer_accepted">Candidate has accepted the offer</div>
        <div class="alert-block-title" *ngIf="offerAssessment?.offer_accepted === false">
            Offer declined by candidate
        </div>
        <div class="alert-block-description">
            CANDIDATE ALERT
        </div>
    </div>
    <span class="dropdown-dots" *ngIf="!offerAssessment?.offer_accepted && offerAssessment?.offer_accepted !== false">
        <span class="dots"><i></i><i></i><i></i></span>
        <div class="dropdown">
            <button
                *ngIf="
                    !someoneSigned &&
                    !offerAssessment?.offer_accepted &&
                    offerAssessment?.offer_accepted !== false &&
                    !offerAssessment.retracted
                "
                class="btn btn-dropdown"
                (click)="onEditOffer()"
            >
                <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                Resend Offer
            </button>
            <button
                *ngIf="
                    retractAvailable &&
                    !offerAssessment?.retracted &&
                    offerAssessment?.offer_accepted !== true &&
                    offerAssessment?.offer_accepted !== false
                "
                class="btn btn-dropdown"
                (click)="onRetractOffer()"
            >
                <img src="/assets/images/icons/icons-8-delete-document.svg" alt="" />
                Retract Offer
            </button>
            <button
                *ngIf="
                    offerAssessment?.offer_accepted !== false &&
                    !offerAssessment?.retracted &&
                    offerAssessment?.offer_accepted !== true
                "
                class="btn btn-dropdown"
                (click)="onResendOffer()"
            >
                <img src="/assets/images/icons/icons-8-forward-message.svg" alt="" />
                Resend Notification
            </button>
            <button
                *ngIf="
                    offerAssessment?.offer_accepted !== false &&
                    offerAssessment?.offer_accepted !== true &&
                    !offerAssessment?.retracted
                "
                class="btn btn-dropdown"
                (click)="onCandidateDeclineOffer()"
            >
                <img src="/assets/images/icons/icons-8-id-not-verified.svg" alt="" />
                Candidate Declined
            </button>
            <button *ngIf="offerAssessment?.retracted" class="btn btn-dropdown" (click)="onEditOffer()">
                <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                Send Offer
            </button>
            <button
                *ngIf="!offerAssessment?.retracted && reOffer.available"
                class="btn btn-dropdown"
                (click)="onReOffer()"
            >
                <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                Re-offer
            </button>
        </div>
    </span>
</div>
<app-offer-letter
    *ngIf="acceptedOfferModal.show"
    (close)="acceptedOfferModal = { show: false }"
    (send)="onSentOffer($event)"
    (approval)="onSentOffer($event)"
    [candidate]="acceptedOfferModal.data"
    [workflows]="acceptedOfferModal.workflows"
    [job]="job"
>
</app-offer-letter>

<app-loader *ngIf="contentLoading"></app-loader>
