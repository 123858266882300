import { Action } from '@ngrx/store';
export const LOAD_GENERAL_DATA = '[General] Load General Data';
export const LOAD_GENERAL_DATA_FAIL = '[General] Load General Data Fail';
export const LOAD_GENERAL_DATA_SUCCESS = '[General] Load General Data Success';

export class LoadGeneralData implements Action {
    readonly type = LOAD_GENERAL_DATA;
}

export class LoadGeneralDataFail implements Action {
    readonly type = LOAD_GENERAL_DATA_FAIL;
    constructor(public payload: any) {}
}

export class LoadGeneralDataSuccess implements Action {
    readonly type = LOAD_GENERAL_DATA_SUCCESS;
    constructor(public payload: any) {}
}

export type GeneralDataAction = LoadGeneralData | LoadGeneralDataFail | LoadGeneralDataSuccess;
