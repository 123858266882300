import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '@app/core/services/user.service';
import { environment } from '@env/environment';
import { Subject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    apiURL: string = environment.apiUrl;
    tenantId: string;
    private subject: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private firestore: AngularFirestore,
        private utilities: UtilitiesService,
        private userService: UserService
    ) {
        this.tenantId = this.utilities.getTenant();
    }

    getSurveys() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/surveys`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getSurveysByType(type) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/surveys`, (ref) => ref.where('rules.type', '==', type))
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getSurveyById(id) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/surveys`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    saveSurvey(surveyId, data: any, id = null) {
        if (surveyId === 'new') {
            return this.firestore
                .collection(`tenants/${this.tenantId}/surveys`)
                .doc(id)
                .set(data);
        } else {
            return this.firestore
                .collection(`tenants/${this.tenantId}/surveys`)
                .doc(surveyId)
                .update(data);
        }
    }

    startOneOffSurvey(surveyId) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/surveys/${surveyId}/send`, {})
            .pipe(catchError((error: any) => throwError(error)));
    }

    startScheduleSurvey(surveyId) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/surveys/${surveyId}/schedule`, {})
            .pipe(catchError((error: any) => throwError(error)));
    }

    getEmployeesForSurvey(surveyId) {
        return new Promise(async (resolve, reject) => {
            try {
                const employees: any[] = await this.firestore
                    .collection(
                        `tenants/${this.utilities.getTenant()}/candidates_surveys_assignments/${surveyId}/applicants`
                    )
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1))
                    .toPromise();
                return resolve(employees);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getAllEmployeesAssignment() {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates_surveys_assignments`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getEmployeesAssignment(surveyId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates_surveys_assignments/${surveyId}/candidates`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
    }

    getViewsSurvey(surveyId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates_surveys_assignments/${surveyId}/views`)
            .valueChanges()
            .pipe(take(1))
            .toPromise();
    }

    getStartsSurvey(surveyId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates_surveys_assignments/${surveyId}/starts`)
            .valueChanges()
            .pipe(take(1))
            .toPromise();
    }

    downloadSurveyReport(surveyId, data) {
        return this.http.post(
            `${this.apiURL}/report/tenants/${this.utilities.getTenant()}/survey/${surveyId}/create-report`,
            data
        );
    }
}
