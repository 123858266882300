import { Action } from '@ngrx/store';

// load jobs
export const LOAD_STAGES_DATA = '[Jobs] Load Stages Data';
export const LOAD_STAGES_DATA_FAIL = '[Jobs] Load Stages Data Fail';
export const LOAD_STAGES_DATA_SUCCESS = '[Jobs] Load Stages Data Success';

export class LoadStagesData implements Action {
    readonly type = LOAD_STAGES_DATA;
}

export class LoadStagesDataFail implements Action {
    readonly type = LOAD_STAGES_DATA_FAIL;
    constructor(public payload: any) {}
}

export class LoadStagesDataSuccess implements Action {
    readonly type = LOAD_STAGES_DATA_SUCCESS;
    constructor(public payload: any) {}
}

// update(load) questionnaires Stages Data
export const UPDATE_QUESTIONNAIRES_STAGES_DATA = '[Jobs] Update Questionnaires Stages Data';
export const UPDATE_QUESTIONNAIRES_STAGES_DATA_FAIL = '[Jobs] Update Questionnaires Stages Data Fail';
export const UPDATE_QUESTIONNAIRES_STAGES_DATA_SUCCESS = '[Jobs] Update Questionnaires Stages Data Success';

export class UpdateQuestionnairesStagesData implements Action {
    readonly type = UPDATE_QUESTIONNAIRES_STAGES_DATA;
    constructor() {}
}

export class UpdateQuestionnairesStagesDataFail implements Action {
    readonly type = UPDATE_QUESTIONNAIRES_STAGES_DATA_FAIL;
    constructor(public payload: any) {}
}

export class UpdateQuestionnairesStagesDataSuccess implements Action {
    readonly type = UPDATE_QUESTIONNAIRES_STAGES_DATA_SUCCESS;
    constructor(public payload) {}
}

export type StagesDataAction =
    | LoadStagesData
    | LoadStagesDataFail
    | LoadStagesDataSuccess
    | UpdateQuestionnairesStagesData
    | UpdateQuestionnairesStagesDataFail
    | UpdateQuestionnairesStagesDataSuccess;
