import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { pick } from 'lodash';
import moment from 'moment';
import { map, retry, take } from 'rxjs/operators';
import { Candidate } from '../models';
import { Event } from '../models/events';
import { User } from '../models/user';
import { TenantService } from './tenant.service';
import { UtilitiesService } from './utilities.service';

const eventUpdateFields = [
    'title',
    'capacity',
    'description',
    'end_date',
    'end_time',
    'id',
    'is_virtual',
    'location',
    'location_short',
    'meeting_url',
    'presenters',
    'request_attendee',
    'room',
    'start_date',
    'start_time',
    'title',
    'status',
    'promotional_image'
];

@Injectable({
    providedIn: 'root'
})
export class EventService {
    apiURL: string = environment.apiUrl;
    baseURL = '';
    constructor(
        private http: HttpClient,
        private utilities: UtilitiesService,
        private db: AngularFirestore,
        private tenantService: TenantService
    ) {
        this.tenantService.init();
    }

    getEventById$(eventId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/events-items`)
            .doc(eventId)
            .valueChanges()
            .pipe(
                take(1),
                map((event: Event) => {
                    if (event) {
                        event.id = eventId;
                        return event;
                    } else {
                        return null;
                    }
                })
            );
    }
    getAllEvents() {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/events-items`, (ref) => ref.orderBy('start_date'))
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                retry(2),
                map((events) => {
                    return events
                        .sort((a: any, b: any) => {
                            const dateA = moment(a.start_date, 'DD-MM-YYYY').unix();
                            const dateB = moment(b.start_date, 'DD-MM-YYYY').unix();
                            if (dateA === dateB) {
                                const timeA = moment(a.start_time, 'HH:mm').unix();
                                const timeB = moment(b.start_time, 'HH:mm').unix();
                                return timeA - timeB;
                            } else {
                                return dateA - dateB;
                            }
                        })
                        .sort((a: any, b: any) => {
                            if (a.status === 'published' && b.status !== 'published') {
                                return -1;
                            } else if (a.status === 'published' && b.status !== 'published') {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                })
            );
    }

    getEventById(eventId): Promise<Event> {
        return new Promise(async (resolve, reject) => {
            this.getEventById$(eventId).subscribe(
                (event: Event) => {
                    event.id = eventId;
                    return resolve(event);
                },
                (errorResponse) => reject(errorResponse)
            );
        });
    }

    saveEvent(event: Event, user: User): Promise<Event> {
        return new Promise(async (resolve, reject) => {
            try {
                if (event.id) {
                    // Update
                    // console.log('saveEvent => update', event);
                    const updData = pick(event, eventUpdateFields);
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/events-items`)
                        .doc(event.id)
                        .update(updData);

                    delete event.created;
                    return resolve(event);
                } else {
                    event.created_by = user.id;
                    event.created_at = Math.floor(Date.now() / 1000);
                    // console.log('saveEvent => create', event);
                    const response = await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/events-items`)
                        .add(event);
                    const eventId = response.id;
                    const dbEvent = await this.getEventById(eventId);
                    return resolve(dbEvent);
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    getAllAttendees(eventId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/events-items/${eventId}/attendees`, (ref) =>
                ref.orderBy('added_at')
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1)
                // map((attendees) => {
                //     return attendees.sort((a: any, b: any) => {
                //         return a.added_at - b.added_at;
                //     });
                // })
            );
    }

    async updateStatus(eventId, status) {
        return new Promise(async (resolve, reject) => {
            const record = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/events-items`)
                .doc(eventId)
                .update({
                    status
                });
            resolve(record);
        });
    }

    async declineAttendee(eventId, attendeeId) {
        return new Promise(async (resolve, reject) => {
            const record = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/events-items/${eventId}/attendees`)
                .doc(attendeeId)
                .update({
                    status: 'decline'
                });
            const candidate: any = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                .doc(attendeeId)
                .valueChanges()
                .pipe(take(1))
                .toPromise();
            if (!candidate.declined_event_id) {
                candidate.declined_event_id = [];
            }
            if (candidate.declined_event_id.indexOf(eventId) === -1) {
                candidate.declined_event_id.push(eventId);
            }
            candidate.event_id = candidate.event_id.filter((id) => id !== eventId);
            await this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                .doc(attendeeId)
                .update({
                    declined_event_id: candidate.declined_event_id,
                    event_id: candidate.event_id
                });
            const attendees: any =
                (await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/events-items/${eventId}/attendees`)
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise()) || [];
            const in_person = attendees.filter((a) => a.type === 'in_person' && a.status === 'accepted');
            const virtual = attendees.filter((a) => a.type === 'virtual' && a.status === 'accepted');
            await this.db
                .collection(`tenants/${this.utilities.getTenant()}/events-items`)
                .doc(eventId)
                .update({
                    attendees_in_person: in_person.length || 0,
                    attendees_virtual: virtual.length || 0,
                    total: attendees.length || 0
                });
            resolve(record);
        });
    }

    notifyAttendees(eventId) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/event/${eventId}/notify-attendees`);
    }
}
