import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent, UnsupportedBrowserComponent } from './core/components';
import { LandingPageComponent } from './core/components/landing-page/landing-page.component';
import { MsalSignoutComponent } from './core/components/msal-signout/msal-signout.component';
import { StartComponent } from './core/components/start.component';
import { AuthGuard, BrowserGuard } from './core/guards';
import { MaintenancePageComponent } from './modules/shared/maintenance-page/maintenance-page.component';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [BrowserGuard, AuthGuard],
        component: StartComponent
    },
    {
        path: 'auth',
        canActivateChild: [BrowserGuard],
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: 'tenant/:tenantId/hire',
        canActivateChild: [BrowserGuard],
        loadChildren: () => import('./products/hire/hire.module').then((m) => m.HireModule)
    },
    {
        path: 'tenant/:tenantId/landing',
        canActivate: [BrowserGuard, AuthGuard],
        component: LandingPageComponent
    },
    {
        path: 'recruiters',
        canActivateChild: [BrowserGuard],
        loadChildren: () =>
            import('./products/hire/modules/recruiters/recruiters.module').then((m) => m.RecruitersModule)
    },
    { path: 'msal-signout', component: MsalSignoutComponent },
    { path: 'unsupported-browser', component: UnsupportedBrowserComponent },
    { path: 'not-found', component: PageNotFoundComponent },
    { path: 'maintenance', component: MaintenancePageComponent },
    { path: '**', redirectTo: 'not-found' }
    // { path: '**', redirectTo: 'maintenance' }
];

@NgModule({
    declarations: [StartComponent],
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
