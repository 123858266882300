import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { environment } from '@env/environment';
import { take, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeService {
    apiURL: string = environment.apiUrl;
    baseURL = '';
    constructor(private utilities: UtilitiesService, private firestore: AngularFirestore, private http: HttpClient) {}

    getDocuments() {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/documents-types`, (ref) => ref.orderBy('name', 'asc'))
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getDocument(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/documents-types`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    addDocument(template) {
        return this.firestore.collection(`tenants/${this.utilities.getTenant()}/documents-types`).add(template);
    }

    updateDocument(id, data) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/documents-types`)
            .doc(id)
            .update(data);
    }

    deleteDocument(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/documents-types`)
            .doc(id)
            .delete();
    }

    async setDocumentStatus(type, status) {
        const documents: any[] = await this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/documents-types`, (ref) => ref.where('name', '==', type))
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
        for (let document of documents) {
            this.updateDocument(document.id, { active: status });
        }
    }

    // deleteDocument(id) {
    //     return this.http.delete(
    //         `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/documents-types/${id}`
    //     );
    // }
}
