<div
    class="candidate-tags-preview"
    *ngIf="(candidate.tags || []).length > 0 || candidateRating"
    [ngClass]="{ 'with-rating': candidateRating }"
>
    <div class="tags-block" *ngIf="(candidate.tags || []).length > 0">
        <div
            *ngFor="let tag of candidate.tags || []"
            class="tag"
            [style.background]="tag.color"
            [pTooltip]="titleCase(tag.hash)"
            tooltipPosition="top"
        ></div>
    </div>
    <div class="rating-preview" *ngIf="candidateRating">
        <svg
            *ngFor="let key of candidateRating | countIterable"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 16 15"
        >
            <path
                fill="#F7B500"
                d="M7.5 0L5.328 5.148 0 5.73 3.984 9.492 2.863 15 7.5 12.175 12.137 15 11.016 9.492 15 5.73 9.672 5.148z"
                transform="translate(.438)"
            />
        </svg>
    </div>
</div>
<div class="candidate-info">
    <div class="ava-column">
        <div class="ava">
            <img [src]="candidate.profile_image_link" alt="profile image" *ngIf="candidate.profile_image_link" />
            <div class="ava-placeholder" *ngIf="!candidate.profile_image_link">
                {{ candidate | initials }}
            </div>
            <div
                *ngIf="userRole !== 'recruitment_agency' && userRole !== 'agency_user'"
                class="check-wrapper"
                [ngClass]="{ checked: selected }"
                (click)="onSelectCandidateClick($event, candidate.id)"
            >
                <div class="check-box">
                    <img src="/assets/images/check.svg" />
                </div>
            </div>
        </div>
    </div>
    <div class="block-content">
        <strong
            class="title"
            *ngIf="candidate.first_name || candidate.last_name; else noFirstName"
            [ngClass]="{ read: candidate.hasRead }"
        >
            {{ candidate.first_name | titlecase }} {{ candidate.last_name | titlecase }}</strong
        >
        <ng-template #noFirstName>
            <strong class="title" [ngClass]="{ read: candidate.hasRead }">{{ candidate.email }}</strong>
        </ng-template>
        <span class="position">{{ candidate.lastPosition }}</span>
    </div>
</div>
<div class="candidate-footer">
    <ng-container *ngIf="stage === 'applied'">
        <div
            class="candidate-status"
            [ngClass]="{
                error: candidate.stageClass === 'red',
                success: candidate.stageClass === 'green',
                acceptable: candidate.stageClass === 'orange',
                awaiting: candidate.stageClass === 'yellow',
                pending: candidate.stageClass === 'pending',
                expired: candidate.stageClass === 'expired',
                progress: candidate.stageClass === 'grey',
                progressred: candidate.stageClass === 'grey-red',
                progressyellow: candidate.stageClass === 'grey-yellow',
                notification: candidate.stageClass === 'notification',
                'resume-required': candidate.stageClass === 'resume-required',
                'self-deleted': candidate.stageClass === 'self-deleted',
                deleted: candidate.stageClass === 'deleted',
                'under-review': candidate.stageClass === 'under-review',
                'accepted-another-offer': candidate.stageClass === 'accepted-another-offer'
            }"
        >
            <div class="inner-block success">
                <div class="status-icon success">
                    <div class="holder"></div>
                </div>
                Successful
            </div>
            <div class="inner-block error">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Failed
            </div>
            <div class="inner-block acceptable">
                <div class="status-icon acceptable">
                    <div class="holder"></div>
                </div>
                Partially successful
            </div>
            <div class="inner-block awaiting">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Pending, awaiting response
            </div>
            <div class="inner-block required">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Resume required
            </div>
            <div class="inner-block pending">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                New matched candidate, awaiting review
            </div>
            <div class="inner-block expired">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Expired
            </div>
            <div class="inner-block progress">
                <div class="status-icon empty green">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block progressred">
                <div class="status-icon empty red">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block progressyellow">
                <div class="status-icon empty yellow">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block empty notification">
                <div class="status-icon empty">
                    <div class="holder"></div>
                </div>
                Notification sent
            </div>
            <div class="inner-block self-deleted">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate withdrew application
            </div>
            <div class="inner-block deleted">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate declined
            </div>
            <div class="inner-block under-review">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Candidate under offer on another job
            </div>
            <div class="inner-block accepted-another-offer">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate accepted another job
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="stage !== 'applied'">
        <div
            class="candidate-status"
            [ngClass]="{
                error: candidate.stageClass === 'red',
                success: candidate.stageClass === 'green',
                acceptable: candidate.stageClass === 'orange',
                expired: candidate.stageClass === 'yellow',
                progress: candidate.stageClass === 'grey',
                progressred: candidate.stageClass === 'grey-red',
                progressyellow: candidate.stageClass === 'grey-yellow',
                accepted: candidate.stageClass === 'offer_accepted',
                declined: candidate.stageClass === 'offer_declined',
                notification: candidate.stageClass === 'notification',
                'self-deleted': candidate.stageClass === 'self-deleted',
                deleted: candidate.stageClass === 'deleted',
                'under-review': candidate.stageClass === 'under-review',
                'accepted-another-offer': candidate.stageClass === 'accepted-another-offer',
                'offer-signing': candidate.stageClass === 'offer-signing',
                'offer-retracted': candidate.stageClass === 'offer-retracted',
                'offer-approval': candidate.stageClass === 'offer-approval',
                'offer-approval-rejected': candidate.stageClass === 'offer-approval-rejected'
            }"
        >
            <div class="inner-block success">
                <div class="status-icon success">
                    <div class="holder"></div>
                </div>
                Successful
            </div>
            <div class="inner-block error">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Failed
            </div>
            <div class="inner-block acceptable">
                <div class="status-icon acceptable">
                    <div class="holder"></div>
                </div>
                Partially successful
            </div>
            <div class="inner-block progress">
                <div class="status-icon empty green">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block progressred">
                <div class="status-icon empty red">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block progressyellow">
                <div class="status-icon empty yellow">
                    <div class="holder"></div>
                </div>
                In progress…
            </div>
            <div class="inner-block expired">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Expired
            </div>
            <div class="inner-block accepted">
                <div class="status-icon success-inversed">
                    <div class="holder"></div>
                </div>
                Offer Accepted
            </div>
            <div class="inner-block declined">
                <div class="status-icon error-inversed">
                    <div class="holder"></div>
                </div>
                Offer Declined
            </div>
            <div class="inner-block offer-retracted">
                <div class="status-icon error-inversed">
                    <div class="holder"></div>
                </div>
                Offer retracted by recruiter
            </div>
            <div class="inner-block offer-signing">
                <div class="status-icon empty green">
                    <div class="holder"></div>
                </div>
                Out for Signing
            </div>
            <div class="inner-block offer-approval-rejected">
                <div class="status-icon error-inversed">
                    <div class="holder"></div>
                </div>
                HR Rejected
            </div>
            <div class="inner-block offer-approval">
                <div class="status-icon success">
                    <div class="holder"></div>
                </div>
                Offer Acceptance Pending
            </div>
            <div class="inner-block empty notification">
                <div class="status-icon empty">
                    <div class="holder"></div>
                </div>
                Notification sent
            </div>
            <div class="inner-block self-deleted">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate withdrew application
            </div>
            <div class="inner-block deleted">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate declined
            </div>
            <div class="inner-block under-review">
                <div class="status-icon warning">
                    <div class="holder">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="fill"></div>
                        <div class="mark"></div>
                    </div>
                </div>
                Candidate under offer on another job
            </div>
            <div class="inner-block accepted-another-offer">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
                Candidate accepted another job
            </div>
        </div>
    </ng-container>
</div>
