<div class="content">
    <div class="header">
        <h3>Approve Document</h3>
        <button class="close-btn btn" (click)="onClose()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="form-content-section" [formGroup]="form">
        <div class="form-content-holder">
            <p class="text">
                Would you like to send this document directly to the provider?
            </p>
            <mat-radio-group formControlName="send_email">
                <mat-radio-button [value]="true">
                    <p>Yes, send to provider</p>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    <p>No, do not send</p>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="form.get('send_email').value">
                <p class="text mb20">Which attachments should be included in email to the vendor?</p>
                <div formArrayName="attachments">
                    <div
                        class="attachment-block"
                        *ngFor="let item of form.get('attachments')['controls']; let i = index"
                        [formGroupName]="i"
                    >
                        <mat-checkbox formControlName="checked">
                            {{ item.value.title }}
                        </mat-checkbox>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="footer">
        <button class="btn btn-grey" (click)="onClose()">Cancel</button>
        <button class="btn btn-primary" (click)="onApprove()">
            Approve Document
        </button>
    </div>
</div>

<app-loader *ngIf="contentLoading"></app-loader>
