import * as fromGeneralActions from '../actions/general-information.action';

export interface StagesDataListState {
    document_types: any;
    business_units: any[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: StagesDataListState = {
    document_types: null,
    business_units: null,
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromGeneralActions.GeneralDataAction): StagesDataListState {
    switch (action.type) {
        case fromGeneralActions.LOAD_GENERAL_DATA: {
            return { ...state, loading: true };
        }

        case fromGeneralActions.LOAD_GENERAL_DATA_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromGeneralActions.LOAD_GENERAL_DATA_SUCCESS: {
            const data = action.payload;
            const document_types = data[0]
                ? data[0].map((r) => {
                      return { label: r.name, value: r.name, type: r.type, slug: r.slug };
                  })
                : [];
            const business_units = data[1] && data[1].length ? data[1] : [{ value: 'other', label: 'Other' }];
            return {
                ...state,
                document_types,
                business_units
            };
        }
    }
    return state;
}

export const getDataLoading = (state: StagesDataListState) => state.loading;
export const getDataLoaded = (state: StagesDataListState) => state.loaded;
export const getDocumentTypesData = (state: StagesDataListState) => state.document_types;
