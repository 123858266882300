<p-dialog [(visible)]="visible" [style]="{ width: '450px' }" [modal]="true" [showHeader]="false" class="decline-modal">
    <div class="p-header p-header-new bg-green">
        View AOD Statement
        <button class="close-btn" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="modal-body">
        <div class="form-content-section">
            <div class="form-content-holder">
                <p class="mb0 paragraph">
                    Please select the date that you would like the statement to be prepared for.
                </p>
                <div class="input-block">
                    <mat-calendar
                        [minDate]="minDate"
                        [selected]="selectedDate"
                        (selectedChange)="onSelect($event)"
                    ></mat-calendar>
                </div>
            </div>
        </div>
    </div>
    <div class="p-footer p-footer-new">
        <div class="action">
            <button class="btn add-workflow-btn" (click)="onViewStatement()">
                View Statement
            </button>
        </div>
    </div>
</p-dialog>
<app-loader *ngIf="contentLoading"></app-loader>
