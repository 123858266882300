import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Candidate } from '@app/core/models';
import { CandidateService, UtilitiesService } from '@app/core/services';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { MinTwoWordsValidator } from '@app/core/validators/min-two-words.validator';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { SelectItem } from 'primeng/api';
import { TenantService } from './../../../../../../core/services/tenant.service';

@Component({
    selector: 'app-candidate-item-profile-edit-modal',
    templateUrl: './candidate-item-profile-edit-modal.component.html',
    styleUrls: ['./candidate-item-profile-edit-modal.component.scss']
})
export class CandidateItemProfileEditModalComponent implements OnInit {
    @Input() candidate: Candidate;
    @Input() showModal: boolean;
    @Output() closeModal = new EventEmitter<number>();

    allInfo = [
        { value: 'facebook_url', label: 'Facebook', icon: 'facebook.svg' },
        { value: 'twitter_url', label: 'Twitter', icon: 'twitter.svg' },
        { value: 'linkedin_url', label: 'LinkedIn', icon: 'linkedin.svg' },
        { value: 'instagram_url', label: 'Instagram', icon: 'instagram_new.svg' },
        { value: 'github_url', label: 'Github', icon: 'github.svg' }
    ];
    notAppliedInfos: any[] = [{ isStandart: true, label: 'Select Value', value: '' }];
    appliedInfos: any[] = [];
    addressOptions: SelectItem[] = [];
    locationOptions: any;

    initialCountry: string = 'za';
    form: FormGroup;

    @ViewChild('locationInputRef') locationInputRef: ElementRef;
    inputAddress: string = '';
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    place: any;

    isEmpty: boolean = false;
    createNewLocation: boolean = false;

    constructor(
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private candidateService: CandidateService,
        private tenantService: TenantService,
        private utilities: UtilitiesService
    ) {
        this.tenantService.init();
        this.locationOptions = {
            bounds: null,
            componentRestrictions: {
                country: []
            }
        };
    }

    ngOnInit() {
        this.form = this.fb.group(
            {
                fullName: [
                    (this.candidate && (this.candidate.first_name || '') + ' ' + (this.candidate.last_name || '')) ||
                        '',
                    Validators.required
                ],
                email: [(this.candidate && this.candidate.email) || '' || '', Validators.required],
                location: [(this.candidate && this.candidate.location) || '' || '', Validators.required],
                address: [''],
                phone: [(this.candidate && this.candidate.phone) || ''],

                facebook_url: [(this.candidate && this.candidate.facebook_url) || ''],
                instagram_url: [(this.candidate && this.candidate.instagram_url) || ''],
                github_url: [(this.candidate && this.candidate.github_url) || ''],
                linkedin_url: [(this.candidate && this.candidate.linkedin_url) || ''],
                twitter_url: [(this.candidate && this.candidate.twitter_url) || '']
            },
            {
                validators: [MinTwoWordsValidator]
            }
        );

        this.tenantService.locations().subscribe((locations) => {
            if (locations) {
                locations.forEach((l) => {
                    if (l.location === 'any') {
                        this.addressOptions.push({ label: `${l.name}`, value: l.location });
                    } else {
                        this.addressOptions.push({ label: `${l.name}: ${l.location}`, value: l.location });
                    }
                });
                if (!this.addressOptions.find((l) => l.value === this.candidate.location) && this.candidate.location) {
                    this.addressOptions.push({ label: this.candidate.location, value: this.candidate.location });
                }
                this.addressOptions.push({ label: 'Add a new location', value: '#location' });
            }

            if (this.candidate && !this.candidate.location) {
                this.form.get('location').setValue('');
            } else {
                this.form.get('location').setValue(this.candidate.location);
            }
        });

        this.allInfo.forEach((el) => {
            if (this.candidate[el.value]) {
                this.appliedInfos.push(el);
            } else {
                this.notAppliedInfos.push(el);
            }
        });
    }

    onLocationChange(address) {
        this.place = address;
        this.form.patchValue({
            location: address && address.formatted_address ? this.locationInputRef.nativeElement.value : '',
            address: this.utilities.tranformLocation(address)
        });
    }

    focused() {
        this.isEmpty = false;
    }

    blured() {
        this.isEmpty = !this.locationInputRef.nativeElement.value.length;
    }

    handleAddressTextChange(event) {
        this.isEmpty = !this.locationInputRef.nativeElement.value.length;

        setTimeout(() => {
            if (!this.place) {
                this.place = null;
                this.form.patchValue({ location: null });
                this.inputAddress = '';
                this.placesRef.reset();
                this.locationInputRef.nativeElement.value = '';
                this.isEmpty = !this.locationInputRef.nativeElement.value.length;
            }
        }, 400);
    }

    async submitForm() {
        this.formHelper.markFormGroupTouched(this.form);
        if (!this.form.valid) {
            console.error('FORM IS INVALID', this.form);
            return;
        }

        this.form.value.email = this.form.get('email').value.toLowerCase();
        const email = this.form.get('email').value.toLowerCase();
        // check is unique email address
        try {
            if (email !== this.candidate.email.toLowerCase()) {
                console.log('Check if email is unique');
                const isUnique = await this.candidateService.checkEmailUnique(email);
                console.log(isUnique ? 'Email is unique' : 'Email is not unique');
                if (isUnique) {
                    console.log(this.form);
                    let nameWords = this.form.value.fullName.split(' ');
                    const contactInfo = {
                        ...this.form.value,
                        first_name: nameWords[0],
                        last_name: nameWords.slice(1).join(' ')
                    };
                    this.closeModal.emit(contactInfo);
                } else {
                    this.form.get('email').setErrors({ EmailNotUnique: true });
                }
            } else {
                console.log(this.form);
                let nameWords = this.form.value.fullName.split(' ');
                const contactInfo = {
                    ...this.form.value,
                    first_name: nameWords[0],
                    last_name: nameWords.slice(1).join(' ')
                };
                this.closeModal.emit(contactInfo);
            }
        } catch (error) {
            console.error(error);
            this.form.get('email').setErrors({ EmailNotUnique: true });
        }
    }

    onHideModal() {
        this.closeModal.emit(null); // get data from form
    }

    selectAppliedInfo(event) {
        const value = event.value;
        let foundElem = null;
        this.notAppliedInfos = this.notAppliedInfos.filter((el) => {
            if (el.value == value) {
                foundElem = el;
                return false;
            } else {
                return true;
            }
        });
        this.appliedInfos.push(foundElem);
    }
}
