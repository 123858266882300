import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Candidate, User } from '@app/core/models';
import { InterviewsService } from '@app/core/services';
import { MsService } from '@app/core/services/ms.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SdkJob } from './../../../../../core/models/job';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';

@Component({
    selector: 'app-candidate-interviews',
    templateUrl: './candidate-interviews.component.html',
    styleUrls: ['./candidate-interviews.component.scss']
})
export class CandidateInterviewsComponent implements OnInit, OnDestroy {
    groupInterviews;
    showInterviewModal = false;
    @Input() job: SdkJob;
    @Input() candidate: Candidate;
    @Input() candidateInterviews: any[];

    @Input() videos;
    @Input() questionnaires;
    @Input() logicTest;
    @Input() stagesData;
    @Input() radar_chart_data;
    @Input() personality_assessment;
    @Input() personalityProfileScores;

    showScorecardModal: boolean = false;

    contentLoading = false;

    activeInterview = null;
    steps = ['select', 'create'];
    selectedStep = 'select';
    selectedService;
    baseUrl: string;
    meetingTime;
    usersSubscription: Subscription;
    users: User[] = [];
    user: User;
    checkedOfficeToken = false;
    scopes = ['user.read', 'openid', 'profile', 'Calendars.ReadWrite', 'Contacts.Read', 'OnlineMeetings.ReadWrite'];
    config = {
        scopes: this.scopes
    };

    saveInterviewScorecardSubscription: Subscription;

    constructor(
        private store: Store<fromStore.State>,
        private msal: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private msalService: MsService,
        private interviewService: InterviewsService,
        private router: Router,
        private route: ActivatedRoute,
        private _renderer2: Renderer2
    ) {
        // this.msalService.logout();
    }

    ngOnInit() {
        this._renderer2.addClass(document.body, 'hide-sidebar');
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const action = this.route.snapshot.queryParamMap.get('action');
            const interview_id = this.route.snapshot.queryParamMap.get('interviewId');
            if (action === 'open-scorecard') {
                const interview = this.candidateInterviews.find((i) => i.id === interview_id);
                if (interview) {
                    setTimeout(() => {
                        this.goToScoreCard(interview);
                        this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
                    }, 200);
                }
            }
        });
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((u: User[]) => {
            this.users = [...u];
            if (this.candidateInterviews && this.candidateInterviews.length) {
                this.transformInterviews();
            }
        });
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = { ...user };
            this.candidateInterviews.forEach((i) => {
                this.interviewService.calculateRating(i, this.user);
            });
            this.msalBroadcastService.inProgress$
                .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
                .subscribe(() => {
                    console.log('MSAL AUTHENTICATE');
                    if (this.msal.instance.getAllAccounts().length) {
                        const account = this.msal.instance.getAllAccounts()[0];
                        this.msal.acquireTokenSilent({ account, scopes: this.scopes }).subscribe(
                            (response) => {
                                console.log('Got silent token', response);
                                this.checkedOfficeToken = true;
                                this.msalService.setAuthenticated(true);
                                this.selectedService = 'office';
                                this.selectedStep = 'create';

                                this.msalService.saveMsCredentialsForUser(this.user, response);
                            },
                            (errorResponse) => {
                                console.error('acquireTokenSilent Failed', errorResponse);
                                this.checkedOfficeToken = true;
                                this.msalService.setAuthenticated(false);
                            }
                        );
                    } else {
                        this.checkedOfficeToken = true;
                        this.msalService.setAuthenticated(false);
                    }
                });
        });

        this.saveInterviewScorecardSubscription = this.interviewService.updatedCandidateInterview.subscribe((int) => {
            // console.log(this.candidateInterviews);
            // console.log('updatedCandidateInterview', int);
            const interview = this.candidateInterviews.find((i) => i.id === int.interviewId);
            // console.log(interview);
            interview.criteria_votes = int.interviewPostData.criteria_votes;
            interview.overall_votes = int.interviewPostData.overall_votes;
            this.interviewService.calculateRating(interview, this.user);
        });
    }

    transformInterviews() {
        console.log('⚛️ transformInterviews', this.candidateInterviews);
        this.candidateInterviews.forEach((i) => {
            i.to = moment(i.start_time, 'HH:mm')
                .add(i.duration, 'minutes')
                .format('HH:mm');
            // i.start_date = moment(i.interview_date, 'YYYY-MM-DD').format('D MMM YYYY (dddd)');
            if (i.job_owner_id) {
                const owner = this.users.find((u) => u.id === i.job_owner_id);
                if (owner) {
                    i.owner = owner.full_name || owner.first_name;
                }
            } else {
                console.log('Job Owner not found', this.users, i.job_owner_id);
                i.owner = 'Job Owner';
            }
            const todayStart = moment()
                .startOf('day')
                .unix();
            const started_at = moment(i.interview_date, 'YYYY-MM-DD')
                .startOf('day')
                .unix();
            if (started_at < todayStart) {
                i.finished = true;
            }
            // i.isInterviewMenuVisible = this.isInterviewMenuVisible(i);
        });
        this.candidateInterviews.sort((a, b) => {
            let after = moment(a.interview_date, 'YYYY-MM-DD').isAfter(moment(b.interview_date, 'YYYY-MM-DD'));
            if (a.interview_date === b.interview_date) {
                after = moment(a.start_time, 'HH:mm').isAfter(moment(b.start_time, 'HH:mm'));
            }
            return after ? 1 : -1;
        });
        this.groupInterviews = groupBy(this.candidateInterviews, 'interview_date');
        // console.log(this.groupInterviews);
    }

    canAddInterwiew() {
        return (
            this.user.id === this.job.owner ||
            this.user.role == 'account_owner' ||
            this.user.role == 'admin' ||
            this.job.recruiters?.includes(this.user.id)
        );
    }

    openModal() {
        this.showInterviewModal = true;
    }

    onCloseModal() {
        this.showInterviewModal = false;
    }

    selectService(service) {
        this.selectedService = service;
        this.selectedStep = 'create';
    }

    goToScoreCard(interview) {
        if (this.user && (this.user.role === 'recruitment_agency' || this.user.role === 'agency_user')) {
            return false;
        }
        if (interview.interview_date) {
            this.showScorecardModal = true;
            this.activeInterview = interview;
            return;
        }

        if (!interview.interview_date) {
            this.selectedStep = 'create';
            this.openModal();
        }
    }

    closeScorecardModal() {
        this.showScorecardModal = false;
        this.activeInterview = null;
    }

    onFindMeetingTime() {
        this.meetingTime = true;
        setTimeout(() => {
            this.meetingTime = false;
        });
    }

    onAddedSchedule(event) {
        if (!this.candidateInterviews.length) {
            this.candidateInterviews = [];
        }
        this.candidateInterviews.push(event);
        this.transformInterviews();
    }

    getProviderName(provider: string) {
        if (provider === 'teamsForBusiness') {
            return 'Microsoft Teams';
        }
        if (provider === 'skypeForBusiness') {
            return 'Skype for business';
        }
        return provider;
    }

    ngOnDestroy() {
        this._renderer2.removeClass(document.body, 'hide-sidebar');
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.saveInterviewScorecardSubscription) {
            this.saveInterviewScorecardSubscription.unsubscribe();
        }
    }
}
