<div class="content">
    <div class="header">
        <ng-container *ngIf="view === 'default' || view === 'workflow' || view === 'details'">
            <h3>Offer Letter</h3>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <h3>Compensation</h3>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <h3>Review {{ form.value?.full_name }}’s Offer Letter</h3>
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <h3>Options</h3>
        </ng-container>
        <button class="close-btn btn" *ngIf="view !== 'preview'" (click)="onHideOfferModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="form-content-section" [formGroup]="form">
        <ng-container *ngIf="view === 'default'">
            <div class="form-content-holder">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Candidate Full Name</mat-label>
                                    <input type="text" autocomplete="off" formControlName="full_name" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.full_name.valid && form.controls.full_name.touched"
                                    >
                                        Full Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Candidate Identity Number</mat-label>
                                    <input type="text" autocomplete="off" formControlName="identity_number" matInput />
                                    <mat-error
                                        *ngIf="
                                            !form.controls.identity_number.valid &&
                                            form.controls.identity_number.touched
                                        "
                                    >
                                        Identity Number is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Job Title</mat-label>
                                    <input type="text" autocomplete="off" formControlName="designation" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.designation.valid && form.controls.designation.touched"
                                    >
                                        Job Title is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="businessUnitOptions"
                                    [newStyle]="true"
                                    placeholder="Business Unit"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="form.controls.business_unit"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.business_unit.valid && form.controls.business_unit.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="form.controls.business_unit.touched && !form.controls.business_unit.valid"
                                >
                                    Business Unit is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="jobLocationOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Job Location"
                                    [control]="form.controls.location"
                                    [ngClass]="{
                                        invalid: !form.controls.location.valid && form.controls.location.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="timeOffOptions"
                                    [newStyle]="true"
                                    placeholder="Time Off"
                                    [control]="form.controls.time_off"
                                    [ngClass]="{
                                        invalid: !form.controls.time_off.valid && form.controls.time_off.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="!form.controls.time_off.valid && form.controls.time_off.touched"
                                >
                                    Time Off is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>Dates</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                    <mat-label>Start Date</mat-label>
                                    <input
                                        formControlName="start_date"
                                        matInput
                                        [matDatepicker]="picker1"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error
                                        *ngIf="!form.controls.start_date.valid && form.controls.start_date.touched"
                                    >
                                        Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block" *ngIf="form.value.details?.offer_type === 'fixed'">
                                <mat-form-field appearance="outline" (click)="picker2.open()">
                                    <mat-label>End Date</mat-label>
                                    <input
                                        formControlName="end_date"
                                        matInput
                                        [matDatepicker]="picker2"
                                        [min]="minEndDate"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error *ngIf="!form.controls.end_date.valid && form.controls.end_date.touched">
                                        End Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                            <div
                                class="input-block"
                                *ngIf="
                                    form.value.details?.offer_type === 'permanent' &&
                                    form.value.details?.original_appointment
                                "
                            >
                                <mat-form-field appearance="outline" (click)="picker3.open()">
                                    <mat-label>Original Start Date</mat-label>
                                    <input
                                        formControlName="original_start_date"
                                        matInput
                                        [matDatepicker]="picker3"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker3></mat-datepicker>
                                    <mat-error
                                        *ngIf="
                                            !form.controls.original_start_date.valid &&
                                            form.controls.original_start_date.touched
                                        "
                                    >
                                        Original Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>Reporting Structure</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block" *ngIf="usersOptions?.length">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Hiring Manager"
                                    [control]="form.controls.manager"
                                    [ngClass]="{
                                        invalid: !form.controls.manager.valid && form.controls.manager.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="contactsOptions"
                                    [newStyle]="true"
                                    placeholder="Hiring Executive"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="form.controls.hiring_executive"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hiring_executive.valid &&
                                            form.controls.hiring_executive.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        form.controls.hiring_executive.touched && !form.controls.hiring_executive.valid
                                    "
                                >
                                    Hiring Executive is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="HR Business Partner"
                                    [control]="form.controls.hr_business_partner"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hr_business_partner.valid &&
                                            form.controls.hr_business_partner.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <div class="form-content-holder">
                <div class="form-block">
                    <ng-container formGroupName="salary">
                        <div class="form-block-columns mb10">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span class="input-prefix" *ngIf="form.value.salary.salary">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field class="right-label-align" appearance="outline">
                                        <mat-label>Salary Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="salary"
                                            matInput
                                        />
                                        <mat-error
                                            *ngIf="
                                                !form.get('salary').controls.salary.valid &&
                                                form.get('salary').controls.salary.touched
                                            "
                                        >
                                            Salary Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div
                                    class="input-block"
                                    *ngIf="!form.value.details || form.value.details?.offer_type === 'fixed'"
                                >
                                    <app-auto-dropdown
                                        [options]="salaryOptions"
                                        [newStyle]="true"
                                        [control]="form.get('salary').controls.period"
                                        [placeholder]="'Frequency'"
                                        [ngClass]="{
                                            invalid:
                                                form.get('salary').controls.period.touched &&
                                                !form.get('salary').controls.period.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            form.get('salary').controls.period.touched &&
                                            !form.get('salary').controls.period.valid
                                        "
                                    >
                                        Period is required
                                    </div>
                                </div>
                                <div class="input-block" *ngIf="form.value.details?.offer_type === 'permanent'">
                                    <span class="input-prefix" *ngIf="form.value.salary.commission_amount">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field class="right-label-align" appearance="outline">
                                        <mat-label>Commission Amount </mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="commission_amount"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="currencyOptions"
                                        [newStyle]="true"
                                        [control]="form.get('salary').controls.currency"
                                        placeholder="Currency"
                                        [ngClass]="{
                                            invalid:
                                                form.get('salary').controls.currency.touched &&
                                                !form.get('salary').controls.currency.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            form.get('salary').controls.currency.touched &&
                                            !form.get('salary').controls.currency.valid
                                        "
                                    >
                                        Currency is required
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="form-block-columns mb15">
                            <ng-container *ngIf="form.value.details?.offer_type === 'fixed'">
                                <div class="form-block-col">
                                    <div class="input-block">
                                        <span class="input-prefix" *ngIf="form.value.salary.commission_amount">{{
                                            form.value.salary.currency
                                        }}</span>
                                        <mat-form-field class="right-label-align" appearance="outline">
                                            <mat-label>Commission Amount </mat-label>
                                            <input
                                                class="align-right"
                                                type="text"
                                                currencyMask
                                                [options]="{
                                                    prefix: '',
                                                    thousands: ',',
                                                    align: 'left'
                                                }"
                                                autocomplete="off"
                                                formControlName="commission_amount"
                                                matInput
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-block-col-separator"></div>
                            </ng-container>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span class="input-prefix" *ngIf="form.value.salary.on_target_earnings">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field class="right-label-align" appearance="outline">
                                        <mat-label>On Target Earnings</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="on_target_earnings"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <ng-container *ngIf="form.value.details?.offer_type === 'permanent'">
                                <div class="form-block-col-separator"></div>
                                <div class="form-block-col">
                                    <div class="input-block" *ngIf="form.value.details.work_schedule === 'shift'">
                                        <span class="input-prefix" *ngIf="form.value.salary.shift_allowance">{{
                                            form.value.salary.currency
                                        }}</span>
                                        <mat-form-field class="right-label-align" appearance="outline">
                                            <mat-label>Shift Allowance</mat-label>
                                            <input
                                                class="align-right"
                                                type="text"
                                                currencyMask
                                                [options]="{
                                                    prefix: '',
                                                    thousands: ',',
                                                    align: 'left'
                                                }"
                                                autocomplete="off"
                                                formControlName="shift_allowance"
                                                matInput
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div
                            class="form-block-columns mb15"
                            *ngIf="
                                form.value.details?.offer_type === 'fixed' &&
                                form.value.details.work_schedule === 'shift'
                            "
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span class="input-prefix" *ngIf="form.value.salary.shift_allowance">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field class="right-label-align" appearance="outline">
                                        <mat-label>Shift Allowance</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="shift_allowance"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col"></div>
                        </div>
                        <!-- <div class="form-block-columns mb15">
                            <p-checkbox
                                class="commission-checkbox"
                                binary="true"
                                label="Add Variable Pay / Commission"
                                (onChange)="onCommissionCheckboxChange($event)"
                                formControlName="variable_pay"
                            >
                            </p-checkbox>
                        </div> -->
                        <div
                            class="form-block-columns"
                            *ngIf="form.get('salary').controls.commission"
                            formGroupName="commission"
                        >
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="comissionTypeOptions"
                                    [newStyle]="true"
                                    [control]="form.get('salary').controls.commission.controls.type"
                                    placeholder="Pay Type"
                                    [ngClass]="{
                                        invalid:
                                            form.get('salary').controls.commission.controls.type.touched &&
                                            !form.get('salary').controls.commission.controls.type.valid
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span
                                        class="input-prefix"
                                        *ngIf="
                                            form.value.salary.commission.type === 'fixed' &&
                                            form.value.salary.commission.amount
                                        "
                                        >{{ form.value.salary.currency }}</span
                                    >
                                    <span
                                        class="input-sufix"
                                        *ngIf="
                                            form.value.salary.commission.type === 'percentage' &&
                                            form.value.salary.commission.amount
                                        "
                                        >%</span
                                    >
                                    <mat-form-field
                                        *ngIf="form.value.salary.commission.type !== 'percentage'"
                                        class="right-label-align"
                                        appearance="outline"
                                    >
                                        <mat-label>Value</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="form.value.salary.commission.type === 'percentage'"
                                        class="right-label-align"
                                        appearance="outline"
                                    >
                                        <mat-label>Percent</mat-label>
                                        <input
                                            type="number"
                                            min="1"
                                            max="100"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <app-auto-dropdown
                                    [options]="payScheduleOptions"
                                    [newStyle]="true"
                                    [control]="form.get('salary').controls.commission.controls.payout_frequency"
                                    placeholder="Frequency"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="bonuses" *ngIf="form.get('bonuses')['controls'].length">
                        <div class="separator-line"></div>
                        <h4>Bonuses</h4>
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('bonuses')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="bonusTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Bonus Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Bonus Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <span class="input-prefix" *ngIf="item.value.amount">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Bonus Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-5 delete-ico-col">
                                <span (click)="onDeleteBonus(i)">
                                    <svg
                                        width="11px"
                                        viewBox="0 0 8 10"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g
                                            id="Recruit-Setup"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Job-View---Default"
                                                transform="translate(-498.000000, -237.000000)"
                                                fill="#212b35"
                                                fill-rule="nonzero"
                                            >
                                                <g id="icons8-delete" transform="translate(498.000000, 237.000000)">
                                                    <path
                                                        d="M3.26988655,0 C2.67329527,0 2.18181818,0.519831538 2.18181818,1.15084154 L2.18181818,1.53846154 L0,1.53846154 L0,2.30769231 L0.727272727,2.30769231 L0.727272727,8.84615385 C0.727272727,9.48016846 1.21874982,10 1.81818182,10 L6.18181818,10 C6.78125018,10 7.27272727,9.48016846 7.27272727,8.84615385 L7.27272727,2.30769231 L8,2.30769231 L8,1.53846154 L5.81818182,1.53846154 L5.81818182,1.15084154 C5.81818182,0.519831538 5.32670473,0 4.73011345,0 L3.26988655,0 Z M3.26988655,0.769230769 L4.73011345,0.769230769 C4.93465891,0.769230769 5.09090909,0.934495385 5.09090909,1.15084154 L5.09090909,2.30769231 L6.54545455,2.30769231 L6.54545455,8.84615385 C6.54545455,9.0625 6.38636364,9.23076923 6.18181818,9.23076923 L1.81818182,9.23076923 C1.61363636,9.23076923 1.45454545,9.0625 1.45454545,8.84615385 L1.45454545,2.30769231 L2.90909091,2.30769231 L2.90909091,1.15084154 C2.90909091,0.934495385 3.06534109,0.769230769 3.26988655,0.769230769 Z M2.18181818,3.07692308 L2.18181818,8.46153846 L2.90909091,8.46153846 L2.90909091,3.07692308 L2.18181818,3.07692308 Z M3.63636364,3.07692308 L3.63636364,8.46153846 L4.36363636,8.46153846 L4.36363636,3.07692308 L3.63636364,3.07692308 Z M5.09090909,3.07692308 L5.09090909,8.46153846 L5.81818182,8.46153846 L5.81818182,3.07692308 L5.09090909,3.07692308 Z"
                                                        id="Shape"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="allowances" *ngIf="form.get('allowances')['controls'].length">
                        <div class="separator-line"></div>
                        <h4>Allowances</h4>
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('allowances')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="allowancesTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Allowance Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                        (onChange)="onChangeAllowancesTypeOptions(i)"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Allowance Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block" *ngIf="item.get('type').value !== 'funeral cover'">
                                    <span class="input-prefix" *ngIf="item.value.amount">{{
                                        form.value.salary.currency
                                    }}</span>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Allowance Amount</mat-label>
                                        <input
                                            class="align-right"
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-5 delete-ico-col">
                                <span (click)="onDeleteAllowance(i)">
                                    <svg
                                        width="11px"
                                        viewBox="0 0 8 10"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g
                                            id="Recruit-Setup"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Job-View---Default"
                                                transform="translate(-498.000000, -237.000000)"
                                                fill="#212b35"
                                                fill-rule="nonzero"
                                            >
                                                <g id="icons8-delete" transform="translate(498.000000, 237.000000)">
                                                    <path
                                                        d="M3.26988655,0 C2.67329527,0 2.18181818,0.519831538 2.18181818,1.15084154 L2.18181818,1.53846154 L0,1.53846154 L0,2.30769231 L0.727272727,2.30769231 L0.727272727,8.84615385 C0.727272727,9.48016846 1.21874982,10 1.81818182,10 L6.18181818,10 C6.78125018,10 7.27272727,9.48016846 7.27272727,8.84615385 L7.27272727,2.30769231 L8,2.30769231 L8,1.53846154 L5.81818182,1.53846154 L5.81818182,1.15084154 C5.81818182,0.519831538 5.32670473,0 4.73011345,0 L3.26988655,0 Z M3.26988655,0.769230769 L4.73011345,0.769230769 C4.93465891,0.769230769 5.09090909,0.934495385 5.09090909,1.15084154 L5.09090909,2.30769231 L6.54545455,2.30769231 L6.54545455,8.84615385 C6.54545455,9.0625 6.38636364,9.23076923 6.18181818,9.23076923 L1.81818182,9.23076923 C1.61363636,9.23076923 1.45454545,9.0625 1.45454545,8.84615385 L1.45454545,2.30769231 L2.90909091,2.30769231 L2.90909091,1.15084154 C2.90909091,0.934495385 3.06534109,0.769230769 3.26988655,0.769230769 Z M2.18181818,3.07692308 L2.18181818,8.46153846 L2.90909091,8.46153846 L2.90909091,3.07692308 L2.18181818,3.07692308 Z M3.63636364,3.07692308 L3.63636364,8.46153846 L4.36363636,8.46153846 L4.36363636,3.07692308 L3.63636364,3.07692308 Z M5.09090909,3.07692308 L5.09090909,8.46153846 L5.81818182,8.46153846 L5.81818182,3.07692308 L5.09090909,3.07692308 Z"
                                                        id="Shape"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <div
                        class="separator-line"
                        *ngIf="
                            form.get('bonuses')['controls'].length === 0 &&
                            form.value.details?.offer_type === 'permanent'
                        "
                    ></div>
                    <ng-container *ngIf="form.value.details?.offer_type === 'permanent'">
                        <button
                            class="add-section-button ng-star-inserted"
                            (click)="onAddBonusSection()"
                            *ngIf="
                                !form.get('bonuses')['controls'] ||
                                form.get('bonuses')['controls'].length < bonusTypeOptions?.length
                            "
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" /><span>Add Bonus</span>
                        </button>
                        <button
                            class="add-section-button ng-star-inserted"
                            (click)="onAddAllowanceSection()"
                            *ngIf="
                                !form.get('allowances')['controls'] ||
                                form.get('allowances')['controls'].length < allowancesTypeOptions?.length
                            "
                        >
                            <img src="/assets/images/plus-icon.svg" alt="" /><span>Add Allowance</span>
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <div class="preview-holder">
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Personal Details</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Full Name</div>
                            <span>{{ form.value.full_name }}</span>
                        </div>
                        <div class="preview-item">
                            <div>ID Number</div>
                            <span>{{ form.value.identity_number }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Job Title</div>
                            <span>{{ form.value.designation }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Location</div>
                            <span>{{ previewData?.location_short || form.value.location }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Start Date</div>
                            <!-- <span>{{ form.value.start_date | amDateFormat: 'DD MMM YYYY' }}</span> -->
                            <span *ngIf="previewData.start_date">{{
                                previewData.start_date | amDateFormat: 'DD MMM YYYY'
                            }}</span>
                        </div>
                        <div class="preview-item" *ngIf="previewData.end_date">
                            <div>End Date</div>
                            <span>{{ previewData.end_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item" *ngIf="previewData.original_start_date">
                            <div>Original Start Date</div>
                            <span>{{ previewData.original_start_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Business Unit</div>
                            <span>{{ form.value.business_unit }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Time Off</div>
                            <span>{{ form.value.time_off }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Reporting Structure</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Hiring Manager</div>
                            <span>{{ getUserName(form.value.manager) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Hiring Executive</div>
                            <span>{{ getUserName(form.value.hiring_executive) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>HR Business Partner</div>
                            <span>{{ getUserName(form.value.hr_business_partner) }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="preview-block preview-compensation"
                    [ngClass]="{ last: form.value?.allowances.length === 0 }"
                >
                    <div class="preview-title">
                        <strong>Compensation</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper preview-allowances">
                        <div class="preview-item">
                            <div>Salary Amount</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.salary | number: '.2-2' }}
                                <ng-container [ngSwitch]="form.value.salary.period">
                                    <ng-container *ngSwitchDefault>{{ form.value.salary.period }}</ng-container>
                                    <ng-container *ngSwitchCase="'hourly'">per hour</ng-container>
                                    <ng-container *ngSwitchCase="'dayly'">per day</ng-container>
                                    <ng-container *ngSwitchCase="'weekly'">per week</ng-container>
                                    <ng-container *ngSwitchCase="'monthly'">per month</ng-container>
                                    <ng-container *ngSwitchCase="'yearly'">per year</ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.commission_amount">
                            <div>Commission Amount</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.commission_amount | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.on_target_earnings">
                            <div>On Target Earnings</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.on_target_earnings | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.shift_allowance">
                            <div>Shift Allowance</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.shift_allowance | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.commission?.amount">
                            <div>Variable Pay / Commission</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.commission.amount | number: '.2-2' }}
                            </span>
                        </div>
                        <div class="preview-item" *ngFor="let bonus of form.value.bonuses">
                            <div>Bonus</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ bonus.amount | number: '.2-2' }}
                                ({{ bonus.type | titlecase }})</span
                            >
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-allowances" *ngIf="form.value?.allowances.length">
                    <div class="preview-title">
                        <strong>Allowances</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item" *ngFor="let item of form.value.allowances">
                            <ng-container *ngIf="item.type === 'funeral cover'">
                                <div>Funeral Cover</div>
                                <span>Yes</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'mobile phone'">
                                <div>Mobile Phone</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number: '.2-2' }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'motor vehicle allowance'">
                                <div>Motor Vehicle Allowance</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number: '.2-2' }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'petrol'">
                                <div>Petrol</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number: '.2-2' }}</span
                                >
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'workflow'">
            <div class="workflow-view">
                <p>
                    Please select a workflow that you would like to send to this candidate. If you select “don’t
                    allocate a workflow”, the offer details will be saved to the candidate record but they will not
                    receive the workflow.
                </p>
                <div class="form-block">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="manualWorkflowsOptions"
                            [control]="form.controls.workflow_id"
                            placeholder="Workflow"
                            [dropdownValueUpdated]="dropdownValueUpdated"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <div class="form-content-holder ftc_reasons-holder" formArrayName="ftc_reasons">
                <p>
                    Select the applicable reasons for employing this candidate on a Fixed Term Contract. If none apply,
                    please add your own reason below.
                </p>
                <p
                    *ngFor="let item of form.get('ftc_reasons')['controls']; let i = index; let last = last"
                    [formGroupName]="i"
                >
                    <ng-container *ngIf="!last">
                        <mat-checkbox
                            [disabled]="item.value.disabled"
                            (change)="onChangeCheckboxOptions($event)"
                            formControlName="checked"
                        >
                            {{ item.value.title }}
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="last">
                        <mat-checkbox (change)="onChangeCheckboxOptions($event, 'manually')" formControlName="checked">
                        </mat-checkbox>
                        <div class="form-block">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <textarea
                                        matInput
                                        formControlName="title"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="1"
                                        resize="vertical"
                                    ></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'details'" formArrayName="details">
            <div class="form-details-section">
                <p class="text">This candidate will receive a <strong>system generated offer letter</strong>?</p>
                <mat-radio-group formControlName="send_offer_letter">
                    <mat-radio-button [value]="true">
                        <p>Yes, they will</p>
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        <p>No, they will not</p>
                    </mat-radio-button>
                </mat-radio-group>

                <ng-container
                    *ngIf="
                        form.value.details.send_offer_letter === true || form.value.details.send_offer_letter === false
                    "
                >
                    <p class="text">Please select a <strong>company template</strong>?</p>
                    <div class="input-block" style="max-width: 300px;">
                        <app-auto-dropdown
                            [options]="companyTypeOptions"
                            [newStyle]="true"
                            placeholder="Company"
                            [dropdownValueUpdated]="dropdownValueUpdated"
                            [control]="form.controls.details.controls.company"
                        >
                        </app-auto-dropdown>
                    </div>
                    <p class="text">
                        Is this offer for a <strong>Permanent Employee</strong> or a
                        <strong>Fixed Term Contractor</strong>?
                    </p>
                    <mat-radio-group formControlName="offer_type" (change)="onChangeOfferType($event)">
                        <mat-radio-button value="permanent">
                            <p>Permanent Employee</p>
                        </mat-radio-button>
                        <mat-radio-button value="fixed">
                            <p>Fixed Term Contractor</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <div class="work-days-wrapper mt20 mb20" *ngIf="form.value.details.offer_type">
                    <mat-checkbox formControlName="send_onboarding_workflow">
                        Send Onboarding Workflow after signing
                    </mat-checkbox>
                </div>
                <ng-container *ngIf="form.value.details.offer_type === 'permanent'">
                    <p class="text">
                        If the employee is returning within 12 months, converting from a Fixed Term Contract or
                        transferring from a group company, we should recognise their
                        <strong>original appointment date</strong>?
                    </p>
                    <mat-radio-group
                        formControlName="original_appointment"
                        (change)="onChangeOriginalAppointment($event)"
                    >
                        <mat-radio-button [value]="true">
                            <p>Yes, recognise original appointment date</p>
                        </mat-radio-button>
                        <mat-radio-button [value]="false">
                            <p>No, this does not apply</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-container *ngIf="form.value.details.offer_type === 'fixed'">
                    <p class="text">Is this candidate a <strong> Foreign National</strong>?</p>
                    <mat-radio-group formControlName="is_foreign_national">
                        <mat-radio-button [value]="true">
                            <p>Yes, they are a Foreign National</p>
                        </mat-radio-button>
                        <mat-radio-button [value]="false">
                            <p>No, they are not</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-container
                    *ngIf="
                        form.value.details.original_appointment === true ||
                        form.value.details.original_appointment === false ||
                        form.value.details.is_foreign_national === true ||
                        form.value.details.is_foreign_national === false
                    "
                >
                    <p class="text">
                        What are the <strong>days and hours of work</strong> for this <strong>candidate</strong>?
                    </p>
                    <mat-radio-group formControlName="work_schedule" (change)="onChangeWorkSchedule($event)">
                        <mat-radio-button value="standard">
                            <p>Standard - 40 hours / week</p>
                        </mat-radio-button>
                        <mat-radio-button value="shift">
                            <p>Shift Worker - 45 hours / week based on schedule</p>
                        </mat-radio-button>
                        <mat-radio-button value="part" *ngIf="form.value.details.offer_type === 'fixed'">
                            <p>Part Time - days to be determined</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-container *ngIf="form.value.details.work_schedule === 'part'">
                    <p class="text">On <strong>which days</strong> will the candidate be required to work?</p>
                    <div class="work-days-wrapper" formArrayName="days_of_work">
                        <ng-container
                            *ngFor="let item of form.get('details').get('days_of_work')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <mat-checkbox formControlName="checked">
                                {{ item.value.title }}
                            </mat-checkbox>
                        </ng-container>
                    </div>
                    <div class="work-time-wrapper">
                        <div class="form-block-col">
                            <div class="form-block-columns">
                                <div class="form-block-col">
                                    <div class="input-block">
                                        <mat-form-field appearance="outline">
                                            <mat-label>From</mat-label>
                                            <input
                                                [format]="24"
                                                [ngxTimepicker]="timepicker"
                                                formControlName="start_time"
                                                placeholder="hh:mm"
                                                matInput
                                                autocomplete="off"
                                            />
                                            <!-- <mat-error *ngIf="!form.controls.start_time.valid && form.controls.start_time.touched">
                                                Start Time is required</mat-error
                                            > -->
                                        </mat-form-field>
                                        <ngx-material-timepicker #timepicker [minutesGap]="5"></ngx-material-timepicker>
                                    </div>
                                </div>
                                <div class="form-block-col-separator"></div>
                                <div class="form-block-col">
                                    <div class="input-block">
                                        <mat-form-field appearance="outline">
                                            <mat-label>To</mat-label>
                                            <input
                                                [format]="24"
                                                [ngxTimepicker]="timepicker2"
                                                formControlName="end_time"
                                                placeholder="hh:mm"
                                                matInput
                                                autocomplete="off"
                                            />
                                            <!-- <mat-error *ngIf="!form.controls.start_time.valid && form.controls.start_time.touched">
                                                Start Time is required</mat-error
                                            > -->
                                        </mat-form-field>
                                        <ngx-material-timepicker
                                            #timepicker2
                                            [minutesGap]="5"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="work-days-wrapper">
                        <mat-checkbox formControlName="include_lunch_break">
                            Work hours include a one hour lunch break
                        </mat-checkbox>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <ng-container *ngIf="view === 'default'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w140" *ngIf="form.value.end_date" (click)="onNext()">Options</button>
            <button class="btn btn-primary w140" *ngIf="!form.value.end_date" (click)="onNext()">Compensation</button>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <button
                *ngIf="form.value.details.send_offer_letter"
                class="btn btn-primary mb10"
                (click)="onPreviewContract()"
            >
                Preview Contract
            </button>
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button
                *ngIf="form.value.workflow_id && form.value.details.send_offer_letter === true"
                class="btn btn btn-primary"
                (click)="onSendOffer('workflow')"
            >
                Send for Approval
            </button>
            <!-- <button
                *ngIf="!form.value.workflow_id"
                class="btn btn btn-primary ns"
                (click)="onMoveCandidateToOfferStage()"
            >
                Send for Approval
            </button> -->
            <button
                *ngIf="form.value.details.send_offer_letter === false"
                class="btn btn-primary"
                (click)="onMoveCandidateToOfferStage()"
            >
                Send
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary" (click)="onPreview()">
                Preview
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'options'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w140" (click)="onNext()">Compensation</button>
        </ng-container>
        <ng-container *ngIf="view === 'workflow'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w72" (click)="onAddWorkflow()">Next</button>
        </ng-container>
        <ng-container *ngIf="view === 'details'">
            <button class="btn btn-primary w72" (click)="onAddDetails()">Details</button>
        </ng-container>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideOfferModal()"></div>

<div class="offer-preview-modal" *ngIf="previewContractLink">
    <div class="overlay"></div>
    <button class="close-btn btn" (click)="onHideOfferPreview()">
        <img src="/assets/images/icons/icons-8-delete.svg" />
    </button>
    <pdf-viewer
        *ngIf="previewContractLink"
        [src]="previewContractLink"
        [render-text]="true"
        [original-size]="true"
        [autoresize]="true"
        [zoom]="1"
        [render-text]="true"
        [show-borders]="false"
        style="display: block;max-width: 800px;"
    >
    </pdf-viewer>
</div>
