import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as fromJobsStore from '@app/products/hire/modules/jobs/store';
import * as fromStore from '@app/store';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-candidate-modal',
    templateUrl: './candidate-modal.component.html',
    styleUrls: ['./candidate-modal.component.scss']
})
export class CandidateModalComponent implements OnInit {
    @Input() showCandidateModal;
    @Input() candidateData;
    @Input() initialJob;
    @Input() candidateDataList;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    selectedCandidateIdx;
    updateRatingData;
    updateOwner;
    constructor(private store: Store<fromStore.State>, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.transformList();
        this.cdr.markForCheck();
    }

    closeCandidateModal() {
        if (this.updateRatingData) {
            const [candidateId, jobId, rating] = this.updateRatingData;
            this.store.dispatch(
                new fromJobsStore.UpdateJobCandidate({
                    jobId,
                    candidateId,
                    data: { rating }
                })
            );
        }
        if (this.updateOwner) {
            const [candidateId, jobId, owner] = this.updateOwner;
            this.store.dispatch(
                new fromJobsStore.UpdateJobCandidate({
                    jobId,
                    candidateId,
                    data: { owner }
                })
            );
        }
        this.closeModal.next();
    }

    closeModalFromCandidate() {
        this.closeModal.next();
    }

    changeCandidate(direction) {
        if (direction === 'next') {
            this.selectedCandidateIdx++;
        } else {
            this.selectedCandidateIdx--;
        }
        this.candidateData.candidateId = this.candidateDataList[this.selectedCandidateIdx];
    }

    transformList() {
        this.candidateDataList = this.unique(this.candidateDataList);
        this.selectedCandidateIdx = this.candidateDataList.findIndex(
            (candidateId) => candidateId === this.candidateData.candidateId
        );
    }

    unique(arr) {
        let result = [];

        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }

        return result;
    }

    changeCandidateRating(data) {
        this.updateRatingData = data;
    }

    changeCandidateOwner(data) {
        this.updateOwner = data;
    }
}
