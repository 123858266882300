import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stage, User } from '@app/core/models';
import { CandidateService, InterviewsService, JobService, UtilitiesService } from '@app/core/services';
import * as fromJobsStore from '@app/products/hire/modules/jobs/store';
import * as fromStore from '@app/store';
import * as fromSelectors from '@app/store/selectors';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SdkJob } from './../../../../../core/models/job';

@Component({
    selector: 'app-candidate-interview-scorecard',
    templateUrl: './candidate-interview-scorecard.component.html',
    styleUrls: ['./candidate-interview-scorecard.component.scss']
})
export class CandidateInterviewScorecardComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    @Input() jobId;
    @Input() candidate;
    @Input() activeInterview;
    @Output() closeScorecardModal: EventEmitter<any> = new EventEmitter();
    user: User;
    job: SdkJob;

    @Input() videos;
    @Input() questionnaires;
    @Input() logicTest;
    @Input() stagesData;
    @Input() radar_chart_data;
    @Input() personality_assessment;
    @Input() personalityProfileScores;

    usersSubscription: Subscription;
    contentLoading = true;
    stages: Stage[] = [];
    // stagesData: any[] = [];
    stageId: string = '';
    offerAssessment = null;
    jobOwner = false;
    resumeThreshold: number;
    candidateSubscription: Subscription;
    userSubscription: Subscription;
    jobSubscription: Subscription;
    activeSectionOverview = 'overview';
    activeSectionScorecard = 'scorecard';
    sectionsOverview: string[] = ['overview', 'details', 'resume', 'questions', 'history'];
    sectionsScorecard: string[] = ['scorecard'];
    filledInterview;
    potentialBehalfUsers = [];
    changedBehalfUserId;
    resumeDocument;
    documentPreviewModal = {
        show: false,
        file: null
    };
    constructor(
        private jobService: JobService,
        private route: ActivatedRoute,
        private utilities: UtilitiesService,
        private store: Store<fromStore.State>,
        private candidateService: CandidateService,
        private interviewsService: InterviewsService,
        private jobsStore: Store<fromJobsStore.JobsState>,
        private router: Router,
        private renderer2: Renderer2
    ) {
        // this.jobId = this.route.snapshot.paramMap.get('id');
        // this.candidateId = this.route.snapshot.paramMap.get('candidateId');
    }

    ngOnInit(): void {
        this.userSubscription = this.store
            .pipe(takeUntil(this.unsubscribe))
            .pipe(select(fromStore.getUserEntity))
            .subscribe((user: User) => {
                this.user = user;
            });
        this.loadCandidate(this.jobId);
        this.loadInterview();
        this.renderer2.addClass(document.body, 'hide-sidebar');
        this.resumeDocument = this.candidate.uploaded.find((d) => d.type === 'resume');
        this.resumeDocument.extension = this.utilities.getExtension(this.resumeDocument.name);
        if (this.resumeDocument && this.resumeDocument.link && this.resumeDocument.extension === 'docx') {
            let file = this.resumeDocument;
            this.contentLoading = true;
            const id = file.id ? file.id : file.documentType;
            this.utilities.convertToPDF(this.candidate.id, file.link, id).subscribe((response: any) => {
                console.log('convertToPDF response', response);
                file.link = response.url;
                this.resumeDocument.extension = 'pdf';
                this.contentLoading = false;
            });
        }
    }

    loadInterview() {
        this.candidateService
            .getScorecard(this.activeInterview.scorecard_id)
            .pipe(map((data: { success: boolean; scorecard: any }) => data.scorecard))
            .subscribe((scorecard) => {
                this.filledInterview = { ...this.activeInterview };
                this.filledInterview.scorecard = scorecard;
            });
    }

    loadCandidate(jobId) {
        this.getCandidateInfo(this.candidate, jobId);
    }

    getCandidateInfo(candidate, jobId) {
        if (!candidate && this.candidate) {
            console.log('Probably candidate was deleted and we need to close the modal');
        }
        if (!candidate) {
            return this.router.navigateByUrl('/not-found');
        }
        if (this.candidate.candidate_matches) {
            this.transformSkills(this.candidate.candidate_matches, candidate);
        } else {
            candidate.candidate_skills = [];
        }
        this.candidate = candidate;

        if (!this.candidate.resume_file && !this.candidate.resume_file_new) {
            this.sectionsOverview = ['overview'];
        }
        this.setCandidateFields(jobId);
        forkJoin([this.getJob(jobId), this.getCandidate(jobId, this.candidate)]).subscribe((response: any) => {
            const [job, candidateData] = response;
            if (candidateData) {
                this.completeCandidateWithData(candidateData);
            }
            console.log('RESPONSe', response);
            this.job = job;
            this.getPotentialBehalfUsers();
            this.resumeThreshold = this.utilities.getResumeThreshold(this.job);
            if (this.candidate.stage && this.candidate.stage[jobId]) {
                this.stageId = this.candidate.stage[jobId];
            }
            if (!this.job.tags) this.job.tags = [];

            this.stages = this.job.stages.sort((a, b) => a.order - b.order);

            this.checkResumeFile();
            // this.stagesData = [];
            // this.getAllAudit();
            if (this.candidate.assignments) {
                let assignments = this.candidate.assignments;
                let assignment = assignments.find((ass) => ass.type === 'offer');
                if (assignment) {
                    this.offerAssessment = assignment;
                }
            }
            this.jobOwner = this.job.owner === this.user.id;
            this.contentLoading = false;
        });
    }

    getCandidate(jobId, candidate) {
        const observable = new Observable((subscriber) => {
            if (candidate.loadedFull) {
                subscriber.next(candidate);
                subscriber.complete();
            } else {
                this.jobService.getCandidate(jobId, candidate.id).subscribe(
                    (candidateData: any) => {
                        subscriber.next(candidateData);
                        subscriber.complete();
                    },
                    (error) => subscriber.error(error)
                );
            }
        });
        return observable;
    }

    getJob(jobId: string) {
        const observable = new Observable((subscriber) => {
            this.jobSubscription = this.jobsStore
                .pipe(takeUntil(this.unsubscribe), select(fromJobsStore.getJobById, { jobId }))
                .subscribe(
                    async (job: SdkJob) => {
                        if (job) {
                            if (!job.stages) {
                                const stages = await this.jobService
                                    .getStages(jobId)
                                    .pipe(take(1))
                                    .toPromise();
                                job.stages = stages;
                            }
                            subscriber.next(job);
                            subscriber.complete();
                        } else {
                            this.jobService.getJob(jobId).subscribe(
                                async (job: SdkJob) => {
                                    job.stages = [];
                                    const stages = await this.jobService
                                        .getStages(jobId)
                                        .pipe(take(1))
                                        .toPromise();
                                    job.stages = stages;
                                    subscriber.next(job);
                                    subscriber.complete();
                                },
                                (error) => subscriber.error(error)
                            );
                        }
                    },
                    (error) => subscriber.error(error)
                );
        });
        return observable;
    }

    setCandidateFields(jobId) {
        if (!this.candidate.assignments) {
            this.candidate.assignments = [];
        }
        if (this.candidate.profile_image && !this.candidate.profile_image_link) {
            if (this.candidate.profile_image.includes('https://')) {
                this.candidate.profile_image_link = this.candidate.profile_image;
            } else {
                this.candidateService.getProfileImageLink(`${this.candidate.profile_image}&collection=Users`).subscribe(
                    (response: string) => (this.candidate.profile_image_link = response),
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    completeCandidateWithData(data: any) {
        // console.log('GOT candidate data:', data);
        if (data.education && data.education.languages) {
            this.candidate.languages = data.education.languages;
        }
        if (data.education && data.education.education_history) {
            this.candidate.education_history = data.education.education_history;
        }
        if (data.skills) {
            this.candidate.skills = data.skills;
        }
        if (data.summary) {
            this.candidate.summary = data.summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_summary) {
            this.candidate.employment_summary = data['candidate-resume'].employment_summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_history) {
            this.candidate.employment_history = data['candidate-resume'].employment_history;
        }
        if (data.sex) {
            this.candidate.gender = data.sex;
        }
        if (data.ethnicity) {
            this.candidate.ethnicity = data.ethnicity;
        }
        if (data.management_story) {
            this.candidate.management_story = data.management_story;
        }
        if (data.avg_months_per_employer) {
            this.candidate.avg_months_per_employer = this.utilities.convertMonthsToYears(
                parseInt(data.avg_months_per_employer, 10)
            );
        }
    }

    checkResumeFile() {
        // Attachments
        if (this.candidate.resume_file && this.candidate.resume_file.length) {
            this.candidateService.getResumeLink(this.candidate.resume_file).subscribe(
                (response: string) => (this.candidate.resume_link = response),
                (errorResponse) => console.error(errorResponse)
            );

            this.candidate.resume_file_type = this.getExtension(this.candidate.resume_file);
        }
        if (this.candidate.resume_file_new && this.candidate.resume_file_new.length) {
            this.candidateService.getResumeLink(this.candidate.resume_file_new).subscribe(
                (response: string) => (this.candidate.resume_link_new = response),
                (errorResponse) => console.error(errorResponse)
            );

            this.candidate.resume_file_new_type = this.getExtension(this.candidate.resume_file_new);
        }
    }

    checkOtherFiles() {
        if (this.candidate.uploaded && this.candidate.uploaded.length) {
            for (let i = 0; i <= this.candidate.uploaded.length - 1; i++) {
                this.candidateService.getResumeLink(this.candidate.uploaded[i].name).subscribe(
                    (response: string) => {
                        if (this.candidate && this.candidate.uploaded && this.candidate.uploaded[i]) {
                            this.candidate.uploaded[i]['link'] = response;
                            this.candidate.uploaded[i]['type'] = this.getExtension(this.candidate.uploaded[i].name);
                        }
                    },
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    getExtension(filename) {
        const allowedExtensions = ['pdf'];
        const newFilename = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        if (allowedExtensions.indexOf(newFilename) !== -1) {
            return newFilename;
        } else {
            return 'docx';
        }
    }

    onChangeSection(section: string, type) {
        if (type === 'overview') {
            this.activeSectionOverview = section;
        }

        if (type === 'scorecard') {
            this.activeSectionScorecard = section;
        }
    }

    closeModal() {
        this.closeScorecardModal.emit(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.renderer2.removeClass(document.body, 'hide-sidebar');
    }

    onSaveScoreCard() {
        this.contentLoading = true;
        const idForSave = this.changedBehalfUserId ? this.changedBehalfUserId : this.user.id;
        console.log('onSaveScoreCard', idForSave);
        this.interviewsService.saveInterviewScorecard.next(idForSave);
        setTimeout(() => {
            this.contentLoading = false;
            this.closeModal();
        }, 1000);
    }

    onSaveScoreCardDraft() {
        console.log('onSaveScoreCardDraft');
        this.contentLoading = true;
        const idForSave = this.changedBehalfUserId ? this.changedBehalfUserId : this.user.id;
        this.interviewsService.saveInterviewScorecardDraft.next(idForSave);
        setTimeout(() => {
            this.contentLoading = false;
        }, 500);
    }

    changeBehalfUser(event) {
        const newBehalfId = event.value.id;
        this.changedBehalfUserId = newBehalfId;
    }

    getPotentialBehalfUsers() {
        if (this.user.id !== this.job.owner && !this.job.recruiters.includes(this.user.id)) {
            return;
        }

        const hiring_managers = this.job.hiring_managers ? this.job.hiring_managers : [];
        const hr_business_partners = this.job.hr_business_partners ? this.job.hr_business_partners : [];
        const hiring_panels = this.job.hiring_panels ? this.job.hiring_panels : [];
        const recruiters = this.job.recruiters ? this.job.recruiters : [];
        const recruitment_agencies = this.job.recruitment_agencies ? this.job.recruitment_agencies : [];

        const behalfUsersIds = [
            ...hiring_managers,
            this.job.owner,
            ...recruiters,
            //...recruitment_agencies,
            ...hiring_panels,
            ...hr_business_partners
        ];
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.potentialBehalfUsers = users
                .filter(
                    (user) =>
                        (behalfUsersIds.includes(user.id) && user.id !== this.user.id) ||
                        (user.role === 'admin' && user.activated && user.id !== this.user.id)
                )
                .map((user, idx) => {
                    return {
                        label: user.first_name + ' ' + user.last_name,
                        value: { id: user.id, color: this.randomColor() }
                    };
                });

            console.log(this.potentialBehalfUsers);
            this.potentialBehalfUsers.unshift({
                label: this.user.first_name + ' ' + this.user.last_name,
                value: { id: this.user.id, color: '#cce2cb' }
            });
        });
    }

    randomColor() {
        let hex = Math.floor(Math.random() * 0xffffff);
        let color = '#' + hex.toString(16);

        return color;
    }

    transformSkills(matches, candidate) {
        const skills = [];
        if (matches.EnrichedScoreData && matches.EnrichedScoreData.Skills && matches.EnrichedScoreData.Skills.Found) {
            matches.EnrichedScoreData.Skills.Found.forEach((s) => {
                if (s.IsCurrent) {
                    skills.unshift({ skill: s.Skill, type: 'current' });
                } else {
                    skills.push({ skill: s.Skill, type: 'not-current' });
                }
            });
        }
        if (
            matches.EnrichedScoreData &&
            matches.EnrichedScoreData.Skills &&
            matches.EnrichedScoreData.Skills.NotFound
        ) {
            matches.EnrichedScoreData.Skills.NotFound.forEach((s) => {
                skills.push({ skill: s, type: 'not-exist' });
            });
        }
        candidate.candidate_skills = skills;
    }

    onDocumentPreview(file) {
        // console.log(file, this.utilities.getExtension(file.link));
        if (file.extension === 'docx' && file.id) {
            this.contentLoading = true;
            const id = file.id ? file.id : file.documentType;
            this.utilities.convertToPDF(this.candidate.id, file.link, id).subscribe((response: any) => {
                // console.log('convertToPDF response', response);
                file.link = response.url;
                this.resumeDocument.extension = 'pdf';
                this.contentLoading = false;
                this.documentPreviewModal = {
                    show: true,
                    file
                };
            });
        } else {
            this.documentPreviewModal = {
                show: true,
                file
            };
        }
    }
}
