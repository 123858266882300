import { Component, Input, OnInit } from '@angular/core';
import { CandidateService } from '@app/core/services';
import { WorkflowService } from '@app/core/services/workflow.service';

@Component({
    selector: 'app-candidate-offer-approval-reject',
    templateUrl: './candidate-offer-approval-reject.component.html',
    styleUrls: ['./candidate-offer-approval-reject.component.scss']
})
export class CandidateOfferApprovalRejectBlockComponent implements OnInit {
    @Input() job;
    @Input() candidate;
    @Input() user;
    contentLoading = false;
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflows: any[];
    } = {
        show: false,
        data: null,
        workflows: []
    };
    showBlock = true;
    status: string;
    constructor(private candidateService: CandidateService, private workflowService: WorkflowService) {}

    async ngOnInit() {
        this.status = this.candidate['offer-approval'].status || 'pending';
    }

    async onEditOffer() {
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
    }

    async onApprovalOffer(event) {
        console.log('onApprovalOffer', event);
        this.acceptedOfferModal.show = false;
        const data = {
            stageClass: 'offer-approval',
            complianceRateClass: 'red',
            order: 5,
            'offer-approval': null
        };
        await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
        this.candidateService.updateCandidate(this.candidate.id, data);
    }
}
