import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PromoteService {
    apiURL: string = environment.apiUrl;
    private addedItems$ = new Subject<any>();
    constructor(private http: HttpClient) {}

    getAddedItems() {
        return this.addedItems$.asObservable();
    }

    updateAddedItems(data) {
        return this.addedItems$.next(data);
    }

    getRecommendedBoards(tenantId, jobId) {
        return this.http.get(`${this.apiURL}/promote/recommended?tenantId=${tenantId}&jobId=${jobId}`);
    }

    getProducts(tenantId, jobId, offset = 0, limit = 100) {
        return this.http.get(
            `${this.apiURL}/promote/products?tenantId=${tenantId}&jobId=${jobId}&offset=${offset}&limit=${limit}`
        );
    }

    getProductById(id) {
        return this.http.get(`${this.apiURL}/promote/product/${id}`);
    }

    toggleFavoriteProductById(id) {
        return this.http.post(`${this.apiURL}/promote/product/${id}/favorite`, {});
    }

    createCampaign(data) {
        return this.http.post(`${this.apiURL}/promote/campaign`, data);
    }

    getCampaignDetails(campaignId) {
        return this.http.get(`${this.apiURL}/promote/campaign/${campaignId}`);
    }

    cancelCampaign(campaignId) {
        return this.http.delete(`${this.apiURL}/promote/campaign/${campaignId}`);
    }

    getCampaignStatus(campaignId) {
        return this.http.get(`${this.apiURL}/promote/campaign/${campaignId}/status`);
    }
}
