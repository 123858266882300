import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    bloodGroupOptions,
    employeeStatusOptions,
    ethnicityTypeOptions,
    gendersTypeOptions,
    jobBusinessUnitOptions,
    jobDesignationOptions,
    jobLevelOptions,
    jobShiftOptions,
    jobTypeOptions,
    languagesOptions,
    maritalStatusOptions
} from '@app/core/constants/options.constants';
import { ITag } from '@app/core/models';
import { UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { TenantService } from '@app/core/services/tenant.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
    selector: 'app-employees-filter',
    templateUrl: './employees-filter.component.html',
    styleUrls: ['./employees-filter.component.scss']
})
export class EmployeesFilterComponent implements OnInit {
    @Input() form: FormGroup;
    globalTags: ITag[] = [];
    entryRulesForm: FormControl;
    entryCriteriaDropDown = true;
    dropdownValueUpdated = false;
    formSubscription: Subscription;
    filteredUsers = [];
    filteredUsersRequestsComplete = false;
    innerContentLoading = true;
    startDateMinValue = moment()
        .add(1, 'days')
        .format();
    @Input() rules;
    entryRulesOptions: any[] = [
        { value: 'type', label: 'Employee Type' },
        { value: 'job_location', label: 'Employee Location' },
        { value: 'cost', label: 'Cost to Company' },
        { value: 'gender', label: 'Gender' },
        { value: 'ethnicity', label: 'Ethnicity' },
        { value: 'salary_start_date', label: 'Salary Start Date' },
        { value: 'status', label: 'Employee Status' },
        { value: 'department', label: 'Department' },
        { value: 'designation', label: 'Designation of Title' },
        { value: 'business_unit', label: 'Business Unit' },
        { value: 'level', label: 'Level' },
        { value: 'shift', label: 'Shift' },
        // { value: 'manager', label: 'Reporting Manager' },
        // { value: 'business_partner', label: 'HR Business Partner' },
        // { value: 'notice_period', label: 'Notice Period' },
        // { value: 'termination_date', label: 'Termination Date' },
        // { value: 'termination_category', label: 'Termination Category' },
        { value: 'date_of_birth', label: 'Date of Birth' },
        { value: 'marital_status', label: 'Marital Status' },
        { value: 'languages', label: 'Spoken Language' },
        { value: 'blood_group', label: 'Blood Group' },
        { value: 'tags', label: 'Tags' }
    ];

    dropdownOptions = {
        type: [{ value: 'type', label: 'Employee Type' }],
        cost: [{ value: 'cost', label: 'Cost to Company' }],
        job_location: [{ value: 'job_location', label: 'Location' }],
        gender: [{ value: 'gender', label: 'Gender' }],
        ethnicity: [{ value: 'ethnicity', label: 'Ethnicity' }],
        salary: [{ value: 'salary', label: 'Annual Salary' }],
        salary_start_date: [{ value: 'salary_start_date', label: 'Salary Start Date' }],
        status: [{ value: 'status', label: 'Employee Status' }],
        department: [{ value: 'department', label: 'Department' }],
        designation: [{ value: 'designation', label: 'Designation of Title' }],
        business_unit: [{ value: 'business_unit', label: 'Business Unit' }],
        level: [{ value: 'level', label: 'Level' }],
        shift: [{ value: 'shift', label: 'Shift' }],
        marital_status: [{ value: 'marital_status', label: 'Marital Status' }],
        date_of_birth: [{ value: 'date_of_birth', label: 'Date of Birth' }],
        languages: [{ value: 'languages', label: 'Spoken Language' }],
        blood_group: [{ value: 'blood_group', label: 'Blood Group' }],
        tags: [{ value: 'tags', label: 'Tags' }]
    };

    conditionTypeOptions = [
        { value: '==', label: 'is equal to' },
        { value: '!==', label: 'is not equal to' },
        { value: '<', label: 'less than' },
        { value: '>', label: 'greater than' }
    ];
    conditionTypeOptions2 = [
        { value: '==', label: 'is equal to' },
        { value: '!==', label: 'is not equal to' }
    ];
    compareTypeOptions = [
        { value: 'and', label: 'and' },
        { value: 'or', label: 'or' }
    ];
    tagsOptions = [];
    filtersOptions = {
        type: jobTypeOptions,
        job_location: [],
        gender: gendersTypeOptions,
        ethnicity: ethnicityTypeOptions,
        status: employeeStatusOptions,
        department: [],
        designation: jobDesignationOptions,
        business_unit: jobBusinessUnitOptions,
        level: jobLevelOptions,
        shift: jobShiftOptions,
        marital_status: maritalStatusOptions,
        blood_group: bloodGroupOptions,
        languages: languagesOptions.map((l) => {
            return { label: l.name, value: l.name };
        }),
        tags: this.tagsOptions
    };

    timeTypeOptions = [
        { label: '08:00', value: '8:00 a.m.' },
        { label: '09:00', value: '9:00 a.m.' },
        { label: '10:00', value: '10:00 a.m.' },
        { label: '11:00', value: '11:00 a.m.' },
        { label: '12:00', value: '12:00 p.m.' },
        { label: '13:00', value: '1:00 p.m.' },
        { label: '14:00', value: '2:00 p.m.' },
        { label: '15:00', value: '3:00 p.m.' },
        { label: '16:00', value: '4:00 p.m.' },
        { label: '17:00', value: '5:00 p.m.' }
    ];
    locationTypeOptions = [];
    constructor(
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private tenantService: TenantService,
        private employeeService: EmployeesService,
        private utilities: UtilitiesService
    ) {}

    async ngOnInit() {
        console.log('empl filter component', this.form, this.form.value);
        this.globalTags = await this.employeeService.getEmployeesTags();
        this.filtersOptions.tags = this.globalTags.map((l) => {
            return { label: l.title, value: l.title };
        });

        // this.form = this.fb.group({
        //     entry_criteria: [''],
        //     type: ['ongoing', Validators.required],
        //     start: ['immediately'],
        //     start_date: [''],
        //     start_time: [''],
        //     trigger: ['', Validators.required],
        //     filters: this.fb.array([])
        // });
        this.tenantService.locations().subscribe((response) => {
            if (response) {
                response = response.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                response.forEach((l) => {
                    this.locationTypeOptions.push({
                        label: `${l.name} - ${l.location_short}`,
                        value: l.location
                    });
                });
                this.filtersOptions.job_location = this.locationTypeOptions.slice();
                (this.form.controls['filters'] as FormArray).clear();
                if (this.rules) {
                    this.populateForm(this.rules);
                } else {
                    this.valueChangeFormSubsribe();
                }
                this.innerContentLoading = false;
            }
        });
        this.employeeService.getDepartments().then((departments: string[]) => {
            // console.log('DEPARTMENTS:', departments);

            this.filtersOptions.department = departments.map((d: string) => ({ label: d, value: d }));
        });
    }

    get filters() {
        return this.form.get('filters') as FormArray;
    }

    populateForm(rules) {
        // this.form = this.fb.group({
        //     entry_criteria: [''],
        //     type: [rules.type || '', Validators.required],
        //     start: [rules.start || ''],
        //     start_date: [rules.start_date ? moment(rules.start_date, 'DD-MM-YYYY').format() : ''],
        //     start_time: [rules.start_time],
        //     trigger: [rules.trigger],
        //     filters: this.fb.array([])
        // });
        if (rules.filters && rules.filters.length) {
            rules.filters.forEach((f) => {
                const filter = f.split('~');
                let value;
                filter[2].trim().charAt(0) === `'`
                    ? (value = filter[2].trim().substring(1, filter[2].trim().length - 1))
                    : (value = Number(filter[2].trim()));
                if (filter[0].trim() === 'salary_start_date' || filter[0].trim() === 'date_of_birth') {
                    value = moment(value, 'DD-MM-YYYY').format();
                }
                (this.form.controls['filters'] as FormArray).push(
                    this.fb.group({
                        type: [filter[0].trim()],
                        value: [value, Validators.required],
                        condition: [filter[1].trim()],
                        compare: [filter[3].trim() === '&&' ? 'and' : 'or']
                    })
                );
            });
            this.filteredEmployees();
            this.entryCriteriaDropDown = false;
        }
        this.findSelectedOptions();

        this.dropdownValueUpdated = false;
        setTimeout(() => {
            this.dropdownValueUpdated = true;
        }, 100);
        this.valueChangeFormSubsribe();
    }

    valueChangeFormSubsribe() {
        this.formSubscription = this.form
            .get('filters')
            .valueChanges.pipe(
                filter((values) => {
                    const nullValueExist = values.find((v) => v.value === null);
                    return nullValueExist ? false : true;
                }),
                debounceTime(1000)
            )
            .subscribe((r) => {
                this.filteredEmployees();
            });
    }

    filteredEmployees() {
        const data = {
            tenantId: this.utilities.getTenant(),
            filters: this.tranformFiltersValue()
        };
        this.innerContentLoading = true;
        this.filteredUsersRequestsComplete = false;
        this.employeeService.filteredEmployees(data).subscribe(
            (response: any[]) => {
                this.filteredUsersRequestsComplete = true;
                // console.log(response, this.tranformFiltersValue());
                this.filteredUsers =
                    response.map((u) => {
                        u.initials = u.name.charAt(0);
                        return u;
                    }) || [];
                this.innerContentLoading = false;
            },
            (err) => {
                this.filteredUsersRequestsComplete = true;
                this.innerContentLoading = false;
                this.filteredUsers = [];
                console.log(err);
            }
        );
    }

    onPlus() {
        const price = this.form.get('price').value;
        this.form.get('price').patchValue(price + 100);
    }

    onMinus() {
        const price = this.form.get('price').value;
        this.form.get('price').patchValue(price - 100 < 0 ? 0 : price - 100);
    }

    onChangeValue(e) {
        this.onAdd(e.value);
        this.entryCriteriaDropDown = false;
        this.form.get('entry_criteria').patchValue(null);
    }

    onDelete(index: number) {
        const type = this.form.get('filters')['controls'][index].value.type;
        const option = this.entryRulesOptions.find((o) => o.value === type);
        if (option) {
            option.selected = false;
        }
        const filters = this.form.get('filters') as FormArray;
        filters.removeAt(index);
        if (index > 0) {
            filters.controls[index - 1].patchValue({ compare: 'and' });
        }
        this.form.get('entry_criteria').patchValue(null);
        index === 0 ? (this.entryCriteriaDropDown = true) : (this.entryCriteriaDropDown = false);
        this.findSelectedOptions();
    }

    findSelectedOptions() {
        for (let opt in this.filtersOptions) {
            this.filtersOptions[opt].forEach((o) => (o.selected = false));
        }
        this.entryRulesOptions.forEach((o) => (o.selected = false));
        this.form.getRawValue().filters.forEach((f) => {
            // console.log(f, this.filtersOptions[f.type]);
            if (this.filtersOptions[f.type]) {
                this.filtersOptions[f.type].forEach((o) => {
                    if (o.value === f.value) {
                        o.selected = true;
                    }
                });
            }
        });
        this.entryRulesOptions.forEach((o) => {
            // console.log(o);
            const filter = this.form.getRawValue().filters.find((f) => o.value === f.type && f.compare === 'and');
            // console.log(filter);
            filter ? (o.selected = true) : (o.selected = false);
        });
    }

    showEntryCriteriaDropDown(i) {
        // console.log(this.form.value, this.form.valid, i, this.form.get('filters'));
        const formArr = this.form.get('filters');
        if (!formArr.valid) {
            this.formHelper.markFormGroupTouched(formArr['controls'][i]);
            return;
        }
        const item = this.form.value.filters[i];
        // if (
        //     item.type !== 'cost' &&
        //     item.type !== 'salary' &&
        //     item.type !== 'salary_start_date' &&
        //     item.type !== 'date_of_birth'
        // ) {
        //     formArr['controls'][i].get('value').disable();
        //     formArr['controls'][i].get('compare').disable();
        // }
        this.findSelectedOptions();
        this.entryCriteriaDropDown = true;
    }

    onAdd(type) {
        // console.log('type', type);
        (this.form.controls['filters'] as FormArray).push(
            this.fb.group({
                type,
                value: [null, Validators.required],
                condition: ['=='],
                compare: ['and']
            })
        );
        this.entryCriteriaDropDown = true;
    }

    tranformFiltersValue() {
        let filters = [];
        this.form.getRawValue().filters.forEach((cr) => {
            if (cr.type === 'salary_start_date' || cr.type === 'date_of_birth') {
                cr.value = moment(cr.value).format('DD-MM-YYYY');
            }
            let value;
            typeof cr.value === 'string' ? (value = `'${cr.value}'`) : (value = cr.value);
            filters.push(`${cr.type} ~ ${cr.condition} ~ ${value} ~ ${cr.compare === 'and' ? '&&' : '||'}`);
        });
        return filters;
    }

    ngOnDestroy() {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        for (let opt in this.filtersOptions) {
            this.filtersOptions[opt].forEach((o) => (o.selected = false));
        }
        this.entryRulesOptions.forEach((o) => (o.selected = false));
    }

    onChangeCompareOptions() {
        this.findSelectedOptions();
    }
}
