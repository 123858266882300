<div class="alert-block green-bg" (click)="onOverviewProgress()">
    <div class="img-wrapper">
        <img src="/assets/images/icons/icons-8-signing-a-document.svg" alt="" />
    </div>
    <div>
        <div class="alert-block-title">
            Offer letter in progress for {{ candidate?.first_name }} {{ candidate?.last_name }}
        </div>

        <div class="alert-block-description">
            CLICK FOR MORE DETAILS
        </div>
    </div>
    <button class="btn">
        <svg-icon
            src="/assets/images/portal/right-arrow.svg"
            [svgStyle]="{ 'width.px': 13, fill: '#ffffff' }"
            class="ml20"
        ></svg-icon>
    </button>
</div>
<app-offer-letter
    *ngIf="acceptedOfferModal.show"
    (close)="acceptedOfferModal = { show: false }"
    (send)="onSentOffer($event)"
    (approval)="onSentOffer($event)"
    [candidate]="acceptedOfferModal.data"
    [workflows]="acceptedOfferModal.workflows"
    [job]="job"
>
</app-offer-letter>

<app-loader *ngIf="contentLoading"></app-loader>

<div class="offer-tracking-modal" *ngIf="offerTrackingModal.show">
    <div class="content">
        <div class="header">
            <h3>Offer Letter Status</h3>
            <button class="close-btn btn" (click)="onHideOfferTrackingModal()">
                <img src="/assets/images/icons/icons-8-delete.svg" />
            </button>
        </div>

        <div class="tracking-holder" *ngIf="tracking.signers && tracking.signers.length">
            <p-accordion [multiple]="true">
                <p-accordionTab *ngFor="let item of tracking.signers" [selected]="item.expanded">
                    <p-header>
                        <div class="img-wrapper">
                            <div *ngIf="item.signed" class="status-icon success ng-star-inserted">
                                <div class="holder"></div>
                            </div>
                            <svg-icon
                                style="background: #fff;"
                                *ngIf="!item.signed && item.sent_at"
                                src="assets/images/clock-icon.svg"
                                [svgStyle]="{ 'width.px': 21, 'height.px': 21, fill: '#ee9500' }"
                            >
                            </svg-icon>
                        </div>
                        <div class="signer-name" *ngIf="item.label !== 'Candidate'">
                            {{ item.label }} - {{ item.full_name }}
                            <ng-container *ngIf="item.employee_id">({{ item.employee_id }})</ng-container>
                        </div>
                        <div class="signer-name" *ngIf="item.label === 'Candidate'">
                            Out for signing - {{ item.full_name }}
                            <ng-container *ngIf="item.employee_id">({{ item.employee_id }})</ng-container>
                        </div>
                    </p-header>
                    <div class="description">
                        <div class="signer-item">
                            <div class="signer-tracking">
                                <div class="tracking-status">
                                    <div *ngIf="item.sent_at" class="status-icon success ng-star-inserted">
                                        <div class="holder"></div>
                                    </div>
                                    <div class="empty-circle" *ngIf="!item.sent_at"></div>
                                </div>
                                <div class="tracking-action">Sent</div>
                                <div class="tracking-time">
                                    <ng-container *ngIf="item.sent_at">
                                        {{ item.sent_at | amFromUnix | amDateFormat: 'DD MMMM YYYY [at] HH:mm' }}
                                    </ng-container>
                                    <ng-container *ngIf="!item.sent_at">-</ng-container>
                                </div>
                            </div>
                            <div class="signer-tracking">
                                <div class="tracking-status">
                                    <div *ngIf="item.delivered_at" class="status-icon success ng-star-inserted">
                                        <div class="holder"></div>
                                    </div>
                                    <div class="empty-circle" *ngIf="!item.delivered_at"></div>
                                </div>
                                <div class="tracking-action">Delivered</div>
                                <div class="tracking-time">
                                    <ng-container *ngIf="item.delivered_at">{{
                                        item.delivered_at | amFromUnix | amDateFormat: 'DD MMMM YYYY [at] HH:mm'
                                    }}</ng-container>
                                    <ng-container *ngIf="!item.delivered_at">-</ng-container>
                                </div>
                            </div>
                            <div class="signer-tracking">
                                <div class="tracking-status">
                                    <div *ngIf="item.approved_at" class="status-icon success ng-star-inserted">
                                        <div class="holder"></div>
                                    </div>
                                    <div class="empty-circle" *ngIf="!item.approved_at"></div>
                                </div>
                                <div class="tracking-action">
                                    {{ item.label === 'Approver' ? 'Approved' : 'Signed' }}
                                </div>
                                <div class="tracking-time">
                                    <ng-container *ngIf="item.approved_at">
                                        {{ item.approved_at | amFromUnix | amDateFormat: 'DD MMMM YYYY [at] HH:mm' }}
                                    </ng-container>
                                    <ng-container *ngIf="!item.approved_at">-</ng-container>
                                </div>
                            </div>
                            <button
                                *ngIf="item.resend"
                                class="btn add-section-button"
                                (click)="onResendNotification(item)"
                            >
                                Resend Notification
                            </button>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div class="footer">
            <button class="btn btn-grey w140 dropdown-dots">
                Options
                <svg-icon src="assets/images/arrow-down1.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }">
                </svg-icon>
                <div class="dropdown" *ngIf="offerAssessment?.offer_accepted !== true">
                    <button
                        *ngIf="
                            !someoneSigned &&
                            !offerAssessment?.offer_accepted &&
                            offerAssessment?.offer_accepted !== false &&
                            !offerAssessment?.retracted
                        "
                        class="btn btn-dropdown"
                        (click)="onEditOffer()"
                    >
                        <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                        Resend Offer
                    </button>
                    <button
                        *ngIf="
                            retractAvailable &&
                            !offerAssessment?.retracted &&
                            offerAssessment?.offer_accepted !== true &&
                            offerAssessment?.offer_accepted !== false
                        "
                        class="btn btn-dropdown"
                        (click)="onRetractOffer()"
                    >
                        <img src="/assets/images/icons/icons-8-delete-document.svg" alt="" />
                        Retract Offer
                    </button>
                    <button
                        *ngIf="
                            offerAssessment?.offer_accepted !== false &&
                            offerAssessment?.offer_accepted !== true &&
                            !offerAssessment?.retracted
                        "
                        class="btn btn-dropdown"
                        (click)="onCandidateDeclineOffer()"
                    >
                        <img src="/assets/images/icons/icons-8-id-not-verified.svg" alt="" />
                        Candidate Declined
                    </button>
                    <button *ngIf="offerAssessment?.retracted" class="btn btn-dropdown" (click)="onEditOffer()">
                        <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                        Send Offer
                    </button>
                    <button
                        *ngIf="!offerAssessment?.retracted && reOffer.available"
                        class="btn btn-dropdown"
                        (click)="onReOffer()"
                    >
                        <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                        Re-offer
                    </button>
                </div>
            </button>
            <button class="btn btn-primary w140" (click)="onHideOfferTrackingModal()">Done</button>
        </div>
    </div>
    <div class="overlay" (click)="onHideOfferTrackingModal()"></div>
</div>
