import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { pick } from 'lodash';
import moment from 'moment';
import { map, retry, take } from 'rxjs/operators';
import { Post } from '../models/post';
import { User } from '../models/user';
import { TenantService } from './tenant.service';
import { UtilitiesService } from './utilities.service';

const postUpdateFields = [
    'title',
    'post_text',
    'start_date',
    'start_time',
    'status',
    'location',
    'business_unit',
    'post_file'
];

@Injectable({
    providedIn: 'root'
})
export class CommunicationsService {
    apiURL: string = environment.apiUrl;
    baseURL = '';
    constructor(
        private http: HttpClient,
        private utilities: UtilitiesService,
        private db: AngularFirestore,
        private tenantService: TenantService
    ) {
        this.tenantService.init();
    }

    getPostById$(postId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/posts`)
            .doc(postId)
            .valueChanges()
            .pipe(
                take(1),
                map((post: Post) => {
                    if (post) {
                        post.id = postId;
                        return post;
                    } else {
                        return null;
                    }
                })
            );
    }

    getPostCategories() {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/post_categories`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getAllPosts() {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/posts`, (ref) => ref.orderBy('created_at', 'desc'))
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                retry(2),
                map((events) => {
                    events.forEach((e: any) => {
                        if (e.send_type === 'Scheduled') {
                            let now = moment().unix();
                            let start_date = moment(e.start_date);
                            let start_time = e.start_time.split(':');
                            start_date.add(Number(start_time[0]), 'hours');
                            start_date.add(Number(start_time[1]), 'minutes');
                            if (now > start_date.unix()) {
                                e.send_type_status = 'Sent';
                            }
                        }
                    });
                    return events
                        .filter((p: any) => p.title)
                        .sort((a: any, b: any) => {
                            const dateA =
                                a.start_date && a.send_type === 'Scheduled'
                                    ? moment(a.start_date).unix()
                                    : a.created_at.seconds;
                            const dateB =
                                b.start_date && b.send_type === 'Scheduled'
                                    ? moment(b.start_date).unix()
                                    : b.created_at.seconds;
                            if (dateA === dateB) {
                                const timeA = moment(a.start_time, 'HH:mm').unix();
                                const timeB = moment(b.start_time || '23:55', 'HH:mm').unix();
                                return timeB - timeA;
                            } else {
                                return dateB - dateA;
                            }
                        });
                })
            );
    }

    getAllComments(postId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/posts/${postId}/comments`, (ref) =>
                ref.orderBy('created_at', 'desc')
            )
            .valueChanges({ idField: 'id' })
            .pipe(take(1), retry(2));
    }

    getPostById(postId): Promise<Post> {
        return new Promise(async (resolve, reject) => {
            this.getPostById$(postId).subscribe(
                (post: Post) => {
                    post.id = postId;
                    return resolve(post);
                },
                (errorResponse) => reject(errorResponse)
            );
        });
    }

    savePost(post: Post, user: User): Promise<Post> {
        return new Promise(async (resolve, reject) => {
            try {
                if (post.id) {
                    // Update
                    console.log('savePost => update', post);
                    const updData = pick(post, postUpdateFields);
                    console.log(updData);
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/posts`)
                        .doc(post.id)
                        .update(updData);

                    return resolve(post);
                } else {
                    post.created_at = Math.floor(Date.now() / 1000);
                    post.userId = user.id;
                    post.full_name = `${user.first_name} ${user.last_name}`;
                    console.log('savePost => create', post);

                    const response = await this.db.collection(`tenants/${this.utilities.getTenant()}/posts`).add(post);
                    const postId = response.id;
                    const dbPost = await this.getPostById(postId);
                    return resolve(dbPost);
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    sendNotifications(data) {
        return this.http.post(`${this.apiURL}/send-notifications`, data);
    }

    createPost(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/communications`, { data });
    }

    updatePost(id, data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/communications/${id}`, { data });
    }

    deletePost(postId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/posts`)
            .doc(postId)
            .delete();
    }
}
