<div
    class="alert-block red-bg"
    [ngClass]="{
        'red-bg': status === 'reject',
        'green-bg': status === 'pending'
    }"
>
    <div class="img-wrapper">
        <img src="/assets/images/icons/icons-8-signing-a-document.svg" alt="" />
    </div>
    <div>
        <div class="alert-block-title">
            {{ status === 'reject' ? 'HR Rejected' : 'Offer Acceptance Pending' }}
        </div>
        <div class="alert-block-description">
            CANDIDATE ALERT
        </div>
    </div>
    <span class="dropdown-dots">
        <span class="dots"><i></i><i></i><i></i></span>
        <div class="dropdown">
            <button class="btn btn-dropdown" (click)="onEditOffer()">
                <img src="/assets/images/icons/icons-8-edit-file.svg" alt="" />
                Resend Offer
            </button>
        </div>
    </span>
</div>
<app-offer-letter
    *ngIf="acceptedOfferModal.show"
    (close)="acceptedOfferModal = { show: false }"
    (send)="onApprovalOffer($event)"
    (approval)="onApprovalOffer($event)"
    [candidate]="acceptedOfferModal.data"
    [workflows]="acceptedOfferModal.workflows"
    [job]="job"
>
</app-offer-letter>

<app-loader *ngIf="contentLoading"></app-loader>
