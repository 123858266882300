import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SdkJob } from '@app/core/models';
import { CandidateService, JobService, OffersService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, Subscription } from 'rxjs';

@Component({
    selector: 'app-employee-offer-tracking',
    templateUrl: './employee-offer-tracking.component.html',
    styleUrls: ['./employee-offer-tracking.component.scss']
})
export class EmployeeOfferTrackingComponent implements OnInit, OnDestroy {
    @Input() employee;
    @Input() user;
    contentLoading = false;
    offerTrackingModal = {
        show: false
    };
    terminationLetterTrackingModal = {
        show: false
    };
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflows: any[];
        delete_workflow_id?: null;
    } = {
        show: false,
        data: null,
        workflows: []
    };
    tracking: any;
    terminationTracking: any;

    offer;
    offerAssessment;
    declineByCandidate = false;
    someoneSigned = false;
    retractAvailable = true;
    reOffer = {
        available: false,
        workflow_id: null
    };
    candidateSigned = false;
    offerApproval: any;
    subscription: Subscription = new Subscription();
    offerCompleted = false;
    constructor(
        private offerService: OffersService,
        private employeeService: EmployeesService,
        private toaster: ToastrService
    ) {}

    async ngOnInit() {
        const offerSubscription = combineLatest([
            this.offerService.getEmployeeOfferTrackings(this.employee.id),
            this.offerService.getAllEmployeeOfferApprovalDetails(this.employee.id)
        ]).subscribe((response: any[]) => {
            // console.log('offer tracking response', response, this.employee.id);
            if (!response[0][0]) {
                return;
            }

            this.offerApproval = response[1][0];
            // console.log('offerApproval', this.offerApproval);
            this.tracking = response[0].find((s) => this.offerApproval.id === s.id);

            this.tracking.signers.forEach((t, index) => {
                t.expanded = false;
                t.resend = false;
                if (t.label === 'Approver') {
                    if (this.offerApproval && this.offerApproval.approve_at) {
                        t.approved_at = this.offerApproval.approve_at;
                        t.signed = true;
                    }
                }
                const offerSigns = this.offerApproval.offer_signs || [];

                // console.log(offerSigns, t);
                const item = offerSigns.find((o) => o.id === t.id);
                if (item && t.label !== 'Approver') {
                    t.signed = item.signed;
                    t.approved_at = item.signed_at;
                }
            });
            if (this.offerApproval.offer_signs?.length) {
                this.offerCompleted = this.offerApproval.offer_signs.every((o) => o.signed);
                if (this.offerCompleted && !this.offerApproval.viewed) {
                    this.subscription.unsubscribe();
                    this.offerService.updateEmployeeOfferApprovalDetails(this.employee.id, this.offerApproval.id, {
                        viewed: true,
                        viewed_at: Math.floor(Date.now() / 1000)
                    });
                }
            }
            const currentSigner = this.tracking.signers.find((s: any) => !s.approved_at);
            if (currentSigner) {
                currentSigner.resend = true;
                currentSigner.expanded = true;
            }
        });
        this.subscription.add(offerSubscription);

        const terminationSubscription = this.offerService
            .getTerminationApprovalDetails(this.employee.id)
            .subscribe((response: any) => {
                if (response && response.tracking) {
                    this.terminationTracking = response;
                    // console.log('terminationTracking', this.terminationTracking);
                    this.terminationTracking.tracking.forEach((t, index) => {
                        t.expanded = false;
                        t.resend = false;
                        const signs = this.terminationTracking.signs || [];
                        const item = signs.find((o) => o.id === t.id);
                        if (item) {
                            t.signed = item.signed;
                            t.approved_at = item.signed_at;
                            if (!t.approved_at) {
                                t.expanded = true;
                            }
                        }
                    });
                }
            });
        this.subscription.add(terminationSubscription);
    }

    onOverviewProgress() {
        this.offerTrackingModal = {
            show: true
        };
    }

    onOverviewTerminationProgress() {
        this.terminationLetterTrackingModal = {
            show: true
        };
    }

    onHideOfferTrackingModal() {
        this.offerTrackingModal = {
            show: false
        };
    }

    onHideTerminationLetterTrackingModal() {
        this.terminationLetterTrackingModal = {
            show: false
        };
    }

    onResendNotification(item) {
        console.log('resend notification', item);
        if (item.label === 'Approver') {
            this.contentLoading = true;
            this.employeeService
                .resendOfferApprovalNotification(
                    this.employee.id,
                    this.tracking.id,
                    this.offerApproval.hr_business_partner
                )
                .subscribe((response) => {
                    console.log('Success', response);
                    this.toaster.success('Email Sent');
                    this.contentLoading = false;
                });
        } else {
            this.contentLoading = true;
            this.employeeService.resendOfferNotification(this.employee.id, this.tracking.id).subscribe((response) => {
                console.log('Success', response);
                this.contentLoading = false;
            });
        }
    }

    async onRetractOffer() {
        const data = {
            status: 'reject',
            updated_at: Math.floor(Date.now() / 1000)
        };
        this.updateEmployeeDetails(data);
    }

    async onCandidateDeclineOffer() {
        const data = {
            status: 'decline',
            updated_at: Math.floor(Date.now() / 1000)
        };
        this.updateEmployeeDetails(data);
    }

    async updateEmployeeDetails(data) {
        await this.offerService.updateEmployeeOfferApprovalDetails(this.employee.id, this.offerApproval.id, data);
        const currentSigner = this.offerApproval.offer_signs.find((o) => !o.signed);
        if (currentSigner && currentSigner.label !== 'Candidate') {
            await this.offerService.updateEmployeeTask(currentSigner.id, currentSigner.signature_id, {
                completed: true
            });
        } else if (currentSigner && currentSigner.label === 'Candidate') {
        }
        this.onHideOfferTrackingModal();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
