// { label: '', value: '' },
export const CompanyRule = [
    {
        CompanyRuleCode: 'TOYOTA',
        'Company Rule Description': 'Toyota',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'BMW',
        'Company Rule Description': 'BMW',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'IC_AUTO',
        'Company Rule Description': 'IC Auto',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'MERCEDES_BENZ',
        'Company Rule Description': 'Mercedes Benz',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'HERTZ',
        'Company Rule Description': 'Hertz',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'GROUP_OFFICE',
        'Company Rule Description': 'Group Office',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'VOLKSWAGEN',
        'Company Rule Description': 'Volkswagen',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'OPEL_ISUZU',
        'Company Rule Description': 'Opel/Isuzu',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'SPOTTERS',
        'Company Rule Description': 'Spotters',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'ITC',
        'Company Rule Description': 'Isuzu Truck Centre',
        CompanyCode: 'ITC ',
        LeavePolicyID: 18,
        'PaymentRunDefID ACB': 3,
        'PaymentRunDefID CASH': 10,
        RemunerationEarnDefID: 95,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'CFAO_CENTRA_PAY',
        'Company Rule Description': 'CFAO Centralised Office Payroll',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'TEST',
        'Company Rule Description': 'Test',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'MAN',
        'Company Rule Description': 'Man',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'AUTO_MALL',
        'Company Rule Description': 'Auto Mall',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'CFAO_NAM_SERV',
        'Company Rule Description': 'Hertz Namibia Services',
        CompanyCode: 'HERTZ_NAM_SERV',
        LeavePolicyID: 24,
        'PaymentRunDefID ACB': 6,
        'PaymentRunDefID CASH': 11,
        RemunerationEarnDefID: 213,
        TaxCountryCode: 'NAM',
        CCY: 'NAD - Namibia, Dollars',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'CFAO_CENTRAL',
        'Company Rule Description': 'CFAO Centralised Office',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'HERTZ_BOT',
        'Company Rule Description': 'Hertz Botswana',
        CompanyCode: 'HERTZ_BOT',
        LeavePolicyID: 25,
        'PaymentRunDefID ACB': 8,
        'PaymentRunDefID CASH': 9,
        RemunerationEarnDefID: 221,
        TaxCountryCode: 'BWA',
        CCY: 'BWP - Botswana, Pula',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '07/01/2023',
        'Company Tax Year End': '06/30/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'SPOTTERS_ITC',
        'Company Rule Description': 'Spotters Isuzu Truck Centre',
        CompanyCode: 'ITC',
        LeavePolicyID: 18,
        'PaymentRunDefID ACB': 3,
        'PaymentRunDefID CASH': 10,
        RemunerationEarnDefID: 95,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'FORD',
        'Company Rule Description': 'Ford',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'AUTO_TEAM',
        'Company Rule Description': 'Auto Team',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'UM_LEARNERS',
        'Company Rule Description': 'UM Learners',
        CompanyCode: 'CFAO_MOBILITY',
        LeavePolicyID: 3,
        'PaymentRunDefID ACB': 1,
        'PaymentRunDefID CASH': 2,
        RemunerationEarnDefID: 2,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'TTSAP',
        'Company Rule Description': 'Toyota Tsusho SA Process',
        CompanyCode: 'TTSAP',
        LeavePolicyID: 27,
        'PaymentRunDefID ACB': 14,
        'PaymentRunDefID CASH': 15,
        RemunerationEarnDefID: 286,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'TTAF',
        'Company Rule Description': 'TTAF',
        CompanyCode: 'TTAF',
        LeavePolicyID: 28,
        'PaymentRunDefID ACB': 16,
        'PaymentRunDefID CASH': 'None',
        RemunerationEarnDefID: 445,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'AMS',
        'Company Rule Description': 'AMS',
        CompanyCode: 'AMS',
        LeavePolicyID: 29,
        'PaymentRunDefID ACB': 17,
        'PaymentRunDefID CASH': 'None',
        RemunerationEarnDefID: 533,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'CFAO_H',
        'Company Rule Description': 'CFAO Holdings',
        CompanyCode: 'CFAO_H',
        LeavePolicyID: 30,
        'PaymentRunDefID ACB': 18,
        'PaymentRunDefID CASH': 'None',
        RemunerationEarnDefID: 621,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'TGSA',
        'Company Rule Description': 'TGSA',
        CompanyCode: 'TGSA',
        LeavePolicyID: 31,
        'PaymentRunDefID ACB': 19,
        'PaymentRunDefID CASH': 'None',
        RemunerationEarnDefID: 709,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    },
    {
        CompanyRuleCode: 'ADVICS',
        'Company Rule Description': 'ADVICS',
        CompanyCode: 'ADVICS',
        LeavePolicyID: 32,
        'PaymentRunDefID ACB': 20,
        'PaymentRunDefID CASH': 'None',
        RemunerationEarnDefID: 726,
        TaxCountryCode: 'ZAF',
        CCY: 'ZAR - South Africa, Rands',
        DefaultTaxStatus: 'ST',
        DefaultTaxCalculation: 'A',
        'Company Tax Year Start': '03/01/2023',
        'Company Tax Year End': '02/29/2024',
        Status: 'A - Active'
    }
];
export const leavePolicies = [
    {
        'Leave Policy Id': 32,
        Code: 'ADVICS_20',
        'Short Description': 'Advics Standard',
        'Long Description': 'Advics Standard',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 33,
        Code: 'ADVICS_CON',
        'Short Description': 'Advics Contractors',
        'Long Description': 'Advics Contractors',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 37,
        Code: 'TGSA_MIBCO_C1',
        'Short Description': 'TGSA MIBCO C1',
        'Long Description': 'TGSA MIBCO C1',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 29,
        Code: 'AMS_20',
        'Short Description': 'AMS Standard',
        'Long Description': 'AMS Standard',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 36,
        Code: 'AMS_CON',
        'Short Description': 'AMS Contractors',
        'Long Description': 'AMS Contractors',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 30,
        Code: 'CFAO_H_20',
        'Short Description': 'CFAO Standard',
        'Long Description': 'CFAO Standard',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 35,
        Code: 'CFAO_H_CON',
        'Short Description': 'CFAO Holdings Contractors',
        'Long Description': 'CFAO Holdings Contractors',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 1,
        Code: 'DIV_EXEC',
        'Short Description': 'Divisional Chief Executives',
        'Long Description': 'Divisional Chief Executives',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 2,
        Code: 'GEN_MAN',
        'Short Description': 'General Managers',
        'Long Description': 'General Managers',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 3,
        Code: 'NORM_30',
        'Short Description': 'Normal 30',
        'Long Description': 'Normal 30',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 4,
        Code: 'NORM_36',
        'Short Description': 'Normal 36',
        'Long Description': 'Normal 36',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 5,
        Code: 'S&A_SAT',
        'Short Description': 'Sick & Accident Saterday',
        'Long Description': 'Sick & Accident Saterday',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 6,
        Code: 'S&A',
        'Short Description': 'Sick & Accident',
        'Long Description': 'Sick & Accident',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 7,
        Code: 'ZERO_LEAVE',
        'Short Description': 'Zero Leave',
        'Long Description': 'Zero Leave',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 9,
        Code: 'GOM',
        'Short Description': 'Group Office Managers',
        'Long Description': 'Group Office Managers',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 10,
        Code: 'GOE',
        'Short Description': 'Group Office Employees',
        'Long Description': 'Group Office Employees',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 12,
        Code: 'HERTZ_EMP',
        'Short Description': 'Hertz Employees',
        'Long Description': 'Hertz Employees',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 13,
        Code: 'HERTZ_MAN',
        'Short Description': 'Hertz Managers',
        'Long Description': 'HERTZ MANAGERS',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 14,
        Code: 'RDS_PRE',
        'Short Description': 'Reeds Pre 01/05/2011',
        'Long Description': 'Reeds Pre 01/05/2011',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 15,
        Code: 'RDS_POST',
        'Short Description': 'Reeds Post 01/05/2011',
        'Long Description': 'Reeds Post 01/05/2011',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 16,
        Code: 'REEDS_S&A',
        'Short Description': 'Reeds Sick & Accident',
        'Long Description': 'Reeds Sick & Accident',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 20,
        Code: 'HERTZ_EMP_OVERW',
        'Short Description': 'Hertz Employees Overwrite',
        'Long Description': 'Hertz Employees Overwrite',
        Comment:
            "Hertz Shift employees moet units kan vermeerder wanneer hulle transaksies inlees wat oor 'n naweek val.\r\n",
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 21,
        Code: 'HERTZ_MAN_OVERW',
        'Short Description': 'Hertz Managers Overwrite',
        'Long Description': 'Hertz Managers Overwrite',
        Comment:
            "HERTZ SHIFT WORKERS MOET UNITRS KAN OVERWRITE WANNEER DIE VERLOF TRANSAKSIE OOR 'N NAWEEK VAL. DAAROM DIE EKSTRA POLICY\r\n",
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 25,
        Code: 'HERTZ_BOT',
        'Short Description': 'Hertz Bot',
        'Long Description': 'Hertz Bot',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 23,
        Code: 'HERTZ_NAM',
        'Short Description': 'Hertz Namibia',
        'Long Description': 'Hertz Namibia',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 24,
        Code: 'UNITRANS_NAM',
        'Short Description': 'Unitrans Namibia',
        'Long Description': 'Unitrans Namibia',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 22,
        Code: 'HERTZ_ZIM',
        'Short Description': 'Hertz Zim',
        'Long Description': 'ZERO',
        'Overlapping Dates Selection': '3 - Allow without warning',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 8,
        Code: 'ZERO_LEAVE',
        'Short Description': 'ZERO LEAVE',
        'Long Description': 'ZERO LEAVE',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 17,
        Code: 'ITC_PRE',
        'Short Description': 'ITC Pre 01/05/2011',
        'Long Description': 'ITC Pre 01/05/2011',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 18,
        Code: 'ITC_POST',
        'Short Description': 'ITC Post 01/05/2011',
        'Long Description': 'ITC Post 01/05/2011',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 19,
        Code: 'ITC_S&A',
        'Short Description': 'ITC S&A',
        'Long Description': 'ITC S&A',
        'Overlapping Dates Selection': '1 - Stop',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 26,
        Code: 'SEC',
        'Short Description': 'Sec',
        'Long Description': 'Sec',
        'Overlapping Dates Selection': '3 - Allow without warning',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 31,
        Code: 'TGSA_20',
        'Short Description': 'TGSA Standard',
        'Long Description': 'TGSA ',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 38,
        Code: 'TGSA_MIB_C1',
        'Short Description': 'TGSA Mibco Chapter 1',
        'Long Description': 'TGSA Mibco Chapter 1',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 39,
        Code: 'TGSA_CON',
        'Short Description': 'TGSA Contractor',
        'Long Description': 'TGSA Contractor',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 40,
        Code: 'TGSA_MIB_C3',
        'Short Description': 'TGSA MIBCO Chapter 3',
        'Long Description': 'TGSA MIBCO Chapter 3',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 47,
        Code: 'TTAF_NO_LVE',
        'Short Description': 'TTAF No Leave',
        'Long Description': 'TTAF No Leave',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 28,
        Code: 'TTAF_20',
        'Short Description': 'TTAF 20 Days',
        'Long Description': 'TTAF 20 Days',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 41,
        Code: 'TTAF_CON',
        'Short Description': 'TTAF Contractors',
        'Long Description': 'TTAF Contractors',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 42,
        Code: 'TTAF_MIB_C1',
        'Short Description': 'TTAF MIBCO C1',
        'Long Description': 'TTAF MIBCO C1',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 43,
        Code: 'TTAF_MIB_C3',
        'Short Description': 'TTAF Mibco Chapter 3',
        'Long Description': 'TTAF Mibco Chapter 3',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 27,
        Code: 'TTSAP_STANDARD',
        'Short Description': 'TTSAP Standard',
        'Long Description': 'TTSAP Standard',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 44,
        Code: 'TTSAP_CON',
        'Short Description': 'TTSAP Contractors',
        'Long Description': 'TTSAP Contractors',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 45,
        Code: 'TTSAP_MEIBC',
        'Short Description': 'TTSAP MEIBC',
        'Long Description': 'TTSAP MEIBC',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    },
    {
        'Leave Policy Id': 46,
        Code: 'TTSAP_NO_LVE',
        'Short Description': 'TTSAP NO LEAVE',
        'Long Description': 'TTSAP NO LEAVE',
        'Overlapping Dates Selection': '2 - Warn',
        'Transaction Allocation': '1 - Transaction Start Date',
        'Custom At Employee Level': true
    }
];
export const titleType = [
    {
        Code: 'ADV',
        'Short Description': 'Adv'
    },
    {
        Code: 'DR',
        'Short Description': 'Dr'
    },
    {
        Code: 'JUDGE',
        'Short Description': 'Judge'
    },
    {
        Code: 'MISS',
        'Short Description': 'Miss'
    },
    {
        Code: 'MR',
        'Short Description': 'Mr'
    },
    {
        Code: 'MRS',
        'Short Description': 'Mrs'
    },
    {
        Code: 'MS',
        'Short Description': 'Ms'
    },
    {
        Code: 'PROF',
        'Short Description': 'Prof.'
    },
    {
        Code: 'PR',
        'Short Description': 'Pr'
    },
    {
        Code: 'RABBI',
        'Short Description': 'Rabbi'
    },
    {
        Code: 'REV',
        'Short Description': 'Rev'
    },
    {
        Code: 'SIR',
        'Short Description': 'Sir'
    },
    {
        Code: 'HH',
        'Short Description': 'His Royal Highness'
    }
];
export const MaritalStatusType = [
    {
        Code: 'SIN',
        ShortDescription: 'Single'
    },
    {
        Code: 'MRI',
        ShortDescription: 'Married in Community'
    },
    {
        Code: 'MRO',
        ShortDescription: 'Married out of Community'
    },
    {
        Code: 'DIV',
        ShortDescription: 'Divorced'
    },
    {
        Code: 'WID',
        ShortDescription: 'Widowed'
    },
    {
        Code: 'PES',
        ShortDescription: 'Permanently Separated'
    },
    {
        Code: 'LIF',
        ShortDescription: 'Life Partner'
    },
    {
        Code: 'MRD',
        ShortDescription: 'Married'
    }
];
export const NatureOfContract = [
    {
        Code: 'PERM',
        'Short Description': 'Permanent',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'TEMP',
        'Short Description': 'Temporary',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'FIXED',
        'Short Description': 'Fixed Period',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'CONTRACT',
        'Short Description': 'Contractor Worker',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'INSTRUCTOR',
        'Short Description': 'Instructor for Training',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'APPLICANT',
        'Short Description': 'Job Applicant',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'OTHER',
        'Short Description': 'Other',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'REGCAS',
        'Short Description': 'Regular & Casual',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'STAFF',
        'Short Description': 'Staff',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'STAFFTIME',
        'Short Description': 'Staff – Time Sheet'
    },
    {
        Code: 'CASUAL',
        'Short Description': 'Casual',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'COMM_ONLY',
        'Short Description': 'Commission only',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'FIXED_PENSIONER',
        'Short Description': 'FIXED TERM PENSIONER',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'FIXED_APPY',
        'Short Description': 'FIXED TERM APPRENTICE',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'ILL_HEALTH',
        'Short Description': 'ILL HEALTH ',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'DISABLED_EMPLOY',
        'Short Description': 'Disabled Employee',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'INTERNS',
        'Short Description': 'INTERNS',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'FIXED_WORKPMT',
        'Short Description': 'FIXED TERM WORK PERMIT HOLDER',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'NED',
        'Short Description': 'NON EXECUTIVE DIRECTOR'
    },
    {
        Code: 'PERM_WORKPMT',
        'Short Description': 'PERMANENT WORK PERMIT HOLDER',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'PART_TIME',
        'Short Description': 'PART TIME <45 HOURS PER WEEK',
        'Equity Type Of Employment Type': '2 - Temporary',
        'Skills Type Of Employment Type': '2 - Non-Permanent'
    },
    {
        Code: 'PERM_NON_PROV',
        'Short Description': 'Permanent',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'LEARNER',
        'Short Description': 'Learner',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    },
    {
        Code: 'YES_LEARNER',
        'Short Description': 'YES LEARNER',
        'Equity Type Of Employment Type': '1 - Permanent',
        'Skills Type Of Employment Type': '1 - Permanent'
    }
];
export const UniversalBankCodes = [
    {
        Bank: 'ABSA',
        'Branch Code': '632005',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'CAPITEC',
        'Branch Code': '470010',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'FNB',
        'Branch Code': '250655',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'INVESTEC',
        'Branch Code': '580105',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'NEDBANK',
        'Branch Code': '198765',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'SAPOST',
        'Branch Code': '460005',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'STDBANK',
        Label: 'STDBANK (UNIVERSAL)',
        'Branch Code': '051001',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'TYME',
        Label: 'TYME (Universal Code)',
        'Branch Code': '678910',
        'Branch Name': 'Universal Code'
    },
    {
        Bank: 'DISCOVERY',
        'Branch Code': '679000',
        'Branch Name': 'Discovery Universal Bank'
    },
    {
        Bank: 'STDBANK',
        Label: 'STDBANK (Universal)',
        'Branch Code': '051005',
        'Branch Name': 'Universal'
    },
    {
        Bank: 'TYME',
        Label: 'TYME (Universal)',
        'Branch Code': '678910',
        'Branch Name': 'Universal'
    },
    {
        Bank: 'FRB',
        'Branch Code': '201419',
        'Branch Name': 'UNIVERSAL'
    },
    {
        Bank: 'BIDVEST BANK',
        'Branch Code': '462005',
        'Branch Name': 'Bidvest Bank (BIDVEST)'
    }
];
export const LicenceCategories = [
    {
        Code: 'ZIMB',
        'Short Description': 'ZIMB LIC'
    },
    {
        Code: 'PDP',
        'Short Description': 'PUBLIC DRIVERS PERMIT'
    },
    {
        Code: 'EB',
        'Short Description': 'EB'
    },
    {
        Code: 'B',
        'Short Description': 'B'
    },
    {
        Code: 'A',
        'Short Description': 'A'
    },
    {
        Code: 'A1',
        'Short Description': 'A1'
    },
    {
        Code: 'C',
        'Short Description': 'C'
    },
    {
        Code: 'C1',
        'Short Description': 'C1'
    },
    {
        Code: 'EC1',
        'Short Description': 'EC1'
    },
    {
        Code: 'EC',
        'Short Description': 'EC'
    },
    {
        Code: 'BOT_B',
        'Short Description': 'Botswana B'
    },
    {
        Code: 'BOT_C',
        'Short Description': 'Botswana C'
    },
    {
        Code: 'NAM_B',
        'Short Description': 'Namibia B'
    },
    {
        Code: 'NAM_C',
        'Short Description': 'Namibia C'
    },
    {
        Code: 'NAM_BE',
        'Short Description': 'Namibia BE'
    }
];
export const Paypoint = [
    {
        'Hierarchy Code': 'AUTU15',
        'Hierarchy Name': 'Autoteam East Rand'
    },
    {
        'Hierarchy Code': 'CLEX',
        'Hierarchy Name': 'Lexus Clearwater'
    },
    {
        'Hierarchy Code': 'ELEX',
        'Hierarchy Name': 'Lexus East Rand'
    },
    {
        'Hierarchy Code': 'MB_CREDITORS',
        'Hierarchy Name': 'Group Creditors'
    },
    {
        'Hierarchy Code': 'PLEX',
        'Hierarchy Name': 'Lexus Limpopo'
    },
    {
        'Hierarchy Code': 'TRUC',
        'Hierarchy Name': 'Hino East Rand'
    },
    {
        'Hierarchy Code': 'WTRU',
        'Hierarchy Name': 'Hino West Rand'
    },
    {
        'Hierarchy Code': 'ERA',
        'Hierarchy Name': 'Toyota East Rand Automark'
    },
    {
        'Hierarchy Code': 'RONDH',
        'Hierarchy Name': 'Hertz Rondebosch East'
    },
    {
        'Hierarchy Code': 'ROND',
        'Hierarchy Name': 'Toyota Rondebosch East'
    },
    {
        'Hierarchy Code': 'BOTH',
        'Hierarchy Name': 'Toyota Bothaville'
    },
    {
        'Hierarchy Code': 'BTRU',
        'Hierarchy Name': 'Hino Buffalo'
    },
    {
        'Hierarchy Code': 'CTC',
        'Hierarchy Name': 'Toyota Cape Town City'
    },
    {
        'Hierarchy Code': 'CTCL',
        'Hierarchy Name': 'Lexus Cape Town City'
    },
    {
        'Hierarchy Code': 'DIAH',
        'Hierarchy Name': 'Hino Algoa'
    },
    {
        'Hierarchy Code': 'EAST',
        'Hierarchy Name': 'Toyota East London'
    },
    {
        'Hierarchy Code': 'HART',
        'Hierarchy Name': 'Toyota Hartswater'
    },
    {
        'Hierarchy Code': 'KIMB',
        'Hierarchy Name': 'Toyota Kimberley'
    },
    {
        'Hierarchy Code': 'KLER',
        'Hierarchy Name': 'Toyota Klerksdorp'
    },
    {
        'Hierarchy Code': 'KROO',
        'Hierarchy Name': 'Toyota Kroonstad'
    },
    {
        'Hierarchy Code': 'MTHA',
        'Hierarchy Name': 'Toyota Mthatha'
    },
    {
        'Hierarchy Code': 'NEWT',
        'Hierarchy Name': 'Toyota Port Elizabeth'
    },
    {
        'Hierarchy Code': 'TOKA',
        'Hierarchy Name': 'Toyota Tokai'
    },
    {
        'Hierarchy Code': 'UITE',
        'Hierarchy Name': 'Toyota Uitenhage'
    },
    {
        'Hierarchy Code': 'VRYB',
        'Hierarchy Name': 'Toyota Vryburg'
    },
    {
        'Hierarchy Code': 'WELK',
        'Hierarchy Name': 'Toyota Welkom'
    },
    {
        'Hierarchy Code': 'BFN',
        'Hierarchy Name': 'Toyota Bloemfontein'
    },
    {
        'Hierarchy Code': 'CAV',
        'Hierarchy Name': 'Toyota Cavendish'
    },
    {
        'Hierarchy Code': 'HAM',
        'Hierarchy Name': 'Hino Oranje'
    },
    {
        'Hierarchy Code': 'KWT',
        'Hierarchy Name': 'Toyota King Williams Town'
    },
    {
        'Hierarchy Code': 'OTT',
        'Hierarchy Name': 'Toyota Ottosdal'
    },
    {
        'Hierarchy Code': 'SWR',
        'Hierarchy Name': 'Toyota Schweizer Reneke'
    },
    {
        'Hierarchy Code': 'TUL',
        'Hierarchy Name': 'Man Tulisa Park'
    },
    {
        'Hierarchy Code': 'UNI',
        'Hierarchy Name': 'Man United Truck Centre'
    },
    {
        'Hierarchy Code': 'BOKS',
        'Hierarchy Name': 'Toyota Boksburg'
    },
    {
        'Hierarchy Code': 'CONS',
        'Hierarchy Name': 'Toyota Clearwater'
    },
    {
        'Hierarchy Code': 'PIET',
        'Hierarchy Name': 'Toyota Limpopo'
    },
    {
        'Hierarchy Code': 'PTRU',
        'Hierarchy Name': 'Hino Limpopo'
    },
    {
        'Hierarchy Code': 'RAND',
        'Hierarchy Name': 'Toyota Randfontein'
    },
    {
        'Hierarchy Code': 'RIVO',
        'Hierarchy Name': 'Toyota Rivonia'
    },
    {
        'Hierarchy Code': 'SPRI',
        'Hierarchy Name': 'Toyota Springs'
    },
    {
        'Hierarchy Code': 'CAR',
        'Hierarchy Name': 'Toyota Carnival'
    },
    {
        'Hierarchy Code': 'ERT',
        'Hierarchy Name': 'Toyota East Rand'
    },
    {
        'Hierarchy Code': 'WRT',
        'Hierarchy Name': 'Toyota West Rand'
    },
    {
        'Hierarchy Code': 'MELR',
        'Hierarchy Name': 'Toyota Melrose'
    },
    {
        'Hierarchy Code': 'STA',
        'Hierarchy Name': 'Toyota Killarney Stanger'
    },
    {
        'Hierarchy Code': 'HQ_TOY',
        'Hierarchy Name': 'Toyota Admin Office'
    },
    {
        'Hierarchy Code': '49',
        'Hierarchy Name': 'Opel/Isuzu Edenvale'
    },
    {
        'Hierarchy Code': '46',
        'Hierarchy Name': 'Opel/Isuzu Humansdorp'
    },
    {
        'Hierarchy Code': '45',
        'Hierarchy Name': 'Opel/Isuzu Port Elizabeth'
    },
    {
        'Hierarchy Code': '33',
        'Hierarchy Name': 'Opel/Isuzu Fourways'
    },
    {
        'Hierarchy Code': '41UI',
        'Hierarchy Name': 'Opel/Isuzu Uitenhage'
    },
    {
        'Hierarchy Code': '44PEHA',
        'Hierarchy Name': 'Haval Port Elizabeth'
    },
    {
        'Hierarchy Code': '32',
        'Hierarchy Name': 'Opel/Isuzu The Glen'
    },
    {
        'Hierarchy Code': '47',
        'Hierarchy Name': 'Mahindra Durban'
    },
    {
        'Hierarchy Code': '35',
        'Hierarchy Name': 'Opel/Isuzu Midrand'
    },
    {
        'Hierarchy Code': '38KR',
        'Hierarchy Name': 'Opel/Isuzu Kroonstad'
    },
    {
        'Hierarchy Code': '48',
        'Hierarchy Name': 'Isuzu Trucks Midrand'
    },
    {
        'Hierarchy Code': '37',
        'Hierarchy Name': 'Isuzu Trucks Port Elizabeth'
    },
    {
        'Hierarchy Code': 'AUTO',
        'Hierarchy Name': 'BMW Autobahn'
    },
    {
        'Hierarchy Code': 'NCLF',
        'Hierarchy Name': 'BMW Northcliff'
    },
    {
        'Hierarchy Code': 'BETH',
        'Hierarchy Name': 'Mercedes Bethal'
    },
    {
        'Hierarchy Code': 'CRYS',
        'Hierarchy Name': 'Crysler Secunda'
    },
    {
        'Hierarchy Code': 'MICH',
        'Hierarchy Name': 'Mitsubishi Secunda'
    },
    {
        'Hierarchy Code': 'SECU',
        'Hierarchy Name': 'Mercedes Secunda'
    },
    {
        'Hierarchy Code': 'AERM',
        'Hierarchy Name': 'Audi East Rand Mall'
    },
    {
        'Hierarchy Code': 'AGLN',
        'Hierarchy Name': 'Audi The Glen'
    },
    {
        'Hierarchy Code': 'AKLE',
        'Hierarchy Name': 'Audi Klerksdorp'
    },
    {
        'Hierarchy Code': 'VBEN',
        'Hierarchy Name': 'Volkswagen Benoni City'
    },
    {
        'Hierarchy Code': 'VCOM',
        'Hierarchy Name': 'Volkswagen Commercial'
    },
    {
        'Hierarchy Code': 'VDUR',
        'Hierarchy Name': 'Volkswagen Weiss Durban'
    },
    {
        'Hierarchy Code': 'VGLN',
        'Hierarchy Name': 'Volkswagen The Glen'
    },
    {
        'Hierarchy Code': 'VGRP',
        'Hierarchy Name': 'Volkswagen Group Office'
    },
    {
        'Hierarchy Code': 'VKLE',
        'Hierarchy Name': 'Volkswagen Klerksdorp'
    },
    {
        'Hierarchy Code': 'VKRO',
        'Hierarchy Name': 'Volkswagen Kroonstad'
    },
    {
        'Hierarchy Code': 'VMCD',
        'Hierarchy Name': 'Volkswagen Mc Duling Motors'
    },
    {
        'Hierarchy Code': 'VSTR',
        'Hierarchy Name': 'Volkswagen Strijdom Park'
    },
    {
        'Hierarchy Code': 'TOT',
        'Hierarchy Name': 'Volkswagen Weiss Toti'
    },
    {
        'Hierarchy Code': 'VWCP',
        'Hierarchy Name': 'Volkswagen West Cape'
    },
    {
        'Hierarchy Code': 'BE_N',
        'Hierarchy Name': 'Nissan Benoni'
    },
    {
        'Hierarchy Code': 'BE_R',
        'Hierarchy Name': 'Renault Benoni'
    },
    {
        'Hierarchy Code': 'KE_N',
        'Hierarchy Name': 'Nissan Kempton Park'
    },
    {
        'Hierarchy Code': 'KE_R',
        'Hierarchy Name': 'Renault Kempton Park'
    },
    {
        'Hierarchy Code': 'RO_N',
        'Hierarchy Name': 'Nissan Roodepoort'
    },
    {
        'Hierarchy Code': 'RO_R',
        'Hierarchy Name': 'Renault Roodepoort'
    },
    {
        'Hierarchy Code': 'HQ_IC01',
        'Hierarchy Name': 'Nissan Group Office'
    },
    {
        'Hierarchy Code': '2101',
        'Hierarchy Name': 'O R TAMBO'
    },
    {
        'Hierarchy Code': '2121',
        'Hierarchy Name': 'CONTANTIA'
    },
    {
        'Hierarchy Code': '2301',
        'Hierarchy Name': 'BLOEM APT'
    },
    {
        'Hierarchy Code': '2202',
        'Hierarchy Name': 'PHALABORWA'
    },
    {
        'Hierarchy Code': '9020',
        'Hierarchy Name': 'FINANCE'
    },
    {
        'Hierarchy Code': '1303',
        'Hierarchy Name': 'KATHU'
    },
    {
        'Hierarchy Code': '6000',
        'Hierarchy Name': 'FINES'
    },
    {
        'Hierarchy Code': '2300',
        'Hierarchy Name': 'BLOEM D/TOWN'
    },
    {
        'Hierarchy Code': '1205',
        'Hierarchy Name': 'GEORGE'
    },
    {
        'Hierarchy Code': '1104',
        'Hierarchy Name': 'STELLENBOSCH'
    },
    {
        'Hierarchy Code': '1301',
        'Hierarchy Name': 'UPINGTON APT'
    },
    {
        'Hierarchy Code': '1207',
        'Hierarchy Name': 'MTHATHA'
    },
    {
        'Hierarchy Code': '2104',
        'Hierarchy Name': 'JHB CHAFFEUR'
    },
    {
        'Hierarchy Code': '6041',
        'Hierarchy Name': 'DURBAN CENTRAL'
    },
    {
        'Hierarchy Code': '2122',
        'Hierarchy Name': 'SUN CITY'
    },
    {
        'Hierarchy Code': '7230',
        'Hierarchy Name': 'SALES KZN'
    },
    {
        'Hierarchy Code': '9080',
        'Hierarchy Name': 'CUSTOMER RELATIONS'
    },
    {
        'Hierarchy Code': '2401',
        'Hierarchy Name': 'DURBAN AIRPORT'
    },
    {
        'Hierarchy Code': '2407',
        'Hierarchy Name': 'DURBAN CHAUFFEUR'
    },
    {
        'Hierarchy Code': '2402',
        'Hierarchy Name': 'DURBAN DEPOT'
    },
    {
        'Hierarchy Code': '30HO',
        'Hierarchy Name': 'Opel/Isuzu Group Office'
    },
    {
        'Hierarchy Code': '2106',
        'Hierarchy Name': 'SANDTON EYE'
    },
    {
        'Hierarchy Code': '34PR',
        'Hierarchy Name': 'Opel/Isuzu Pretoria'
    },
    {
        'Hierarchy Code': '2109',
        'Hierarchy Name': 'LANSERIA'
    },
    {
        'Hierarchy Code': '2107',
        'Hierarchy Name': 'JETPARK'
    },
    {
        'Hierarchy Code': '2302',
        'Hierarchy Name': 'KIMBERLEY'
    },
    {
        'Hierarchy Code': '1201',
        'Hierarchy Name': 'PE AIRPORT'
    },
    {
        'Hierarchy Code': '1203',
        'Hierarchy Name': 'EL AIRPORT'
    },
    {
        'Hierarchy Code': '9040',
        'Hierarchy Name': 'DEBTORS'
    },
    {
        'Hierarchy Code': '9090',
        'Hierarchy Name': 'SUPPORT SERVICES'
    },
    {
        'Hierarchy Code': '2201',
        'Hierarchy Name': 'NELSPRUIT'
    },
    {
        'Hierarchy Code': '1114',
        'Hierarchy Name': 'CENTURY CITY'
    },
    {
        'Hierarchy Code': '9030_RECOVERIES',
        'Hierarchy Name': 'RECOVERIES'
    },
    {
        'Hierarchy Code': '2120',
        'Hierarchy Name': 'BRYANSTON'
    },
    {
        'Hierarchy Code': '1102',
        'Hierarchy Name': 'LOOP STREET'
    },
    {
        'Hierarchy Code': '7000',
        'Hierarchy Name': 'MARKETING'
    },
    {
        'Hierarchy Code': '1101',
        'Hierarchy Name': 'CAPE TOWN AIRPORT'
    },
    {
        'Hierarchy Code': '1107',
        'Hierarchy Name': 'CAPE TOWN DEPOT'
    },
    {
        'Hierarchy Code': '9050',
        'Hierarchy Name': 'IT'
    },
    {
        'Hierarchy Code': '0331',
        'Hierarchy Name': 'WH Edenvale Value Cars'
    },
    {
        'Hierarchy Code': '8000',
        'Hierarchy Name': 'RESERVATIONS'
    },
    {
        'Hierarchy Code': '2110',
        'Hierarchy Name': 'POLOKWANE'
    },
    {
        'Hierarchy Code': '7220',
        'Hierarchy Name': 'SALES WESTERN CAPE'
    },
    {
        'Hierarchy Code': '1202',
        'Hierarchy Name': 'PE DOWNTOWN'
    },
    {
        'Hierarchy Code': '1204',
        'Hierarchy Name': 'EL DOWNTOWN'
    },
    {
        'Hierarchy Code': '300',
        'Hierarchy Name': 'WINDHOEK DOWNTOWN'
    },
    {
        'Hierarchy Code': '100',
        'Hierarchy Name': 'HOESEA KUTAKO AIRPOT'
    },
    {
        'Hierarchy Code': '400',
        'Hierarchy Name': 'NAMIBIA SALES'
    },
    {
        'Hierarchy Code': '200',
        'Hierarchy Name': 'SWAKOPMUND'
    },
    {
        'Hierarchy Code': 'BMW',
        'Hierarchy Name': 'BMW'
    },
    {
        'Hierarchy Code': 'HOAD',
        'Hierarchy Name': 'HOAD'
    },
    {
        'Hierarchy Code': 'GM',
        'Hierarchy Name': 'General Motors'
    },
    {
        'Hierarchy Code': 'UINS',
        'Hierarchy Name': 'CFAO Insurance'
    },
    {
        'Hierarchy Code': 'TOYO',
        'Hierarchy Name': 'Toyota'
    },
    {
        'Hierarchy Code': 'VOLK',
        'Hierarchy Name': 'Volkswagen'
    },
    {
        'Hierarchy Code': 'NISS',
        'Hierarchy Name': 'Nissan'
    },
    {
        'Hierarchy Code': 'URS',
        'Hierarchy Name': 'CFAO Retail'
    },
    {
        'Hierarchy Code': 'HERT',
        'Hierarchy Name': 'Hertz'
    },
    {
        'Hierarchy Code': 'SPOTTERS',
        'Hierarchy Name': 'Spotters'
    },
    {
        'Hierarchy Code': '2103',
        'Hierarchy Name': 'PRETORIA'
    },
    {
        'Hierarchy Code': '2124',
        'Hierarchy Name': 'MIDRAND'
    },
    {
        'Hierarchy Code': '9000',
        'Hierarchy Name': 'EXECUJET'
    },
    {
        'Hierarchy Code': 'MB_MARKETING',
        'Hierarchy Name': 'Group Admin Marketing'
    },
    {
        'Hierarchy Code': 'R_CT',
        'Hierarchy Name': 'Reeds Cape Town'
    },
    {
        'Hierarchy Code': '2102',
        'Hierarchy Name': 'PARK STATION'
    },
    {
        'Hierarchy Code': '2412',
        'Hierarchy Name': 'AMANZIMTOTI'
    },
    {
        'Hierarchy Code': 'RUS',
        'Hierarchy Name': 'Man Rustenburg'
    },
    {
        'Hierarchy Code': 'RE_TYGER',
        'Hierarchy Name': 'Reeds Tygervalley'
    },
    {
        'Hierarchy Code': 'RE_CLAREMONT',
        'Hierarchy Name': 'Reeds Claremont'
    },
    {
        'Hierarchy Code': 'RE_N1_CITY',
        'Hierarchy Name': 'Reeds N1 City'
    },
    {
        'Hierarchy Code': 'ITC',
        'Hierarchy Name': 'Isuzu Truck Centre ITC'
    },
    {
        'Hierarchy Code': 'RE_PDI',
        'Hierarchy Name': 'Reeds Pdi'
    },
    {
        'Hierarchy Code': 'RE_AD',
        'Hierarchy Name': 'Reeds Admin'
    },
    {
        'Hierarchy Code': 'RE_CARENT',
        'Hierarchy Name': 'Reeds Car Rental'
    },
    {
        'Hierarchy Code': '2117',
        'Hierarchy Name': 'EAST RAND'
    },
    {
        'Hierarchy Code': '2409',
        'Hierarchy Name': 'UMHLANGA_HERTZ'
    },
    {
        'Hierarchy Code': 'AUTU_AD92',
        'Hierarchy Name': 'Autoteam Wholesale'
    },
    {
        'Hierarchy Code': '1115',
        'Hierarchy Name': 'C/T HILTON HOTEL CHAUFFEUR'
    },
    {
        'Hierarchy Code': '2126',
        'Hierarchy Name': 'KLERKSDORP'
    },
    {
        'Hierarchy Code': '2127',
        'Hierarchy Name': 'MENLYN'
    },
    {
        'Hierarchy Code': '2125',
        'Hierarchy Name': 'RANDFONTEIN'
    },
    {
        'Hierarchy Code': '2128',
        'Hierarchy Name': 'BAKKIE & VAN RENTAL'
    },
    {
        'Hierarchy Code': 'MB_IT',
        'Hierarchy Name': 'Group IT'
    },
    {
        'Hierarchy Code': '2404',
        'Hierarchy Name': 'PIETERMARITZBURG'
    },
    {
        'Hierarchy Code': '2405',
        'Hierarchy Name': 'RICHARDSBAY '
    },
    {
        'Hierarchy Code': '4302',
        'Hierarchy Name': 'SANDTON HILTON CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'IC_MTHA',
        'Hierarchy Name': 'Nissan Mthatha'
    },
    {
        'Hierarchy Code': 'AM_HONDA',
        'Hierarchy Name': 'Automall Honda'
    },
    {
        'Hierarchy Code': 'AM_KIA',
        'Hierarchy Name': 'Automall Kia'
    },
    {
        'Hierarchy Code': 'AM_NIS',
        'Hierarchy Name': 'Automall Nissan'
    },
    {
        'Hierarchy Code': 'AM_REN',
        'Hierarchy Name': 'Automall Renault'
    },
    {
        'Hierarchy Code': 'AM_PRE_OWNED',
        'Hierarchy Name': 'Automall Pre Owned'
    },
    {
        'Hierarchy Code': '4101',
        'Hierarchy Name': 'JETPARK_24_ASSIST'
    },
    {
        'Hierarchy Code': '1111',
        'Hierarchy Name': 'HERMANUS'
    },
    {
        'Hierarchy Code': 'BLEX',
        'Hierarchy Name': 'Lexus Bloemfontein'
    },
    {
        'Hierarchy Code': 'AM_AD',
        'Hierarchy Name': 'Automall Group Office'
    },
    {
        'Hierarchy Code': '0001',
        'Hierarchy Name': 'ZIM HEAD OFFICE'
    },
    {
        'Hierarchy Code': '1001',
        'Hierarchy Name': 'ZIM HARARE'
    },
    {
        'Hierarchy Code': '1021',
        'Hierarchy Name': 'ZIM VIC FALLS'
    },
    {
        'Hierarchy Code': 'N0001',
        'Hierarchy Name': 'NAM HEAD OFFICE'
    },
    {
        'Hierarchy Code': 'N1001',
        'Hierarchy Name': 'NAM WINDHOEK APT'
    },
    {
        'Hierarchy Code': 'N1021',
        'Hierarchy Name': 'NAM WALVIS BAY'
    },
    {
        'Hierarchy Code': 'N1081',
        'Hierarchy Name': 'NAM WINDHOEK D/T'
    },
    {
        'Hierarchy Code': 'N1101',
        'Hierarchy Name': 'NAM SALES'
    },
    {
        'Hierarchy Code': 'N1201',
        'Hierarchy Name': 'NAM ODANGWA'
    },
    {
        'Hierarchy Code': '101',
        'Hierarchy Name': 'HERTZ BOTSWANA GABORONE APT'
    },
    {
        'Hierarchy Code': '102',
        'Hierarchy Name': 'HERTZ BOTSWANA GABARONE D/T'
    },
    {
        'Hierarchy Code': '103',
        'Hierarchy Name': 'HERTZ BOTSWANA MAUN'
    },
    {
        'Hierarchy Code': '104',
        'Hierarchy Name': 'HERTZ BOTSWANA FRANCIS TOWN'
    },
    {
        'Hierarchy Code': '105',
        'Hierarchy Name': 'HERTZ BOTSWANA SALES'
    },
    {
        'Hierarchy Code': '106',
        'Hierarchy Name': 'HERTZ BOTSWANA H/O'
    },
    {
        'Hierarchy Code': '107',
        'Hierarchy Name': 'HERTZ BOTSWANA KASANE'
    },
    {
        'Hierarchy Code': '3121',
        'Hierarchy Name': 'BLOEMFONTEIN AUTO TEAM'
    },
    {
        'Hierarchy Code': 'UFIN',
        'Hierarchy Name': 'CFAO Auto Finance'
    },
    {
        'Hierarchy Code': '5181',
        'Hierarchy Name': 'HERTZ CENTURION'
    },
    {
        'Hierarchy Code': '4004',
        'Hierarchy Name': 'HERTZ GAUTRAIN',
        Column3: 'RHODESFIELD'
    },
    {
        'Hierarchy Code': '4003',
        'Hierarchy Name': 'HERTZ GAUTRAIN',
        Column3: 'PARK STATION'
    },
    {
        'Hierarchy Code': '4002',
        'Hierarchy Name': 'HERTZ GAUTRAIN',
        Column3: 'SANDTON'
    },
    {
        'Hierarchy Code': '5091',
        'Hierarchy Name': 'HERTZ GAUTRAIN',
        Column3: 'MIDRAND'
    },
    {
        'Hierarchy Code': '4261',
        'Hierarchy Name': 'HERTZ GAUTRAIN',
        Column3: 'HATFIELD'
    },
    {
        'Hierarchy Code': 'AM_MTHA',
        'Hierarchy Name': 'Automall Mthatha'
    },
    {
        'Hierarchy Code': 'VBAL',
        'Hierarchy Name': 'Volkswagen Ballito'
    },
    {
        'Hierarchy Code': 'MES',
        'Hierarchy Name': 'Toyota Messina'
    },
    {
        'Hierarchy Code': 'FEL',
        'Hierarchy Name': 'Ford Mokopane'
    },
    {
        'Hierarchy Code': 'VMOK',
        'Hierarchy Name': 'Volkswagen Mokopane'
    },
    {
        'Hierarchy Code': 'H1221',
        'Hierarchy Name': 'NAM HILTON'
    },
    {
        'Hierarchy Code': 'H1222',
        'Hierarchy Name': 'NAM CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'KHIN',
        'Hierarchy Name': 'Hino Klerksdorp'
    },
    {
        'Hierarchy Code': '0071',
        'Hierarchy Name': 'HERTZ FLEET PANELSHOP'
    },
    {
        'Hierarchy Code': 'FMO',
        'Hierarchy Name': 'Ford Modimolle'
    },
    {
        'Hierarchy Code': 'FBE',
        'Hierarchy Name': 'Ford Bela',
        Column3: 'Bela'
    },
    {
        'Hierarchy Code': 'RE_CRM',
        'Hierarchy Name': 'Reeds Customer Care'
    },
    {
        'Hierarchy Code': 'LTFBA',
        'Hierarchy Name': 'Ford Louis Trichardt'
    },
    {
        'Hierarchy Code': 'LTM',
        'Hierarchy Name': 'Mazda Louis Trichardt'
    },
    {
        'Hierarchy Code': 'LTK',
        'Hierarchy Name': 'Kia Louis Trichardt'
    },
    {
        'Hierarchy Code': 'AUTO_PS',
        'Hierarchy Name': 'BMW Autobahn Bodyshop'
    },
    {
        'Hierarchy Code': '29_OPEL_DIST',
        'Hierarchy Name': 'Opel Distributors'
    },
    {
        'Hierarchy Code': '39',
        'Hierarchy Name': 'Opel/Isuzu Centurion'
    },
    {
        'Hierarchy Code': '50',
        'Hierarchy Name': 'Opel/Isuzu Roodepoort'
    },
    {
        'Hierarchy Code': 'CLEAR',
        'Hierarchy Name': 'BMW Clearwater'
    },
    {
        'Hierarchy Code': 'FMAL',
        'Hierarchy Name': 'Ford Malmesbury'
    },
    {
        'Hierarchy Code': 'FMOOR',
        'Hierarchy Name': 'Ford Moorreesburg'
    },
    {
        'Hierarchy Code': 'AUTN',
        'Hierarchy Name': 'Nissan Autoteam Northcliff'
    },
    {
        'Hierarchy Code': 'GWM',
        'Hierarchy Name': 'Automall GWM'
    },
    {
        'Hierarchy Code': 'RE_BEL',
        'Hierarchy Name': 'Reeds Bellville'
    },
    {
        'Hierarchy Code': '43',
        'Hierarchy Name': 'Opel/Isuzu Klerksdorp'
    },
    {
        'Hierarchy Code': '1181',
        'Hierarchy Name': 'WINDHOEK DEPOT'
    },
    {
        'Hierarchy Code': '0101',
        'Hierarchy Name': 'HERTZ RECOVERIES'
    },
    {
        'Hierarchy Code': 'H_0001',
        'Hierarchy Name': 'HERTZ HEAD OFFICE'
    },
    {
        'Hierarchy Code': 'AF_ROODEPOORT',
        'Hierarchy Name': 'Ford Roodepoort'
    },
    {
        'Hierarchy Code': 'AF_KRUGERSDORP',
        'Hierarchy Name': 'Ford Krugersdorp'
    },
    {
        'Hierarchy Code': 'AF_CITRUSDAL',
        'Hierarchy Name': 'Ford Citrusdal'
    },
    {
        'Hierarchy Code': 'AF_LICHTENBURG',
        'Hierarchy Name': 'Ford Lichtenburg'
    },
    {
        'Hierarchy Code': 'AF_ZEERUST',
        'Hierarchy Name': 'Ford Zeerust'
    },
    {
        'Hierarchy Code': 'AF_AGRI',
        'Hierarchy Name': 'Ford Agri'
    },
    {
        'Hierarchy Code': 'AF_HEAD_OFF',
        'Hierarchy Name': 'Ford Head Office'
    },
    {
        'Hierarchy Code': 'MB_BOKS_DEBTORS',
        'Hierarchy Name': 'Group Debtors'
    },
    {
        'Hierarchy Code': 'MB_PARTS',
        'Hierarchy Name': 'Group Parts Department'
    },
    {
        'Hierarchy Code': 'MB_FLEET',
        'Hierarchy Name': 'CFAO Fleet Department'
    },
    {
        'Hierarchy Code': 'MB_ROODEPRT_INT',
        'Hierarchy Name': 'Group Admin Westrand Interns'
    },
    {
        'Hierarchy Code': 'MB_BOKSBURG_INT',
        'Hierarchy Name': 'Group Admin Eastrand Interns'
    },
    {
        'Hierarchy Code': 'MB_IT_INT',
        'Hierarchy Name': 'Group IT Interns'
    },
    {
        'Hierarchy Code': 'MB_MARKET_INT',
        'Hierarchy Name': 'Group Marketing Interns'
    },
    {
        'Hierarchy Code': 'MB_FLEET_HERTZ',
        'Hierarchy Name': 'CFAO Fleet Department At Hertz'
    },
    {
        'Hierarchy Code': 'MB_AUDIT',
        'Hierarchy Name': 'Group Admin Internal Audit'
    },
    {
        'Hierarchy Code': '4361',
        'Hierarchy Name': 'KRUGERSDORP'
    },
    {
        'Hierarchy Code': '9001',
        'Hierarchy Name': 'TRAINING'
    },
    {
        'Hierarchy Code': 'INSPECTACAR',
        'Hierarchy Name': 'InspectaCar'
    },
    {
        'Hierarchy Code': '1181WC',
        'Hierarchy Name': 'Hertz Paarl WC'
    },
    {
        'Hierarchy Code': 'EXEC',
        'Hierarchy Name': 'EXEC'
    },
    {
        'Hierarchy Code': 'FOUR',
        'Hierarchy Name': 'BMW Fourways'
    },
    {
        'Hierarchy Code': 'MELR_DW',
        'Hierarchy Name': 'Toyota Melrose Driveway'
    },
    {
        'Hierarchy Code': '8000_',
        'Hierarchy Name': 'H_Replacement & Car Track'
    },
    {
        'Hierarchy Code': '0001_',
        'Hierarchy Name': 'Legal'
    },
    {
        'Hierarchy Code': 'SUZUKI',
        'Hierarchy Name': 'Suzuki'
    },
    {
        'Hierarchy Code': '5221',
        'Hierarchy Name': 'Hertz Potchefstroom'
    },
    {
        'Hierarchy Code': 'BFN_AUT',
        'Hierarchy Name': 'Bloemfontein Autoteam'
    },
    {
        'Hierarchy Code': '5241',
        'Hierarchy Name': 'Mahikeng Car Rental'
    },
    {
        'Hierarchy Code': '336',
        'Hierarchy Name': 'Isuzu Truck Centre Pretoria'
    },
    {
        'Hierarchy Code': 'RENAULT_MTHA',
        'Hierarchy Name': 'Renault Mthatha'
    },
    {
        'Hierarchy Code': 'HAVAL_MTHA',
        'Hierarchy Name': 'Haval Mthatha'
    },
    {
        'Hierarchy Code': 'SUZUKI_MTHA',
        'Hierarchy Name': 'Suzuki Mthatha'
    },
    {
        'Hierarchy Code': 'CHERY_KRU',
        'Hierarchy Name': 'Ford Chery Krugersdorp'
    },
    {
        'Hierarchy Code': 'AUTU_JET92',
        'Hierarchy Name': 'Autoteam Jetpark'
    },
    {
        'Hierarchy Code': '0340',
        'Hierarchy Name': 'WH Fourways Haval'
    },
    {
        'Hierarchy Code': '0341',
        'Hierarchy Name': 'WH Fourways Isuzu'
    },
    {
        'Hierarchy Code': '0358',
        'Hierarchy Name': 'Reeds N1 City Used'
    },
    {
        'Hierarchy Code': '0359',
        'Hierarchy Name': 'Haval N1 City'
    },
    {
        'Hierarchy Code': 'LEARNERS',
        'Hierarchy Name': 'Learners'
    },
    {
        'Hierarchy Code': 'GMP_TTSAP',
        'Hierarchy Name': 'General Manager'
    },
    {
        'Hierarchy Code': 'SUZUKI_KROON',
        'Hierarchy Name': 'Suzuki Kroonstad'
    },
    {
        'Hierarchy Code': 'AM_HONDABIKE',
        'Hierarchy Name': 'Automall Honda Bike'
    },
    {
        'Hierarchy Code': 'NIS_TOK',
        'Hierarchy Name': 'Nissan Tokai'
    },
    {
        'Hierarchy Code': 'CALL_CENTRE',
        'Hierarchy Name': 'Group Call Centre'
    },
    {
        'Hierarchy Code': 'H_UOFS',
        'Hierarchy Name': 'HERTZ UNIVERSITY OF FREE STATE'
    },
    {
        'Hierarchy Code': 'HAVAL_LT',
        'Hierarchy Name': 'Haval Louis Trichardt'
    },
    {
        'Hierarchy Code': 'SUZUKI_LT',
        'Hierarchy Name': 'Suzuki Louis Trichardt'
    },
    {
        'Hierarchy Code': 'BMWNB',
        'Hierarchy Name': 'BMW Nelson Mandela Bay'
    },
    {
        'Hierarchy Code': 'VMOD',
        'Hierarchy Name': 'Volkswagen Modimolle'
    },
    {
        'Hierarchy Code': 'CHERY_LICHTENBU',
        'Hierarchy Name': 'Chery Lichtenburg'
    },
    {
        'Hierarchy Code': 'SUZUKI_LICHTENB',
        'Hierarchy Name': 'Suzuki Lichtenburg'
    },
    {
        'Hierarchy Code': 'SINO_L',
        'Hierarchy Name': 'Lichtenburg Sinotruk'
    },
    {
        'Hierarchy Code': 'SINO_T',
        'Hierarchy Name': 'Tulisa Park Sinotruk'
    },
    {
        'Hierarchy Code': 'BMW_SPEEDP',
        'Hierarchy Name': 'BMW Speed Point'
    },
    {
        'Hierarchy Code': 'MB_HR',
        'Hierarchy Name': 'Group HR'
    },
    {
        'Hierarchy Code': 'MB_VSB_RECON',
        'Hierarchy Name': 'VSB Recon'
    },
    {
        'Hierarchy Code': 'MB_VSB_SUPPORT',
        'Hierarchy Name': 'VSB Support'
    },
    {
        'Hierarchy Code': 'MB_EFT',
        'Hierarchy Name': 'EFT'
    },
    {
        'Hierarchy Code': 'MB_GL',
        'Hierarchy Name': 'Group General Ledger'
    },
    {
        'Hierarchy Code': 'MB_CENTRAL',
        'Hierarchy Name': 'Central Office'
    },
    {
        'Hierarchy Code': 'MB_FM',
        'Hierarchy Name': 'Group FM'
    },
    {
        'Hierarchy Code': 'MB_SERVICE',
        'Hierarchy Name': 'Group Service'
    },
    {
        'Hierarchy Code': 'MB_PROPERTY',
        'Hierarchy Name': 'Group Property'
    },
    {
        'Hierarchy Code': 'MB_PAYROLL',
        'Hierarchy Name': 'Group Payroll'
    },
    {
        'Hierarchy Code': 'AF_SPRINGBOK',
        'Hierarchy Name': 'Ford Springbok'
    }
];
export const Department = [
    {
        'Hierarchy Code': 'NV_A',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'SD_A',
        'Hierarchy Name': 'SERVICE DEPARTMENT'
    },
    {
        'Hierarchy Code': 'UV_A',
        'Hierarchy Name': 'USED VEHICLES'
    },
    {
        'Hierarchy Code': 'AD_90',
        'Hierarchy Name': 'ADMINISTRATION'
    },
    {
        'Hierarchy Code': 'AD_MARKETING_92',
        'Hierarchy Name': 'ADMIN MARKETING 92'
    },
    {
        'Hierarchy Code': 'NV_F&I_2',
        'Hierarchy Name': 'NEW F&I'
    },
    {
        'Hierarchy Code': 'UV_F&I_2',
        'Hierarchy Name': 'USED F&I'
    },
    {
        'Hierarchy Code': 'AD_CALL91',
        'Hierarchy Name': 'CALL CENTRE'
    },
    {
        'Hierarchy Code': 'DW',
        'Hierarchy Name': 'DRIVEWAY'
    },
    {
        'Hierarchy Code': 'FITM',
        'Hierarchy Name': 'FITMENT CENTRE'
    },
    {
        'Hierarchy Code': 'NV_MINI',
        'Hierarchy Name': 'MINI NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'NV_TRUCK',
        'Hierarchy Name': 'NEW TRUCKS'
    },
    {
        'Hierarchy Code': 'NF&I',
        'Hierarchy Name': 'NEW F&I'
    },
    {
        'Hierarchy Code': 'NV',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'NV_2',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'PA',
        'Hierarchy Name': 'PARTS'
    },
    {
        'Hierarchy Code': 'PDI',
        'Hierarchy Name': 'PDI'
    },
    {
        'Hierarchy Code': 'PS',
        'Hierarchy Name': 'PANELSHOP'
    },
    {
        'Hierarchy Code': 'PA_WH',
        'Hierarchy Name': 'PARTS WAREHOUSE'
    },
    {
        'Hierarchy Code': 'SD',
        'Hierarchy Name': 'SERVICE DEPARTMENT'
    },
    {
        'Hierarchy Code': 'SD_TRUCK',
        'Hierarchy Name': 'SERVICE DEPARTMENT'
    },
    {
        'Hierarchy Code': 'UV_F&I',
        'Hierarchy Name': 'USED F&I'
    },
    {
        'Hierarchy Code': 'UV_UNIC',
        'Hierarchy Name': 'UNITED'
    },
    {
        'Hierarchy Code': 'UV',
        'Hierarchy Name': 'USED VEHICLES'
    },
    {
        'Hierarchy Code': 'UV_2',
        'Hierarchy Name': 'USED VEHICLES'
    },
    {
        'Hierarchy Code': 'H_CT_APT',
        'Hierarchy Name': 'CAPE TOWN APT'
    },
    {
        'Hierarchy Code': 'H_LOOP',
        'Hierarchy Name': 'LOOP STREET'
    },
    {
        'Hierarchy Code': 'H_STELB',
        'Hierarchy Name': 'STELLENBOSCH'
    },
    {
        'Hierarchy Code': 'H_CT_CHAF',
        'Hierarchy Name': 'CAPE TOWN CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'H_CT_DEP',
        'Hierarchy Name': 'CAPE TOWN DEPOT'
    },
    {
        'Hierarchy Code': 'H_CENTC',
        'Hierarchy Name': 'CENTURY CITY'
    },
    {
        'Hierarchy Code': 'H_PE_APT',
        'Hierarchy Name': 'PE AIRPORT'
    },
    {
        'Hierarchy Code': 'H_PE_DT',
        'Hierarchy Name': 'PE DOWNTOWN'
    },
    {
        'Hierarchy Code': 'H_EL_APT',
        'Hierarchy Name': 'EL AIRPORT'
    },
    {
        'Hierarchy Code': 'H_EL_DT',
        'Hierarchy Name': 'EL DOWNTOWN'
    },
    {
        'Hierarchy Code': 'H_GEORG',
        'Hierarchy Name': 'GEORGE'
    },
    {
        'Hierarchy Code': 'H_MTHA',
        'Hierarchy Name': 'MTHATHA'
    },
    {
        'Hierarchy Code': 'H_UPT_APT',
        'Hierarchy Name': 'UPINGTON APT'
    },
    {
        'Hierarchy Code': 'H_ORT',
        'Hierarchy Name': 'O R TAMBO'
    },
    {
        'Hierarchy Code': 'H_PTA',
        'Hierarchy Name': 'PRETORIA'
    },
    {
        'Hierarchy Code': 'H_JHB_CHAF',
        'Hierarchy Name': 'JHB CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'H_SAND_EYE',
        'Hierarchy Name': 'SANDTON EYE'
    },
    {
        'Hierarchy Code': 'H_JET_DEP',
        'Hierarchy Name': 'JETPARK DEPOT'
    },
    {
        'Hierarchy Code': 'H_LANS',
        'Hierarchy Name': 'LANSERIA'
    },
    {
        'Hierarchy Code': 'H_POL',
        'Hierarchy Name': 'POLOKWANE'
    },
    {
        'Hierarchy Code': 'H_BRYAN',
        'Hierarchy Name': 'BRYANSTON'
    },
    {
        'Hierarchy Code': 'H_CONS',
        'Hierarchy Name': 'CONSTANTIA'
    },
    {
        'Hierarchy Code': 'H_SUNC',
        'Hierarchy Name': 'SUN CITY'
    },
    {
        'Hierarchy Code': 'H_NELS',
        'Hierarchy Name': 'NELSPRUIT'
    },
    {
        'Hierarchy Code': 'H_BFN_DT',
        'Hierarchy Name': 'BLOEM D/TOWN'
    },
    {
        'Hierarchy Code': 'H_BFN_APT',
        'Hierarchy Name': 'BLOEM APT'
    },
    {
        'Hierarchy Code': 'H_KIMB',
        'Hierarchy Name': 'KIMBERLEY'
    },
    {
        'Hierarchy Code': 'H_DURB_APT',
        'Hierarchy Name': 'DURBAN AIRPORT'
    },
    {
        'Hierarchy Code': 'H_DURB_DT',
        'Hierarchy Name': 'DURBAN DEPOT'
    },
    {
        'Hierarchy Code': 'H_DURB_CENTR',
        'Hierarchy Name': 'DURBAN CENTRAL'
    },
    {
        'Hierarchy Code': 'H_DURB_CHAF',
        'Hierarchy Name': 'DURBAN CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'H_TRAIN',
        'Hierarchy Name': 'TRAINING'
    },
    {
        'Hierarchy Code': 'H_INB_SAL',
        'Hierarchy Name': 'INBOUND SALES'
    },
    {
        'Hierarchy Code': 'H_SAL',
        'Hierarchy Name': 'SALES'
    },
    {
        'Hierarchy Code': 'H_SAL_GAUT',
        'Hierarchy Name': 'SALES GAUTENG'
    },
    {
        'Hierarchy Code': 'H_SAL_WC',
        'Hierarchy Name': 'SALES WESTERN CAPE'
    },
    {
        'Hierarchy Code': 'H_SAL_KZN',
        'Hierarchy Name': 'SALES KZN'
    },
    {
        'Hierarchy Code': 'H_SAL_EC',
        'Hierarchy Name': 'SALES EASTERN CAPE'
    },
    {
        'Hierarchy Code': 'H_SAL_CENT',
        'Hierarchy Name': 'SALES CENTRAL'
    },
    {
        'Hierarchy Code': 'H_RESERV',
        'Hierarchy Name': 'RESERVATIONS'
    },
    {
        'Hierarchy Code': 'H_HO',
        'Hierarchy Name': 'HEAD OFFICE'
    },
    {
        'Hierarchy Code': 'H_FLEET',
        'Hierarchy Name': 'FLEET'
    },
    {
        'Hierarchy Code': 'H_FIN',
        'Hierarchy Name': 'FINANCE'
    },
    {
        'Hierarchy Code': 'H_RECOV',
        'Hierarchy Name': 'RECOVERIES'
    },
    {
        'Hierarchy Code': 'H_DEBT',
        'Hierarchy Name': 'DEBTORS'
    },
    {
        'Hierarchy Code': 'H_IT',
        'Hierarchy Name': 'IT'
    },
    {
        'Hierarchy Code': 'H_GOLD',
        'Hierarchy Name': 'GOLD'
    },
    {
        'Hierarchy Code': 'H_RATE',
        'Hierarchy Name': 'RATES'
    },
    {
        'Hierarchy Code': 'H_CUST_REL',
        'Hierarchy Name': 'CUSTOMER RELATIONS'
    },
    {
        'Hierarchy Code': 'UV_VAL_CAR_RE',
        'Hierarchy Name': 'VALUE CARS REEDS'
    },
    {
        'Hierarchy Code': 'AD_CUST_RE',
        'Hierarchy Name': 'CUSTOMER CARE REEDS'
    },
    {
        'Hierarchy Code': 'H_UMHL',
        'Hierarchy Name': 'UMHLANGA'
    },
    {
        'Hierarchy Code': 'SD_MARBLE_HALL',
        'Hierarchy Name': 'MARBLE HALL 63'
    },
    {
        'Hierarchy Code': 'SD_HOEDSPRUIT',
        'Hierarchy Name': 'HOEDSPRUIT 64'
    },
    {
        'Hierarchy Code': 'H_CT_HELT',
        'Hierarchy Name': 'C/T HILTON HOTEL CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'UV_VALUE_CARS',
        'Hierarchy Name': 'VALUE CARS'
    },
    {
        'Hierarchy Code': 'H_PIET',
        'Hierarchy Name': 'PIETERMARITZBURG'
    },
    {
        'Hierarchy Code': 'H_RICH',
        'Hierarchy Name': 'RICHARDSBAY '
    },
    {
        'Hierarchy Code': 'H_SAND_HIL',
        'Hierarchy Name': 'SANDTON HILTON CHAUFFEUR'
    },
    {
        'Hierarchy Code': 'UV_AUT21',
        'Hierarchy Name': 'USED VEHICLES'
    },
    {
        'Hierarchy Code': 'NV_DATSUN',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'H_JETP_24ASSIST',
        'Hierarchy Name': 'HERTZ JETPARK 24 ASSIST'
    },
    {
        'Hierarchy Code': 'H_HERM',
        'Hierarchy Name': 'HERMANUS'
    },
    {
        'Hierarchy Code': 'HN0001',
        'Hierarchy Name': 'H_NAM HEAD OFFICE'
    },
    {
        'Hierarchy Code': 'HN1001',
        'Hierarchy Name': 'H_NAM WINDHOEK APT'
    },
    {
        'Hierarchy Code': 'HN1021',
        'Hierarchy Name': 'H_NAM WALVIS BAY'
    },
    {
        'Hierarchy Code': 'HN1081',
        'Hierarchy Name': 'H_NAM WINDHOEK D/T'
    },
    {
        'Hierarchy Code': 'HN1101',
        'Hierarchy Name': 'H_NAM SALES'
    },
    {
        'Hierarchy Code': 'HN1201',
        'Hierarchy Name': 'H_NAM ODANGWA'
    },
    {
        'Hierarchy Code': 'HB_GAB_APT',
        'Hierarchy Name': 'HERTZ BOTSWANA GABORONE APT'
    },
    {
        'Hierarchy Code': 'HB_GAB_D/T',
        'Hierarchy Name': 'HERTZ BOTSWANA GABORONE D/T'
    },
    {
        'Hierarchy Code': 'HB_MAUN',
        'Hierarchy Name': 'HERTZ BOTSWANA MAUN'
    },
    {
        'Hierarchy Code': 'HB_FRANCIS',
        'Hierarchy Name': 'HERTZ BOTSWANA FRANCIS TOWN'
    },
    {
        'Hierarchy Code': 'HB_SALES',
        'Hierarchy Name': 'HERTZ BOTSWANA SALES'
    },
    {
        'Hierarchy Code': 'HB_HO',
        'Hierarchy Name': 'HERTZ BOTSWANA H\\O'
    },
    {
        'Hierarchy Code': 'HB_KASANE',
        'Hierarchy Name': 'HERTZ BOTSWANA KASANE'
    },
    {
        'Hierarchy Code': 'H_BFN_AUTO',
        'Hierarchy Name': 'BLOEMFONTEIN AUTO TEAM'
    },
    {
        'Hierarchy Code': 'HN1221',
        'Hierarchy Name': 'H_NAM_HILTON'
    },
    {
        'Hierarchy Code': 'H_FLEET_PANELS',
        'Hierarchy Name': 'HERTZ FLEET PANELSHOP'
    },
    {
        'Hierarchy Code': 'H_WINDH_DEPOT',
        'Hierarchy Name': 'H_WINDHOEK_DEPOT'
    },
    {
        'Hierarchy Code': 'H_OPERATIONS',
        'Hierarchy Name': 'OPERATIONS'
    },
    {
        'Hierarchy Code': 'H_HR',
        'Hierarchy Name': 'HUMAN RESOURCES'
    },
    {
        'Hierarchy Code': 'H_LEGAL',
        'Hierarchy Name': 'LEGAL'
    },
    {
        'Hierarchy Code': 'H_CLAIMS',
        'Hierarchy Name': 'CLAIMS'
    },
    {
        'Hierarchy Code': 'NV_MOTORRAD_SAL',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'SD_MOTORRAD',
        'Hierarchy Name': 'SERVICE DEPARTMENT'
    },
    {
        'Hierarchy Code': 'AD_ARC',
        'Hierarchy Name': 'ADMIN ARC'
    },
    {
        'Hierarchy Code': 'SD_KLERK',
        'Hierarchy Name': 'SERVICE DEPARTMENT'
    },
    {
        'Hierarchy Code': 'AD_RE_CARENT',
        'Hierarchy Name': 'REEDS CAR RENTAL ADMIN'
    },
    {
        'Hierarchy Code': 'HERT_00',
        'Hierarchy Name': 'HERTZ 00'
    },
    {
        'Hierarchy Code': 'AD_AUDIT',
        'Hierarchy Name': 'ADMIN AUDIT'
    },
    {
        'Hierarchy Code': 'AD_BOKS',
        'Hierarchy Name': 'ADMIN BOKSBURG'
    },
    {
        'Hierarchy Code': 'NV_MOTORR_NSAL',
        'Hierarchy Name': 'NEW VEHICLE MOTORRAD NON SALES'
    },
    {
        'Hierarchy Code': '4361',
        'Hierarchy Name': 'KRUGERSDORP'
    },
    {
        'Hierarchy Code': '5181',
        'Hierarchy Name': 'HERTZ CENTURION'
    },
    {
        'Hierarchy Code': 'NV_HAVAL',
        'Hierarchy Name': 'NEW VEHICLES'
    },
    {
        'Hierarchy Code': 'NF&I_HAVAL',
        'Hierarchy Name': 'NEW F&I'
    },
    {
        'Hierarchy Code': '1181WC',
        'Hierarchy Name': 'Hertz Paarl WC'
    },
    {
        'Hierarchy Code': 'NF&I_TRUCKS',
        'Hierarchy Name': 'NEW F&I'
    },
    {
        'Hierarchy Code': 'H_REPL&CARTRACK',
        'Hierarchy Name': 'Hertz Replacement & Car Track'
    },
    {
        'Hierarchy Code': '5221',
        'Hierarchy Name': 'Hertz Potchefstroom'
    },
    {
        'Hierarchy Code': 'H_MAHIKENG',
        'Hierarchy Name': 'Mahikeng Car Rental'
    },
    {
        'Hierarchy Code': 'CH_2300',
        'Hierarchy Name': 'Chemicals'
    },
    {
        'Hierarchy Code': 'CH_2030',
        'Hierarchy Name': 'Clearing & Forwarding'
    },
    {
        'Hierarchy Code': 'CH_2052',
        'Hierarchy Name': 'Compliance'
    },
    {
        'Hierarchy Code': 'CH_2014',
        'Hierarchy Name': 'Corporate'
    },
    {
        'Hierarchy Code': 'CH_2019',
        'Hierarchy Name': 'Corporate'
    },
    {
        'Hierarchy Code': 'CH_2009',
        'Hierarchy Name': 'Corporate Other'
    },
    {
        'Hierarchy Code': 'CH_2681',
        'Hierarchy Name': 'Equipment and Services'
    },
    {
        'Hierarchy Code': 'CH_2206',
        'Hierarchy Name': 'Facilities'
    },
    {
        'Hierarchy Code': 'CH_2000',
        'Hierarchy Name': 'Finance'
    },
    {
        'Hierarchy Code': 'CH_2200',
        'Hierarchy Name': 'Global Auto Parts'
    },
    {
        'Hierarchy Code': 'CH_2310',
        'Hierarchy Name': 'Global AutoParts'
    },
    {
        'Hierarchy Code': 'CH_2150',
        'Hierarchy Name': 'Green Metals'
    },
    {
        'Hierarchy Code': 'CH_2061',
        'Hierarchy Name': 'Human Resources'
    },
    {
        'Hierarchy Code': 'CH_2010',
        'Hierarchy Name': 'IT Services'
    },
    {
        'Hierarchy Code': 'CH_2090',
        'Hierarchy Name': 'Legal Department'
    },
    {
        'Hierarchy Code': 'CH_2210',
        'Hierarchy Name': 'Machinery'
    },
    {
        'Hierarchy Code': 'CH_2215',
        'Hierarchy Name': 'Machinery'
    },
    {
        'Hierarchy Code': 'CH_2690',
        'Hierarchy Name': 'Multi',
        Column3: 'brand'
    },
    {
        'Hierarchy Code': 'CH_2072',
        'Hierarchy Name': 'New Business Development'
    },
    {
        'Hierarchy Code': 'CH_2700',
        'Hierarchy Name': 'PARTS'
    },
    {
        'Hierarchy Code': 'CH_2020',
        'Hierarchy Name': 'Production Support Centre'
    },
    {
        'Hierarchy Code': 'CH_2053',
        'Hierarchy Name': 'Safety'
    },
    {
        'Hierarchy Code': 'CH_2600',
        'Hierarchy Name': 'Sales & Marketing'
    },
    {
        'Hierarchy Code': 'CH_2710',
        'Hierarchy Name': 'Service'
    },
    {
        'Hierarchy Code': 'CH_2100',
        'Hierarchy Name': 'Steel'
    },
    {
        'Hierarchy Code': 'CH_2110',
        'Hierarchy Name': 'Steel'
    },
    {
        'Hierarchy Code': 'CH_2130',
        'Hierarchy Name': 'Steel'
    },
    {
        'Hierarchy Code': 'CH_2041',
        'Hierarchy Name': 'Strategic Integration'
    },
    {
        'Hierarchy Code': 'CH_2055',
        'Hierarchy Name': 'Toyota Forklift'
    },
    {
        'Hierarchy Code': 'CH_2510',
        'Hierarchy Name': 'Transport'
    },
    {
        'Hierarchy Code': 'CH_2500',
        'Hierarchy Name': 'Vehicle Logistics Centre'
    },
    {
        'Hierarchy Code': 'CH_2230',
        'Hierarchy Name': 'Wheel & Tyre'
    },
    {
        'Hierarchy Code': 'CH_4000',
        'Hierarchy Name': 'TTSAP'
    },
    {
        'Hierarchy Code': 'CH_3000',
        'Hierarchy Name': 'TGSA'
    },
    {
        'Hierarchy Code': 'H_UOFS',
        'Hierarchy Name': 'HERTZ UNIVERSITY OF FREE STATE'
    }
];
export const Division = [
    {
        'Hierarchy Code': '002',
        'Hierarchy Name': 'UTR Toyota SGO'
    },
    {
        'Hierarchy Code': '001',
        'Hierarchy Name': 'UTR Toyota N'
    },
    {
        'Hierarchy Code': '003',
        'Hierarchy Name': 'UTR Toyota S'
    },
    {
        'Hierarchy Code': '004',
        'Hierarchy Name': 'UTR Man UNTD T'
    },
    {
        'Hierarchy Code': '005',
        'Hierarchy Name': 'UTR Toyota NGO'
    },
    {
        'Hierarchy Code': '010',
        'Hierarchy Name': 'Autobahn BMW'
    },
    {
        'Hierarchy Code': '014',
        'Hierarchy Name': 'Northcliff Auto'
    },
    {
        'Hierarchy Code': '006',
        'Hierarchy Name': 'Williams Hunt'
    },
    {
        'Hierarchy Code': '015',
        'Hierarchy Name': 'Unitrans Auto'
    },
    {
        'Hierarchy Code': '016',
        'Hierarchy Name': 'United Motors'
    },
    {
        'Hierarchy Code': '018',
        'Hierarchy Name': 'Unitrans VW'
    },
    {
        'Hierarchy Code': '023',
        'Hierarchy Name': 'IC Auto'
    },
    {
        'Hierarchy Code': '080',
        'Hierarchy Name': 'Hertz Salary'
    },
    {
        'Hierarchy Code': '083_REEDS',
        'Hierarchy Name': 'Reeds Motors'
    },
    {
        'Hierarchy Code': '084_REEDS',
        'Hierarchy Name': 'Reeds Motors'
    },
    {
        'Hierarchy Code': '085_REEDS',
        'Hierarchy Name': 'ITC'
    },
    {
        'Hierarchy Code': '007',
        'Hierarchy Name': 'Auto Mall'
    },
    {
        'Hierarchy Code': '024',
        'Hierarchy Name': 'Ford'
    },
    {
        'Hierarchy Code': 'CLEAR',
        'Hierarchy Name': 'Clearwater'
    },
    {
        'Hierarchy Code': 'CENTRAL_CAREL',
        'Hierarchy Name': 'Central Carel'
    },
    {
        'Hierarchy Code': 'INSPECTACAR',
        'Hierarchy Name': 'InspectaCar'
    },
    {
        'Hierarchy Code': 'EXEC',
        'Hierarchy Name': 'EXEC'
    },
    {
        'Hierarchy Code': 'CENTRAL_GIDEON',
        'Hierarchy Name': 'Central Gideon'
    },
    {
        'Hierarchy Code': 'FOUR',
        'Hierarchy Name': 'BMW Fourways'
    },
    {
        'Hierarchy Code': 'CENTRAL_HANRU',
        'Hierarchy Name': 'Central Hanru'
    },
    {
        'Hierarchy Code': 'CENTRAL_QUINTON',
        'Hierarchy Name': 'Central Quinton'
    },
    {
        'Hierarchy Code': 'CENTRAL_RIAAN',
        'Hierarchy Name': 'Central Riaan'
    },
    {
        'Hierarchy Code': '017',
        'Hierarchy Name': 'Action Ford'
    },
    {
        'Hierarchy Code': 'CENTRAL_LIZELLE',
        'Hierarchy Name': 'Central Lizelle'
    },
    {
        'Hierarchy Code': 'CENTRAL_LYNN',
        'Hierarchy Name': 'Centrall Lynn'
    },
    {
        'Hierarchy Code': 'CENTRAL_CAL_CEN',
        'Hierarchy Name': 'Central Call Centre_Marketing'
    },
    {
        'Hierarchy Code': 'CENTRAL_PARTS',
        'Hierarchy Name': 'Central Parts'
    },
    {
        'Hierarchy Code': 'L_INTERS',
        'Hierarchy Name': 'Interns'
    },
    {
        'Hierarchy Code': 'CH_CORP',
        'Hierarchy Name': 'Corporate '
    },
    {
        'Hierarchy Code': 'CH_AUTO',
        'Hierarchy Name': 'Automotive'
    },
    {
        'Hierarchy Code': 'CH_GTL',
        'Hierarchy Name': 'Global Trade & Logistics'
    },
    {
        'Hierarchy Code': 'CH_MET',
        'Hierarchy Name': 'Metal'
    },
    {
        'Hierarchy Code': 'CH_MACH',
        'Hierarchy Name': 'Machinery & Chemicals'
    },
    {
        'Hierarchy Code': 'CH_ALD',
        'Hierarchy Name': 'Automotive Logistics'
    },
    {
        'Hierarchy Code': 'CH_NBD',
        'Hierarchy Name': 'New Business Development'
    },
    {
        'Hierarchy Code': 'CH_W&T',
        'Hierarchy Name': 'Wheel & Tyre'
    },
    {
        'Hierarchy Code': 'HOC',
        'Hierarchy Name': 'House of Cleaning'
    },
    {
        'Hierarchy Code': 'L_LEARNERSHIPS',
        'Hierarchy Name': 'Learnerships'
    },
    {
        'Hierarchy Code': 'CENTRAL_TIAAN',
        'Hierarchy Name': 'Central Tiaan'
    },
    {
        'Hierarchy Code': 'L_APY_SUBS',
        'Hierarchy Name': 'Appy Subsidized by HQ '
    },
    {
        'Hierarchy Code': 'L_SALE_AN_SUPP',
        'Hierarchy Name': 'Sales & Support Learnership'
    },
    {
        'Hierarchy Code': 'L_YES_PROG_22IN',
        'Hierarchy Name': 'Yes Program 22 Intake'
    },
    {
        'Hierarchy Code': 'L_DISABLED_EMPL',
        'Hierarchy Name': 'Disabled Employees'
    },
    {
        'Hierarchy Code': 'BMWNB',
        'Hierarchy Name': 'BMW Nelson Mandela Bay'
    },
    {
        'Hierarchy Code': 'CENTRAL_HR',
        'Hierarchy Name': 'Central HR'
    },
    {
        'Hierarchy Code': 'L_YES_PROG_23IN',
        'Hierarchy Name': 'Yes Program 23 Intake'
    },
    {
        'Hierarchy Code': 'CH_TTSAP',
        'Hierarchy Name': 'TTSAP'
    }
];
export const ESS_Levels = [
    {
        'Hierarchy Code': 'ESS_LEVEL1',
        'Hierarchy Name': 'ESS - 1 Level of Escalations'
    },
    {
        'Hierarchy Code': 'ESS_LEVEL2',
        'Hierarchy Name': 'ESS - 2 Levels of Escalations'
    },
    {
        'Hierarchy Code': 'NA',
        'Hierarchy Name': 'Not Applicable'
    }
];
export const PaymentRunDefs = [
    {
        'Payment Run Definition Id': 1,
        Code: 'ACB',
        'Short Description': 'ACB',
        'Long Description': 'ACB',
        Company: 'CFAO_MOBILITY'
    },
    {
        'Payment Run Definition Id': 2,
        Code: 'CASH',
        'Short Description': 'CASH',
        'Long Description': 'CASH',
        Company: 'CFAO_MOBILITY'
    },
    {
        'Payment Run Definition Id': 3,
        Code: 'ACB',
        'Short Description': 'ACB',
        'Long Description': 'ACB',
        Company: 'ITC '
    },
    {
        'Payment Run Definition Id': 4,
        Code: 'INACTIVE',
        'Short Description': 'INACTIVE',
        'Long Description': 'INACTIVE',
        Company: 'CFAO_MOBILITY'
    },
    {
        'Payment Run Definition Id': 5,
        Code: 'CASH',
        'Short Description': 'ZIM',
        'Long Description': 'ZIM',
        Company: 'HERTZ_ZIM '
    },
    {
        'Payment Run Definition Id': 6,
        Code: 'ACB',
        'Short Description': 'Unitrans Namibia',
        'Long Description': 'Unitrans Namibia',
        Company: 'HERTZ_NAM_SERV '
    },
    {
        'Payment Run Definition Id': 7,
        Code: 'ACB',
        'Short Description': 'Hertz Namibia ONE',
        'Long Description': 'Hertz Namibia ONE',
        Company: 'HERTZ_NAM_RENT '
    },
    {
        'Payment Run Definition Id': 8,
        Code: 'ACB',
        'Short Description': 'ACB Botswana',
        'Long Description': 'ACB Botswana',
        Company: 'HERTZ_BOT '
    },
    {
        'Payment Run Definition Id': 9,
        Code: 'CASH',
        'Short Description': 'CASH',
        'Long Description': 'CASH',
        Company: 'HERTZ_BOT '
    },
    {
        'Payment Run Definition Id': 10,
        Code: 'CASH',
        'Short Description': 'CASH',
        'Long Description': 'CASH',
        Company: 'ITC '
    },
    {
        'Payment Run Definition Id': 11,
        Code: 'CASH',
        'Short Description': 'Unitrans Namibia Services Hertz',
        'Long Description': 'Unitrans Namibia Services Hertz',
        Company: 'HERTZ_NAM_SERV '
    },
    {
        'Payment Run Definition Id': 12,
        Code: 'CASH',
        'Short Description': 'Hertz Namibia One Cash',
        'Long Description': 'Hertz Namibia One Cash',
        Company: 'HERTZ_NAM_RENT '
    },
    {
        'Payment Run Definition Id': 13,
        Code: 'CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        Company: 'SEC '
    },
    {
        'Payment Run Definition Id': 14,
        Code: 'TTSAP_ACB',
        'Short Description': 'TTSAP ACB',
        'Long Description': 'TTSAP ACB',
        Company: 'TTSAP '
    },
    {
        'Payment Run Definition Id': 15,
        Code: 'TTSAP_CASH',
        'Short Description': 'TTSAP Cash',
        'Long Description': 'TTSAP Cash',
        Company: 'TTSAP '
    },
    {
        'Payment Run Definition Id': 16,
        Code: 'TTAF_ACB',
        'Short Description': 'TTAF ACB',
        'Long Description': 'TTAF ACB',
        Company: 'TTAF '
    },
    {
        'Payment Run Definition Id': 17,
        Code: 'AMS_ACB',
        'Short Description': 'AMS ACB',
        'Long Description': 'AMS ACB',
        Company: 'AMS '
    },
    {
        'Payment Run Definition Id': 18,
        Code: 'CFAO_H_ACB',
        'Short Description': 'CFAO Holdigs ACB',
        'Long Description': 'CFAO Holdigs ACB',
        Company: 'CFAO_H '
    },
    {
        'Payment Run Definition Id': 19,
        Code: 'TGSA_ACB',
        'Short Description': 'TGSA ACB',
        'Long Description': 'TGSA ACB',
        Company: 'TGSA '
    },
    {
        'Payment Run Definition Id': 20,
        Code: 'ADVICS_ACB',
        'Short Description': 'ADVICS ACB',
        'Long Description': 'ADVICS ACB',
        Company: 'ADVICS '
    },
    {
        'Payment Run Definition Id': 21,
        Code: 'TTSAP_3RD_PAR',
        'Short Description': 'TTSAP 3rd Party Payments',
        'Long Description': 'TTSAP 3rd Party Payments',
        Company: 'TTSAP '
    }
];
export const RemunerationEarnDefs = [
    {
        'Earning Definition Id': 726,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 727,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 795,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 811,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 469,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 476,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 504,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 533,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 557,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 564,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 592,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 621,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 2,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 37,
        Code: 'CASH_COM',
        'Short Description': 'Cash Component',
        'Long Description': 'Cash Component',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 273,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY_NON_PENS',
        'Short Description': 'Salary Non Pensionable',
        'Long Description': 'Salary Non Pensionable',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 221,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 282,
        'Earning Group': 'SALARY - Salary',
        Code: 'COVID-19_SUBS',
        'Short Description': 'COVID-19 Subsidy',
        'Long Description': 'COVID-19 Subsidy',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 211,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 213,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 262,
        Code: 'LUMPSUM',
        'Short Description': 'Lumpsum',
        'Long Description': 'Lumpsum',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 198,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 209,
        'Earning Group': 'SALARY - Salary',
        Code: 'BACKPAY',
        'Short Description': 'Backpay',
        'Long Description': 'Backpay',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 95,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 96,
        Code: 'CASH_COM',
        'Short Description': 'Cash Component',
        'Long Description': 'Cash Component',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 143,
        Code: 'ARB_AWARD/DRC',
        'Short Description': 'Arbitration Award / DRC',
        'Long Description': 'Arbitration Award / DRC',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 254,
        'Earning Group': 'SALARY - Salary',
        Code: 'SALARY',
        'Short Description': 'Salary',
        'Long Description': 'Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 255,
        Code: 'SEC',
        'Short Description': 'Sec',
        'Long Description': 'Sec',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 645,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 652,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 680,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 709,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 381,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 388,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 416,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 445,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 286,
        Code: 'H_SALARY',
        'Short Description': 'Basic Salary',
        'Long Description': 'Basic Salary',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 287,
        Code: 'H_CASH',
        'Short Description': 'Cash',
        'Long Description': 'Cash',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 355,
        Code: 'H_DOSA_REVERSAL',
        'Short Description': 'Dosa Reversal',
        'Long Description': 'Dosa Reversal',
        'Tax Type': 'NRM - Normal'
    },
    {
        'Earning Definition Id': 371,
        Code: 'H_NET_UP',
        'Short Description': 'Net Up',
        'Long Description': 'Net Up',
        'Tax Type': 'NRM - Normal'
    }
];

export const countriesTypeOptions = [
    { name: 'Afghanistan', code: 'AF', alpha3Code: 'AFG' },
    { name: 'Albania', code: 'AL', alpha3Code: 'ALB' },
    { name: 'Algeria', code: 'DZ', alpha3Code: 'DZA' },
    { name: 'American Samoa', code: 'AS', alpha3Code: 'ASM' },
    { name: 'Andorra', code: 'AD', alpha3Code: 'AND' },
    { name: 'Angola', code: 'AO', alpha3Code: 'AGO' },
    { name: 'Anguilla', code: 'AI', alpha3Code: 'AIA' },
    { name: 'Antarctica', code: 'AQ', alpha3Code: 'ATA' },
    { name: 'Antigua and Barbuda', code: 'AG', alpha3Code: 'ATG' },
    { name: 'Argentina', code: 'AR', alpha3Code: 'ARG' },
    { name: 'Armenia', code: 'AM', alpha3Code: 'ARM' },
    { name: 'Aruba', code: 'AW', alpha3Code: 'ABW' },
    { name: 'Australia', code: 'AU', alpha3Code: 'AUS' },
    { name: 'Austria', code: 'AT', alpha3Code: 'AUT' },
    { name: 'Azerbaijan', code: 'AZ', alpha3Code: 'AZE' },
    { name: 'Bahamas (the)', code: 'BS', alpha3Code: 'BHS' },
    { name: 'Bahrain', code: 'BH', alpha3Code: 'BHR' },
    { name: 'Bangladesh', code: 'BD', alpha3Code: 'BGD' },
    { name: 'Barbados', code: 'BB', alpha3Code: 'BRB' },
    { name: 'Belarus', code: 'BY', alpha3Code: 'BLR' },
    { name: 'Belgium', code: 'BE', alpha3Code: 'BEL' },
    { name: 'Belize', code: 'BZ', alpha3Code: 'BLZ' },
    { name: 'Benin', code: 'BJ', alpha3Code: 'BEN' },
    { name: 'Bermuda', code: 'BM', alpha3Code: 'BMU' },
    { name: 'Bhutan', code: 'BT', alpha3Code: 'BTN' },
    { name: 'Bolivia (Plurinational State of)', code: 'BO', alpha3Code: 'BOL' },
    { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', alpha3Code: 'BES' },
    { name: 'Bosnia and Herzegovina', code: 'BA', alpha3Code: 'BIH' },
    { name: 'Botswana', code: 'BW', alpha3Code: 'BWA' },
    { name: 'Bouvet Island', code: 'BV', alpha3Code: 'BVT' },
    { name: 'Brazil', code: 'BR', alpha3Code: 'BRA' },
    { name: 'British Indian Ocean Territory (the)', code: 'IO', alpha3Code: 'IOT' },
    { name: 'Brunei Darussalam', code: 'BN', alpha3Code: 'BRN' },
    { name: 'Bulgaria', code: 'BG', alpha3Code: 'BGR' },
    { name: 'Burkina Faso', code: 'BF', alpha3Code: 'BFA' },
    { name: 'Burundi', code: 'BI', alpha3Code: 'BDI' },
    { name: 'Cabo Verde', code: 'CV', alpha3Code: 'CPV' },
    { name: 'Cambodia', code: 'KH', alpha3Code: 'KHM' },
    { name: 'Cameroon', code: 'CM', alpha3Code: 'CMR' },
    { name: 'Canada', code: 'CA', alpha3Code: 'CAN' },
    { name: 'Cayman Islands (the)', code: 'KY', alpha3Code: 'CYM' },
    { name: 'Central African Republic (the)', code: 'CF', alpha3Code: 'CAF' },
    { name: 'Chad', code: 'TD', alpha3Code: 'TCD' },
    { name: 'Chile', code: 'CL', alpha3Code: 'CHL' },
    { name: 'China', code: 'CN', alpha3Code: 'CHN' },
    { name: 'Christmas Island', code: 'CX', alpha3Code: 'CXR' },
    { name: 'Cocos (Keeling) Islands (the)', code: 'CC', alpha3Code: 'CCK' },
    { name: 'Colombia', code: 'CO', alpha3Code: 'COL' },
    { name: 'Comoros (the)', code: 'KM', alpha3Code: 'COM' },
    { name: 'Congo (the Democratic Republic of the)', code: 'CD', alpha3Code: 'COD' },
    { name: 'Congo (the)', code: 'CG', alpha3Code: 'COG' },
    { name: 'Cook Islands (the)', code: 'CK', alpha3Code: 'COK' },
    { name: 'Costa Rica', code: 'CR', alpha3Code: 'CRI' },
    { name: 'Croatia', code: 'HR', alpha3Code: 'HRV' },
    { name: 'Cuba', code: 'CU', alpha3Code: 'CUB' },
    { name: 'Curaçao', code: 'CW', alpha3Code: 'CUW' },
    { name: 'Cyprus', code: 'CY', alpha3Code: 'CYP' },
    { name: 'Czechia', code: 'CZ', alpha3Code: 'CZE' },
    { name: "Côte d'Ivoire", code: 'CI', alpha3Code: 'CIV' },
    { name: 'Denmark', code: 'DK', alpha3Code: 'DNK' },
    { name: 'Djibouti', code: 'DJ', alpha3Code: 'DJI' },
    { name: 'Dominica', code: 'DM', alpha3Code: 'DMA' },
    { name: 'Dominican Republic (the)', code: 'DO', alpha3Code: 'DOM' },
    { name: 'Ecuador', code: 'EC', alpha3Code: 'ECU' },
    { name: 'Egypt', code: 'EG', alpha3Code: 'EGY' },
    { name: 'El Salvador', code: 'SV', alpha3Code: 'SLV' },
    { name: 'Equatorial Guinea', code: 'GQ', alpha3Code: 'GNQ' },
    { name: 'Eritrea', code: 'ER', alpha3Code: 'ERI' },
    { name: 'Estonia', code: 'EE', alpha3Code: 'EST' },
    { name: 'Eswatini', code: 'SZ', alpha3Code: 'SWZ' },
    { name: 'Ethiopia', code: 'ET', alpha3Code: 'ETH' },
    { name: 'Falkland Islands (the) [Malvinas]', code: 'FK', alpha3Code: 'FLK' },
    { name: 'Faroe Islands (the)', code: 'FO', alpha3Code: 'FRO' },
    { name: 'Fiji', code: 'FJ', alpha3Code: 'FJI' },
    { name: 'Finland', code: 'FI', alpha3Code: 'FIN' },
    { name: 'France', code: 'FR', alpha3Code: 'FRA' },
    { name: 'French Guiana', code: 'GF', alpha3Code: 'GUF' },
    { name: 'French Polynesia', code: 'PF', alpha3Code: 'PYF' },
    { name: 'French Southern Territories (the)', code: 'TF', alpha3Code: 'ATF' },
    { name: 'Gabon', code: 'GA', alpha3Code: 'GAB' },
    { name: 'Gambia (the)', code: 'GM', alpha3Code: 'GMB' },
    { name: 'Georgia', code: 'GE', alpha3Code: 'GEO' },
    { name: 'Germany', code: 'DE', alpha3Code: 'DEU' },
    { name: 'Ghana', code: 'GH', alpha3Code: 'GHA' },
    { name: 'Gibraltar', code: 'GI', alpha3Code: 'GIB' },
    { name: 'Greece', code: 'GR', alpha3Code: 'GRC' },
    { name: 'Greenland', code: 'GL', alpha3Code: 'GRL' },
    { name: 'Grenada', code: 'GD', alpha3Code: 'GRD' },
    { name: 'Guadeloupe', code: 'GP', alpha3Code: 'GLP' },
    { name: 'Guam', code: 'GU', alpha3Code: 'GUM' },
    { name: 'Guatemala', code: 'GT', alpha3Code: 'GTM' },
    { name: 'Guernsey', code: 'GG', alpha3Code: 'GGY' },
    { name: 'Guinea', code: 'GN', alpha3Code: 'GIN' },
    { name: 'Guinea-Bissau', code: 'GW', alpha3Code: 'GNB' },
    { name: 'Guyana', code: 'GY', alpha3Code: 'GUY' },
    { name: 'Haiti', code: 'HT', alpha3Code: 'HTI' },
    { name: 'Heard Island and McDonald Islands', code: 'HM', alpha3Code: 'HMD' },
    { name: 'Holy See (the)', code: 'VA', alpha3Code: 'VAT' },
    { name: 'Honduras', code: 'HN', alpha3Code: 'HND' },
    { name: 'Hong Kong', code: 'HK', alpha3Code: 'HKG' },
    { name: 'Hungary', code: 'HU', alpha3Code: 'HUN' },
    { name: 'Iceland', code: 'IS', alpha3Code: 'ISL' },
    { name: 'India', code: 'IN', alpha3Code: 'IND' },
    { name: 'Indonesia', code: 'ID', alpha3Code: 'IDN' },
    { name: 'Iran (Islamic Republic of)', code: 'IR', alpha3Code: 'IRN' },
    { name: 'Iraq', code: 'IQ', alpha3Code: 'IRQ' },
    { name: 'Ireland', code: 'IE', alpha3Code: 'IRL' },
    { name: 'Isle of Man', code: 'IM', alpha3Code: 'IMN' },
    { name: 'Israel', code: 'IL', alpha3Code: 'ISR' },
    { name: 'Italy', code: 'IT', alpha3Code: 'ITA' },
    { name: 'Jamaica', code: 'JM', alpha3Code: 'JAM' },
    { name: 'Japan', code: 'JP', alpha3Code: 'JPN' },
    { name: 'Jersey', code: 'JE', alpha3Code: 'JEY' },
    { name: 'Jordan', code: 'JO', alpha3Code: 'JOR' },
    { name: 'Kazakhstan', code: 'KZ', alpha3Code: 'KAZ' },
    { name: 'Kenya', code: 'KE', alpha3Code: 'KEN' },
    { name: 'Kiribati', code: 'KI', alpha3Code: 'KIR' },
    { name: "Korea (the Democratic People's Republic of)", code: 'KP', alpha3Code: 'PRK' },
    { name: 'Korea (the Republic of)', code: 'KR', alpha3Code: 'KOR' },
    { name: 'Kuwait', code: 'KW', alpha3Code: 'KWT' },
    { name: 'Kyrgyzstan', code: 'KG', alpha3Code: 'KGZ' },
    { name: "Lao People's Democratic Republic (the)", code: 'LA', alpha3Code: 'LAO' },
    { name: 'Latvia', code: 'LV', alpha3Code: 'LVA' },
    { name: 'Lebanon', code: 'LB', alpha3Code: 'LBN' },
    { name: 'Lesotho', code: 'LS', alpha3Code: 'LSO' },
    { name: 'Liberia', code: 'LR', alpha3Code: 'LBR' },
    { name: 'Libya', code: 'LY', alpha3Code: 'LBY' },
    { name: 'Liechtenstein', code: 'LI', alpha3Code: 'LIE' },
    { name: 'Lithuania', code: 'LT', alpha3Code: 'LTU' },
    { name: 'Luxembourg', code: 'LU', alpha3Code: 'LUX' },
    { name: 'Macao', code: 'MO', alpha3Code: 'MAC' },
    { name: 'Madagascar', code: 'MG', alpha3Code: 'MDG' },
    { name: 'Malawi', code: 'MW', alpha3Code: 'MWI' },
    { name: 'Malaysia', code: 'MY', alpha3Code: 'MYS' },
    { name: 'Maldives', code: 'MV', alpha3Code: 'MDV' },
    { name: 'Mali', code: 'ML', alpha3Code: 'MLI' },
    { name: 'Malta', code: 'MT', alpha3Code: 'MLT' },
    { name: 'Marshall Islands (the)', code: 'MH', alpha3Code: 'MHL' },
    { name: 'Martinique', code: 'MQ', alpha3Code: 'MTQ' },
    { name: 'Mauritania', code: 'MR', alpha3Code: 'MRT' },
    { name: 'Mauritius', code: 'MU', alpha3Code: 'MUS' },
    { name: 'Mayotte', code: 'YT', alpha3Code: 'MYT' },
    { name: 'Mexico', code: 'MX', alpha3Code: 'MEX' },
    { name: 'Micronesia (Federated States of)', code: 'FM', alpha3Code: 'FSM' },
    { name: 'Moldova (the Republic of)', code: 'MD', alpha3Code: 'MDA' },
    { name: 'Monaco', code: 'MC', alpha3Code: 'MCO' },
    { name: 'Mongolia', code: 'MN', alpha3Code: 'MNG' },
    { name: 'Montenegro', code: 'ME', alpha3Code: 'MNE' },
    { name: 'Montserrat', code: 'MS', alpha3Code: 'MSR' },
    { name: 'Morocco', code: 'MA', alpha3Code: 'MAR' },
    { name: 'Mozambique', code: 'MZ', alpha3Code: 'MOZ' },
    { name: 'Myanmar', code: 'MM', alpha3Code: 'MMR' },
    { name: 'Namibia', code: 'NA', alpha3Code: 'NAM' },
    { name: 'Nauru', code: 'NR', alpha3Code: 'NRU' },
    { name: 'Nepal', code: 'NP', alpha3Code: 'NPL' },
    { name: 'Netherlands (the)', code: 'NL', alpha3Code: 'NLD' },
    { name: 'New Caledonia', code: 'NC', alpha3Code: 'NCL' },
    { name: 'New Zealand', code: 'NZ', alpha3Code: 'NZL' },
    { name: 'Nicaragua', code: 'NI', alpha3Code: 'NIC' },
    { name: 'Niger (the)', code: 'NE', alpha3Code: 'NER' },
    { name: 'Nigeria', code: 'NG', alpha3Code: 'NGA' },
    { name: 'Niue', code: 'NU', alpha3Code: 'NIU' },
    { name: 'Norfolk Island', code: 'NF', alpha3Code: 'NFK' },
    { name: 'North Macedonia', code: 'MK', alpha3Code: 'MKD' },
    { name: 'Northern Mariana Islands (the)', code: 'MP', alpha3Code: 'MNP' },
    { name: 'Norway', code: 'NO', alpha3Code: 'NOR' },
    { name: 'Oman', code: 'OM', alpha3Code: 'OMN' },
    { name: 'Pakistan', code: 'PK', alpha3Code: 'PAK' },
    { name: 'Palau', code: 'PW', alpha3Code: 'PLW' },
    { name: 'Palestine, State of', code: 'PS', alpha3Code: 'PSE' },
    { name: 'Panama', code: 'PA', alpha3Code: 'PAN' },
    { name: 'Papua New Guinea', code: 'PG', alpha3Code: 'PNG' },
    { name: 'Paraguay', code: 'PY', alpha3Code: 'PRY' },
    { name: 'Peru', code: 'PE', alpha3Code: 'PER' },
    { name: 'Philippines (the)', code: 'PH', alpha3Code: 'PHL' },
    { name: 'Pitcairn', code: 'PN', alpha3Code: 'PCN' },
    { name: 'Poland', code: 'PL', alpha3Code: 'POL' },
    { name: 'Portugal', code: 'PT', alpha3Code: 'PRT' },
    { name: 'Puerto Rico', code: 'PR', alpha3Code: 'PRI' },
    { name: 'Qatar', code: 'QA', alpha3Code: 'QAT' },
    { name: 'Romania', code: 'RO', alpha3Code: 'ROU' },
    { name: 'Russian Federation (the)', code: 'RU', alpha3Code: 'RUS' },
    { name: 'Rwanda', code: 'RW', alpha3Code: 'RWA' },
    { name: 'Réunion', code: 'RE', alpha3Code: 'REU' },
    { name: 'Saint Barthélemy', code: 'BL', alpha3Code: 'BLM' },
    { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH', alpha3Code: 'SHN' },
    { name: 'Saint Kitts and Nevis', code: 'KN', alpha3Code: 'KNA' },
    { name: 'Saint Lucia', code: 'LC', alpha3Code: 'LCA' },
    { name: 'Saint Martin (French part)', code: 'MF', alpha3Code: 'MAF' },
    { name: 'Saint Pierre and Miquelon', code: 'PM', alpha3Code: 'SPM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', alpha3Code: 'VCT' },
    { name: 'Samoa', code: 'WS', alpha3Code: 'WSM' },
    { name: 'San Marino', code: 'SM', alpha3Code: 'SMR' },
    { name: 'Sao Tome and Principe', code: 'ST', alpha3Code: 'STP' },
    { name: 'Saudi Arabia', code: 'SA', alpha3Code: 'SAU' },
    { name: 'Senegal', code: 'SN', alpha3Code: 'SEN' },
    { name: 'Serbia', code: 'RS', alpha3Code: 'SRB' },
    { name: 'Seychelles', code: 'SC', alpha3Code: 'SYC' },
    { name: 'Sierra Leone', code: 'SL', alpha3Code: 'SLE' },
    { name: 'Singapore', code: 'SG', alpha3Code: 'SGP' },
    { name: 'Sint Maarten (Dutch part)', code: 'SX', alpha3Code: 'SXM' },
    { name: 'Slovakia', code: 'SK', alpha3Code: 'SVK' },
    { name: 'Slovenia', code: 'SI', alpha3Code: 'SVN' },
    { name: 'Solomon Islands', code: 'SB', alpha3Code: 'SLB' },
    { name: 'Somalia', code: 'SO', alpha3Code: 'SOM' },
    { name: 'South Africa', code: 'ZA', alpha3Code: 'ZAF' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS', alpha3Code: 'SGS' },
    { name: 'South Sudan', code: 'SS', alpha3Code: 'SSD' },
    { name: 'Spain', code: 'ES', alpha3Code: 'ESP' },
    { name: 'Sri Lanka', code: 'LK', alpha3Code: 'LKA' },
    { name: 'Sudan (the)', code: 'SD', alpha3Code: 'SDN' },
    { name: 'Suriname', code: 'SR', alpha3Code: 'SUR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', alpha3Code: 'SJM' },
    { name: 'Sweden', code: 'SE', alpha3Code: 'SWE' },
    { name: 'Switzerland', code: 'CH', alpha3Code: 'CHE' },
    { name: 'Syrian Arab Republic (the)', code: 'SY', alpha3Code: 'SYR' },
    { name: 'Taiwan (Province of China)', code: 'TW', alpha3Code: 'TWN' },
    { name: 'Tajikistan', code: 'TJ', alpha3Code: 'TJK' },
    { name: 'Tanzania, the United Republic of', code: 'TZ', alpha3Code: 'TZA' },
    { name: 'Thailand', code: 'TH', alpha3Code: 'THA' },
    { name: 'Timor-Leste', code: 'TL', alpha3Code: 'TLS' },
    { name: 'Togo', code: 'TG', alpha3Code: 'TGO' },
    { name: 'Tokelau', code: 'TK', alpha3Code: 'TKL' },
    { name: 'Tonga', code: 'TO', alpha3Code: 'TON' },
    { name: 'Trinidad and Tobago', code: 'TT', alpha3Code: 'TTO' },
    { name: 'Tunisia', code: 'TN', alpha3Code: 'TUN' },
    { name: 'Turkey', code: 'TR', alpha3Code: 'TUR' },
    { name: 'Turkmenistan', code: 'TM', alpha3Code: 'TKM' },
    { name: 'Turks and Caicos Islands (the)', code: 'TC', alpha3Code: 'TCA' },
    { name: 'Tuvalu', code: 'TV', alpha3Code: 'TUV' },
    { name: 'Uganda', code: 'UG', alpha3Code: 'UGA' },
    { name: 'Ukraine', code: 'UA', alpha3Code: 'UKR' },
    { name: 'United Arab Emirates (the)', code: 'AE', alpha3Code: 'ARE' },
    { name: 'United Kingdom of Great Britain and Northern Ireland (the)', code: 'GB', alpha3Code: 'GBR' },
    { name: 'United States Minor Outlying Islands (the)', code: 'UM', alpha3Code: 'UMI' },
    { name: 'United States of America (the)', code: 'US', alpha3Code: 'USA' },
    { name: 'Uruguay', code: 'UY', alpha3Code: 'URY' },
    { name: 'Uzbekistan', code: 'UZ', alpha3Code: 'UZB' },
    { name: 'Vanuatu', code: 'VU', alpha3Code: 'VUT' },
    { name: 'Venezuela (Bolivarian Republic of)', code: 'VE', alpha3Code: 'VEN' },
    { name: 'Viet Nam', code: 'VN', alpha3Code: 'VNM' },
    { name: 'Virgin Islands (British)', code: 'VG', alpha3Code: 'VGB' },
    { name: 'Virgin Islands (U.S.)', code: 'VI', alpha3Code: 'VIR' },
    { name: 'Wallis and Futuna', code: 'WF', alpha3Code: 'WLF' },
    { name: 'Western Sahara*', code: 'EH', alpha3Code: 'ESH' },
    { name: 'Yemen', code: 'YE', alpha3Code: 'YEM' },
    { name: 'Zambia', code: 'ZM', alpha3Code: 'ZMB' },
    { name: 'Zimbabwe', code: 'ZW', alpha3Code: 'ZWE' },
    { name: 'Åland Islands', code: 'AX', alpha3Code: 'ALA' }
];
