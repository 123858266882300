<ng-container>
    <div class="light-overlay" *ngIf="contentLoading"></div>
    <div class="candidate-rating">
        <div
            class="star-block"
            [ngClass]="{ active: currentRating && currentRating === i + 1 }"
            *ngFor="let block of initialStars; let i = index"
            (click)="onSaveRating(i + 1)"
        >
            <span class="star-block__title">
                {{ i + 1 + ' Star' + (i !== 0 ? 's' : '') }}
            </span>
            <div class="star-block__amount">
                <img src="/assets/images/icons/rating-star.svg" alt="" *ngFor="let key of i + 1 | countIterable" />
            </div>
        </div>
    </div>
</ng-container>
