<ng-container *ngFor="let questionnaire of questionnaires">
    <div class="content-block" *ngFor="let item of questionnaire.questions">
        <ng-container *ngIf="item.type === 'questions'">
            <p-accordion>
                <p-accordionTab
                    [disabled]="
                        !item?.completed ||
                        (item.expired && !item?.completed) ||
                        ((user.role === 'recruitment_agency' || user.role === 'agency_user') &&
                            item?.questions?.sensitive)
                    "
                >
                    <p-header>
                        <ng-container *ngIf="item?.completed">
                            <div class="status-icon">
                                <img
                                    *ngIf="!item?.questionSuccess"
                                    width="15"
                                    src="/assets/images/icons-8-cancel.svg"
                                    alt="Icon Cancel"
                                />
                                <img
                                    *ngIf="item?.questionSuccess"
                                    width="15"
                                    src="/assets/images/icons-8-ok@2x.png"
                                    alt="Icon OK"
                                />
                            </div>
                            <div class="text-block">
                                <div class="title">
                                    <div class="position">{{ item?.questions?.title }}</div>
                                    <span class="sensitive" *ngIf="item?.questions?.sensitive">Sensitive</span>
                                </div>
                                <span class="date"> Complete: {{ dateFormat(item?.updated_at) }} </span>
                            </div>

                            <!--                            <div-->
                            <!--                                    class="question-status"-->
                            <!--                                    *ngIf="item.hasKnockoutQuestion"-->
                            <!--                                    [ngClass]="{ fail: !item?.questionSuccess }"-->
                            <!--                            >-->
                            <!--                                <h1>{{ item?.questionSuccess ? 'pass' : 'fail' }}</h1>-->
                            <!--                                &lt;!&ndash; <p>{{ item?.correctQuestions }} of {{ item?.questions?.questions }} questions</p> &ndash;&gt;-->
                            <!--                            </div>-->
                        </ng-container>
                        <ng-container *ngIf="!item?.completed">
                            <div class="status-icon" *ngIf="item.expired">
                                <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                            </div>
                            <div class="status-icon auto-applied" *ngIf="!item.expired">
                                <div class="holder"></div>
                            </div>
                            <div class="text-block">
                                <div class="title">
                                    <div class="position">{{ item?.questions?.title }}</div>
                                    <div
                                        class="reset-video-block"
                                        *ngIf="
                                            !item.completed &&
                                            item.expired &&
                                            user.role !== 'recruitment_agency' &&
                                            user.role !== 'agency_user' &&
                                            !hideButtons
                                        "
                                    >
                                        <div class="dots"><i></i><i></i><i></i></div>
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-dropdown"
                                                (click)="onExtendDeadline(['questions', item.id, item])"
                                            >
                                                Extend by 3 days
                                            </button>
                                        </div>
                                    </div>
                                    <span class="sensitive" *ngIf="item?.questions?.sensitive">Sensitive</span>
                                </div>
                                <span class="date"> Sent: {{ item.invitationSent }} </span>
                            </div>
                        </ng-container>
                    </p-header>
                    <div class="description">
                        <div class="question-answers">
                            <div
                                class="question-block"
                                *ngFor="let question of item?.questionsAnswers?.questions"
                                ngClass="{{ question?.isKnockout }}"
                            >
                                <h3 class="question-text">{{ question.text }}</h3>
                                <ng-container *ngFor="let answer of question?.answers; let i = index">
                                    <p class="answer-text" *ngIf="!answer?.action">
                                        {{ answer }}
                                    </p>
                                    <p class="answer-text" *ngIf="answer?.action === 'file'">
                                        <a [href]="answer.link">{{ answer.name }}</a>
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="question?.isKnockout">
                                    <img
                                        class="icon-answer"
                                        *ngIf="question?.isKnockout === 'knockout wrong'"
                                        src="/assets/images/icons-8-cancel.svg"
                                    />
                                    <img
                                        class="icon-answer"
                                        *ngIf="question?.isKnockout === 'knockout'"
                                        src="/assets/images/icons-8-ok@2x.png"
                                    />
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </ng-container>
        <ng-container *ngIf="item.type === 'video-interview'">
            <p-accordion [(activeIndex)]="index">
                <p-accordionTab
                    [disabled]="
                        !item.videos ||
                        !item.videos.length ||
                        ((user.role === 'recruitment_agency' || user.role === 'agency_user') &&
                            item?.questions?.sensitive)
                    "
                >
                    <p-header>
                        <div class="status-icon" *ngIf="!item.completed && item.expired">
                            <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                        </div>
                        <div class="status-icon success" *ngIf="item.completed">
                            <div class="holder"></div>
                        </div>
                        <div class="status-icon auto-applied" *ngIf="!item.completed && !item.expired">
                            <div class="holder"></div>
                        </div>
                        <div class="text-block">
                            <div class="title">
                                <div class="position">{{ item?.questions?.title }}</div>
                                <div
                                    class="reset-video-block"
                                    *ngIf="
                                        isResetAvailable(item) ||
                                        (!item.completed &&
                                            item.expired &&
                                            user.role !== 'recruitment_agency' &&
                                            !hideButtons)
                                    "
                                >
                                    <div class="dots"><i></i><i></i><i></i></div>
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-dropdown"
                                            (click)="onResetQuestionnaire($event, item)"
                                            (blur)="onBlurResetQuestionnaireBtn()"
                                        >
                                            {{ resetPrompt.text }}
                                        </button>
                                        <button
                                            class="btn btn-dropdown"
                                            (click)="onExtendDeadline(['video-interview', null, item])"
                                        >
                                            Extend by 3 days
                                        </button>
                                    </div>
                                </div>
                                <span class="sensitive" *ngIf="item?.questions?.sensitive">Sensitive</span>
                                <span
                                    class="status-rating"
                                    [ngClass]="{
                                        Bad: item.averageRating < 3 && item.averageRating > 0,
                                        Average: item.averageRating >= 3 && item.averageRating < 4,
                                        Good: item.averageRating >= 4
                                    }"
                                    *ngIf="item.averageRating && item.allowShowScore"
                                    >{{ item.ratingStatus }} - {{ item.averageRating }}/5</span
                                >
                                <span class="status-rating" *ngIf="!item.allowShowScore && isAllowRateVideo"
                                    >Not Assessed</span
                                >
                            </div>
                            <span class="date" *ngIf="item.completed">
                                Complete: {{ dateFormat(item?.updated_at) }}
                            </span>
                            <span class="date" *ngIf="!item.completed"> Sent: {{ item.invitationSent }} </span>
                        </div>
                    </p-header>
                    <div class="question-content description videos">
                        <div class="question-answers" *ngIf="item.videos && item.videos.length">
                            <div class="question-block" *ngFor="let video of item.videos; let i = index">
                                <div class="video-answer">
                                    <div
                                        class="video-answer__placeholder"
                                        [ngStyle]="{ 'background-image': 'url(https:' + video.thumbnail_url + ') ' }"
                                    >
                                        <button class="btn btn-view" (click)="onViewAndRate(video.id)">
                                            <img src="/assets/images/icons/shapes-and-symbols.svg" />
                                        </button>
                                    </div>
                                    <div class="video-answer__description">
                                        <div class="text">
                                            {{ video.question }}
                                        </div>
                                        <app-set-rating
                                            *ngIf="isAllowRateVideo"
                                            [video]="video"
                                            [dataUpdated]="dataUpdated"
                                            [videos]="videos"
                                            [candidateId]="candidateId"
                                            [jobId]="jobId"
                                            [userId]="user.id"
                                            [userRole]="user.role"
                                            (ratesUpdated)="onRatesUpdated()"
                                        ></app-set-rating>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="candidate?.stage && (candidate?.stage)[jobId]">
    <ng-container *ngFor="let item of stagesData">
        <div class="content-block" *ngFor="let ass of item.assessments">
            <ng-container *ngIf="ass.type === 'personality'">
                <p-accordion>
                    <p-accordionTab [disabled]="!personality_assessment">
                        <p-header>
                            <div class="status-icon" *ngIf="!personality_assessment && ass.expired">
                                <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                            </div>
                            <div class="status-icon success" *ngIf="personality_assessment">
                                <div class="holder"></div>
                            </div>
                            <div class="status-icon auto-applied" *ngIf="!personality_assessment && !ass.expired">
                                <div class="holder"></div>
                            </div>
                            <div class="text-block">
                                <div class="title">
                                    <div class="position">Personality Profile</div>
                                </div>
                                <div
                                    class="reset-video-block"
                                    *ngIf="
                                        ass.expired &&
                                        !personality_assessment &&
                                        user.role !== 'recruitment_agency' &&
                                        !hideButtons
                                    "
                                >
                                    <div class="dots"><i></i><i></i><i></i></div>
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-dropdown"
                                            (click)="onExtendDeadline(['personality', null, ass])"
                                        >
                                            Extend by 3 days
                                        </button>
                                    </div>
                                </div>
                                <span class="date"> Sent: {{ ass?.invitationSent }} </span>
                            </div>
                        </p-header>
                        <div class="description">
                            <div class="question-content">
                                <div class="personality-profile-holder" *ngIf="personality_assessment">
                                    <div class="personality-profile-graph">
                                        <p-chart
                                            #chart
                                            type="radar"
                                            [options]="radar_chart_options"
                                            [data]="radar_chart_data"
                                        ></p-chart>
                                    </div>
                                    <div class="personality-profile-scores scores">
                                        <div class="score-item" *ngFor="let score of personalityProfileScores">
                                            <div class="score-name">{{ score.title }}</div>
                                            <div class="score-chart">
                                                <div
                                                    class="line"
                                                    [ngStyle]="{ width: (score.value / 120) * 100 + '%' }"
                                                    [ngClass]="{
                                                        'green-bg': score?.scoreText === 'neutral',
                                                        'orange-bg':
                                                            score?.scoreText === 'low' || score?.scoreText === 'high'
                                                    }"
                                                ></div>
                                            </div>
                                            <div class="score-chart">
                                                <div
                                                    class="line grey-bg"
                                                    [ngStyle]="{ width: score.average + '%' }"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>
            <ng-container *ngIf="ass.type === 'logic-test'">
                <p-accordion>
                    <p-accordionTab [disabled]="!logicTest">
                        <p-header>
                            <div class="status-icon success" *ngIf="logicTest?.score > 8">
                                <div class="holder"></div>
                            </div>
                            <div
                                class="status-icon"
                                *ngIf="(logicTest && logicTest.score <= 5) || (!logicTest && ass.expired)"
                            >
                                <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                            </div>
                            <div class="status-icon acceptable" *ngIf="logicTest?.score > 5 && logicTest?.score < 8">
                                <div class="holder"></div>
                            </div>
                            <div class="status-icon auto-applied" *ngIf="!logicTest && !ass.expired">
                                <div class="holder"></div>
                            </div>
                            <div class="text-block">
                                <div class="title">
                                    <div class="position">&team Logic Assessment</div>
                                    <div
                                        class="reset-video-block"
                                        *ngIf="
                                            ass.expired &&
                                            !logicTest &&
                                            user.role !== 'recruitment_agency' &&
                                            !hideButtons
                                        "
                                    >
                                        <div class="dots"><i></i><i></i><i></i></div>
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-dropdown"
                                                (click)="onExtendDeadline(['logic-test', null, ass])"
                                            >
                                                Extend by 3 days
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <span class="date" *ngIf="logicTest && logicTest?.completed">
                                    Complete: {{ logicTest?.completed }}
                                </span>
                                <span class="date" *ngIf="ass.invitationSent && (!logicTest || !logicTest?.completed)">
                                    Sent: {{ ass.invitationSent }}
                                </span>
                            </div>
                        </p-header>
                        <div class="description">
                            <div class="question-content">
                                <div class="interview-holder" *ngIf="logicTest">
                                    <div class="form-content-section">
                                        <div class="description-assessment">
                                            <p *ngIf="logicTest && logicTest.invited">
                                                Invitation sent: {{ logicTest.invited }}
                                            </p>
                                            <p>Assessment complete: {{ logicTest?.completed }}</p>
                                        </div>
                                        <div class="form-content-holder assessment-holder">
                                            <div
                                                class="assessment-result"
                                                [ngClass]="{
                                                    low: logicTest?.score <= 5,
                                                    middle: logicTest?.score > 5 && logicTest?.score < 8
                                                }"
                                            >
                                                <span>{{ (logicTest?.score / 10) * 100 }} %</span>
                                                <!-- <p>{{ logicTest?.score }} of 10 points</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>
            <ng-container *ngIf="ass.type === 'devskiller'">
                <p-accordion>
                    <p-accordionTab [disabled]="!ass?.data?.results || (ass.expired && !ass.completed)">
                        <p-header>
                            <div class="status-icon auto-applied" *ngIf="!ass.data.completed && !ass.expired">
                                <div class="holder"></div>
                            </div>
                            <div
                                class="status-icon success"
                                *ngIf="(ass.data?.results?.scoredPoints / ass.data?.results?.maxPoints) * 100 > 59"
                            >
                                <div class="holder"></div>
                            </div>
                            <div
                                class="status-icon"
                                *ngIf="
                                    (ass.data?.results?.scoredPoints / ass.data?.results?.maxPoints) * 100 <= 39 ||
                                    ass.expired
                                "
                            >
                                <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                            </div>
                            <div
                                class="status-icon acceptable"
                                *ngIf="
                                    (ass.data?.results?.scoredPoints / ass.data?.results?.maxPoints) * 100 > 39 &&
                                    (ass.data?.results?.scoredPoints / ass.data?.results?.maxPoints) * 100 < 59
                                "
                            >
                                <div class="holder"></div>
                            </div>
                            <div class="text-block">
                                <div class="title">
                                    <div class="position">{{ ass?.data?.exam?.name }}</div>
                                </div>
                                <span class="date" *ngIf="ass.data && ass.data.completed">
                                    Complete: {{ ass.data.assessmentComplete }}
                                </span>
                                <span class="date" *ngIf="ass.data && !ass.data.completed && !ass.data.expired">
                                    Sent: {{ ass.data.invitationSent }}
                                </span>
                                <span class="date" *ngIf="ass.data && !ass.data.completed && ass.data.expired">
                                    Expired: {{ ass.assessmentExpired || ass.data.assessmentExpired }}
                                </span>
                            </div>
                        </p-header>
                        <div class="description">
                            <div class="question-content">
                                <!-- <ng-container *ngFor="let test of ass.data"> -->
                                <div
                                    class="interview-holder"
                                    *ngIf="ass.data && ass.data.completed && ass.data.candidate && ass.data.results"
                                >
                                    <div class="devskiller-skills">
                                        <div class="item-skill" *ngFor="let skill of ass.data.results.skills">
                                            <div class="name">{{ skill?.name }}</div>
                                            <div
                                                class="slider"
                                                [ngClass]="{
                                                    small: math.round((skill.scoredPoints / skill.maxPoints) * 100) < 50
                                                }"
                                            >
                                                <span
                                                    [ngStyle]="{
                                                        width:
                                                            math.round((skill.scoredPoints / skill.maxPoints) * 100) +
                                                            '%'
                                                    }"
                                                ></span>
                                            </div>
                                            <div class="result">
                                                {{ math.round((skill.scoredPoints / skill.maxPoints) * 100) }}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-content-section">
                                        <div class="description-assessment">
                                            <p>Invitation code: {{ ass.data.invitationCode }}</p>
                                            <p>Invitation sent: {{ ass.data.invitationSent }}</p>
                                            <p>Assessment complete: {{ ass.data.assessmentComplete }}</p>
                                        </div>
                                        <div class="form-content-holder assessment-holder">
                                            <div
                                                class="assessment-result"
                                                [ngClass]="{
                                                    low:
                                                        (ass.data.results.scoredPoints / ass.data.results.maxPoints) *
                                                            100 <=
                                                        39,
                                                    middle:
                                                        (ass.data.results.scoredPoints / ass.data.results.maxPoints) *
                                                            100 >
                                                            39 &&
                                                        (ass.data.results.scoredPoints / ass.data.results.maxPoints) *
                                                            100 <
                                                            59
                                                }"
                                            >
                                                <span
                                                    >{{
                                                        math.round(
                                                            ((ass.data.results.scoredPoints || 0) /
                                                                ass.data.results.maxPoints) *
                                                                100
                                                        )
                                                    }}%</span
                                                >
                                                <p>
                                                    {{ ass.data.results.scoredPoints || 0 }} of
                                                    {{ ass.data.results.maxPoints }} points
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </ng-container> -->
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>
            <ng-container *ngIf="ass.type === 'ikm'">
                <p-accordion>
                    <p-accordionTab
                        [disabled]="
                            !ass?.data?.ikmResult ||
                            ass?.data?.status === 'SUSPENDED' ||
                            (ass.expired && !ass.completed)
                        "
                    >
                        <p-header>
                            <ng-container *ngIf="ass?.completed">
                                <div class="status-icon acceptable" *ngIf="ass?.data?.result === 'proficient'">
                                    <div class="holder"></div>
                                </div>
                                <div class="status-icon">
                                    <img
                                        *ngIf="ass?.data?.result === 'weak'"
                                        width="15"
                                        src="/assets/images/icons-8-cancel.svg"
                                        alt="Icon Cancel"
                                    />
                                </div>
                                <div *ngIf="ass?.data?.result === 'strong'" class="status-icon success">
                                    <div class="holder"></div>
                                </div>
                                <div class="text-block">
                                    <div class="title">
                                        <div class="position">{{ ass?.data?.ikmResponse?.test_name }}</div>
                                    </div>
                                    <span class="date" *ngIf="ass?.data?.status !== 'SUSPENDED'">
                                        Complete: {{ dateFormat(ass?.data?.completed_at) }}
                                    </span>
                                    <span class="date" *ngIf="ass?.data?.status === 'SUSPENDED'">
                                        Assessment Suspended: {{ dateFormat(ass?.data?.updated_at) }}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!ass?.completed">
                                <div class="status-icon" *ngIf="ass.expired">
                                    <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                                </div>
                                <div class="status-icon auto-applied" *ngIf="!ass.expired">
                                    <div class="holder"></div>
                                </div>
                                <div class="text-block">
                                    <div class="title">
                                        <div class="position">{{ ass?.data?.ikmResponse?.test_name }}</div>
                                        <div
                                            class="reset-video-block"
                                            *ngIf="
                                                isResetAvailable(ass) ||
                                                (!ass.completed && user.role !== 'recruitment_agency' && !hideButtons)
                                            "
                                        >
                                            <div class="dots"><i></i><i></i><i></i></div>
                                            <div class="dropdown">
                                                <button
                                                    class="btn btn-dropdown"
                                                    (click)="onResetQuestionnaire($event, ass.data)"
                                                    (blur)="onBlurResetQuestionnaireBtn()"
                                                >
                                                    {{ resetPrompt.text }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="date"> Sent: {{ ass.invitationSent }} </span>
                                </div>
                            </ng-container>
                        </p-header>
                        <div class="ikm-scores" *ngIf="ass?.data?.ikmResult">
                            <p>
                                {{ candidate.first_name }} {{ candidate.last_name }} has a score higher than
                                {{ ass?.data?.ikmResult?.test_result_percentile }}% of all scores on this assessment.
                                Please refer to the report for further detail.
                            </p>
                            <div class="score-table">
                                <div
                                    class="score-row low"
                                    [ngClass]="{ active: ass?.data?.ikmResult?.test_result_percentile <= 59 }"
                                >
                                    <div class="percent">{{ ass?.data?.ikmResult?.test_result_percentile }}%</div>
                                    <strong></strong>
                                    <span>Weak</span>
                                </div>
                                <div
                                    class="score-row middle"
                                    [ngClass]="{
                                        active:
                                            ass?.data?.ikmResult?.test_result_percentile > 59 &&
                                            ass?.data?.ikmResult?.test_result_percentile < 80
                                    }"
                                >
                                    <div class="percent">{{ ass?.data?.ikmResult?.test_result_percentile }}%</div>
                                    <strong></strong>
                                    <span>Proficient</span>
                                </div>
                                <div
                                    class="score-row high"
                                    [ngClass]="{ active: ass?.data?.ikmResult?.test_result_percentile >= 80 }"
                                >
                                    <div class="percent">{{ ass?.data?.ikmResult?.test_result_percentile }}%</div>
                                    <strong></strong>
                                    <span>Strong</span>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<!-- Criminal and ID Verification -->
<ng-container *ngIf="criminalCheckAss">
    <div class="content-block criminal-check-block">
        <p-accordion>
            <p-accordionTab [disabled]="true">
                <p-header>
                    <div
                        class="status-icon auto-applied"
                        *ngIf="
                            (!criminalCheckAss.expired && !criminalCheckAss.reportFile) ||
                            (criminalCheckAss.completed && !criminalCheckAss.reportFile)
                        "
                    >
                        <div class="holder"></div>
                    </div>
                    <div class="status-icon success" *ngIf="criminalCheckAss.reportFile">
                        <div class="holder"></div>
                    </div>
                    <div
                        class="status-icon"
                        *ngIf="criminalCheckAss.expired && !criminalCheckAss.completed && !criminalCheckAss.reportFile"
                    >
                        <img width="15" src="/assets/images/icons-8-cancel.svg" alt="Icon Cancel" />
                    </div>
                    <div class="text-block">
                        <div class="title">
                            <div class="position">
                                Criminal and ID Verification
                            </div>
                            <div
                                class="reset-video-block"
                                *ngIf="
                                    criminalCheckAss.expired &&
                                    !criminalCheckAss.reportFile &&
                                    (user.role !== 'recruitment_agency' || user.role !== 'agency_user') &&
                                    !hideButtons
                                "
                            >
                                <div class="dots"><i></i><i></i><i></i></div>
                                <div class="dropdown">
                                    <button
                                        class="btn btn-dropdown"
                                        (click)="
                                            onExtendDeadline(['criminal-check', criminalCheckAss.id, criminalCheckAss])
                                        "
                                    >
                                        Extend by 3 days
                                    </button>
                                </div>
                            </div>
                        </div>
                        <span class="date">
                            {{ getAssignmentStatus('criminal-check', criminalCheckAss) }}
                        </span>
                    </div>
                </p-header>
                <!-- <div class="description">
                    <div class="question-content">
                        <div class="interview-holder" *ngIf="criminalCheckAss">
                            <div class="form-content-section">
                                <p *ngIf="criminalCheckAss.reportFile">
                                    <a
                                        [href]="criminalCheckAss.reportFile.link"
                                        [title]="criminalCheckAss.reportFile.original_name"
                                        class="file-block-frame"
                                        *ngIf="criminalCheckAss.reportFile.link"
                                    >
                                        <span class="file-icon"
                                            ><img src="/assets/images/icons/files-icon-pdf.svg" alt=""
                                        /></span>
                                        <div class="file-block-holder">
                                            <span class="title"
                                                >{{ criminalCheckAss.reportFile.original_name }}
                                                <span class="thin" *ngIf="criminalCheckAss.reportFile.size">{{
                                                    convertSize(criminalCheckAss.reportFile.size)
                                                }}</span></span
                                            >
                                            <div class="label-row">
                                                <span class="label">Report</span>
                                                <span class="thin" *ngIf="criminalCheckAss.reportFile.uploaded_at">{{
                                                    criminalCheckAss.reportFile.uploaded_at | date: 'MMM d, y'
                                                }}</span>
                                            </div>
                                        </div>
                                    </a>
                                </p>
                                <p *ngIf="criminalCheckAss.reportFileSaps">
                                    <a
                                        [href]="criminalCheckAss.reportFileSaps.link"
                                        [title]="criminalCheckAss.reportFileSaps.original_name"
                                        class="file-block-frame"
                                        *ngIf="criminalCheckAss.reportFileSaps.link"
                                    >
                                        <span class="file-icon"
                                            ><img src="/assets/images/icons/files-icon-pdf.svg" alt=""
                                        /></span>
                                        <div class="file-block-holder">
                                            <span class="title"
                                                >{{ criminalCheckAss.reportFileSaps.original_name }}
                                                <span class="thin" *ngIf="criminalCheckAss.reportFileSaps.size">{{
                                                    convertSize(criminalCheckAss.reportFileSaps.size)
                                                }}</span></span
                                            >
                                            <div class="label-row">
                                                <span class="label">Report</span>
                                                <span
                                                    class="thin"
                                                    *ngIf="criminalCheckAss.reportFileSaps.uploaded_at"
                                                    >{{
                                                        criminalCheckAss.reportFileSaps.uploaded_at | date: 'MMM d, y'
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </p-accordionTab>
        </p-accordion>
    </div>
</ng-container>
<!-- /Criminal and ID Verification -->

<ng-container *ngIf="showVideoScore">
    <div class="video-score-modal">
        <div class="inner">
            <ng-container *ngFor="let question of videos; let i = index">
                <ng-container *ngIf="question.current">
                    <div class="video-wrap">
                        <video controls>
                            <source [src]="question.video_url" type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div class="question-wrap">
                        <h3>Question</h3>
                        <div class="text">
                            {{ question.question }}
                        </div>
                        <app-set-rating
                            [video]="question"
                            [videos]="videos"
                            [candidateId]="candidateId"
                            [jobId]="jobId"
                            [userId]="user.id"
                            [userRole]="user.role"
                            (ratesUpdated)="onRatesUpdated()"
                        >
                        </app-set-rating>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="backdrop" (click)="onCloseModal()"></div>
</ng-container>
<div
    class="candidate-attention no-data"
    *ngIf="!questionnaires.length && !(candidate?.stage && (candidate?.stage)[jobId]) && !criminalCheckAss"
>
    <img alt="warning" class="icon" height="20" src="/assets/images/warning.svg" width="23" />
    <span>Sorry, no data avaliable</span>
</div>
<h2></h2>
