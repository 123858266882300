<div class="container">
    <div class="list-container">
        <div id="table" class="hc-list">
            <div class="hc-list-header">
                <div class="col col 2 flex-5"></div>
                <div class="col col 2 flex-20">Email</div>
                <div class="col col 3 flex-15">Name</div>
                <div class="col col 4 flex-40">Link</div>
                <div class="col col 2 flex-20">Job Id</div>
            </div>
            <div class="nr-list-content">
                <div class="hc-blocker"></div>
                <div class="hc-list-row" *ngFor="let employee of employees; let index = index">
                    <div class="hc-list-row-content">
                        <div class="col col 2 flex-5">{{ index + 1 }}</div>
                        <div class="col col1 flex-20">{{ employee.email }}</div>
                        <div class="col col 3 flex-15">{{ employee?.first_name }} {{ employee?.last_name }}</div>
                        <div class="col col 2 flex-40" (click)="onDonloadDocument(employee)">{{ employee.link }}</div>
                        <div class="col col 2 flex-20">{{ employee.applied_job }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
