import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { currencyOptions } from '@app/core/constants/options.constants';
import { User } from '@app/core/models';
import { JobService, UserService, UtilitiesService } from '@app/core/services';
import { EmsiService } from '@app/core/services/emsi.service';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { GeoService } from '@app/core/services/geo.service';
import { SiteService } from '@app/core/services/site.service';
import { CompareTwoFieldsValidator } from '@app/core/validators/compareTwoFields.validator';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime, distinct, filter } from 'rxjs/operators';
import { TenantService } from './../../../core/services/tenant.service';
import * as fromStore from './../../../store';
import * as fromSelectors from './../../../store/selectors';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@Component({
    selector: 'app-request-position',
    templateUrl: './request-position.component.html',
    styleUrls: ['./request-position.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class RequestPositionComponent implements OnInit, OnDestroy {
    isHovering: boolean;
    files: {
        approval: File[];
        specification: File[];
    } = {
        approval: [],
        specification: []
    };

    hireForm: FormGroup;
    hiringType = 'new';
    contentLoading = true;
    contentLoaded = false;
    dropdownValueUpdated = false;
    businessUnitOptions: any[] = [];
    jobTypeOptions: any[] = [
        { label: 'Full Time', value: 'permanent' },
        { label: 'Temporary', value: 'temporary' },
        { label: 'Fixed Term', value: 'fixed_term' },
        { label: 'Freelance', value: 'freelance' },
        { label: 'Internship', value: 'internship' }
    ];
    contractDuration: any[] = [
        { label: 'More than 6 Months', value: 'more_6_month' },
        { label: '3 to 6 Months', value: '3_to_6_month' },
        { label: '1 to 3 Months', value: '1_to_3_month' },
        { label: 'Less than 1 Month', value: 'less_1_month' }
    ];
    currencyOptions: any[] = currencyOptions;

    salaryOptions: any[] = [
        { label: 'per hour', value: 'hourly' },
        { label: 'per day', value: 'dayly' },
        { label: 'per week', value: 'weekly' },
        { label: 'per month', value: 'monthly' },
        { label: 'per year', value: 'yearly' }
    ];
    tenantLocationOptions: any[] = [];
    disableContractDuration = true;
    hiresOptions: any[];
    subscribtions: Subscription = new Subscription();
    setSuggestions = false;
    user: User;
    supportedFileTypes: string[];

    jdEnabled: boolean;
    filteredTitlesOptions: string[] = [];
    jobDescription = {
        skills: [],
        additionalSkills: [],
        requirements: '',
        qualifications: '',
        roleSummary: '',
        pain: [],
        sellTheJob: '',
        result: ''
    };
    skillsList: any[] = [];
    painPoints: any[] = [];
    additionalSkills: any[] = [];
    emsiTitles: string[] = [];
    showRequirementsModal = false;
    skillsLoading = false;

    // chips config
    selectableAdditionalSkill = true;
    removableAdditionalSkill = true;
    addOnBlurAdditionalSkill = true;
    theme: any = {};
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        private fb: FormBuilder,
        private jobService: JobService,
        private siteService: SiteService,
        private tenantService: TenantService,
        private router: Router,
        private route: ActivatedRoute,
        private formHelper: FormHelperService,
        private userService: UserService,
        @Inject(PLATFORM_ID) private platformId,
        private utilitiesService: UtilitiesService,
        private emsiService: EmsiService,
        private store: Store<fromStore.State>
    ) {
        this.hiresOptions = [];
        for (let i = 1; i <= 100; i++) {
            this.hiresOptions.push({ label: `${i} ${i === 1 ? 'hire' : 'hires'}`, value: i });
        }
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
    }

    async ngOnInit() {
        this.userService.getUser().subscribe((user: User) => {
            this.user = user;
        });

        this.initForm();
        this.contentLoaded = true;
        this.contentLoading = false;

        forkJoin([
            this.jobService.getAlllocations(),
            this.tenantService.integrations(),
            this.tenantService.config()
        ]).subscribe(([locationsResponse, integrationsResponse, config]) => {
            locationsResponse
                .sort((a, b) => {
                    const labelA = a.name.toUpperCase();
                    const labelB = b.name.toUpperCase();
                    return labelA.localeCompare(labelB);
                })
                .forEach((l) => {
                    this.tenantLocationOptions.push({
                        name: l.name,
                        location: l.location_short,
                        id: l.location,
                        currency: l.currency
                    });
                });

            const integrations: any = integrationsResponse;
            if (integrations.jobdescription) {
                this.jdEnabled = true;
                this.hireForm.get('description').updateValueAndValidity();
                this.hireForm.get('description').clearValidators();
            }
            this.theme = config;
            document.documentElement.style.setProperty('--primary-color', this.theme.color);
        });

        this.emsiTitles = this.emsiService.getInitialTitles();
        this.emsiService.getTitles().subscribe((emsiTitles: string[]) => {
            this.emsiTitles = emsiTitles;
        });
        this.store
            .pipe(
                select(fromSelectors.getBusinessUnits),
                filter((res) => !!res)
            )
            .subscribe(
                (res: any) => {
                    console.log('res', res);
                    this.businessUnitOptions = res;
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
    }

    private initForm(): void {
        this.hireForm = this.fb.group(
            {
                title: ['', Validators.required],
                is_remote: [false],
                location: ['', Validators.required],
                business_unit: ['', Validators.required],
                ref: ['', Validators.required],
                cost_centre: [''],
                type: ['', Validators.required],
                number_of_hires: ['', Validators.required],
                start_date: [''],
                contract_duration: [{ value: '', disabled: this.disableContractDuration }],
                salary: this.fb.group({
                    from: ['', [Validators.required, Validators.min(0)]],
                    to: ['', [Validators.required, Validators.min(1)]],
                    period: ['yearly', Validators.required],
                    currency: [''],
                    hide: [''],
                    single: [false]
                }),
                description: ['', Validators.required],
                company: ['', Validators.required],

                hiring_role: ['new', Validators.required],
                status: ['BUILD'],
                approval: ['', Validators.required],
                specification: ['']
            },
            {
                validators: [CompareTwoFieldsValidator]
            }
        );

        this.subscribtions.add(
            this.hireForm.get('is_remote').valueChanges.subscribe((value) => {
                if (value === false) {
                    this.hireForm.get('location').setValidators([Validators.required]);
                } else {
                    this.hireForm.get('location').clearValidators();
                }
                this.hireForm.get('location').updateValueAndValidity();
            })
        );

        const jobTypeValue = this.hireForm.get('type').value;
        this.toggleDisableOfContractDuration(jobTypeValue);

        this.subscribtions.add(
            this.hireForm.get('type').valueChanges.subscribe((value) => {
                this.toggleDisableOfContractDuration(value);
            })
        );

        this.subscribtions.add(
            this.hireForm.get('title').valueChanges.subscribe((title: string) => {
                // console.log('Title changed 1:', title);
                const value = title.trim().toLowerCase();
                this.filteredTitlesOptions = this._filter(value);
            })
        );

        this.subscribtions.add(
            this.hireForm
                .get('title')
                .valueChanges.pipe(
                    filter((value) => value.length > 1),
                    debounceTime(1500),
                    distinct()
                )
                .subscribe((title: string) => {
                    console.log('Title changed 2:', title);
                    // get skills list
                    if (title && title.length > 2) {
                        this.skillsLoading = true;
                        this.emsiService.skills(title).subscribe(
                            (skills: string[]) => {
                                this.skillsLoading = false;
                                // console.log('response:', skills);

                                this.skillsList =
                                    skills && skills.length
                                        ? skills.map((item) => ({ name: item, selected: false }))
                                        : [];
                                console.log('Got skills for', title, ':', this.skillsList);
                            },
                            (errorResponse) => {
                                this.skillsLoading = false;
                                console.error(errorResponse.error);
                            }
                        );
                    }
                })
        );
    }

    onLocationAdded(location) {
        const locValues = location.map((loc) => loc.id);
        this.hireForm.get('location').setValue(locValues);
        this.hireForm.get('location').markAsDirty();
        if (location.length === 1) {
            this.hireForm.get('salary.currency').setValue(location[0].currency || 'ZAR');
            this.hireForm.get('salary.currency').markAsDirty();
            this.dropdownValueUpdated = false;
            setTimeout(() => {
                this.dropdownValueUpdated = true;
            }, 100);
        }
    }

    private _filter(title: string) {
        const filterValue = title.toLowerCase();
        return this.emsiTitles.filter((option) => option.toLowerCase().includes(filterValue)).slice(0, 10);
    }

    private toggleDisableOfContractDuration(value) {
        if (value !== 'temporary' && value !== 'freelance' && value !== 'fixed_term' && value !== null) {
            this.disableContractDuration = true;
            this.hireForm.get('contract_duration').disable();
            this.hireForm.get('contract_duration').patchValue('');
            this.hireForm.get('contract_duration').clearValidators();
            this.hireForm.get('contract_duration').updateValueAndValidity();
        } else {
            if (value !== null) {
                this.disableContractDuration = false;
                this.hireForm.get('contract_duration').enable();
                this.hireForm.get('contract_duration').setValidators([Validators.required]);
                this.hireForm.get('contract_duration').updateValueAndValidity();
            }
        }
    }

    get singleSalary() {
        return this.hireForm.get('salary.single').value as boolean;
    }

    toggleSalaryField() {
        const currentSingleSalaryValue = this.hireForm.get('salary.single').value;
        this.hireForm.get('salary.single').patchValue(!currentSingleSalaryValue);
        const single_salary = !currentSingleSalaryValue;
        console.log('toggleSalaryField', single_salary);
        if (single_salary) {
            console.log('clear to validators');
            this.hireForm.get('salary.to').clearValidators();
            this.hireForm.get('salary.to').updateValueAndValidity();
        } else {
            console.log('set to as required');
            this.hireForm.get('salary.to').setValidators([Validators.required, Validators.min(1)]);
            this.hireForm.get('salary.to').updateValueAndValidity();
        }

        console.log(this.hireForm.get('salary.to'));
    }

    onChangeHiringType(type: string): void {
        this.hiringType = type;
        this.hireForm.controls['hiring_role'].patchValue(type);
    }

    changeInForm(form: FormGroup) {
        form.markAsDirty();
        this.setContractDuration();
    }

    private setContractDuration(): void {
        if (
            this.hireForm.controls.type.value === 'temporary' ||
            this.hireForm.controls.type.value === 'fixed_term' ||
            this.hireForm.controls.type.value === 'freelance'
        ) {
            this.hireForm.get('contract_duration').enable();
            this.hireForm.get('contract_duration').setValidators([Validators.required]);
            this.hireForm.get('contract_duration').updateValueAndValidity();
        } else {
            this.hireForm.get('contract_duration').patchValue('');
            this.hireForm.get('contract_duration').disable();
            this.hireForm.get('contract_duration').clearValidators();
            this.hireForm.get('contract_duration').updateValueAndValidity();
        }
    }

    onClose() {
        this.router.navigateByUrl('/home');
    }

    prepareJobData(formValue: any) {
        const jobData = { ...formValue };
        if (jobData.start_date && typeof jobData.start_date !== 'string') {
            try {
                jobData.start_date = moment(jobData.start_date).format('YYYY-MM-DD HH:mm Z');
            } catch (error) {
                console.error(error);
            }
        }
        return jobData;
    }

    async onSubmitRequest() {
        console.log(this.hireForm.value, this.hireForm, this.jobDescription);
        if (this.hireForm.get('salary.single').value && this.hireForm.get('salary.from').value) {
            this.hireForm.get('salary.to').setValue(this.hireForm.get('salary.from').value);
            this.hireForm.get('salary.to').markAsDirty();
        }

        if (this.jdEnabled) {
            // clearing validators
            this.hireForm.get('description').updateValueAndValidity();
            this.hireForm.get('description').clearValidators();
        }

        this.hireForm.controls['company'].patchValue(this.theme.title);
        if (!this.hireForm.valid) {
            this.formHelper.markFormGroupTouched(this.hireForm);
            return;
        }
        this.hireForm.value.created_by = this.user.id;
        this.hireForm.value.owner = null;
        this.hireForm.value.created_at = Math.floor(Date.now() / 1000);
        const jobData = this.prepareJobData(this.hireForm.value);
        this.contentLoading = true;
        jobData.creationMode = this.jdEnabled ? 'jdAI' : 'standard';
        if (this.jdEnabled) {
            jobData.jdAiCompleted = false;
        }
        console.log(this.hireForm.value, jobData, this.jobDescription);
        const res: any = await this.jobService.saveRequestJob(jobData);
        if (this.jdEnabled) {
            jobData.id = res.id;
            console.log('Save job description details for ', res.id, this.jobDescription);
            this.jobService
                .saveJobDescriptionDetails(jobData, this.jobDescription)
                .then(() => console.log('+ job description details saved'))
                .catch((error) => console.error(error));
        }
        this.contentLoading = false;
        this.router.navigateByUrl(`/tenant/${this.utilitiesService.getTenant()}/hire/jobs`);
        return {
            jobData,
            jobDescription: this.jobDescription
        };

        // this.router.navigateByUrl('/home');
    }

    toggleHover(event: any) {
        this.isHovering = event;
    }

    onDrop(files: FileList, control) {
        for (let i = 0; i < files.length; i++) {
            this.files[control].push(files.item(i));
        }
    }

    startUpload(target: any, type, control) {
        const files = type === 'change' ? target.files : target;
        if (
            this.validateFileType(files[0], this.supportedFileTypes) &&
            this.utilitiesService.isFileSizeValid(files[0].size)
        ) {
            for (let i = 0; i < files.length; i++) {
                this.files[control].push(files.item(i));
            }
        } else {
            console.log(
                'unvalid format or size',
                this.validateFileType(files[0], this.supportedFileTypes),
                this.utilitiesService.isFileSizeValid(files[0].size)
            );
        }
    }

    fileUploaded($event, control) {
        this.files[control] = [];
        this.hireForm.get(control).patchValue($event);
    }

    fileUploadedError(control) {
        this.files[control] = [];
        this.hireForm.get(control).setErrors({ incorrect: true });
    }

    onRemoveFile(control) {
        this.hireForm.get(control).patchValue(null);
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    // Job Descriptoin AI
    addAdditionalSkill(event: any): void {
        const input = event.input;
        const value = (event.value || '').trim();
        // Add our fruit
        if (value) {
            this.additionalSkills.push({ name: value });
        }

        // Clear the input value
        if (input) {
            input.value = '';
        }

        // console.log(this.additionalSkills);
        this.jobDescription.additionalSkills = this.additionalSkills.map((s) => s.name);
    }

    removeAdditionalSkill(skill): void {
        const index = this.additionalSkills.indexOf(skill);
        if (index >= 0) {
            this.additionalSkills.splice(index, 1);
        }

        console.log(this.additionalSkills);
        this.jobDescription.additionalSkills = this.additionalSkills.map((s) => s.name);
    }

    onSkillClick(skill: { name: string; selected: boolean }) {
        skill.selected = !skill.selected;
        const selectedSkills = this.skillsList.filter((p) => p.selected).map((p) => p.name);
        this.jobDescription.skills = selectedSkills;
        // console.log(this.jobDescription);
    }
    onMoreSkills() {
        const title = this.hireForm.get('title').value;
        // console.log('Suggest more skills for', title);
        if (title && title.length > 2) {
            this.skillsLoading = true;
            this.emsiService.skills(title).subscribe(
                (skills: string[]) => {
                    this.skillsLoading = false;
                    this.skillsList = this.skillsList.filter((s) => s.selected);
                    skills.forEach((s) => {
                        if (!this.skillsList.find((skill) => skill.name === s)) {
                            this.skillsList.push({ name: s, selected: false });
                        }
                    });
                },
                (errorResponse) => {
                    this.skillsLoading = false;
                    console.error(errorResponse.error);
                }
            );
        }
    }

    ngOnDestroy() {
        this.subscribtions.unsubscribe();
    }
}
