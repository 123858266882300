import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { TenantService } from '@app/core/services/tenant.service';
import { environment } from '@env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, take } from 'rxjs/operators';
import { User } from './../models/user';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    apiURL: string = environment.apiUrl;
    tenantId = 'undefined';
    baseURL = '';

    constructor(
        private http: HttpClient,
        private utilities: UtilitiesService,
        private tenantService: TenantService,
        private db: AngularFirestore
    ) {
        this.tenantService.init();
    }

    getUser(): Observable<User> {
        return this.http
            .get<User>(`${environment.apiUrl}/me?tenant_id=${this.utilities.getTenant()}`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getUserById(id): Observable<any> {
        return this.http
            .get<any>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/${id}`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    create(data): Observable<User> {
        return this.http
            .post<User>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users`, { data })
            .pipe(catchError((error: any) => throwError(error)));
    }

    update(data): Observable<User> {
        return this.http
            .post<User>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/${data.id}`, { data })
            .pipe(catchError((error: any) => throwError(error)));
    }

    resendInvitation(userId: string) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/resend-invitation`, {
            user_id: userId
        });
    }

    getUsers(): Observable<User[]> {
        return this.http
            .get<User[]>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getRoleUsers(): Observable<User[]> {
        return this.http
            .get<User[]>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/role-users`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getAgencyUsers(): Observable<User[]> {
        return this.http
            .get<User[]>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/agency-users`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    crateAgencyUser(data: any): Observable<User> {
        return this.http
            .post<User>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/agency-users`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    updateAgencyUser(userId: string, data: any): Observable<User> {
        return this.http
            .put<User>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/agency-users/${userId}`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    deleteAgencyUser(userId: string): Observable<any> {
        return this.http
            .delete<any>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/agency-users/${userId}`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    resendAgencyUserInvitation(userId: string): Observable<any> {
        return this.http
            .post<any>(
                `${this.apiURL}/tenants/${this.utilities.getTenant()}/agency-users/${userId}/resend-invitation`,
                {}
            )
            .pipe(catchError((error: any) => throwError(error)));
    }

    getUsersForOwner(): Observable<User[]> {
        return this.http
            .get<User[]>(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/forOwner`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    sendInvitationalEmail(email: string) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/send-invitational`, {
            email
        });
    }

    takeover(email) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/takeover`, { email });
    }

    bulkDeleteUsers(ids) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users/delete/bulk`, { items: ids });
    }

    uploadCertificate(formData: object) {
        return this.http.post(`${this.apiURL}/applications/upload-certificate`, formData);
    }

    changeUsersRoles(rules) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/convert-roles`, rules);
    }

    userAddToHiringTeam(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/user-add-hiring-team`, data);
    }

    agenciesAddToJob(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/agencies-add-job`, data);
    }

    getToken(): Observable<string> {
        return this.http.get<string>(`${environment.apiUrl}/token`).pipe(catchError((error: any) => throwError(error)));
    }

    getDbUser(email: string) {
        return new Promise(async (resolve, reject) => {
            const users$ = this.db
                .collection(`users`, (ref) => ref.where('email', '==', email))
                .valueChanges({ idField: 'id' });

            users$.subscribe((users: any[]) => {
                if (users && users.length) {
                    return resolve(users[0]);
                } else {
                    return resolve(null);
                }
            });
        });
    }

    updateDbUser(userId: string, data: any) {
        return this.db
            .collection(`users`)
            .doc(userId)
            .update(data);
    }

    getUserResume(userId: string) {
        return this.db
            .collection(`users_resumes`)
            .doc(userId)
            .valueChanges()
            .pipe(first())
            .toPromise();
    }

    getCandidateExists(email: string): Promise<{ userExists: boolean }> {
        return new Promise(async (resolve, reject) => {
            const users$ = this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                    ref.where('email', '==', email)
                )
                .valueChanges({ idField: 'id' });
            users$.subscribe((users: any[]) => {
                if (users && users.length) {
                    return resolve({ userExists: true });
                } else {
                    return resolve({ userExists: false });
                }
            });
        });
    }

    getInterviews() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/interviews`);
    }

    getTasks() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/tasks`);
    }

    phoneIsUniqueForTenantValidator(email): AsyncValidatorFn {
        // console.log('running phoneIsUniqueForTenantValidator', email);
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control && control.value && control.value !== +27) {
                return this.db
                    .collection('users', (ref) =>
                        ref
                            .where('phone', '==', control.value)
                            .where('tenant_id', 'array-contains', this.utilities.getTenant())
                            .limit(5)
                    )
                    .valueChanges({ idField: 'id' })
                    .pipe(
                        take(1),
                        map((c) => c.filter((c: any) => c.email !== email)),
                        map((c: any) => {
                            if (c[0]) {
                                return { phoneIsUniqueValidator: true };
                            } else {
                                return null;
                            }
                        })
                    );
            } else {
                return of(null);
            }
        };
    }
}
