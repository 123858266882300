import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { SelectItem } from 'primeng/api';
// import { UtilitiesService } from '@app/core/services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-auto-dropdown',
    templateUrl: './auto-dropdown.component.html',
    styleUrls: ['./auto-dropdown.component.scss']
})
export class AutoDropdownComponent implements OnInit, OnChanges {
    @Input() control: FormControl;
    @Input() options: SelectItem[];
    @Input() value: SelectItem;
    @Input() placeholder: string;
    @Input() readonly: boolean;
    @Input() disabled: boolean;
    @Input() newStyle: boolean;
    @Input() disallowEmpty: boolean;
    @Input() set dropdownValueUpdated(v) {
        if (v) {
            this.ngOnInit();
        }
    }
    @Output() onChange = new EventEmitter<any>();
    @ViewChild('matInput') matInput: any;
    filterControl = new FormControl({
        value: null,
        label: null
    });
    filteredOptions: Observable<any[]>;
    filteredList: SelectItem[];
    selectedValue: any;
    oldValue: any;
    cleared = false;

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes) {
        if (
            (changes && changes.options?.currentValue) ||
            (changes && changes.disabled) ||
            (changes && changes.dropdownValueUpdated)
        ) {
            this.initDropdown();
        }
    }

    private initDropdown(): void {
        if (this.control && this.options?.length) {
            this.initValue(this.control.value);
            this.filteredList = this.options;
            this.selectedValue = this.value;
            this.filteredOptions = this.filterControl.valueChanges.pipe(
                startWith(''),
                map((value) => (typeof value === 'string' ? value : value ? value.label : '')),
                map((name) => (name ? this._filter(name) : this.filteredList.slice()))
            );
            this.control.statusChanges.subscribe((data) => {
                this.filterControl.setValidators(this.control.validator);
                this.filterControl.updateValueAndValidity();
            });
            this.initFilterControl(this.value ? this.value : '', this.disabled);
        } else {
            this.initFilterControl(this.value ? this.value : '', this.disabled);
        }
    }

    private initFilterControl(value, disable): void {
        const filterControlValue = {
            value: value ? value.value : '',
            label: value ? value.label : ''
        };
        setTimeout(() => {
            this.filterControl.setValue(filterControlValue);
        }, 100);

        if (disable !== undefined) {
            disable ? this.filterControl.disable() : this.filterControl.enable();
        }
    }

    initValue(value: SelectItem): void {
        this.value = this.options?.find((item) => item.value === value || isEqual(item.value, value));
    }

    private _filter(label: string): any[] {
        const filterValue = label.toLowerCase().trim();
        const queryWords = filterValue.split(' ').filter((word) => word);
        return this.options.filter((option) => {
            const matched = queryWords.every((word) => option.label.toLowerCase().indexOf(word) !== -1);
            return matched;
        });
    }

    displayFn(option: any): string {
        return option && option.label ? option.label : '';
    }

    onclear(event) {
        this.cleared = true;
        if (!this.disallowEmpty) {
            this.selectedValue = null;
            this.oldValue = null;
            this.value = null;
            this.control.setValue('');
        }
        this.filterControl?.setValue({ value: '', label: '' });
        setTimeout(() => {
            this.matInput.nativeElement.blur();
        }, 50);
        setTimeout(() => {
            this.matInput.nativeElement.focus();
        }, 150);

        setTimeout(() => {
            this.cleared = false;
        }, 350);
        event.stopPropagation();
    }

    onfocus() {
        this.oldValue = this.selectedValue || this.value;
    }

    onblur() {
        setTimeout(() => {
            if (!this.cleared) {
                if (!this.selectedValue || (this.selectedValue.value !== this.control.value && this.oldValue)) {
                    this.selectedValue = this.oldValue;
                    this.control.setValue((this.selectedValue && this.selectedValue.value) || null);
                    this.filterControl.setValue(this.selectedValue || null);
                }

                if (this.filterControl.value) {
                    const displayValue = this.filterControl.value.value;
                    if (this.selectedValue && this.selectedValue.value !== displayValue) {
                        this.filterControl.patchValue(this.selectedValue);
                    }
                } else {
                    if (this.selectedValue) {
                        this.filterControl.patchValue(this.selectedValue);
                    }
                }
            }
        }, 500);
    }

    optionSelected(event): void {
        this.selectedValue = event.value;
        this.control?.setValue(this.selectedValue.value);
        this.filterControl?.setValue(event.value);
        this.onChange.emit(event.value);
        this.matInput.nativeElement.blur();
        this.control.markAsDirty();
    }

    get showClear() {
        if (!this.filterControl.value) {
            return false;
        }
        const val =
            typeof this.filterControl.value === 'string' ? this.filterControl.value : this.filterControl.value.value;
        if (!val) {
            return false;
        }
        if (val) {
            if (typeof val === 'string') {
                return val.length ? true : false;
            } else {
                return val ? true : false;
            }
        }
    }
}
