import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandidateService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import * as fromStore from '@app/store';
import * as fromSelectors from '@app/store/selectors';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Employee } from './../../../../../core/models/employee';
import { ITag } from './../../../../../core/models/job';
import { User } from './../../../../../core/models/user';
import { UtilitiesService } from './../../../../../core/services/utilities.service';

@Component({
    selector: 'app-employee-tags',
    templateUrl: './employee-tags.component.html',
    styleUrls: ['./employee-tags.component.scss']
})
export class EmployeeTagsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() employee: Employee;
    user: User;
    users: User[];
    usersSubscription: Subscription;

    tagForm: FormGroup;
    contentLoading = false;
    auditData: any[] = [];
    audit = [];
    @ViewChild('pEditor') pEditor: any;
    quill: any;
    cursorPosition: any;
    globalTags: any[] = [];
    currentHash: string = '';
    createMode: boolean = false;
    hashColors: string[] = [];
    newHashes: ITag[] = [];
    lastHash: ITag = null;
    isOpened = false;
    isSaving = false;
    showDropdown = false;

    currentInputValue = null;
    @HostListener('document:click', ['$event'])
    clickout() {
        this.currentHash = '';
    }

    constructor(
        private fb: FormBuilder,
        private employeesService: EmployeesService,
        private store: Store<fromStore.State>,
        private utilities: UtilitiesService,
        private candidateService: CandidateService
    ) {
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            // this.loadAudit();
        });

        this.tagForm = this.fb.group({
            title: ['', Validators.required],
            color: ['']
        });
        this.hashColors = [
            '#f6f8f9',
            '#fe602c',
            '#fc9a04',
            '#eec302',
            '#a4cf2f',
            '#37c3aa',
            '#20aaea',
            '#7a6ff0',
            '#e362e3',
            '#ea4d9d',
            '#fc91ad',
            '#525f7f'
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {}

    async ngOnInit() {
        if (!this.employee.tags) {
            this.employee.tags = [];
        }
        // console.log('🧮 employee TAGS:', this.employee.tags);
        this.employee.tags.forEach((tag) => {
            if (!tag.title && tag.hash) {
                tag.title = tag.hash;
            }
        });

        this.globalTags = await this.employeesService.getGlobalEmployeeTags();
        // console.log('Global tags:', this.globalTags);
    }

    showOpened() {
        this.isOpened = !this.isOpened;
        this.showDropdown = this.isOpened;
    }

    ngAfterViewInit() {}

    get tags(): ITag[] {
        return [...this.globalTags];
    }

    get tagWithTitles(): ITag[] {
        const searchTerm = this.tagForm.controls.title.value
            ? this.tagForm.controls.title.value.replace(/<[^>]*>/g, '')
            : '';

        this.employee.tags.forEach((tag) => {
            if (!tag.title && tag.hash) {
                tag.title = tag.hash;
            }
        });

        const value = this.tags
            .filter(({ title }) => !this.employee.tags.find((tag) => tag.title === title))
            .filter((tag) => (searchTerm.length && tag.title ? tag.title.includes(searchTerm) : true));
        return value;
    }

    get tagTitles(): string[] {
        const titles = this.tags.map((t) => t.title);
        return this.currentHash ? [...titles, this.currentHash] : titles;
    }

    get suggestionPosition() {
        const span = document.querySelector('.ql-editor p:last-child span:last-child');
        if (span) {
            return {
                top:
                    Number(document.querySelector('.ql-editor p:last-child')['offsetTop']) +
                    (this.lastHash ? 29 : 22) -
                    Number(document.querySelector('.ql-editor').scrollTop),
                left: span
                    ? Math.min(span['offsetLeft'], span['offsetParent']['offsetWidth'] - (this.lastHash ? 130 : 269)) -
                      (this.lastHash ? 63 + this.lastHash.hash.length * 3 : 0)
                    : 11
            };
        }
    }

    getTagStyle(color: string) {
        function getTextColor(color: string): string {
            return color === '#f6f8f9' ? 'black' : 'white';
            // const r = parseInt(color.substr(1, 2), 16);
            // const g = parseInt(color.substr(3, 2), 16);
            // const b = parseInt(color.substr(5, 2), 16);
            // return (r * 299 + g * 587 + b * 114) / 1000 > 138 ? 'black' : 'white';
        }
        return {
            background: color,
            color: getTextColor(color)
        };
    }

    getTag(hash: string): ITag {
        return this.tags.find((tag) => tag.hash === hash || this.titleCase(tag.hash) === hash);
    }

    titleCase(str: string) {
        return str
            .replace(/#/g, '')
            .split(/[-|_]/g)
            .join(' ')
            .replace(/\w*\S/g, (t) => t[0].toUpperCase() + t.substr(1));
    }

    placeHash(tag: ITag) {
        this.lastHash = {
            color: tag.color,
            hash: tag.hash
        };

        this.employee.tags.push(tag);

        this.employee.tags.forEach((tag) => {
            if (!tag.title && tag.hash) {
                tag.title = tag.hash;
            }
        });

        // 1. save employee
        this.employeesService
            .updateEmployee(this.employee.id, { tags: this.employee.tags })
            .then(() => console.log('Save'))
            .catch((error) => console.error(error));
        // 2. save audit
        const data = {
            created_at: Math.floor(Date.now() / 1000),
            user_id: this.user.id,
            type: 'tag',
            param: 'added',
            title: tag.title,
            color: tag.color
        };
        this.employeesService.saveEmployeeAudit(this.employee.id, data);
        this.updateCandidatesDataCollection();
    }

    onCreateNew() {
        const title = this.tagForm.get('title').value.trim();
        const existTag = this.employee.tags.find((tag) => tag.title === title);
        if (existTag) {
            return;
        }
        if (title && title.length > 1) {
            this.createMode = true;
            this.lastHash = {
                title: title.replace(/\r?↵/g, ''),
                color: this.hashColors[0]
            };
            this.newHashes.push(this.lastHash);
        }
    }

    async onDeleteTag(deleteTitle) {
        this.contentLoading = true;
        this.employee.tags.forEach((tag) => {
            if (!tag.title && tag.hash) {
                tag.title = tag.hash;
            }
        });

        const removeTag = this.employee.tags.find(({ title }) => title === deleteTitle);
        this.employee.tags = this.employee.tags.filter(({ title }) => title !== deleteTitle);
        this.employeesService
            .updateEmployee(this.employee.id, { tags: this.employee.tags })
            .then(() => {
                this.contentLoading = false;
            })
            .catch((error) => {
                alert('Error');
                this.contentLoading = false;
                console.error(error);
            });
        const data = {
            created_at: Math.floor(Date.now() / 1000),
            user_id: this.user.id,
            type: 'tag',
            param: 'deleted',
            title: removeTag.title,
            color: removeTag.color
        };
        this.employeesService.saveEmployeeAudit(this.employee.id, data);
        this.updateCandidatesDataCollection();
    }

    onChangeHashColor(color: string) {
        this.tagForm.patchValue({ color });
        this.lastHash.color = color;
        this.createMode = false;
        this.saveTag();
    }

    onNewTagTitleKeyup(event) {
        if (event.keyCode === 13) {
            this.onCreateNew();
        }
    }

    saveTag() {
        if (!this.tagForm.valid) {
            return;
        }
        this.isSaving = true;
        const tag = this.tagForm.value;
        this.tagForm.reset();
        this.isSaving = false;
        this.employee.tags.push(tag);
        this.globalTags.push(tag);
        this.employeesService
            .updateEmployee(this.employee.id, { tags: this.employee.tags })
            .then(() => {
                console.log('Save');
                this.employeesService.addToGlobalTags(tag);
            })
            .catch((error) => console.error(error));
    }

    getTags(text) {
        if (typeof text === 'string') {
            return JSON.parse(text);
        }
        return text;
    }

    updateCandidatesDataCollection() {
        this.candidateService.updateCandidatesDataCollectionFull(this.employee.id).subscribe();
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }
}
