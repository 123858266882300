<div class="kpi-holder">
    <div class="kpi-item" *ngFor="let item of employee.kpi">
        <div class="wrapper">
            <h4>Title</h4>
            <div class="title">{{ item.title }}</div>
        </div>
        <div class="wrapper">
            <h4>Category</h4>
            <div class="category">{{ item.category }}</div>
        </div>
        <div class="wrapper">
            <h4>Criteria</h4>
            <div [innerHTML]="item.criteria"></div>
        </div>
        <div class="wrapper">
            <h4>Definition</h4>
            <div [innerHTML]="item.definition"></div>
        </div>
        <div class="wrapper">
            <h4>Employee Note</h4>
            <div [innerHTML]="item.employee_note"></div>
        </div>
        <div class="wrapper">
            <h4>Employee Rating</h4>
            <div class="rating-block">
                <ng-container *ngIf="item?.employee_ratings">
                    <div *ngFor="let it of item.employee_ratings" class="img-block">
                        <img src="/assets/images/workflow-icons/icons-8-star-filled@2x.webp" alt="" />
                    </div>
                </ng-container>
                <ng-container *ngIf="item?.employee_ratings?.length === 0">-</ng-container>
            </div>
        </div>
        <div class="wrapper">
            <h4>Manager Note</h4>
            <div [innerHTML]="item.manager_note"></div>
        </div>
        <div class="wrapper">
            <h4>Manager Rating</h4>
            <div class="rating-block">
                <ng-container *ngIf="item?.manager_ratings">
                    <div *ngFor="let it of item.manager_ratings" class="img-block">
                        <img src="/assets/images/workflow-icons/icons-8-star-filled@2x.webp" alt="" />
                    </div>
                </ng-container>
                <ng-container *ngIf="item?.manager_ratings?.length === 0">-</ng-container>
            </div>
        </div>
    </div>
</div>
