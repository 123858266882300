import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Candidate, ITag, User } from '@app/core/models';
import { CandidateService, JobService, UserService, UtilitiesService } from '@app/core/services';
import { FindVariables } from '@app/libs/util';
import * as fromStore from '@app/store';
import * as fromSelectors from '@app/store/selectors';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SdkJob } from './../../../../../../core/models/job';

@Component({
    selector: 'app-candidate-tags',
    templateUrl: './candidate-tags.component.html',
    styleUrls: ['./candidate-tags.component.scss']
})
export class CandidateTagsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() job: SdkJob;
    @Input() candidate: Candidate;
    @Input() set offerAudit(value) {
        if (value) {
            this.audit = this.transformAudit(value);
        }
    }
    @Input() set tagChanged(value) {
        if (value) {
            if (
                this.candidate.agency_owner &&
                this.candidate.agency_owner[this.job.id] &&
                !this.agencyTags.find((a) => a.hash === '#agency') &&
                !this.candidate.tags.find((a) => a.hash === '#agency')
            ) {
                this.agencyTags.push({
                    agency_owner: true,
                    added_at: 1637184909,
                    color: '#2b6fe3',
                    hash: '#agency'
                });
            } else {
                this.agencyTags = [];
            }
        }
    }
    @Output() public tagUpdated = new EventEmitter<any>();
    user: User;
    users: User[];
    usersSubscription: Subscription;

    commentForm: FormGroup;
    contentLoading = false;
    auditData: any[] = [];
    audit = [];

    @ViewChild('pEditor') pEditor: any;
    quill: any;
    cursorPosition: any;
    globalTags: ITag[] = [];
    currentHash: string = '';
    createMode: boolean = false;
    hashColors: string[] = [];
    newHashes: ITag[] = [];
    lastHash: ITag = null;
    isOpened = false;
    isSaving = false;
    showDropdown = false;

    currentInputValue = null;
    agencyTags: ITag[] = [];
    constructor(
        private fb: FormBuilder,
        private candidateService: CandidateService,
        private jobService: JobService,
        private userService: UserService,
        private toastr: ToastrService,
        private store: Store<fromStore.State>,
        private utilities: UtilitiesService
    ) {
        this.globalTags = [
            {
                hash: '#internal',
                color: '#3bb273'
            },
            {
                hash: '#ex-internal',
                color: '#7a6ff0'
            },
            // {
            //     hash: '#do-not-hire',
            //     color: '#ff3b30'
            // },
            {
                hash: '#agency',
                color: '#2b6fe3'
            }
        ];
        this.hashColors = [
            '#f6f8f9',
            '#fe602c',
            '#fc9a04',
            '#eec302',
            '#a4cf2f',
            '#37c3aa',
            '#20aaea',
            '#7a6ff0',
            '#e362e3',
            '#ea4d9d',
            '#fc91ad',
            '#525f7f'
        ];
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.currentHash = '';
    }

    ngOnInit() {
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            // this.loadAudit();
        });
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            if (this.users && this.users.length) {
                this.loadAudit();
                // checked if owned by agency
                const ownerId = this.candidate.owner ? this.candidate.owner : '';
                const owner = this.users.find((u) => u.id === ownerId);
                // deprecated
                if (owner) {
                    // console.log(owner);
                    // console.log('🏏 OWNER ROLE:', owner.role);
                    const isAgencyOwned =
                        owner.role == 'agency_user' || owner.role == 'recruitment_agency' ? true : false;
                    if (isAgencyOwned) {
                        // add agency tag
                        // console.log('Not agency owned');
                        // this.addAgencyTag();
                    } else {
                        // clear agency tag
                        // console.log('Agency owned');
                        // this.clearAgencyTag();
                    }
                } else {
                    // clear agency tag
                    // this.clearAgencyTag();
                }
            }
        });
        this.commentForm = this.fb.group({
            description: ['', Validators.required]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {}

    showOpened() {
        this.isOpened = !this.isOpened;
    }

    ngAfterViewInit() {
        this.quill = this.pEditor.quill;

        this.textChange(this.quill);
    }

    get tags(): ITag[] {
        const tagsArr = [...this.globalTags];
        if (this.job && this.job.tags && this.job.tags.length) {
            tagsArr.push(...this.job.tags);
        }
        return tagsArr;
    }

    get tagWithTitles(): ITag[] {
        const searchTerm = this.commentForm.controls.description.value
            ? this.commentForm.controls.description.value.replace(/<[^>]*>/g, '')
            : '';
        return this.tags
            .map(({ hash, color }) => ({ hash: hash.toLowerCase(), color }))
            .filter(({ hash }) => !this.candidate.tags.find((tag) => tag.hash === hash))
            .map(({ hash, color }) => ({ hash, color, title: this.titleCase(hash) }))
            .filter((tag) => tag.title.includes(searchTerm));
    }

    get tagTitles(): string[] {
        const titles = [...this.tags.map(({ hash }) => this.titleCase(hash)), ...this.tags.map(({ hash }) => hash)];
        return this.currentHash ? [...titles, this.currentHash] : titles;
    }

    get suggestionPosition() {
        const span = document.querySelector('.ql-editor p:last-child span:last-child');
        if (span) {
            return {
                top:
                    Number(document.querySelector('.ql-editor p:last-child')['offsetTop']) +
                    (this.lastHash ? 29 : 22) -
                    Number(document.querySelector('.ql-editor').scrollTop),
                left: span
                    ? Math.min(span['offsetLeft'], span['offsetParent']['offsetWidth'] - (this.lastHash ? 130 : 269)) -
                      (this.lastHash ? 63 + this.lastHash.hash.length * 3 : 0)
                    : 11
            };
        }
    }

    getTagStyle(color: string) {
        function getTextColor(color: string): string {
            return color === '#f6f8f9' ? 'black' : 'white';
            // const r = parseInt(color.substr(1, 2), 16);
            // const g = parseInt(color.substr(3, 2), 16);
            // const b = parseInt(color.substr(5, 2), 16);
            // return (r * 299 + g * 587 + b * 114) / 1000 > 138 ? 'black' : 'white';
        }
        return {
            background: color,
            color: getTextColor(color)
        };
    }

    getTag(hash: string): ITag {
        return this.tags.find((tag) => tag.hash === hash || this.titleCase(tag.hash) === hash);
    }

    textChange(quill: any) {
        quill.root.setAttribute('spellcheck', false);
        quill.root.setAttribute('autocorrect', false);

        quill.on('text-change', (delta, oldContents, source) => {
            this.formatQuill(quill);
        });
        this.formatQuill(quill);

        quill.on('selection-change', (range, oldRange, source) => {
            this.showDropdown = !!range;
            this.createMode = false;
            this.cursorPosition = oldRange;
        });

        quill.keyboard.bindings['Enter'].splice(0, 0, {
            key: 'Enter',
            collapsed: true,
            handler: this.onCreateNew.bind(this)
        });
    }

    titleCase(str: string) {
        return str
            ? str
                  .replace(/#/g, '')
                  .split(/[-|_]/g)
                  .join(' ')
                  .replace(/\w*\S/g, (t) => t[0].toUpperCase() + t.substr(1))
            : '';
    }

    placeHash(tag: ITag) {
        // this.candidate.tags.push(tag);
        this.lastHash = {
            color: tag.color,
            hash: tag.hash
        };
        this.onSaveComment(true);
    }

    onCreateNew() {
        const existTag = this.candidate.tags.find((tag) => tag.hash === this.currentInputValue);
        if (existTag) {
            return;
        }

        if (this.currentInputValue && this.currentInputValue.length > 1) {
            this.createMode = true;
            this.lastHash = {
                hash: this.currentInputValue.replace(/\r?↵/g, ''),
                color: this.hashColors[0]
            };
            this.newHashes.push(this.lastHash);
        }
    }

    async onDeleteTag(deleteHash) {
        this.contentLoading = true;
        const removeTag = this.candidate.tags.find(({ hash }) => hash === deleteHash);
        this.candidate.tags = this.candidate.tags.filter(({ hash }) => hash !== deleteHash);
        await this.candidateService.updateDbCandidate(this.candidate.id, { tags: this.candidate.tags });
        const comment = {
            id: this.utilities.generateUID(10).toLowerCase(),
            text: JSON.stringify([removeTag]),
            param: 'removed',
            created_at: new Date().getTime(),
            user_id: this.user.id,
            type: 'tags'
        };
        const jobId = this.job && this.job.id ? this.job.id : 'general';
        if (removeTag.added_at && (removeTag.hash === '#internal' || removeTag.hash === '#ex-internal')) {
            this.auditData.push(removeTag);
            this.candidateService
                .addToAudit('general', this.candidate.id, comment)
                .then((response) => {
                    this.commentForm.reset();
                    this.contentLoading = false;
                    this.auditData.push(comment);
                    this.tagUpdated.emit(comment);
                    this.audit = this.transformAudit(this.auditData);
                })
                .catch((errorResponse) => {
                    console.error(errorResponse);
                    this.contentLoading = false;
                });
        } else {
            this.candidateService
                .addToAudit('general', this.candidate.id, comment)
                .then((response) => {
                    this.commentForm.reset();
                    this.contentLoading = false;
                    this.auditData.push(comment);
                    this.tagUpdated.emit(comment);
                    this.audit = this.transformAudit(this.auditData);
                })
                .catch((errorResponse) => {
                    console.error(errorResponse);
                    this.contentLoading = false;
                });
        }
        this.updateCandidatesDataCollectionFull();
    }

    onChangeHashColor(color: string) {
        this.lastHash.color = color;
        // this.formatQuill(this.quill);
        this.createMode = false;
        this.onSaveComment(true);
    }

    formatQuill(quill: any) {
        this.currentInputValue = '';
        const { ops: origin } = quill.getContents();

        const lastIndex = origin[origin.length - 1].insert === '\n' ? origin.length - 2 : origin.length - 1;
        if (lastIndex < 0) return;
        const last = origin[lastIndex].insert.split(' ');

        const transformedValue = last.reduce((acc, curr, idx) => {
            curr = curr.replace(/\s+/g, '');
            if (curr === '') {
                return acc;
            }
            return acc + (idx === 0 ? '' : '-') + curr;
        }, '#');
        this.currentInputValue = transformedValue;

        if (this.currentInputValue.length > 1) {
            this.currentHash = this.currentInputValue;
            this.lastHash = this.currentInputValue;
        }

        let hashIndex = -1;
        if (last.length && (hashIndex = last[last.length - 1].lastIndexOf('#')) !== -1) {
            const newHash = last[last.length - 1].substr(hashIndex).replace(/\n/g, '');
            if (this.currentHash !== newHash && (!this.lastHash || (this.lastHash && this.lastHash.hash !== newHash))) {
                this.currentHash = newHash;
                this.createMode = false;
                this.lastHash = null;
            }
        } else {
            this.currentHash = '';
            if (
                !this.lastHash ||
                (origin[lastIndex].insert !== ' ' &&
                    origin[lastIndex - 1] &&
                    origin[lastIndex - 1].insert === this.titleCase(this.lastHash.hash))
            )
                this.createMode = false;
        }

        let i = 0;
        let offset = 0;

        while (origin[i]) {
            const d = origin[i];
            if (d.attributes) {
                if (d.attributes.color === 'white' || d.attributes.colr === 'black') {
                    const placeholder = this.tags.find(({ hash }) => hash === d.insert);
                    if (!placeholder) {
                        quill.removeFormat(offset, d.insert.length);
                        break;
                    }
                } else {
                    quill.removeFormat(offset, d.insert.length);
                    break;
                }
            } else {
                offset += (d.insert || '').length;
            }
            i++;
        }
        if (origin[i]) return;

        const inset = quill.getText();
        let variables = FindVariables(inset, this.tagTitles);
        offset = 0;
        for (let variable of variables) {
            if (variable.name[0] === '#') {
                const placeholder = this.tags.find(({ hash }) => hash === variable.name);
                if (placeholder) {
                    const delta = { ops: [] };
                    const title = this.titleCase(placeholder.hash);
                    const off = title.length - variable.name.length;
                    delta.ops.push(
                        { retain: variable.index - offset },
                        { delete: variable.name.length },
                        { insert: title + ' ' }
                    );
                    if (variable.index - offset === 0) delta.ops.shift();
                    quill.updateContents(delta);
                    offset += off;
                }
                break;
            }
        }
        if (offset < 0) return;

        for (let variable of variables) {
            const tag = this.getTag(variable.name);
            //     quill.formatText(
            //         variable.index,
            //         variable.index + variable.name.length,
            //         tag
            //             ? this.getTagStyle(tag.color)
            //             : {
            //                   color: '#000',
            //                   background: 'transparent'
            //               },
            //         'silent'
            //     );
            //
            //     quill.formatText(
            //         variable.index + variable.name.length,
            //         variable.index + variable.name.length + 1,
            //         {
            //             color: '#000',
            //             background: 'transparent'
            //         },
            //         'silent'
            //     );
        }
    }

    loadAudit() {
        if (this.job && this.candidate.audit && this.candidate.audit[this.job.id]) {
            this.auditData = this.candidate.audit[this.job.id];
            this.audit = this.transformAudit(this.auditData);
        } else if (this.candidate.audit && this.candidate.audit['general']) {
            this.auditData = this.candidate.audit['general'];
            this.audit = this.transformAudit(this.auditData || []);
        } else {
            this.audit = this.transformAudit([]);
        }
    }

    transformAudit(auditData: any[]) {
        const creationEntry = auditData.find((e) => e.type === 'created');
        if (!creationEntry) {
            if (this.candidate.assignments) {
                let created_app = this.candidate.assignments.find((a) => a.type === 'questions');
                auditData.push({
                    type: 'created',
                    created_at: this.job.created_at * 1000,
                    source: this.candidate.source
                });
            }
        }

        const result = [];
        if (this.candidate && this.candidate.tags && this.candidate.tags.length > 0) {
            this.candidate.tags.forEach((t: any) => {
                if (['#internal', '#ex-internal', '#agency'].includes(t.hash) && t.added_at) {
                    t.type = 'internal';
                    t.text = [{ color: t.color, hash: t.hash }];
                    t.internal = true;
                    t.created_at_rel = this.utilities.fromNow(t.added_at * 1000);
                    t.created_at = t.added_at * 1000;
                    result.push(t);
                }
            });
        }
        auditData.forEach((e) => {
            // temprorary code
            if (e.created_at.toString(10).split('').length === 10 && e.type === 'created') {
                e.created_at = e.created_at * 1000;
            }
            e.created_at_rel = this.utilities.fromNow(e.created_at);
            e.job_title = this.job && this.job.title ? this.job.title : '';
            e.candidate_name = this.candidate.first_name + ' ' + this.candidate.last_name;
            if (e.type === 'created') {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                }
            }
            if (e.type === 'comment' || e.type === 'tags' || e.type === 'offer') {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                    e.image_url = author.icon_url || null;

                    result.push(e);
                }
            } else if (e.type === 'stages_progress') {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                    e.image_url = author.icon_url || null;
                }
                if (this.job) {
                    const stages = this.job.stages;
                    const stage_from = stages.find((s) => s.id === e.stage_from_id);
                    const stage_to = stages.find((s) => s.id === e.stage_to_id);
                    e.stage_from_title = (stage_from && stage_from.title) || 'Applied';
                    e.stage_to_title = (stage_to && stage_to.title) || 'Applied';

                    if (e.user && e.stage_from_title && e.stage_to_title) {
                        result.push(e);
                    }
                }
            } else {
                result.push(e);
            }
        });
        return result.sort((a: any, b: any) => b.created_at - a.created_at);
    }

    getTags(text) {
        if (typeof text === 'string') {
            return JSON.parse(text);
        }
        return text;
    }

    async onSaveComment(addTag?: boolean) {
        if (this.commentForm.valid || addTag) {
            const visits = {};
            this.isSaving = true;
            (this.candidate.tags || []).forEach(({ hash }) => (visits[hash] = true));
            const { ops: origin } = this.quill.getContents();
            const description = this.commentForm.controls.description.value
                ? this.commentForm.controls.description.value.replace(/<[^>]*>/g, '')
                : '';
            const newTags = [];
            if (this.lastHash && this.lastHash.hash) {
                newTags.push({
                    hash: this.lastHash.hash,
                    color: this.lastHash.color,
                    title: this.titleCase(this.lastHash.hash)
                });
                this.commentForm.reset();
            }
            const jobId = this.job && this.job.id ? this.job.id : 'general';
            const tags = this.newHashes;
            if (tags.length > 0 && this.job) {
                try {
                    await this.jobService.updateJob(this.job.id, { tags: this.job.tags || [] });
                } catch (error) {
                    this.toastr.error(error.message);
                }
                this.job.tags ? this.job.tags.push(...tags) : (this.job.tags = tags);
            }
            if (newTags.length > 0 || addTag) {
                try {
                    await this.candidateService.updateDbCandidate(this.candidate.id, {
                        tags: [...(this.candidate.tags || []), ...newTags]
                    });
                    this.updateCandidatesDataCollectionFull();
                } catch (error) {
                    this.toastr.error(error.message);
                }
                const comment = {
                    id: this.utilities.generateUID(10).toLowerCase(),
                    text: JSON.stringify(newTags),
                    param: 'added',
                    created_at: new Date().getTime(),
                    user_id: this.user.id,
                    type: 'tags'
                };
                this.newHashes = [];
                this.lastHash = null;
                this.currentHash = '';
                this.createMode = false;
                this.candidate.tags ? this.candidate.tags.push(...newTags) : (this.candidate.tags = newTags);
                this.candidateService
                    .addToAudit('general', this.candidate.id, comment)
                    .then((response) => {
                        this.auditData.push(comment);
                        this.tagUpdated.emit(comment);
                        this.audit = this.transformAudit(this.auditData);
                    })
                    .catch((errorResponse) => {
                        console.error(errorResponse);
                    });
            } else if (newTags.length === 0 && !addTag) {
                const comment = {
                    id: this.utilities.generateUID(10).toLowerCase(),
                    text: description,
                    created_at: new Date().getTime(),
                    user_id: this.user.id,
                    type: 'comment'
                };
                this.candidateService
                    .addToAudit(jobId, this.candidate.id, comment)
                    .then((response) => {
                        this.commentForm.reset();
                        this.auditData.push(comment);
                        this.tagUpdated.emit(comment);
                        this.audit = this.transformAudit(this.auditData);
                    })
                    .catch((errorResponse) => {
                        console.error(errorResponse);
                    });
            }
            this.isSaving = false;
            this.commentForm.reset();
            this.quill.focus();
        }
    }

    updateCandidatesDataCollectionFull() {
        this.candidateService.updateCandidatesDataCollectionFull(this.candidate.id).subscribe();
    }

    updateCandidatesDataCollection() {
        this.candidateService.updateCandidatesDataCollection(this.candidate.id).subscribe();
    }

    async addAgencyTag() {
        console.log('add agency tag', this.candidate.tags);
        const agencyTag = this.candidate.tags.find((t) => t.hash === '#agency');
        if (!agencyTag) {
            this.candidate.tags.push({
                hash: '#agency',
                color: '#2b6fe3'
            });
            await this.candidateService.updateDbCandidate(this.candidate.id, {
                tags: this.candidate.tags
            });
            await this.candidateService
                .updateJobCandidate(this.candidate.id, this.job.id, { tags: this.candidate.tags })
                .catch((error) => console.error(error));
            this.updateCandidatesDataCollection();
        }
    }

    async clearAgencyTag() {
        // console.log('clear agency tag', this.candidate.tags);
        const agencyTag = this.candidate.tags.find((t) => t.hash === '#agency');
        if (agencyTag) {
            this.candidate.tags = this.candidate.tags.filter((t) => t.hash !== '#agency');
            await this.candidateService.updateDbCandidate(this.candidate.id, {
                tags: this.candidate.tags
            });
            await this.candidateService
                .updateJobCandidate(this.candidate.id, this.job.id, { tags: this.candidate.tags })
                .catch((error) => console.error(error));
            this.updateCandidatesDataCollection();
        }
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }
}
