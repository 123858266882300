<mat-form-field appearance="outline" class="dd-full-width" [ngClass]="{ 'new-form-field': newStyle }">
    <mat-label>{{ placeholder }}</mat-label>
    <input
        #matInput
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="filterControl"
        (focus)="onfocus()"
        (blur)="onblur()"
    />
    <!-- <mat-icon matSuffix>expand_more</mat-icon> -->

    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelected($event.option)"
        [class]="'auto-dd-panel'"
    >
        <mat-option
            *ngFor="let data of filteredOptions | async"
            [value]="data"
            [disabled]="data.selected"
            [ngClass]="{
                'no-access': data.addAccessClass,
                'with-subtitle': data.text,
                'no-ellipsis': data && data.value && data.value.length ? data.value.indexOf('&') !== -1 : false
            }"
        >
            <ng-container *ngIf="data.questionnaire">
                <img
                    class="mat-option-img"
                    *ngIf="data.type === 'video-interview'"
                    src="/assets/images/video.svg"
                    width="13"
                    height="13.4"
                    alt=""
                />
                <img
                    class="mat-option-img"
                    *ngIf="data.type === 'questions'"
                    src="/assets/images/text.svg"
                    width="12"
                    height="9"
                    alt=""
                />
            </ng-container>
            {{ data.label ? data.label : data.value }}
            <div class="mat-subtitle" *ngIf="data.text">{{ data.text }}</div>
        </mat-option>
    </mat-autocomplete>
    <div class="clear-icon" (click)="onclear($event)" *ngIf="showClear">
        <img src="/assets/images/icons8-delete.svg" width="16" height="16" alt="" />
    </div>
</mat-form-field>
