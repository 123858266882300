import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';

import * as fromCountries from './countries.reducer';
import * as fromUser from './user.reducer';
import * as fromUsers from './users.reducer';
import * as fromGeneral from './general-information.reducer';

export interface RouterStateUrl {
    url: string;
    queryParams: Params;
    params: Params;
}

export interface State {
    routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
    user: fromUser.UserState;
    users: fromUsers.UsersState;
    countries: fromCountries.CountriesState;
    general: fromGeneral.StagesDataListState;
}

export const reducers: ActionReducerMap<State> = {
    routerReducer: fromRouter.routerReducer,
    user: fromUser.reducer,
    users: fromUsers.reducer,
    countries: fromCountries.reducer,
    general: fromGeneral.reducer
};

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('routerReducer');

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const { queryParams } = routerState.root;
        let state: ActivatedRouteSnapshot = routerState.root;
        while (state.firstChild) {
            state = state.firstChild;
        }
        const { params } = state;

        return { url, queryParams, params };
    }
}

export const LOGOUT = '[App] Logout';
export class Logout implements Action {
    readonly type = LOGOUT;
}
export function clearState(reducer) {
    return (state, action) => {
        if (action.type === LOGOUT) {
            state = undefined;
        }

        return reducer(state, action);
    };
}
export const metaReducers: Array<MetaReducer<any>> = !environment.production ? [clearState] : [clearState];
