import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { User } from '@app/core/models';
import { UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';

@Component({
    selector: 'app-employee-item-timeline',
    templateUrl: './employee-item-timeline.component.html',
    styleUrls: ['./employee-item-timeline.component.scss']
})
export class EmployeeItemTimelineComponent implements OnInit, OnChanges, OnDestroy {
    @Input() employeeId;
    user: User;
    users: User[] = [];
    usersSubscription: Subscription;
    audit: any[] = [];
    @Input() auditData: any[] = [];
    emailSent = false;

    constructor(
        private store: Store<fromStore.State>,
        private utilities: UtilitiesService,
        private employeeService: EmployeesService
    ) {}

    ngOnInit(): void {
        // console.log('[TIMELINE COMPONENT]', this.employee);
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            // this.loadAudit();
        });
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            if (this.users && this.users.length) {
                this.loadAudit();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {}

    loadAudit() {
        this.audit = this.auditData ? this.transformAudit(this.auditData) : this.transformAudit([]);
    }

    transformAudit(auditData: any[]) {
        const result = [];
        auditData.forEach((e) => {
            if (e.user_id) {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                }
            }

            const today = moment()
                .utc()
                .startOf('day')
                .valueOf();
            const timeinmilisec = today - e.created_at * 1000;
            const days = Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
            days > 14
                ? (e.created_at_rel = moment(e.created_at * 1000).format('DD MMMM YYYY [at] HH:mm'))
                : (e.created_at_rel =
                      this.utilities.fromNow(e.created_at * 1000) + moment(e.created_at * 1000).format('[ at] HH:mm'));
            result.push(e);
        });
        const sorted = result.sort((a: any, b: any) => b.created_at - a.created_at);
        return sorted;
    }

    onResendEmail(audit) {
        this.employeeService.resendNotification(this.employeeId, audit.workflow_id, {}).subscribe();
        this.emailSent = true;
        setTimeout(() => {
            this.emailSent = false;
        }, 2000);
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }
}
