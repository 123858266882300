import { Injectable } from '@angular/core';
import { DocumentTypeService } from '@app/core/services/document-type.service';
import { TenantService } from '@app/core/services/tenant.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as generalDataActions from '../actions/general-information.action';

@Injectable()
export class GeneralEffects {
    constructor(
        private actions$: Actions,
        private documentTypeService: DocumentTypeService,
        private tenantService: TenantService
    ) {}

    @Effect() loadGeneralData$: Observable<Action> = this.actions$.pipe(
        ofType(generalDataActions.LOAD_GENERAL_DATA),
        switchMap(() => {
            return forkJoin([
                this.documentTypeService.getDocuments(),
                this.tenantService.getTenantBusinessUnits()
            ]).pipe(
                map((resp: any) => new generalDataActions.LoadGeneralDataSuccess(resp)),
                catchError((error) => of(new generalDataActions.LoadGeneralDataFail(error)))
            );
        })
    );
}
