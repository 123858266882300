<div class="inner">
    <div class="score-scale-block" *ngIf="activeColor">
        <div class="value">{{ value }}</div>
        <div class="measurement-scale">
            <div
                class="drop"
                [ngStyle]="{
                    'background-color': activeColor.color,
                    'border-color': activeColor.color,
                    left: leftPosition
                }"
            >
                <span
                    [ngStyle]="{
                        'border-top': '16px solid' + activeColor.color + ''
                    }"
                ></span>
            </div>
            <div class="scale" *ngFor="let item of scoreRange">
                <span [ngStyle]="{ 'background-color': item.color }"></span>
                <div class="text">
                    {{ item.text }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!activeColor" class="not-enough-block">
        Not enough data
    </div>
</div>
