import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EmployeesService } from '@app/core/services/employees.service';

@Component({
    selector: 'app-document-send-to-provider',
    templateUrl: './document-send-to-provider.component.html',
    styleUrls: ['./document-send-to-provider.component.scss']
})
export class DocumentSendToProviderComponent implements OnInit {
    contentLoading = false;
    form: FormGroup;
    @Input() data;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() updated: EventEmitter<any> = new EventEmitter();
    constructor(private fb: FormBuilder, private employeeService: EmployeesService) {}

    ngOnInit(): void {
        console.log(this.data);
        this.form = this.fb.group({
            send_email: [true],
            attachments: this.fb.array([])
        });
        this.data.documents.forEach((d) => {
            // console.log('d', d);
            if (d.action === 'file' || !d.action) {
                (this.form.get('attachments') as FormArray).push(
                    this.fb.group({
                        checked: false,
                        title: d.document_type,
                        link: d.link,
                        name: d.name
                    })
                );
            }
        });
    }

    onClose() {
        this.close.emit();
    }

    onApprove() {
        // console.log(this.form.value);
        const attachments = this.form.value.attachments
            .filter((a) => a.checked)
            .map((a) => {
                return {
                    link: a.link,
                    title: a.title,
                    name: a.name
                };
            });
        if (this.form.value.send_email) {
            this.updated.emit({ send_email: true, attachments, document: this.data.document });
        } else {
            this.updated.emit({ send_email: false });
        }
    }
}
