import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { UtilitiesService } from '@app/core/services';
import { WorkflowService } from '@app/core/services/workflow.service';

@Component({
    selector: 'app-upload-task',
    templateUrl: './upload-task.component.html',
    styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit, OnDestroy {
    @Input() file: File;
    @Input() path: string;
    @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
    @Output() fileUploadedError: EventEmitter<any> = new EventEmitter();

    task: AngularFireUploadTask;

    percentage = 0;
    snapshot: Observable<any>;
    downloadURL: string;
    uploadStarted = false;
    uploadFinished = false;
    subsription: Subscription;

    constructor(private utilities: UtilitiesService, private workflowService: WorkflowService) {}

    ngOnInit() {
        this.startUpload();
    }

    startUpload() {
        this.uploadFile(this.file);
    }

    isActive(snapshot) {
        return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    }

    uploadFile(item) {
        this.utilities
            .readFile(item)
            .then((fileValue: any) => {
                this.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    this.percentage = this.percentage + 1 < 100 ? this.percentage + 1 : this.percentage;
                }, 400);
                const formData: { document: any; folder?: string } = {
                    document: fileValue,
                    folder: this.path
                };
                this.workflowService.uploadFile(formData).subscribe(
                    (response: any) => {
                        console.log('📬 Uploaded:', response);
                        this.uploadFinished = true;
                        this.percentage = 100;
                        this.fileUploaded.emit({
                            url: response.doc.url,
                            name: this.file.name,
                            size: this.file.size
                        });
                        clearInterval(uploadProgressInterval);
                    },
                    (response) => {
                        console.error(response);
                    }
                );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
                this.uploadFinished = false;
                this.percentage = 0;
                this.fileUploadedError.emit();
            });
    }

    ngOnDestroy() {}
}
