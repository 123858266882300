import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import moment from 'moment';
import { Job } from './../../../../../../core/models/job';

@Component({
    selector: 'app-candidate-overview',
    templateUrl: './candidate-overview.component.html',
    styleUrls: ['./candidate-overview.component.scss']
})
export class CandidateOverviewComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() offerAssessment;
    @Input() candidate;
    @Input() resumeThreshold;
    @Input() jobId;
    @Input() job: Job;
    @Input() doNotMatch;
    @Input() isDeleted;
    @Input() disableFiles;
    @Input() documents;
    @Input() documentsLoading;
    @Input() documentsTypeOptions;
    @Output() uploadFile: EventEmitter<any> = new EventEmitter();
    @ViewChild('uploadDocument') uploadDocument: ElementRef;
    @ViewChild('overviewDiv') overviewDiv: ElementRef;
    @ViewChild('documentsDiv') documentsDiv: ElementRef;
    @Output() onAddCandidateToJob: EventEmitter<any> = new EventEmitter();
    @Output() onSilentDecline: EventEmitter<any> = new EventEmitter();

    today = new Date();
    uploadQueue: any[] = [];
    supportedFileTypes: string[];
    uploadError: string;
    jobMatchText = null;
    showBlockMatch = false;
    showUploaderOptions = false;
    uploadValidationRules = {
        resume: 5,
        letter: 5,
        portfolio: 20,
        id: 5,
        other: 20
    };
    selectedDocumentType = null;
    resumeUploadDisabled = false;
    viewInitialized = false;
    documentPreviewModal = {
        show: false,
        file: null
    };
    candidateDocumentsTypeOptions: any[] = [];
    contentLoading = false;

    @ViewChild('uploadOptionsDropUp') uploadOptionsDropUp: ElementRef;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.uploadOptionsDropUp && !this.uploadOptionsDropUp.nativeElement.children[0].contains(event.target)) {
            this.showUploaderOptions = false;
        }
    }

    ngAfterViewInit() {
        this.viewInitialized = true;
    }

    constructor(
        private candidateService: CandidateService,
        private utilitiesService: UtilitiesService,
        private employeeService: EmployeesService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
    }

    ngOnInit(): void {
        console.log('👩🏻‍🚀 Candidate:', this.candidate);
        if (this.jobId) {
            this.candidateDocumentsTypeOptions = this.documentsTypeOptions
                .slice(0)
                .filter((d) => d.type === 'recruitment');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.candidate || changes.resumeThreshold) {
            this.showBlockMatch = false;
            this.countJobMatch();
        }
        if (changes.documents) {
            this.resumeUploadDisabled = !!changes.documents.currentValue.find(
                (d) => d.documentType === 'Resume' || d.documentType === 'resume'
            );
            if (this.resumeUploadDisabled) {
                const index = this.candidateDocumentsTypeOptions.findIndex((d) => d.value.toLowerCase() === 'resume');
                this.candidateDocumentsTypeOptions.splice(index, 1);
            }
            this.route.queryParamMap.subscribe(() => {
                const action = this.route.snapshot.queryParamMap.get('action');
                const documentId = this.route.snapshot.queryParamMap.get('documentId');
                if (action === 'open-document') {
                    const file = this.documents.find((d) => d.id === documentId);
                    if (file) {
                        this.documentPreviewModal = {
                            show: true,
                            file
                        };
                        this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
                    }
                }
            });
        }
    }

    onDropFromFiles(event: any) {
        const file = event.dragData;
        this.uploadQueue.push({
            file,
            uploadStarted: false,
            uploadFinished: false,
            progress: 0,
            success: false,
            text: file.file_name
        });
        this.processQueue();
    }

    onDropFile(files) {
        console.log('📥 onDropFile', files);
        const filesDropped = files.target ? files.target.files : files;
        this.processFiles(filesDropped);
    }

    onFileUpload(fileEvent: any) {
        const file = fileEvent.target.files[0];
        console.log(this.selectedDocumentType, file.type);
        if (this.isFileSizeValid(file.size)) {
            this.uploadError = null;
            console.log('File size is valid');
        } else {
            this.uploadError = 'File size is too big';
            setTimeout(() => (this.uploadError = null), 10000);
        }

        if (this.validateFileType(file, this.supportedFileTypes)) {
            this.uploadQueue.push({
                file,
                uploadStarted: false,
                uploadFinished: false,
                progress: 0,
                success: false,
                text: file.name,
                type: this.selectedDocumentType,
                extension: this.getExtension(file.name)
            });
            this.processQueue();
        } else {
            this.uploadError = 'Only supported formats are: pdf, doc, docx, rtf, odt';
            setTimeout(() => (this.uploadError = null), 10000);
        }
    }

    isFileSizeValid(size: number) {
        const maxSize = (this.uploadValidationRules[this.selectedDocumentType] || 5) * 1024 * 1024;
        return size <= maxSize ? true : false;
    }

    processQueue() {
        this.uploadQueue.forEach((item) => {
            if (!item.uploadStarted && !item.uploadFinished) {
                this.uploadFile.emit(item);

                const itemInterval = setInterval(() => {
                    if (item.uploadFinished) {
                        const itemIndex = this.uploadQueue.findIndex((ui) => ui.id === item.id);
                        if (itemIndex !== -1) {
                            this.uploadQueue.splice(itemIndex, 1);
                        }
                        clearInterval(itemInterval);
                    }
                });
            }
        });
    }

    processFiles(files) {
        for (let i = 0, file; (file = files[i]); i++) {
            if (this.validateFileType(file, this.supportedFileTypes)) {
                // ADD TO THE QUEUE
                // console.log('We need to upload that file 🎈');
                this.uploadQueue.push({
                    file,
                    type: this.selectedDocumentType,
                    uploadStarted: false,
                    uploadFinished: false,
                    progress: 0,
                    success: false,
                    text: file.name
                });
            } else {
                this.uploadError = 'Only supported formats are: pdf, doc, docx, rtf, odt';
                setTimeout(() => (this.uploadError = null), 10000);
            }
        }
        this.processQueue();
    }

    getExtension(filename) {
        const allowedExtensions = ['pdf'];
        const newFilename = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        if (allowedExtensions.indexOf(newFilename) !== -1) {
            return newFilename;
        } else {
            return 'docx';
        }
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    countJobMatch() {
        if (!this.candidate.matching) {
            this.candidate.matching = {};
        }
        if (
            this.resumeThreshold === undefined ||
            !this.candidate.matching ||
            typeof this.candidate.matching[this.jobId] == 'undefined'
        ) {
            this.jobMatchText = null;
            return;
        }
        let averageMin = this.resumeThreshold - this.resumeThreshold / 3;
        let averageMax = this.resumeThreshold;
        let value = 0;
        if (this.candidate.matching && this.candidate.matching[this.jobId]) {
            value = this.candidate.matching[this.jobId] || 0;
        }
        if (value >= averageMax || this.resumeThreshold === 0) {
            this.jobMatchText = 'Good';
        } else if (value < averageMax && value >= averageMin) {
            this.jobMatchText = 'Average';
        } else {
            this.jobMatchText = 'Poor';
        }
        this.showBlockMatch = true;
    }

    onAddDocumentBtnClick() {
        this.showUploaderOptions = !this.showUploaderOptions;
    }

    onUploadItemClick(type) {
        // console.log('onUploadItemClick', type);
        this.selectedDocumentType = type;
        this.showUploaderOptions = false;
    }

    convertSize(size: number) {
        const kb = size / 1000;
        const mb = size / 1000 / 1000;

        return mb > 1 ? `${mb.toFixed(2)} MB` : `${Math.round(kb)} KB`;
    }

    onRemoveClick() {
        this.onSilentDecline.next();
    }

    onAddToJob() {
        this.onAddCandidateToJob.next(this.candidate);
    }

    isTopBlock() {
        if (this.viewInitialized && this.overviewDiv && this.documentsDiv) {
            const parentBlockTop = this.overviewDiv.nativeElement.getBoundingClientRect().top;
            const documentsBlockTop = this.documentsDiv.nativeElement.getBoundingClientRect().top;
            const distance = documentsBlockTop - parentBlockTop;
            return distance <= 58 ? true : false;
        } else {
            return false;
        }
    }

    getDocType(type) {
        const map = {
            resume: 'Resume',
            letter: 'Cover Letter',
            portfolio: 'Portfolio',
            id: 'ID / Passport',
            other: 'Other'
        };
        return map[type];
    }

    onDocumentPreview(file) {
        console.log(file);
        if (file.extension === 'docx' && (file.id || file.documentType)) {
            this.contentLoading = true;
            const id = file.id ? file.id : file.documentType;
            this.utilitiesService.convertToPDF(this.candidate.id, file.link, id).subscribe((response: any) => {
                console.log('convertToPDF response', response);
                file.link = response.url;
                file.extension = 'pdf';
                this.documentPreviewModal = {
                    show: true,
                    file
                };
                this.contentLoading = false;
            });
        } else if (file.id || file.documentType) {
            this.documentPreviewModal = {
                show: true,
                file
            };
        }
    }

    onDownloadDocument(event, file) {
        event.stopPropagation();
        let name = '';
        if (this.candidate.employee_id) {
            name = `${this.candidate.employee_id}_`;
        }
        let documentType = this.documentsTypeOptions.find((d) => d.value === file.documentType);
        const url = file.link;
        name += `${this.candidate.first_name}_${this.candidate.last_name}_${
            documentType ? documentType.slug : file.name
        }_${moment.unix(this.candidate.created_at).format('YYYYMMDD')}`;
        this.employeeService.downloadFile(url, name).subscribe();
    }

    getDocName(type: string) {
        // console.log(type);
        if (type === 'resume') {
            type = 'Resume';
        }
        if (type === 'criminal-check-report') {
            type = 'Criminal Check';
        }
        if (type === 'criminal-check-saps-report') {
            type = 'SAPS Report';
        }

        return type;
    }
}
