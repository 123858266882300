import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, retry } from 'rxjs/operators';
import { GraphCredentials } from '../models/graph-credentials';
import { User } from './../models/user';

@Injectable({
    providedIn: 'root'
})
export class MsService {
    _isAuthenticated: BehaviorSubject<boolean>;
    GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';

    scopes = ['user.read', 'openid', 'profile', 'Calendars.ReadWrite', 'Contacts.Read', 'OnlineMeetings.ReadWrite'];
    config = {
        scopes: this.scopes
    };
    extraScopes = ['Calendars.ReadWrite', 'Contacts.ReadWrite'];
    extraConfig = {
        extraScopesToConsent: this.extraScopes
    };

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Prefer: 'outlook.timezone="South Africa Standard Time"'
        })
    };

    isAuthenticated = false;
    newToastrAllowed = true;

    constructor(
        private msal: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private http: HttpClient,
        private cookie: CookieService,
        private toastr: ToastrService,
        private db: AngularFirestore
    ) {
        this._isAuthenticated = new BehaviorSubject(false);
        if (this.msal.instance.getAllAccounts().length) {
            // console.log('MSAL account:', this.msal.getAccount());
            this.setAuthenticated(true);
        }

        this.subscriptions();
    }

    subscriptions() {
        this.msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                console.log('MSAL Login Success:', result);
            });

        // this.msalBroadcastService.inProgress$
        //     .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        //     .subscribe(() => {

        //     });
    }

    getAuthenticated() {
        return this.isAuthenticated;
    }

    setAuthenticated(value) {
        this.isAuthenticated = value;
        this._isAuthenticated.next(value);
    }

    public get $isAuthenticated(): Observable<boolean> {
        return this._isAuthenticated.asObservable();
    }

    authenticate(user: User) {
        console.log('[MsService] authenticate =>');
        if (this.msal.instance.getAllAccounts().length) {
            console.log('[MsService] authenticate => getting token silently', this.msal.instance.getAllAccounts());
            this.getTokenPopup(user);
        } else {
            console.log('[MsService] authenticate => show login popup');
            this.login(user);
        }
    }

    login(user: User) {
        console.log('[MsService] login');
        const isIE =
            window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

        if (isIE) {
            this.msal.loginRedirect(this.config);
        } else {
            this.msal.loginPopup(this.config).subscribe(
                (result: AuthenticationResult) => {
                    console.log('[MSAL] Authentication result:', result);
                    this.saveMsCredentialsForUser(user, result);
                },
                (errorResponse) => {
                    console.error('[MSAL] Authentication with popup failed', errorResponse);
                }
            );
        }
    }

    getTokenPopup(user: User) {
        return this.msal.acquireTokenSilent(this.config).subscribe(
            (tokenResponse) => {
                console.log('[MsService] [getTokenPopup] silently:', tokenResponse);
                if (tokenResponse) {
                    this.setAuthenticated(true);
                    return tokenResponse;
                } else {
                    this.msal.acquireTokenPopup(this.config).subscribe(
                        (tokenResponse) => {
                            console.log('[MsService] Getting token from popup succeeded:', tokenResponse);
                            if (tokenResponse) {
                                this.setAuthenticated(true);
                                this.saveMsCredentialsForUser(user, tokenResponse);
                                return tokenResponse;
                            } else {
                                this.setAuthenticated(false);
                                this.login(user);
                            }
                        },
                        (errorResponse) => {
                            console.error('[MsService] Getting token from popup failed:', errorResponse);
                            this.setAuthenticated(false);
                            this.login(user);
                        }
                    );
                }
            },
            (errorResponse) => {
                console.error('[MsService] Silent token acquisition failed:');
                this.msal.acquireTokenPopup(this.config).subscribe(
                    (tokenResponse) => {
                        if (tokenResponse) {
                            console.log('[MsService] Getting token from popup succeeded:', tokenResponse);
                            this.setAuthenticated(true);
                            this.saveMsCredentialsForUser(user, tokenResponse);
                            return tokenResponse;
                        } else {
                            this.setAuthenticated(false);
                            this.login(user);
                        }
                    },
                    (errorResponse) => {
                        console.error('[MsService] Getting token from popup failed:', errorResponse);
                        this.setAuthenticated(false);
                        this.login(user);
                    }
                );
            }
        );
    }

    logout() {
        console.log('MSAL-logout');
        this.msal.logoutPopup();
    }

    getProfile() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me', this.httpOptions).pipe(retry(1));
    }

    getCalendars() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me/calendars', this.httpOptions);
    }

    getCalendar() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me/calendar', this.httpOptions);
    }

    getSchedule(data: { schedules: string[]; startTime: any; endTime: any; availabilityViewInterval: number }) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/calendar/getSchedule', data, this.httpOptions);
    }

    getEvents(id: string) {
        return this.http.get(this.GRAPH_ENDPOINT + `/groups/${id}/events`, this.httpOptions);
    }

    createEvent(data: any) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/events', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Prefer: 'IdType="ImmutableId"'
            })
        });
    }

    createOnlineMeeting(data: any) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/onlineMeetings', data);
    }

    getEvent(calendarId: string, eventId: string) {
        return this.http.get(this.GRAPH_ENDPOINT + `/groups/${calendarId}/events/${eventId}`, this.httpOptions);
    }

    updateEvent(eventId: string, data) {
        return this.http.patch(this.GRAPH_ENDPOINT + `/me/events/${eventId}`, data).toPromise();
    }

    deleteEvent(eventId: string) {
        return this.http.delete(this.GRAPH_ENDPOINT + `/me/events/${eventId}`).toPromise();
    }

    findMeetingTimes(data: {
        attendees?: any[];
        timeConstraint?: any;
        locationConstraint?: any;
        isOrganizerOptional?: any;
        meetingDuration?: string;
        returnSuggestionReasons?: any;
        minimumAttendeePercentage?: any;
    }) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/findMeetingTimes', data, this.httpOptions);
    }

    setMsalTokenInCookies(payload: any) {
        // console.log('setMsalTokenInCookies', payload);
        this.cookie.set('msal_access_token', payload.accessToken || '', 12, '/');
    }

    isSafari() {
        return (
            navigator &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1
        );
    }

    async saveMsCredentialsForUser(user: User, tokenData) {
        console.log('saveMsCredentialsForUser', user.id, user.email);
        // 1. Prepare data
        const data = this.prepareCredentials(tokenData);
        // 2. Refresh token
        const newData: any = await this.getRefreshedToken(data);
        if (newData && newData.refresh_token) {
            data.refreshToken = newData.refresh_token;
        }
        data.updatedAt = Math.floor(Date.now() / 1000);
        console.log({ ...data });
        // 3. Set credentials data
        await this.db
            .collection(`ms-graph-credentials`)
            .doc(user.id)
            .set(data);
    }

    getRefreshedToken(credentials: GraphCredentials) {
        const url = 'https://login.microsoftonline.com/common/oauth2/v2.0/token';
        let body = new URLSearchParams();
        body.set('client_id', credentials.clientId);
        body.set('redirect_uri', credentials.redirectUri);
        body.set('scope', credentials.scopes.join(' '));
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', credentials.refreshToken);
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.http.post(url, body.toString(), options).toPromise();
    }

    prepareCredentials(tokenData): GraphCredentials {
        const homeAccountId = tokenData.account.homeAccountId;
        const appId = environment.msalClientId;
        const tenantId = tokenData.tenantId;
        const scopes = tokenData.scopes;
        const refreshTokenStorageId = `${homeAccountId}-login.windows.net-refreshtoken-${appId}----`;
        const refreshTokenData = JSON.parse(localStorage.getItem(refreshTokenStorageId) || '{}');
        const username = tokenData.account.username;
        return {
            username,
            clientId: appId,
            homeAccountId,
            tenantId,
            scopes,
            refreshToken: refreshTokenData?.secret || null,
            redirectUri: environment.msalRedirectUrl
        };
    }
}
