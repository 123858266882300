import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreferencesService, UtilitiesService } from '@app/core/services';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Message } from 'primeng';
import { TenantService } from './../../../../../../../core/services/tenant.service';
import { User } from '@app/core/models';
import { currencyOptions } from '@app/core/constants/options.constants';

@Component({
    selector: 'app-new-location',
    templateUrl: './new-location.component.html',
    styleUrls: ['./new-location.component.scss']
})
export class NewLocationComponent implements OnInit {
    contentLoading = false;
    @Input() timeZones;
    @Input() action;
    @Input() user: User;
    @Input() locations: any[];
    @Input() users: User[];
    refresh = false;
    msgs: Message[] = [];
    dropdownValueUpdated = false;
    forbidEdit = false;
    oldLocationName = null;
    @Input() set updatedLocation(item) {
        if (item) {
            this.dropdownValueUpdated = true;
            setTimeout(() => {
                this.dropdownValueUpdated = false;
            }, 100);
            this.oldLocationName = item.location;
            const formGroup: any = {
                name: item.name,
                location: item.location,
                location_short: item.location_short,
                primary_location: item.primary_location,
                currency: item.currency,
                timezone: item.timezone,
                id: item.id,
                address: item.address,
                coordinates: item.coordinates,
                jobs: item.jobs || 0
            };
            if (this.user.tenant_id === 'DR5SALXUV' || this.user.tenant_id === 'JV8E2Q5IO') {
                formGroup.hiring_executive = item.hiring_executive;
                formGroup.comp_number = item.comp_number;
                formGroup.moto_health_code = item.moto_health_code;
                formGroup.provident_fund_code = item.provident_fund_code;
                formGroup.malcor_code = item.malcor_code;
            }
            this.locationForm.patchValue(formGroup);
            this.showFullAddress = true;
        } else {
            this.showFullAddress = false;
        }
        this.forbidEdit = item && item.active ? true : false;
    }
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() createLocation: EventEmitter<any> = new EventEmitter();
    @Output() updateLocation: EventEmitter<any> = new EventEmitter();
    @Output() deleteLocation: EventEmitter<any> = new EventEmitter();
    locationForm: FormGroup;
    place: any;
    inputAddress: string;
    locationOptions: any;
    currencyOptions: any = [];
    countriesOptions: any = [];
    timezoneOptions: any = [];
    showFullAddress = false;
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    @ViewChild('locationInputRef') locationInputRef: ElementRef;
    contactsOptions: any[] = [];
    constructor(
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private preferencesService: PreferencesService,
        private utilities: UtilitiesService,
        private firestore: AngularFirestore,
        private tenantService: TenantService
    ) {
        this.tenantService.init();
        this.locationOptions = {
            bounds: null,
            componentRestrictions: {
                country: []
            }
        };
    }

    ngOnInit(): void {
        const formGroup: any = {
            name: ['', Validators.required],
            location: ['', Validators.required],
            location_short: [''],
            primary_location: [false],
            currency: ['ZAR', Validators.required],
            timezone: ['', Validators.required],
            id: [''],
            address: this.fb.group({
                streetAddress: ['', Validators.required],
                addressLocality: ['', Validators.required],
                addressDistrict: [''],
                addressRegion: ['', Validators.required],
                addressRegionShort: [''],
                addressCountry: ['', Validators.required],
                postalCode: ['', Validators.required],
                streetNumber: [''],
                streetName: ['']
            }),
            coordinates: this.fb.group({
                lat: [''],
                lng: ['']
            }),
            jobs: [0]
        };
        if (this.user.tenant_id === 'DR5SALXUV' || this.user.tenant_id === 'JV8E2Q5IO') {
            formGroup.hiring_executive = this.fb.control('', Validators.required);
            formGroup.comp_number = this.fb.control('', Validators.required);
            formGroup.moto_health_code = this.fb.control('', Validators.required);
            formGroup.provident_fund_code = this.fb.control('', Validators.required);
            formGroup.malcor_code = this.fb.control('', Validators.required);
        }
        this.locationForm = this.fb.group(formGroup);

        this.inputAddress = '';
        this.currencyOptions = currencyOptions;

        this.timezoneOptions = this.timeZones.map((t) => {
            return { value: t.timezone, label: t.timezone };
        });

        this.utilities.getCountries().subscribe((countries: Array<{ name: string; code: string }>) => {
            countries.forEach((c) => {
                this.countriesOptions.push({ label: c.name, value: c.code });
            });
        });
        this.contactsOptions = this.users
            .filter((u) => u.role === 'employee')
            .sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            })
            .map((u) => {
                return {
                    label: `${u.first_name} ${u.last_name}`,
                    value: u.id,
                    email: u.email
                };
            });
    }

    closeNewLocationForm() {
        this.close.emit();
        this.locationForm.reset();
        this.dropdownValueUpdated = true;
        setTimeout(() => {
            this.dropdownValueUpdated = false;
        }, 100);
    }

    onUpdateLocation() {
        const form = this.locationForm;
        if (!form.valid) {
            this.formHelper.markFormGroupTouched(this.locationForm);
            return false;
        }
        this.contentLoading = true;
        form.value.updated_at = Math.floor(Date.now() / 1000);
        const item = form.value;
        console.log(this.user, 'Update', form.value, this.oldLocationName);
        this.tenantService
            .updateLocation(item.id, item)
            .then(() => {
                this.contentLoading = false;
                this.updateLocation.emit(form.value);
                if (form.value.location !== this.oldLocationName) {
                    this.tenantService.updateJobLocation(form.value.location, this.oldLocationName);
                    this.tenantService.addAuditLocation(item.id, {
                        updated_at: Math.floor(Date.now() / 1000).toString(),
                        data: form.value
                    });
                }
                form.reset();
                this.close.emit();
            })
            .catch((error) => console.error(error));
    }

    onSaveLocation() {
        const form = this.locationForm;
        if (!form.valid) {
            this.formHelper.markFormGroupTouched(this.locationForm);
            return false;
        }
        if (this.locations && this.locations.length) {
            const loc = this.locations.find((l) => l.location === form.value.location);
            if (loc) {
                this.showFullAddress = false;
                setTimeout(() => {
                    form.get('location').setErrors({ exists: true });
                    form.get('location').markAsTouched();
                }, 300);
                return false;
            }
        }
        form.value.id = this.utilities.generateUID(10);
        form.value.user_id = this.user.id;
        form.value.created_at = Math.floor(Date.now() / 1000);
        this.contentLoading = true;
        console.log(this.user, 'Save', form.value, form);
        this.tenantService
            .saveLocation(form.value.id, form.value)
            .then(() => {
                this.tenantService.locations().subscribe((response: any[]) => {
                    this.createLocation.emit(form.value);
                    const newLoc = response.find((r) => r.id === form.value.id);
                    if (newLoc) {
                        form.value.location_short = newLoc.location_short;
                    }

                    form.reset();
                    this.close.emit();
                    this.contentLoading = false;
                });
            })
            .catch((error) => console.error(error));
    }

    onLocationChange(address) {
        console.log(address);
        this.place = address;
        let location_short = '';
        const place = {
            streetAddress: '',
            addressLocality: '',
            addressDistrict: '',
            addressRegion: '',
            addressRegionShort: '',
            postalCode: '',
            addressCountry: '',
            streetNumber: '',
            streetName: ''
        };
        if (address && address.address_components) {
            let components = address.address_components.filter(
                (c) => c.types.includes('locality') || c.types.includes('postal_town') || c.types.includes('country')
            );
            if (
                components.find((c) => c.types.includes('locality')) &&
                components.find((c) => c.types.includes('postal_town'))
            ) {
                components = components.filter((c) => c.types.includes('postal_town') || c.types.includes('country'));
            }
            location_short = components.length ? components.map((c) => c.long_name).join(', ') : '';
        }
        if (address && address.address_components) {
            const components = address && address.address_components;
            const streetNumber = components.find((c) => c.types.includes('street_number'));
            const route = components.find((c) => c.types.includes('route'));
            const streetAddress = components.find((c) => c.types.includes('street_address'));
            const addressLocality = components.find(
                (c) => c.types.includes('locality') || c.types.includes('postal_town')
            );
            const addressRegion = components.find((c) => c.types.includes('administrative_area_level_1'));
            const addressDistrict = components.find((c) => c.types.includes('sublocality_level_1'));
            const postalCode = components.find((c) => c.types.includes('postal_code'));
            const addressCountry = components.find((c) => c.types.includes('country'));

            place.streetAddress = streetAddress
                ? streetAddress.longName
                : `${streetNumber ? streetNumber.short_name : ''} ${route ? route.short_name : ''}`;
            place.addressLocality = addressLocality ? addressLocality.short_name : '';
            place.addressRegion = addressRegion ? addressRegion.long_name : '';
            place.addressRegionShort = addressRegion ? addressRegion.short_name : '';
            place.addressDistrict = addressDistrict ? addressDistrict.long_name : '';
            place.postalCode = postalCode ? postalCode.short_name : '';
            place.addressCountry = addressCountry ? addressCountry.short_name : '';
            place.streetNumber = streetNumber ? streetNumber.long_name : '';
            place.streetName = streetAddress ? streetAddress.longName : `${route ? route.short_name : ''}`;
        }
        this.locationForm.patchValue({
            location: address && address.formatted_address ? this.locationInputRef.nativeElement.value : '',
            location_short,
            address: place,
            coordinates: {
                lng: address.geometry.location.lng(),
                lat: address.geometry.location.lat()
            }
        });
        this.showFullAddress = true;
    }

    handleAddressTextChange(event) {
        if (!this.place) {
            this.place = null;
            this.locationForm.patchValue({ location: null });
            this.inputAddress = '';
            this.placesRef.reset();
            this.locationInputRef.nativeElement.value = '';
        }
    }

    onResetLocation() {
        this.locationForm.get('address').reset();
        this.locationForm.get('location').reset();
        this.dropdownValueUpdated = true;
        this.showFullAddress = false;
        setTimeout(() => {
            this.dropdownValueUpdated = false;
        }, 100);
    }

    onDeleteLocation() {
        this.deleteLocation.emit(this.locationForm.value);
    }
}
