import { Component, Input, OnInit } from '@angular/core';
import { SdkJob } from '@app/core/models';
import { CandidateService, JobService, OffersService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { isEqual } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-candidate-offer-block',
    templateUrl: './candidate-offer-block.component.html',
    styleUrls: ['./candidate-offer-block.component.scss']
})
export class CandidateOfferBlockComponent implements OnInit {
    @Input() job;
    @Input() candidate;
    @Input() user;
    contentLoading = false;
    offer;
    offerAssessment;
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflows: any[];
        delete_workflow_id?: null;
    } = {
        show: false,
        data: null,
        workflows: []
    };
    declineByCandidate = false;
    someoneSigned = false;
    retractAvailable = true;
    reOffer = {
        available: false,
        workflow_id: null
    };
    candidateSigned = false;
    constructor(
        private candidateService: CandidateService,
        private offerService: OffersService,
        private workflowService: WorkflowService,
        private employeeService: EmployeesService,
        private toaster: ToastrService,
        private jobService: JobService,
        private utilities: UtilitiesService
    ) {}

    async ngOnInit() {
        const job_details = await this.employeeService.getJobDetails(this.candidate.id);
        this.candidate.job_details = job_details;
        if (this.job['offer_signs']) {
            this.offer = this.job['offer_signs'][this.candidate.id];
            const currentSigner = this.offer.find((o) => !o.signed);
            this.someoneSigned =
                this.offer.some((o) => o.signed) || (currentSigner && currentSigner.label === 'Candidate');
            if (currentSigner && currentSigner.label === 'Candidate') {
                this.retractAvailable = false;
            }
            if (
                this.offer.length > 1 &&
                currentSigner &&
                currentSigner.label === 'Candidate' &&
                this.candidate.workflows &&
                this.candidate.workflows.indexOf(currentSigner.workflow_id) > -1
            ) {
                this.reOffer = {
                    available: true,
                    workflow_id: currentSigner.workflow_id
                };
            }
        }
        this.offerAssessment = this.candidate.assignments.find((a) => {
            return a.stageId === 'hired';
        });

        // this.candidateSigned = assignment.offer_accepted ? assignment.offer_accepted : false;
        // console.log(
        //     this.job,
        //     this.candidate,
        //     this.candidate.assignments,
        //     this.offer,
        //     this.offerAssessment,
        //     this.someoneSigned
        // );
    }

    async onEditOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        if (
            job &&
            job.offer_signs &&
            job.offer_signs[this.candidate.id] &&
            job.offer_signs[this.candidate.id].some((o) => o.signed)
        ) {
            this.toaster.error('Contract has just signed');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
    }

    async onReOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        if (
            job &&
            job.offer_signs &&
            job.offer_signs[this.candidate.id] &&
            job.offer_signs[this.candidate.id].every((o) => o.signed)
        ) {
            this.toaster.error('Contract has just signed');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows,
            delete_workflow_id: this.reOffer.workflow_id
        };
        this.reOffer = {
            available: false,
            workflow_id: null
        };
    }

    async onRetractOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        const currentSigner = this.job['offer_signs'][this.candidate.id].find((o) => !o.signed);
        if (
            (currentSigner && currentSigner.label === 'Candidate') ||
            (job &&
                job.offer_signs &&
                job.offer_signs[this.candidate.id] &&
                job.offer_signs[this.candidate.id].every((o) => o.signed))
        ) {
            this.toaster.error('Could you please refresh page.');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
    }

    // async onRetractOffer() {
    //     const equal = await this.refreshOfferAssessment();
    //     if (!equal) {
    //         this.toaster.error('Could you please refresh page');
    //     } else {
    //         const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
    //         if (
    //             job &&
    //             job.offer_signs &&
    //             job.offer_signs[this.candidate.id] &&
    //             job.offer_signs[this.candidate.id].every((o) => o.signed)
    //         ) {
    //             this.toaster.error('Contract has just signed');
    //             return;
    //         }
    //         this.contentLoading = true;
    //         await this.offerService.retractOffer(this.job.id, this.candidate.id, this.offerAssessment);
    //         this.contentLoading = false;
    //         this.offerAssessment.retracted = true;
    //         const data = {
    //             stageClass: 'offer-retracted'
    //         };
    //         await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
    //         this.candidateService.updateCandidate(this.candidate.id, data);

    //         const auditRecord = {
    //             type: 'offer-retracted',
    //             job_id: this.job.id,
    //             option: this.offerAssessment.option || '',
    //             created_at: new Date().getTime(),
    //             user_id: this.user.id || ''
    //         };
    //         this.candidateService
    //             .addToAudit(this.job.id, this.candidate.id, auditRecord)
    //             .catch((error) => console.error(error));
    //     }
    // }

    async onCandidateDeclineOffer() {
        const equal = await this.refreshOfferAssessment();
        if (!equal) {
            this.toaster.error('Could you please refresh page');
        } else {
            // const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
            // if (
            //     job &&
            //     job.offer_signs &&
            //     job.offer_signs[this.candidate.id] &&
            //     job.offer_signs[this.candidate.id].some((o) => o.signed)
            // ) {
            //     this.toaster.error('Contract has just signed');
            //     return;
            // }
            this.contentLoading = true;
            await this.offerService.declineOffer(this.candidate.id, this.offerAssessment.id);
            this.contentLoading = false;
            this.offerAssessment.offer_accepted = false;
            this.offerAssessment.completed = true;
            const data = {
                stageClass: 'offer_declined'
            };
            await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
            this.candidateService.updateCandidate(this.candidate.id, data);

            const auditRecord = {
                type: 'delete',
                deleted_by: 'user',
                user_id: this.user.id || '',
                created_at: Math.floor(Date.now() / 1000),
                reason: 'Candidate decline offer',
                decline_email_sent: false
            };
            this.candidateService
                .addToAudit(this.job.id, this.candidate.id, auditRecord)
                .catch((error) => console.error(error));
        }
    }

    onResendOffer() {
        this.contentLoading = true;
        this.candidateService.resendOfferNotification(this.job.id, this.candidate.id).subscribe((response) => {
            console.log('Success', response);
            this.contentLoading = false;
        });
    }

    async onSentOffer(event) {
        console.log('onSentOffer', event, this.acceptedOfferModal);
        this.acceptedOfferModal.show = false;
        delete this.offerAssessment.retracted;
        delete this.offerAssessment.offer_accepted;
        const data = {
            stageClass: 'offer-approval',
            complianceRateClass: 'red',
            order: 3
        };
        await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
        this.candidateService.updateCandidate(this.candidate.id, data);
        if (this.acceptedOfferModal.delete_workflow_id) {
            this.employeeService
                .deleteWorkflow(this.candidate.id, this.acceptedOfferModal.delete_workflow_id)
                .subscribe(() => {});
            this.acceptedOfferModal.delete_workflow_id = null;
        }
    }

    onApprovalOffer(event) {
        console.log('onApprovalOffer', event);
    }

    refreshOfferAssessment() {
        return new Promise(async (resolve, reject) => {
            const assessment = await this.offerService.getOfferAssessment(this.candidate.id, this.offerAssessment.id);
            const equal = isEqual(assessment, this.offerAssessment);
            // console.log(equal, assessment, this.offerAssessment);
            resolve(equal);
        });
    }
}
