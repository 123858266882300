<div class="d-flex justify-content-end">
    <button *ngIf="canAddInterwiew()" type="button" class="btn_add justify-content-end" (click)="openModal()">
        <img src="/assets/images/icon-plus.svg" width="22" height="22" />
        <span>Add Interview</span>
    </button>
</div>
<div class="interview-list" *ngIf="candidateInterviews && candidateInterviews.length">
    <ng-container *ngFor="let group of groupInterviews | keyvalue">
        <div class="interview-date-block">
            <span>{{ group.key | amParse: 'YYYY-MM-DD' | amDateFormat: 'D MMM YYYY (dddd)' }}</span>
        </div>
        <div class="content-block interview-content-block" *ngFor="let interview of group.value">
            <p-accordion>
                <p-accordionTab
                    [disabled]="true"
                    (click)="goToScoreCard(interview)"
                    [ngClass]="{ 'active-arr': interview.interview_date || interview.completed }"
                >
                    <p-header>
                        <div class="icon" *ngIf="!interview.interview_date">
                            <img src="/assets/images/icons/icons-8-schedule.svg" alt="" />
                        </div>
                        <div class="has-date" *ngIf="interview.interview_date && !interview.completed">
                            <div class="date">
                                <span class="time">{{ interview.start_time }}</span>
                                <span class="time-to">{{
                                    'to ' + interview?.to + ' - (' + interview.duration + ' mins)'
                                }}</span>
                            </div>
                        </div>

                        <div class="title">
                            <div *ngIf="interview?.owner">
                                Scheduled by {{ interview.owner }}
                                <!-- <div class="options-menu" *ngIf="interview?.isInterviewMenuVisible">
                                    <div class="dots"><i></i><i></i><i></i></div>
                                    <div class="menu-block">
                                        <button (click)="onEditInterviewClick($event, interview)">
                                            Edit Interview
                                        </button>
                                        <button (click)="onDeleteInterviewClick($event, interview)">
                                            Delete Interview
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="team" *ngIf="interview.interview_date">
                                <span *ngIf="interview.type === 'online'">
                                    {{ getProviderName(interview.provider) }}
                                </span>
                                <span *ngIf="interview.type === 'person'">
                                    {{ interview.location_short ? interview.location_short : interview.location }}
                                </span>
                            </div>
                            <div class="expires" *ngIf="!interview.interview_date">Expires in 2 days</div>
                        </div>
                        <ng-container *ngIf="interview.averageRating && interview.allowShowScore">
                            <span
                                class="status-rating"
                                [ngClass]="{
                                    Bad: interview.averageRating < 3 && interview.averageRating > 0,
                                    Average: interview.averageRating >= 3 && interview.averageRating < 4,
                                    Good: interview.averageRating >= 4
                                }"
                                >{{ interview.ratingStatus }} - {{ interview.averageRating }}/5</span
                            >
                        </ng-container>
                        <button class="btn btn-feedback" *ngIf="!interview.allowShowScore && interview.finished">
                            Feedback Required
                        </button>
                    </p-header>
                </p-accordionTab>
            </p-accordion>
        </div>
    </ng-container>
</div>
<ng-container *ngIf="showInterviewModal">
    <div class="interview-modal">
        <div class="interview-container">
            <div class="interview-modal__header">
                <svg-icon src="/assets/images/icon-ampersand.svg" class="mr20"></svg-icon>
                <div class="title">
                    <span>Interview for {{ candidate.first_name }} {{ candidate.last_name }}</span>
                </div>
                <!-- <button
                    type="button"
                    class="simple-button find"
                    *ngIf="selectedStep === 'create'"
                    (click)="onFindMeetingTime()"
                >
                    Find meeting time
                </button>
                <button type="button" class="btn btn-close-icon" (click)="onCloseModal()"></button> -->
                <button type="button" class="btn close-btn" (click)="onCloseModal()">
                    <img src="/assets/images/icons/icons-8-delete.svg" />
                </button>
            </div>
            <div class="interview-modal__content">
                <ng-container *ngIf="selectedStep === 'select' && checkedOfficeToken">
                    <app-interview-select-service
                        [user]="user"
                        (selectService)="selectService($event)"
                    ></app-interview-select-service>
                </ng-container>

                <ng-container *ngIf="selectedStep === 'create' && checkedOfficeToken">
                    <app-interview-create
                        (addedSchedule)="onAddedSchedule($event)"
                        (close)="onCloseModal()"
                        [meetingTime]="meetingTime"
                        [candidate]="candidate"
                        [job]="job"
                        [user]="user"
                        [service]="selectedService"
                    ></app-interview-create>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="backdrop" (click)="onCloseModal()"></div>
</ng-container>

<ng-container *ngIf="showScorecardModal">
    <div class="scorecard-modal">
        <app-candidate-interview-scorecard
            [jobId]="job.id"
            [candidate]="candidate"
            [activeInterview]="activeInterview"
            (closeScorecardModal)="closeScorecardModal()"
            [videos]="videos"
            [questionnaires]="questionnaires"
            [logicTest]="logicTest"
            [stagesData]="stagesData"
            [radar_chart_data]="radar_chart_data"
            [personality_assessment]="personality_assessment"
            [personalityProfileScores]="personalityProfileScores"
        ></app-candidate-interview-scorecard>
    </div>
</ng-container>
