import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyRule } from '@app/core/constants/cfao-offer.constants';
import { UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { EsigningService } from '@app/core/services/esigning.service';
import {} from 'events';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit, OnDestroy {
    @Input() file;
    @Input() employeeId;
    @Input() employee;
    @Input() documents;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() documentUpdated: EventEmitter<any> = new EventEmitter();
    contentLoading = false;
    form: FormGroup;
    showReason = false;
    loading = true;
    approveDocumentModal: any = {
        show: false
    };

    constructor(
        public utilitiesService: UtilitiesService,
        private employeeService: EmployeesService,
        private fb: FormBuilder,
        private _renderer2: Renderer2,
        private esigningService: EsigningService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        console.log(this.file);
        this.form = this.fb.group({
            reason: ['', Validators.required]
        });
        this._renderer2.addClass(document.body, 'hide-sidebar');
    }

    onClose() {
        this.close.emit();
    }

    async onApproveDocument() {
        const template: any = await this.esigningService.getTemplate(this.file.document_id).toPromise();
        console.log(template);
        this.contentLoading = true;
        if (template?.enable_send) {
            this.approveDocumentModal = {
                show: true,
                documents: this.documents['Onboarding Documents'],
                document: this.file,
                employee: this.employee
            };
            this.contentLoading = false;
        } else {
            this.onDocumentUpdated({ send_email: false });
        }
        // await this.employeeService.approveDocument(this.employeeId, this.file.id);
        // this.employeeService.approveEmployeeDocument(this.employeeId, this.file.id, {}).subscribe((response) => {
        //     console.log('sdasd', response);
        //     if (
        //         this.utilitiesService.getTenant() === 'DR5SALXUV' ||
        //         (this.utilitiesService.getTenant() === 'JV8E2Q5IO' && this.employee.sync_with_sage)
        //     ) {
        //         const payrollSettings = this.employee.payroll_settings || {};
        //         const companyRule = CompanyRule.find((c) => payrollSettings.company_rule === c.CompanyRuleCode);
        //         // TODO: UPDATE model object with an actual data
        //         if (companyRule) {
        //             const model = {
        //                 companyCode: companyRule.CompanyCode,
        //                 CompanyRuleCode: payrollSettings.company_rule,
        //                 //"employeeID": 6,
        //                 employeeCode: this.employee.employee_id,
        //                 //"contentCategoryTypeID": 501,
        //                 // "ContentCategoryTypeCode": "NEW_CSTM_CONTENT",
        //                 ContentCategoryTypeCode: 'EMPLOYEE',
        //                 subject: this.file.document_type
        //                 //"ZoneCode": "ZONE1",
        //                 // "SensitivityCode": "CNT_SENS1",
        //                 //"SensitivityCodeID": 3,
        //                 //"ExpiryDate": "2021-12-12"
        //             };
        //             const url = this.file.link;
        //             console.log(model, url);
        //             this.employeeService.saveDocumentToSage({ model, url }).subscribe(
        //                 (r) => {
        //                     console.log('saveDocumentToSage', r);
        //                     this.file.status = 'approved';
        //                     this.documentUpdated.emit(this.file);
        //                     this.contentLoading = false;
        //                 },
        //                 (err) => {
        //                     this.file.status = 'approved';
        //                     this.documentUpdated.emit(this.file);
        //                     this.contentLoading = false;
        //                 }
        //             );
        //         }
        //     } else {
        //         this.file.status = 'approved';
        //         this.documentUpdated.emit(this.file);
        //         this.contentLoading = false;
        //     }
        // });
    }

    async onRejectDocument() {
        this.showReason = true;
    }

    keyupReason(event) {
        console.log('keyup', event, this.form.valid);
        if (this.form.valid) {
            this.contentLoading = true;
            const data = {
                document: this.file,
                reason: this.form.value.reason
            };
            console.log(this.file);
            this.employeeService.rejectDocument(this.employeeId, data).subscribe(
                (response) => {
                    console.log('response', response);
                    this.file.status = 'rejected';
                    this.documentUpdated.emit(this.file);
                    this.contentLoading = false;
                },
                (err) => {
                    console.log(err);
                    this.file.status = 'rejected';
                    this.documentUpdated.emit(this.file);
                    this.contentLoading = false;
                }
            );
        }
    }

    onDocumentLoaded(event) {
        //hide loadeer
        this.loading = false;
    }

    onCloseModal() {
        this.approveDocumentModal = {
            show: false
        };
    }

    ngOnDestroy() {
        this._renderer2.removeClass(document.body, 'hide-sidebar');
    }

    onDocumentUpdated(event: any) {
        // console.log('onDocumentUpdated', event);
        this.contentLoading = true;
        const data: any = {
            send_email: event.send_email
        };
        if (event.attachments) {
            data.attachments = event.attachments;
        }
        if (event.document) {
            data.document = event.document;
        }
        if (
            (this.utilitiesService.getTenant() === 'DR5SALXUV' || this.utilitiesService.getTenant() === 'JV8E2Q5IO') &&
            this.employee.sync_with_sage
        ) {
            data.payload_for_sage = this.preparePayloadForSage();
        }
        this.employeeService.approveEmployeeDocument(this.employeeId, this.file.id, data).subscribe(
            (response) => {
                console.log('approveEmployeeDocument response', response);
                this.documentUpdated.emit(this.file);
                this.file.status = 'approved';
                this.onCloseModal();
                this.contentLoading = false;
            },
            (err) => {
                this.toastr.error('Error');
                this.contentLoading = false;
            }
        );
    }

    preparePayloadForSage() {
        const payrollSettings = this.employee.payroll_settings || {};
        const companyRule = CompanyRule.find((c) => payrollSettings.company_rule === c.CompanyRuleCode);
        // TODO: UPDATE model object with an actual data
        if (companyRule) {
            const model = {
                companyCode: companyRule.CompanyCode,
                CompanyRuleCode: payrollSettings.company_rule,
                //"employeeID": 6,
                employeeCode: this.employee.employee_id,
                //"contentCategoryTypeID": 501,
                // "ContentCategoryTypeCode": "NEW_CSTM_CONTENT",
                ContentCategoryTypeCode: 'EMPLOYEE',
                subject: this.file.document_type
                //"ZoneCode": "ZONE1",
                // "SensitivityCode": "CNT_SENS1",
                //"SensitivityCodeID": 3,
                //"ExpiryDate": "2021-12-12"
            };
            const url = this.file.link;
            // console.log(model, url);
            return { model, url };
        }
    }
}
