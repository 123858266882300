import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-csat-chart',
    templateUrl: './csat-chart.component.html',
    styleUrls: ['./csat-chart.component.scss']
})
export class CSATChartComponent implements OnInit {
    data: any;
    options: any;
    activeColor;
    leftPosition;
    scoreRange: any[] = [
        {
            text: 'Very Dissatisfied',
            color: '#fc5c65',
            value: 1
        },
        {
            color: '#fd8645',
            value: 2
        },
        {
            color: '#f7b731',
            value: 3
        },
        {
            color: '#6dd400',
            value: 4
        },
        {
            text: 'Very Satisfied',
            color: '#20bf6b',
            value: 5
        }
    ];
    @Input() value;

    constructor() {}

    ngOnInit(): void {
        this.activeColor = this.scoreRange.find((s) => s.value === Math.ceil(this.value));
        this.leftPosition = `calc(${(this.value * 100) / 5}% - 13px)`;
        // console.log('activeColor', this.activeColor, this.value, Math.ceil(this.value), this.leftPosition);
    }
}
