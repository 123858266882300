import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JobService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import moment from 'moment';
import { documentTypeOptions } from '@app/core/constants/options.constants';
@Component({
    selector: 'app-completed-offers',
    templateUrl: './completed-offers.component.html',
    styleUrls: ['./completed-offers.component.scss']
})
export class CompletedOffersComponent implements OnInit {
    apiURL: string = environment.apiUrl;
    contentLoading = false;
    employees = [];
    documentsTypeOptions: any[] = documentTypeOptions;
    constructor(
        private job: JobService,
        private db: AngularFirestore,
        private utilities: UtilitiesService,
        private employeeService: EmployeesService
    ) {}

    ngOnInit(): void {
        // this.getCompletedOffers();
        // this.getCompletedLOA();
    }

    async getCompletedOffers() {
        this.contentLoading = true;
        // const jobs = await Firebase.getAll(`tenants/${tenantId}/jobs-items`);
        const jobs: any = await this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
        for (let item of jobs) {
            if (item.offer_signs) {
                for (let it in item.offer_signs) {
                    const length = item.offer_signs[it].filter((f) => f.signed).length;
                    // console.log(item.offer_signs[it].filter(f => f.signed).length, item.id);
                    if (length === 3) {
                        const candidateId = item.offer_signs[it][2].id;
                        const employee: any = await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                            .doc(candidateId)
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise();
                        const document: any[] = await this.db
                            .collection(
                                `tenants/${this.utilities.getTenant()}/candidates/${candidateId}/documents`,
                                (ref) => ref.where('action', '==', 'workflow-contract').limit(1)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise();
                        // console.log('document', document);
                        this.employees.push({
                            ...employee,
                            link: document[0].link,
                            document_type: document[0].document_type,
                            applied_job: item.id
                        });
                        // this.onDonloadDocument({
                        //     ...employee,
                        //     link: document[0].link,
                        //     document_type: document[0].document_type,
                        //     applied_job: item.id
                        // });
                    } else {
                        console.log('in progress');
                    }
                }
            }
        }
        this.contentLoading = false;
    }

    async getCompletedLOA() {
        this.contentLoading = true;
        let i = 0;
        const employees: any = await this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) => ref.where('employee', '==', true))
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
        for (let item of employees) {
            if (i > -1) {
                i += 1;
                const documents: any[] = await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${item.id}/documents`, (ref) =>
                        ref.where('document_type', '==', 'Letter of Appointment')
                    )
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1))
                    .toPromise();
                // console.log('document', documents);
                if (documents && documents.length) {
                    this.employees.push({
                        ...item,
                        link: documents[0].link,
                        document_type: documents[0].document_type,
                        applied_job: item.id
                    });
                    // this.onDonloadDocument({
                    //     ...item,
                    //     link: documents[0].link,
                    //     document_type: documents[0].document_type,
                    //     applied_job: item.id
                    // });
                }
            }
        }
        console.log(this.employees);
        this.contentLoading = false;
    }

    onDonloadDocument(item) {
        let name = '';
        if (item.employee_id) {
            name = `${item.employee_id}_`;
        }
        let documentType = this.documentsTypeOptions.find((d) => d.value === item.document_type);
        const url = item.link;
        name += `${item.first_name} ${item.last_name}_${
            documentType ? documentType.name : item.document_type
        }_${moment().format('YYYYMMDD')}`;
        this.employeeService.downloadFile(url, name).subscribe();
    }
}
