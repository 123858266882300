<div>
    <app-progress-circle class="mla" [progress]="percentage"></app-progress-circle>
</div>
<div class="snapshot" *ngIf="snapshot | async as snap">
    {{ snap.bytesTransferred }} of {{ snap.totalBytes }}
    <div *ngIf="downloadURL as url">
        <img [src]="url" /><br />
        <a [href]="url" target="_blank" rel="noopener">Download Me!</a>
    </div>
</div>
