<form [formGroup]="hireForm" *ngIf="contentLoaded">
    <div class="form-content-section">
        <div class="form-content-holder">
            <h3 class="mb30 mt0">Approval</h3>
            <p class="mb20">
                Please upload the aproval you have received to recruit for this position. You will not be able to submit
                this reuqest without some form or approval.
            </p>
            <label
                *ngIf="!hireForm.get('approval').value"
                class="upload-block"
                for="approval"
                dropzone
                (hovered)="toggleHover($event)"
                (dropped)="startUpload($event, 'drop', 'approval')"
                [class.hovering]="isHovering"
            >
                <strong>Drag and drop your approval here</strong>
                <span>Or select a file from your computer</span>
                <p>
                    Your approval can inculde any of following and must clearly show that you have authorisation to hire
                    for this position. Examples include: BU exec approval email, client purchase order, etc
                </p>
                <input id="approval" type="file" (change)="startUpload($event.target, 'change', 'approval')" />
                <div *ngFor="let file of files['approval']">
                    <app-upload-task
                        path="Job_Approval"
                        (fileUploaded)="fileUploaded($event, 'approval')"
                        (fileUploadedError)="fileUploadedError('approval')"
                        [file]="file"
                    ></app-upload-task>
                </div>
                <div
                    class="error-message"
                    *ngIf="!hireForm.controls.approval.valid && hireForm.controls.approval.touched"
                >
                    Aproval is required
                </div>
                <div class="error-message" *ngIf="hireForm.controls.approval?.errors?.incorrect">
                    Download Error
                </div>
            </label>
            <div *ngIf="hireForm.get('approval').value" class="file-uploaded-block">
                <img src="../../../assets/images/files-icon-docx.svg" class="resume-review-file-icon" alt="file" />
                <div class="file-name">
                    {{ hireForm.get('approval').value.name }}
                </div>
                <button class="btn remove-btn" (click)="onRemoveFile('approval')">
                    <svg-icon
                        class="success-img"
                        src="/assets/images/icons-8-delete2.svg"
                        alt="Delete icon"
                        [svgStyle]="{ 'width.px': 11, 'height.px': 13 }"
                    ></svg-icon>
                </button>
            </div>

            <h3 class="mb30 mt30">Job Details</h3>
            <p class="mb20">
                Enter your job details to the best of your ability. The better the information you provide during this
                process the better we will be able to assist you.
            </p>
            <div class="flex-row mb30">
                <div class="hiring-type" [class.active]="hiringType === 'new'" (click)="onChangeHiringType('new')">
                    New role
                </div>
                <div class="hiring-type" [class.active]="hiringType === 'exist'" (click)="onChangeHiringType('exist')">
                    Existing role
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <mat-form-field appearance="outline">
                        <mat-label>Job Title</mat-label>
                        <input
                            type="text"
                            formControlName="title"
                            maxlength="{{ titleMaxLength }}"
                            matInput
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredTitlesOptions" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Job title</mat-label>
                        <input matInput formControlName="title" />
                    </mat-form-field> -->
                    <div
                        class="error-message"
                        *ngIf="!hireForm.controls.title.valid && hireForm.controls.title.touched"
                    >
                        Job title is required
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <div class="input-block right-option-block">
                        <app-ac-chips-material
                            *ngIf="tenantLocationOptions.length"
                            formControlName="location"
                            [suggestions]="tenantLocationOptions"
                            [setSuggestions]="setSuggestions"
                            title="Location"
                            key="name"
                            (onValueChange)="onLocationAdded($event)"
                            [ngClass]="{
                                invalid: !hireForm.controls.location.valid && hireForm.controls.location.touched
                            }"
                        ></app-ac-chips-material>
                        <div class="right-option">
                            <mat-checkbox formControlName="is_remote"> Remote </mat-checkbox>
                        </div>
                    </div>
                    <div
                        class="error-message"
                        *ngIf="!hireForm.get('location').valid && hireForm.get('location').touched"
                    >
                        Primary job location is required
                    </div>
                    <div class="input-block"></div>
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <app-auto-dropdown
                        [options]="businessUnitOptions"
                        [control]="hireForm.get('business_unit')"
                        placeholder="Business Unit"
                        [ngClass]="{
                            invalid: !hireForm.controls.business_unit.valid && hireForm.controls.business_unit.touched
                        }"
                    >
                    </app-auto-dropdown>
                    <div
                        class="error-message"
                        *ngIf="!hireForm.controls.business_unit.valid && hireForm.controls.business_unit.touched"
                    >
                        Business Unit is required
                    </div>
                </div>
            </div>
            <div class="form-block mb20">
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <div class="input-block">
                            <mat-form-field appearance="outline">
                                <mat-label>Reference / Client / Project</mat-label>
                                <input matInput formControlName="ref" autocomplete="off" />
                            </mat-form-field>
                            <div
                                class="error-message"
                                *ngIf="!hireForm.controls.ref.valid && hireForm.controls.ref.touched"
                            >
                                Reference / Client / Project is required
                            </div>
                        </div>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col">
                        <div class="input-block">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Cost Centre</mat-label>
                                    <input matInput formControlName="cost_centre" autocomplete="off" />
                                </mat-form-field>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        !hireForm.controls.cost_centre.valid && hireForm.controls.cost_centre.touched
                                    "
                                >
                                    Cost Centre is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blocks-separator mt20 mb30"></div>
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [options]="jobTypeOptions"
                                [newStyle]="true"
                                [control]="hireForm.controls.type"
                                [placeholder]="'Job Type'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid: !hireForm.controls.type.valid && hireForm.controls.type.touched
                                }"
                            ></app-auto-dropdown>
                            <div
                                class="error-message"
                                *ngIf="!hireForm.controls.type.valid && hireForm.controls.type.touched"
                            >
                                Job type is required
                            </div>
                        </div>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [options]="hiresOptions"
                                [newStyle]="true"
                                [control]="hireForm.controls.number_of_hires"
                                [placeholder]="'Positions Available'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid:
                                        !hireForm.controls.number_of_hires.valid &&
                                        hireForm.controls.number_of_hires.touched
                                }"
                            ></app-auto-dropdown>
                            <div
                                class="error-message"
                                *ngIf="
                                    !hireForm.controls.number_of_hires.valid &&
                                    hireForm.controls.number_of_hires.touched
                                "
                            >
                                Number of positions is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col form-block-col-50">
                        <div class="input-block">
                            <mat-form-field appearance="outline" (click)="picker1.open()">
                                <mat-label>Ideal Start Date</mat-label>
                                <input
                                    formControlName="start_date"
                                    matInput
                                    [matDatepicker]="picker1"
                                    autocomplete="off"
                                />
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [disabled]="disableContractDuration"
                                [options]="contractDuration"
                                [newStyle]="true"
                                [control]="hireForm.controls.contract_duration"
                                [placeholder]="'Contract Duration (if applicable)'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid:
                                        !hireForm.controls.contract_duration.valid &&
                                        hireForm.controls.contract_duration.touched &&
                                        (hireForm.controls.type.value === 'temporary' ||
                                            hireForm.controls.type.value === 'fixed_term' ||
                                            hireForm.controls.type.value === 'freelance')
                                }"
                            ></app-auto-dropdown>
                            <div
                                class="error-message"
                                *ngIf="
                                    !hireForm.controls.contract_duration.valid &&
                                    hireForm.controls.contract_duration.touched &&
                                    (hireForm.controls.type.value === 'temporary' ||
                                        hireForm.controls.type.value === 'fixed_term' ||
                                        hireForm.controls.type.value === 'freelance')
                                "
                            >
                                Contract Duration is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="mt60 mb30">Budget</h3>
            <div formGroupName="salary">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="currencyOptions"
                                    [newStyle]="true"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="hireForm.get('salary.currency')"
                                    [placeholder]="'Currency'"
                                    (onChange)="changeInForm(hireForm)"
                                    [ngClass]="{
                                        invalid:
                                            !hireForm.get('salary.currency').valid &&
                                            hireForm.get('salary.currency').touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        !hireForm.get('salary.currency').valid &&
                                        hireForm.get('salary.currency').touched
                                    "
                                >
                                    Currency required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="salaryOptions"
                                    [newStyle]="true"
                                    [control]="hireForm.get('salary.period')"
                                    [placeholder]="'Frequency'"
                                    (onChange)="changeInForm(hireForm)"
                                    [ngClass]="{
                                        invalid:
                                            !hireForm.get('salary.period').valid &&
                                            hireForm.get('salary.period').touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        !hireForm.get('salary.period').valid && hireForm.get('salary.period').touched
                                    "
                                >
                                    Frequency required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label *ngIf="!singleSalary">From</mat-label>
                                        <mat-label *ngIf="singleSalary">Salary</mat-label>
                                        <input
                                            type="text"
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                precision: 0,
                                                align: 'left'
                                            }"
                                            currencyMask
                                            placeholder="0"
                                            formControlName="from"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                                <div
                                    class="error-message"
                                    *ngIf="!hireForm.get('salary.from').valid && hireForm.get('salary.from').touched"
                                >
                                    Salary required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-col-separator" *ngIf="!singleSalary"></div>
                        <div class="form-block-col" *ngIf="!singleSalary">
                            <div class="input-block">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>To</mat-label>
                                        <input
                                            type="text"
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                precision: 0,
                                                align: 'left'
                                            }"
                                            currencyMask
                                            placeholder="0"
                                            formControlName="to"
                                            maxlength="20"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        hireForm.controls.salary['controls'].to.errors?.WrongSalary &&
                                        hireForm.controls.salary['controls'].to.touched
                                    "
                                >
                                    Cannot be less than "From"
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="form-salary-tip danger" (click)="toggleSalaryField()" *ngIf="!singleSalary">
                        Use Single Salary
                    </p>
                    <p class="form-salary-tip danger" (click)="toggleSalaryField()" *ngIf="singleSalary">
                        Use Salary Range
                    </p>
                </div>
            </div>
            <!-- Skills  -->
            <div class="form-content-section skills-section">
                <app-loader *ngIf="skillsLoading" class="posa"></app-loader>
                <div class="form-content-holder">
                    <div class="form-block-info mt60">
                        <div class="form-block-info-title-block fdr">
                            <h3 class="form-block-info__title">Skills</h3>
                            <button class="btn btn-simple mla" (click)="onMoreSkills()">
                                <svg-icon src="/assets/images/plus-icon.svg" alt=""></svg-icon>
                                <span class="text">&nbsp;&nbsp;Suggest More</span>
                            </button>
                        </div>
                        <p class="form-block-info__description">
                            Based on your job title, we have identified the following skills that may be appropriate to
                            your job listing. Please select all those that are relevant below. Click Suggest More should
                            you not find the skills you’re looking for below. You can add additional skills that may not
                            have been listed in the input field below.
                        </p>
                    </div>
                    <div class="skills-holder">
                        <div
                            class="skill-item"
                            *ngFor="let skill of skillsList"
                            [ngClass]="{ selected: skill.selected }"
                            (click)="onSkillClick(skill)"
                            title="{{ skill.name }}"
                        >
                            {{ skill.name }}
                        </div>
                    </div>
                    <div class="skills-input">
                        <mat-form-field class="example-chip-list" appearance="outline">
                            <mat-chip-list #chipList aria-label="Additional Skills">
                                <mat-chip
                                    *ngFor="let skill of additionalSkills"
                                    [selectable]="selectableAdditionalSkill"
                                    [removable]="removableAdditionalSkill"
                                    (removed)="removeAdditionalSkill(skill)"
                                >
                                    <span>{{ skill.name }}</span>
                                    <button matChipRemove *ngIf="removableAdditionalSkill">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input
                                    placeholder="Additional Skills"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlurAdditionalSkill"
                                    (matChipInputTokenEnd)="addAdditionalSkill($event)"
                                />
                            </mat-chip-list>
                        </mat-form-field>
                        <span class="note">Please seperate skills with a ","</span>
                    </div>
                </div>
            </div>
            <!-- /Skills  -->
        </div>
    </div>
</form>
<!-- <div class="overlay" *ngIf="createNewLocation" (click)="closeNewLocationForm()"></div> -->
<!-- <app-new-location
    *ngIf="timeZones"
    [timeZones]="timeZones"
    [locations]="locations"
    action="create"
    [ngClass]="{ active: createNewLocation }"
    (close)="closeNewLocationForm()"
    (createLocation)="onCreateLocation($event)"
>
</app-new-location> -->
<app-loader *ngIf="contentLoading"></app-loader>
