import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    tenantId = 'undefined';
    apiURL: string = environment.apiUrl;
    constructor(private utilities: UtilitiesService, private firestore: AngularFirestore, private http: HttpClient) {}

    saveWorkflow(workflowId, data: any, id = null) {
        if (workflowId === 'new') {
            return this.firestore
                .collection(`tenants/${this.utilities.getTenant()}/workflows`)
                .doc(id)
                .set(data);
        } else {
            return this.firestore
                .collection(`tenants/${this.utilities.getTenant()}/workflows`)
                .doc(workflowId)
                .update(data);
        }
    }

    saveSeries(workflowId: string, seriesId: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series`)
            .doc(seriesId)
            .set(data);
    }

    saveWorkflowAudit(workflowId: string, data: any) {
        const timestamp = Math.floor(Date.now() / 1000).toString();
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/audit`)
            .doc(timestamp)
            .set(data);
    }

    removeSeries(workflowId: string, seriesId: string) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series`)
            .doc(seriesId)
            .delete();
    }

    updateWorkflow(workflowId, data: any) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows`)
            .doc(workflowId)
            .update(data);
    }

    getWorkflows() {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows`)
            .snapshotChanges()
            .pipe(
                take(1),
                map((changes: any) => {
                    const items = changes.map(({ payload: { doc } }) => {
                        const data = doc.data();
                        const id = doc.id;
                        return { id, ...data };
                    });
                    return items.sort((a: any, b: any) => {
                        return b.created_at - a.created_at;
                    });
                })
            );
    }

    getManualWorkflows() {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows`, (ref) =>
                ref.where('rules.type', '==', 'manually').where('status', '==', 'live')
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((data) => {
                    data.sort((a: any, b: any) => {
                        if (a.title && b.title) {
                            const labelA = a.title.toUpperCase();
                            const labelB = b.title.toUpperCase();
                            return labelA.localeCompare(labelB);
                        }
                    });
                    return data;
                })
            );
    }

    getWorkflowById(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    getSeriesForWorkFlow(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${id}/series`)
            .valueChanges()
            .pipe(take(1));
    }

    getRatesForSeries(workflowId, seriesId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series/${seriesId}/rates`)
            .valueChanges()
            .pipe(take(1));
    }

    getEngagementForSeries(workflowId, seriesId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series/${seriesId}/engagement`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    uploadImage(data: any) {
        return this.http.post(`${this.apiURL}/upload-image`, data).pipe(catchError((error: any) => throwError(error)));
    }

    uploadFile(data: any) {
        return this.http.post(`${this.apiURL}/upload-file`, data).pipe(catchError((error: any) => throwError(error)));
    }

    getEsigningTemplates() {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/esigning-templates`)
            .snapshotChanges()
            .pipe(
                map((changes: any) => {
                    return changes.map(({ payload: { doc } }) => {
                        const data = doc.data();
                        const id = doc.id;
                        return { id, ...data };
                    });
                })
            );
    }

    getIdommoStoryBoards() {
        return this.http
            .get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/storyboards`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    startOneOffWorkflow(data) {
        return this.http
            .post(`${this.apiURL}/employees/live`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    async duplicateWorflow(workflowId) {
        return new Promise(async (resolve, reject) => {
            try {
                const workflow: any = await this.firestore
                    .collection(`tenants/${this.utilities.getTenant()}/workflows`)
                    .doc(workflowId)
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise();
                const series: any[] = await this.firestore
                    .collection(`tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series`)
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise();
                const newWorkflowId = this.firestore.createId();
                const newWorkflow: any = { ...workflow };
                newWorkflow.title = `${workflow.title} (copy)`;
                newWorkflow.id = newWorkflowId;
                newWorkflow.status = 'draft';
                newWorkflow.duplicated_id = workflowId;
                newWorkflow.created_at = Math.floor(Date.now() / 1000);
                await this.firestore
                    .collection(`tenants/${this.utilities.getTenant()}/workflows`)
                    .doc(newWorkflowId)
                    .set(newWorkflow);
                if (series && series.length) {
                    for (const s of series) {
                        await this.firestore
                            .collection(`tenants/${this.utilities.getTenant()}/workflows/${newWorkflowId}/series`)
                            .doc(s.id)
                            .set(s);
                    }
                }
                return resolve(true);
            } catch (error) {
                console.error(error);
                return reject(error);
            }
        });
    }

    getAssignedEmployees(id) {
        return new Observable((subscriber) => {
            this.getAllPromise(id)
                .then((employees: any[]) => {
                    subscriber.next(employees);
                    subscriber.complete();
                })
                .catch((error) => {
                    console.error(error);
                    subscriber.next([]);
                    subscriber.complete();
                });
        });
    }

    getAllEngagementLengthEmploeesForWorkflow(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                ref.where('workflows', 'array-contains', id)
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((l: any[]) => {
                    return l.length ? l.length : 0;
                })
            );
    }

    async getAllPromise(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const employees: any = await this.firestore
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                        ref.where('workflows', 'array-contains', id)
                    )
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1))
                    .toPromise();
                for (const [index, value] of employees.entries()) {
                    if (index < 13) {
                        const ass = await this.getEmployeeAssignments(value.id, id);
                        value.assignments = ass;
                    }
                }
                return resolve(employees);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getEmployeeAssignments(employeeId, workflowId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/assignments`)
            .doc(workflowId)
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
    }

    extendActiveSeries(employeeId, workflowId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/assignments`)
            .doc(workflowId)
            .valueChanges()
            .pipe(take(1))
            .subscribe(async (response: any) => {
                const active_series = response.active_series;
                const timestamp = Math.floor(Date.now() / 1000);
                for (const s of active_series) {
                    s.added_at = timestamp;
                    s.added_at_formatted = moment(timestamp * 1000).format('DD-MM-YYYY HH:mm');
                }
                await this.firestore
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/assignments`)
                    .doc(workflowId)
                    .update({ active_series: response.active_series });
            });
    }

    async getEmployeesRates(workflowId, seriesIds) {
        return new Promise(async (resolve, reject) => {
            try {
                const rates = {};
                for (let item of seriesIds) {
                    const rate = await this.firestore
                        .collection(
                            `tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series/${item.id}/rates`
                        )
                        .valueChanges({ idField: 'id' })
                        .pipe(take(1))
                        .toPromise();
                    rates[item.id] = rate;
                }
                resolve(rates);
            } catch (err) {
                reject();
            }
        });
    }

    getAllEmploeesForWorkflow(id) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                ref.where('workflows', 'array-contains', id)
            )
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    async getEmployeesEngagement(workflowId, seriesIds) {
        return new Promise(async (resolve, reject) => {
            try {
                const engagements = {};
                for (let item of seriesIds) {
                    const engagement = await this.firestore
                        .collection(
                            `tenants/${this.utilities.getTenant()}/workflows/${workflowId}/series/${item.id}/engagement`
                        )
                        .valueChanges({ idField: 'id' })
                        .pipe(take(1))
                        .toPromise();
                    engagements[item.id] = engagement;
                }
                resolve(engagements);
            } catch (err) {
                reject();
            }
        });
    }

    getSurveyById(surveyId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/surveys`)
            .doc(surveyId)
            .valueChanges()
            .pipe(take(1));
    }

    downloadWorkflowReport(workflowId, data) {
        return this.http.post(
            `${this.apiURL}/report/tenants/${this.utilities.getTenant()}/workflow/${workflowId}/create-report`,
            data
        );
    }

    deleteScheduledWorkflowById(workflowId: string) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/scheduled_workflows`)
            .doc(workflowId)
            .delete();
    }
}
