import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { EmployeesService } from '@app/core/services/employees.service';
import { getSaver, SAVER } from '@app/libs/saver.provider';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-aod-select-date',
    templateUrl: './aod-select-date.component.html',
    styleUrls: ['./aod-select-date.component.scss']
})
export class AodSelectDateComponent implements OnInit {
    contentLoading = false;
    visible = false;
    @Input() data;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    selectedDate: string = moment().format('YYYY-MM-DD');
    minDate = new Date();
    employee;
    constructor(private employeeService: EmployeesService, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.visible = true;
        console.log('DATA', this.data);
        this.employee = this.data;
    }

    onHideModal() {
        this.visible = false;
        this.onClose.emit();
    }

    onViewStatement() {
        this.contentLoading = true;
        const terminationDate = this.selectedDate;
        this.employeeService
            .getAodStatement(
                this.employee.id,
                this.employee.employee_id,
                this.employee.email,
                'acknowledge',
                terminationDate
            )
            .subscribe(
                (response: any) => {
                    console.log('getAodStatement response', response);
                    this.contentLoading = false;
                    let name = `${this.employee.first_name}_${this.employee.last_name}_AOD_${this.selectedDate}`;
                    FileSaver.saveAs(response.url, `${name}.pdf`);
                    this.onHideModal();
                },
                (err) => {
                    console.log('Error', err.error);
                    this.toastr.error(err.error.error);
                    this.contentLoading = false;
                }
            );
    }

    onSelect(event: any) {
        const date = moment(event).format('YYYY-MM-DD');
        this.selectedDate = date;
    }
}
