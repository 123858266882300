import { Injectable } from '@angular/core';
import { AuthService } from '@app/modules/auth/auth.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as fromServices from '../../core/services';
import { LoadGeneralData } from '../actions';
import { UtilitiesService } from './../../core/services/utilities.service';
import * as userActions from './../actions/user.action';
import { State } from './../reducers/index';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userService: fromServices.UserService,
        private utilities: UtilitiesService,
        private authService: AuthService,
        private store: Store<State>
    ) {}

    @Effect() loadUser$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.LOAD_USER),
        switchMap(() => {
            return this.userService.getUser().pipe(
                tap((user) => {
                    if (user.tenant_id === 'undefined') {
                        alert('Tenant is undefined. I found)))');
                        console.log('Tenant Id is undefined. Logout');
                        this.authService.logout();
                    }
                    this.utilities.setTenant(user.tenant_id);
                    this.store.dispatch(new LoadGeneralData());
                }),
                map((user) => new userActions.LoadUserSuccess(user)),
                catchError((error) => of(new userActions.LoadUserFail(error)))
            );
        })
    );
}
