import { JobsListState } from '@app/products/hire/modules/jobs/store/reducers/jobs.reducer';
import * as fromStagesDataActions from '../actions/stagesData.action';

export interface StagesDataListState {
    questionnaires: any;
    devskiller: any;
    ikm: any;
    integrations: any;
    offerTemplates: any;
    manualWorkflows: any;
    locations: any[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: StagesDataListState = {
    questionnaires: null,
    devskiller: null,
    ikm: null,
    integrations: null,
    offerTemplates: null,
    manualWorkflows: null,
    locations: null,
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromStagesDataActions.StagesDataAction): StagesDataListState {
    switch (action.type) {
        case fromStagesDataActions.LOAD_STAGES_DATA: {
            return { ...state, loading: true };
        }

        case fromStagesDataActions.LOAD_STAGES_DATA_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromStagesDataActions.LOAD_STAGES_DATA_SUCCESS: {
            const data = action.payload;
            const questionnaires = data[0];
            const devskiller = data[1];
            const integrations = data[2];
            const offerTemplates = data[3];
            const manualWorkflows = data[4];
            const locations = data[5];
            const ikm = data[6];
            return {
                ...state,
                loading: false,
                loaded: true,
                questionnaires,
                devskiller,
                integrations,
                offerTemplates,
                manualWorkflows,
                locations,
                ikm
            };
        }

        case fromStagesDataActions.UPDATE_QUESTIONNAIRES_STAGES_DATA: {
            return { ...state, loading: true };
        }

        case fromStagesDataActions.UPDATE_QUESTIONNAIRES_STAGES_DATA_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromStagesDataActions.UPDATE_QUESTIONNAIRES_STAGES_DATA_SUCCESS: {
            console.log('UpdateStagesData', action);
            const data = action.payload;
            const questionnaires = data[0];
            return {
                ...state,
                loading: false,
                loaded: true,
                questionnaires
            };
        }
    }

    return state;
}

export const getStagesDataLoading = (state: StagesDataListState) => state.loading;
export const getStagesDataLoaded = (state: StagesDataListState) => state.loaded;
export const getStagesDataLocations = (state: StagesDataListState) => state.locations;
export const getStagesDataEntities = (state: StagesDataListState) => state;
