<app-loader *ngIf="isFormLoading"></app-loader>
<button *ngIf="!action || action === 'takeOwnership'" class="close" (click)="closeModal()">
    <img src="/assets/images/icons/icons-8-delete.svg" />
</button>
<div class="container fixed-container" [ngClass]="{ scrollable: !isFormLoading }">
    <div class="title-block">
        <div class="title-block-inner">
            <div class="title-actions">
                <h1 class="page-title">
                    <svg-icon src="/assets/images/icon-ampersand.svg"></svg-icon>
                    <span>
                        {{ job && job.title ? job.title : 'New Job' }}
                    </span>
                </h1>
                <div class="buttons-title" *ngIf="!action">
                    <ng-container *ngIf="isNewOrDraft; else editJob">
                        <button class="btn btn-simple" (click)="onSaveDraft($event)">
                            <span class="text">Save and Close</span>
                        </button>
                        <button class="btn btn-primary" (click)="onSave($event)" [disabled]="!isNextEnabled()">
                            {{ saveBtnText }}
                        </button>
                    </ng-container>
                    <ng-template #editJob>
                        <button
                            *ngIf="
                                isJobOwner &&
                                ((job.creationMode === 'jdAI' && job.jdAiCompleted) ||
                                    !job.creationMode ||
                                    job.creationMode !== 'jdAI')
                            "
                            class="btn btn-primary mla w140"
                            (click)="onSaveAll($event)"
                        >
                            Save
                        </button>
                        <button
                            *ngIf="isJobOwner && job.creationMode === 'jdAI' && !job.jdAiCompleted"
                            class="btn btn-primary"
                            (click)="onSave($event)"
                            [disabled]="!isNextEnabled()"
                        >
                            {{ saveBtnText }}
                        </button>
                    </ng-template>
                </div>
                <div class="buttons-title" *ngIf="action === 'takeOwnership'">
                    <button class="btn btn-primary mla w140" (click)="onTakeOwnership($event)">
                        Take ownership
                    </button>
                </div>
            </div>
            <div class="sections-nav" *ngIf="!action || action !== 'takeOwnership'">
                <div
                    class="section-nav-link"
                    (click)="onChangeSection('job-details')"
                    [ngClass]="{ active: activeSection === 'job-details' }"
                >
                    Job Details
                </div>
                <ng-container *ngFor="let section of navSections">
                    <div
                        class="section-nav-link"
                        *ngIf="!action"
                        (click)="onChangeSection(section.value)"
                        [ngClass]="{
                            active: activeSection === section.value,
                            disabled:
                                !job.id ||
                                (activeSection === 'details' &&
                                    (mode === 'new' || (mode === 'edit' && job.status === 'BUILD'))) ||
                                (activeSection === 'job-details' &&
                                    section.value === 'description' &&
                                    (mode === 'new' || (mode === 'edit' && job.status === 'BUILD')))
                        }"
                    >
                        {{ section.title }}
                    </div>
                </ng-container>
            </div>
            <div class="section-separator mt20"></div>
        </div>
    </div>
    <div class="content-wrapper">
        <!-- NO JOB DESCRIPTION AI VERSION -->
        <ng-container *ngIf="dataLoaded && !jdEnabled">
            <div class="content-section" id="job-details" *ngIf="activeSection === 'job-details'">
                <form [formGroup]="jobDetailsForm">
                    <div class="form-content-section">
                        <div class="form-content-holder">
                            <div>
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Job Details</h3>
                                    <p class="form-block-info__description">
                                        Enter your job details as you’d like them to appear for candidates. The point is
                                        to keep the titles shorter and more generic and not to mix the type of position
                                        with the specific company "slot" for that position.
                                    </p>
                                </div>
                                <div class="form-block" *ngIf="action === 'takeOwnership'">
                                    <div class="flex-row mb30">
                                        <div
                                            class="hiringType mr30"
                                            [class.active]="jobDetailsForm.value.hiring_role === 'new'"
                                            (click)="onChangeHiringType('new')"
                                        >
                                            Hiring for a new role
                                        </div>
                                        <div
                                            class="hiringType"
                                            [class.active]="jobDetailsForm.value.hiring_role === 'exist'"
                                            (click)="onChangeHiringType('exist')"
                                        >
                                            Hiring for an existing roll
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Job Title</mat-label>
                                            <input
                                                type="text"
                                                formControlName="title"
                                                maxlength="{{ titleMaxLength }}"
                                                matInput
                                            />
                                            <mat-error
                                                *ngIf="
                                                    !jobDetailsForm.controls.title.valid &&
                                                    jobDetailsForm.controls.title.touched
                                                "
                                            >
                                                Job title is required</mat-error
                                            >
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <app-ac-chips-material
                                            formControlName="category"
                                            [suggestions]="categories"
                                            title="Category"
                                            key="title"
                                            (onValueChange)="onCategoriesChange($event)"
                                        ></app-ac-chips-material>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <div class="input-block right-option-block">
                                            <app-ac-chips-material
                                                *ngIf="tenantLocationOptions.length && job"
                                                formControlName="location"
                                                [suggestions]="tenantLocationOptions"
                                                [setSuggestions]="setSuggestions"
                                                [disabled]="job.applications > 0"
                                                title="Location"
                                                key="name"
                                                (onValueChange)="onLocationAdded($event)"
                                                (onAddNewLocation)="onAddNewLocation($event)"
                                                [ngClass]="{
                                                    invalid:
                                                        !jobDetailsForm.get('location').valid &&
                                                        jobDetailsForm.get('location').touched
                                                }"
                                            ></app-ac-chips-material>
                                            <div class="right-option">
                                                <p-checkbox
                                                    label="Remote"
                                                    formControlName="is_remote"
                                                    binary="true"
                                                    inputId="is_remote"
                                                    [disabled]="job.applications > 0"
                                                ></p-checkbox>
                                            </div>
                                        </div>
                                        <div
                                            class="error-message"
                                            *ngIf="
                                                !jobDetailsForm.get('location').valid &&
                                                jobDetailsForm.get('location').touched
                                            "
                                        >
                                            Primary job location is required
                                        </div>
                                        <div class="input-block"></div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <app-auto-dropdown
                                            [options]="businessUnitOptions"
                                            [control]="jobDetailsForm.get('business_unit')"
                                            placeholder="Business Unit"
                                            [ngClass]="{
                                                invalid:
                                                    !jobDetailsForm.controls.business_unit.valid &&
                                                    jobDetailsForm.controls.business_unit.touched
                                            }"
                                        >
                                        </app-auto-dropdown>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Reference / Client / Project</mat-label>
                                                    <input type="text" formControlName="ref" matInput />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Cost Centre</mat-label>
                                                    <input
                                                        type="text"
                                                        formControlName="cost_centre"
                                                        autocomplete="off"
                                                        matInput
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-separator mb30 mt20"></div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="jobTypeOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.type"
                                                    [placeholder]="'Job Type'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.type.valid &&
                                                            jobDetailsForm.controls.type.touched
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.type.valid &&
                                                    jobDetailsForm.controls.type.touched
                                                "
                                            >
                                                Job type is required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="hiresOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.number_of_hires"
                                                    [placeholder]="'Positions Available'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.number_of_hires.valid &&
                                                            jobDetailsForm.controls.number_of_hires.touched
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.number_of_hires.valid &&
                                                    jobDetailsForm.controls.number_of_hires.touched
                                                "
                                            >
                                                Number of positions is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col form-block-col-50">
                                            <div class="input-block">
                                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                                    <mat-label>Ideal Start Date</mat-label>
                                                    <input
                                                        formControlName="start_date"
                                                        matInput
                                                        [matDatepicker]="picker1"
                                                        autocomplete="off"
                                                    />
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [disabled]="disableContractDuration"
                                                    [options]="contractDuration"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.contract_duration"
                                                    [placeholder]="'Contract Duration (if applicable)'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.contract_duration.valid &&
                                                            jobDetailsForm.controls.contract_duration.touched &&
                                                            (jobDetailsForm.controls.type.value === 'temporary' ||
                                                                jobDetailsForm.controls.type.value === 'fixed_term' ||
                                                                jobDetailsForm.controls.type.value === 'freelance')
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.contract_duration.valid &&
                                                    jobDetailsForm.controls.contract_duration.touched &&
                                                    (jobDetailsForm.controls.type.value === 'temporary' ||
                                                        jobDetailsForm.controls.type.value === 'fixed_term' ||
                                                        jobDetailsForm.controls.type.value === 'freelance')
                                                "
                                            >
                                                Contract Duration is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col-separator"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-content-section" formGroupName="salary">
                        <div class="form-content-holder">
                            <div>
                                <div class="form-block-info form-block-info-salary">
                                    <h3 class="form-block-info__title">Salary</h3>
                                    <p class="form-block-info__description">
                                        Entering a salary is required for benchmarking and reporting but you may choose
                                        to hide it from the candidate and hiring team view.
                                    </p>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="currencyOptions"
                                                    [newStyle]="true"
                                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                                    [control]="jobDetailsForm.controls.salary.controls.currency"
                                                    [placeholder]="'Currency'"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.salary.controls.currency.valid &&
                                                    jobDetailsForm.controls.salary.controls.currency.touched
                                                "
                                            >
                                                Currency required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="salaryOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.salary.controls.period"
                                                    [placeholder]="'Frequency'"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <div class="input-block">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label *ngIf="!job?.salary.single">From</mat-label>
                                                        <mat-label *ngIf="job?.salary.single">Salary</mat-label>
                                                        <input
                                                            type="text"
                                                            currencyMask
                                                            [options]="{
                                                                prefix: '',
                                                                thousands: ',',
                                                                precision: 0,
                                                                align: 'left'
                                                            }"
                                                            placeholder="0"
                                                            formControlName="from"
                                                            matInput
                                                        />
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="error-message"
                                                    *ngIf="
                                                        !jobDetailsForm.controls.salary.controls.from.valid &&
                                                        jobDetailsForm.controls.salary.controls.from.touched
                                                    "
                                                >
                                                    Salary required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator" *ngIf="!job?.salary.single"></div>
                                        <div class="form-block-col" *ngIf="!job?.salary.single">
                                            <div class="input-block">
                                                <div class="input-block">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>To</mat-label>
                                                        <input
                                                            type="text"
                                                            [options]="{
                                                                prefix: '',
                                                                thousands: ',',
                                                                precision: 0,
                                                                align: 'left'
                                                            }"
                                                            currencyMask
                                                            placeholder="0"
                                                            formControlName="to"
                                                            maxlength="20"
                                                            matInput
                                                        />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    jobDetailsForm.controls.salary.controls.to.errors?.WrongSalary &&
                                                    jobDetailsForm.controls.salary.controls.to.touched
                                                "
                                            >
                                                Cannot be less than "From"
                                            </div>
                                        </div>
                                    </div>
                                    <p
                                        class="form-tip danger"
                                        (click)="toggleSalaryField()"
                                        *ngIf="!job?.salary.single"
                                    >
                                        Use Single Salary
                                    </p>
                                    <p class="form-tip danger" (click)="toggleSalaryField()" *ngIf="job?.salary.single">
                                        Use Salary Range
                                    </p>
                                </div>
                                <div class="form-block mb25">
                                    <p-checkbox
                                        name="hide_salary"
                                        binary="true"
                                        label="Hide this salary from the job posting"
                                        formControlName="hide"
                                        inputId="hide_salary"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-content-section" *ngIf="job?.status === 'draft' || job?.status === 'BUILD'">
                        <div class="form-content-holder">
                            <div class="form-block-info form-block-info-salary">
                                <h3 class="form-block-info__title">Hiring Team</h3>
                            </div>
                            <div class="form-block mb0">
                                <div class="form-block-columns form-block-hiring">
                                    <div
                                        class="form-block-col"
                                        formArrayName="hiring_managers"
                                        *ngFor="
                                            let item of jobDetailsForm.get('hiring_managers')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="hiringManagerOptions"
                                                [control]="item"
                                                placeholder="Hiring Manager"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                    <div
                                        class="form-block-col"
                                        formArrayName="hr_business_partners"
                                        *ngFor="
                                            let item of jobDetailsForm.get('hr_business_partners')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="hrPartnerOptions"
                                                [control]="item"
                                                placeholder="HR Partner"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-block">
                                <div class="form-block-columns form-block-hiring">
                                    <div
                                        class="form-block-col"
                                        formArrayName="recruiters"
                                        *ngFor="
                                            let item of jobDetailsForm.get('recruiters')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="recruiterManagerOptions"
                                                [control]="item"
                                                placeholder="Recruiters"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-content-section">
                        <div class="form-content-holder">
                            <div>
                                <div class="form-block-info mt30">
                                    <h3 class="form-block-info__title">Job Description</h3>
                                    <p class="form-block-info__description">
                                        Select a job description from our list of job templates or enter your own. Write
                                        your job description with the ideal candidate in mind and consider their search
                                        criteria when looking for your job.
                                    </p>
                                </div>
                                <div class="form-block" *ngIf="isNewOrDraft">
                                    <div class="input-block">
                                        <app-auto-dropdown
                                            *ngIf="JobDescriptionOptions && JobDescriptionOptions.length"
                                            [options]="JobDescriptionOptions"
                                            [control]="jobDetailsForm.controls.role"
                                            [newStyle]="true"
                                            placeholder="Find A Job Description"
                                            (onChange)="onChangeJob($event)"
                                            [editable]="disableEdit"
                                        ></app-auto-dropdown>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="editors-container">
                                        <div class="editors-holder">
                                            <div
                                                class="error-message"
                                                *ngIf="description.errors?.required && description.touched"
                                            >
                                                Job description is required
                                            </div>
                                            <strong class="editor-title">Description</strong>
                                            <app-editor formControlName="description">
                                                <p-header>
                                                    <div>
                                                        <span class="ql-formats">
                                                            <button class="ql-bold" aria-label="Bold"></button>
                                                            <button class="ql-italic" aria-label="Italic"></button>
                                                            <button
                                                                class="ql-underline"
                                                                aria-label="Underline"
                                                            ></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button
                                                                class="ql-list"
                                                                value="ordered"
                                                                type="button"
                                                            ></button>
                                                            <button
                                                                class="ql-list"
                                                                value="bullet"
                                                                type="button"
                                                            ></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button
                                                                aria-label="Remove Styles"
                                                                class="ql-clean"
                                                                type="button"
                                                            ></button>
                                                        </span>
                                                    </div>
                                                    <span
                                                        class="ql-formats job_desc"
                                                        *ngIf="isNewOrDraft && JobDescriptionOptions"
                                                    >
                                                        <p-dropdown
                                                            [options]="JobDescriptionOptions"
                                                            formControlName="role"
                                                            [style]="{ width: '100%' }"
                                                            [filter]="true"
                                                            [(ngModel)]="selectedJob"
                                                            filterBy="label,value.role"
                                                            [editable]="disableEdit"
                                                        ></p-dropdown>
                                                    </span>
                                                </p-header>
                                            </app-editor>
                                        </div>
                                        <div class="editors-holder">
                                            <div
                                                class="error-message"
                                                *ngIf="requirements.errors?.required && requirements.touched"
                                            >
                                                Requirements is required
                                            </div>
                                            <strong class="editor-title">Requirements</strong>
                                            <app-editor formControlName="requirements">
                                                <p-header>
                                                    <div>
                                                        <span class="ql-formats">
                                                            <button class="ql-bold" aria-label="Bold"></button>
                                                            <button class="ql-italic" aria-label="Italic"></button>
                                                            <button
                                                                class="ql-underline"
                                                                aria-label="Underline"
                                                            ></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button
                                                                class="ql-list"
                                                                value="ordered"
                                                                type="button"
                                                            ></button>
                                                            <button
                                                                class="ql-list"
                                                                value="bullet"
                                                                type="button"
                                                            ></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button
                                                                aria-label="Remove Styles"
                                                                class="ql-clean"
                                                                type="button"
                                                            ></button>
                                                        </span>
                                                    </div>
                                                </p-header>
                                            </app-editor>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-block" *ngIf="action === 'takeOwnership'">-->
                                <div
                                    class="form-block"
                                    *ngIf="
                                        (job.approval && job.approval.url) ||
                                        (job.specification && job.specification.url)
                                    "
                                >
                                    <h3 class="mt50 form-block-info__title">Attachments</h3>
                                    <div class="attachments-block">
                                        <a
                                            target="_blank"
                                            [href]="job.approval.url"
                                            [title]="job.approval.name"
                                            *ngIf="job?.approval?.url"
                                        >
                                            <span class="file-icon">
                                                <img
                                                    *ngIf="job.approval.name"
                                                    src="/assets/images/icons/files-icon-{{
                                                        utilities.getExtension(job.approval.name)
                                                    }}.svg"
                                                    alt=""
                                                />
                                            </span>
                                            <div class="file-block-holder">
                                                <span class="title">{{ job.approval.name }}</span>
                                                <span class="label">Approval</span>
                                            </div>
                                        </a>
                                        <a
                                            download
                                            target="_blank"
                                            [href]="job.specification.url"
                                            [title]="job.specification.name"
                                            *ngIf="job?.specification?.url"
                                        >
                                            <span class="file-icon">
                                                <img
                                                    *ngIf="job.specification.name"
                                                    src="/assets/images/icons/files-icon-{{
                                                        utilities.getExtension(job.specification.name)
                                                    }}.svg"
                                                    alt=""
                                                />
                                            </span>
                                            <div class="file-block-holder">
                                                <span class="title">{{ job.specification.name }}</span>
                                                <span class="label">Job Description</span>
                                            </div>
                                        </a>
                                        <a
                                            target="_blank"
                                            [href]="job.job_description.url"
                                            [title]="job.job_description.name"
                                            *ngIf="job?.job_description?.url"
                                        >
                                            <span class="file-icon">
                                                <img
                                                    *ngIf="job.job_description.name"
                                                    src="/assets/images/icons/files-icon-{{
                                                        utilities.getExtension(job.job_description.name)
                                                    }}.svg"
                                                    alt=""
                                                />
                                            </span>
                                            <div class="file-block-holder">
                                                <span class="title">{{ job.job_description.name }}</span>
                                                <span class="label">Job Description</span>
                                            </div>
                                        </a>
                                        <a
                                            target="_blank"
                                            [href]="job.job_brief.url"
                                            [title]="job.job_brief.name"
                                            *ngIf="job?.job_brief?.url"
                                        >
                                            <span class="file-icon">
                                                <img
                                                    *ngIf="job.job_brief.name"
                                                    src="/assets/images/icons/files-icon-{{
                                                        utilities.getExtension(job.job_brief.name)
                                                    }}.svg"
                                                    alt=""
                                                />
                                            </span>
                                            <div class="file-block-holder">
                                                <span class="title">{{ job.job_brief.name }}</span>
                                                <span class="label">Job Brief</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
        <!-- JOB DESCRIPTION AI VERSION -->
        <ng-container *ngIf="dataLoaded && jdEnabled">
            <div class="content-section" id="job-details" *ngIf="activeSection === 'job-details'">
                <form [formGroup]="jobDetailsForm">
                    <div class="form-content-section">
                        <div class="form-content-holder">
                            <div>
                                <div class="form-block-info">
                                    <!-- <h3 class="form-block-info__title">Job Details</h3> -->
                                    <p class="form-block-info__description">
                                        Enter your job details as you’d like them to appear for candidates. The point is
                                        to keep the titles shorter and more generic and not to mix the type of position
                                        with the specific company "slot" for that position.
                                    </p>
                                </div>
                                <div class="form-block" *ngIf="action === 'takeOwnership'">
                                    <div class="flex-row mb30">
                                        <div
                                            class="hiringType mr30"
                                            [class.active]="jobDetailsForm.value.hiring_role === 'new'"
                                            (click)="onChangeHiringType('new')"
                                        >
                                            Hiring for a new role
                                        </div>
                                        <div
                                            class="hiringType"
                                            [class.active]="jobDetailsForm.value.hiring_role === 'exist'"
                                            (click)="onChangeHiringType('exist')"
                                        >
                                            Hiring for an existing roll
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block" [ngClass]="{ warning: titleWarning.show }">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Job Title</mat-label>
                                            <input
                                                type="text"
                                                formControlName="title"
                                                maxlength="{{ titleMaxLength }}"
                                                matInput
                                                [matAutocomplete]="auto"
                                            />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option
                                                    *ngFor="let option of filteredTitlesOptions"
                                                    [value]="option"
                                                >
                                                    {{ option }}
                                                </mat-option>
                                            </mat-autocomplete>

                                            <mat-error
                                                *ngIf="
                                                    !jobDetailsForm.controls.title.valid &&
                                                    jobDetailsForm.controls.title.touched
                                                "
                                            >
                                                Job title is required</mat-error
                                            >
                                        </mat-form-field>
                                        <div class="description-warning description-title" *ngIf="titleWarning.show">
                                            <div class="status-icon warning">
                                                <div class="holder">
                                                    <div class="bar1"></div>
                                                    <div class="bar2"></div>
                                                    <div class="bar3"></div>
                                                    <div class="fill"></div>
                                                    <div class="mark"></div>
                                                </div>
                                            </div>
                                            This job is at risk of not being found due to the use of a non-standard job
                                            title
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <app-ac-chips-material
                                            formControlName="category"
                                            [suggestions]="categories"
                                            title="Category"
                                            key="title"
                                            (onValueChange)="onCategoriesChange($event)"
                                        ></app-ac-chips-material>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="input-block">
                                        <div class="input-block right-option-block">
                                            <app-ac-chips-material
                                                *ngIf="tenantLocationOptions.length && job"
                                                formControlName="location"
                                                [suggestions]="tenantLocationOptions"
                                                [setSuggestions]="setSuggestions"
                                                [disabled]="job.applications > 0"
                                                title="Location"
                                                key="name"
                                                (onValueChange)="onLocationAdded($event)"
                                                (onAddNewLocation)="onAddNewLocation($event)"
                                                [ngClass]="{
                                                    invalid:
                                                        !jobDetailsForm.get('location').valid &&
                                                        jobDetailsForm.get('location').touched
                                                }"
                                            ></app-ac-chips-material>
                                            <div class="right-option">
                                                <p-checkbox
                                                    label="Remote"
                                                    formControlName="is_remote"
                                                    binary="true"
                                                    inputId="is_remote"
                                                    [disabled]="job.applications > 0"
                                                ></p-checkbox>
                                            </div>
                                        </div>
                                        <div
                                            class="error-message"
                                            *ngIf="
                                                !jobDetailsForm.get('location').valid &&
                                                jobDetailsForm.get('location').touched
                                            "
                                        >
                                            Primary job location is required
                                        </div>
                                        <div class="input-block"></div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="businessUnitOptions"
                                                    [control]="jobDetailsForm.get('business_unit')"
                                                    placeholder="Business Unit"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.business_unit.valid &&
                                                            jobDetailsForm.controls.business_unit.touched
                                                    }"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Reference / Client / Project</mat-label>
                                                    <input type="text" formControlName="ref" matInput />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-separator mb30 mt20"></div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="jobTypeOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.type"
                                                    [placeholder]="'Job Type'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.type.valid &&
                                                            jobDetailsForm.controls.type.touched
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.type.valid &&
                                                    jobDetailsForm.controls.type.touched
                                                "
                                            >
                                                Job type is required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="hiresOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.number_of_hires"
                                                    [placeholder]="'Positions Available'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.number_of_hires.valid &&
                                                            jobDetailsForm.controls.number_of_hires.touched
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.number_of_hires.valid &&
                                                    jobDetailsForm.controls.number_of_hires.touched
                                                "
                                            >
                                                Number of positions is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col form-block-col-50">
                                            <div class="input-block">
                                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                                    <mat-label>Ideal Start Date</mat-label>
                                                    <input
                                                        formControlName="start_date"
                                                        matInput
                                                        [matDatepicker]="picker1"
                                                        autocomplete="off"
                                                    />
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [disabled]="disableContractDuration"
                                                    [options]="contractDuration"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.contract_duration"
                                                    [placeholder]="'Contract Duration (if applicable)'"
                                                    [ngClass]="{
                                                        invalid:
                                                            !jobDetailsForm.controls.contract_duration.valid &&
                                                            jobDetailsForm.controls.contract_duration.touched &&
                                                            (jobDetailsForm.controls.type.value === 'temporary' ||
                                                                jobDetailsForm.controls.type.value === 'fixed_term' ||
                                                                jobDetailsForm.controls.type.value === 'freelance')
                                                    }"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.contract_duration.valid &&
                                                    jobDetailsForm.controls.contract_duration.touched &&
                                                    (jobDetailsForm.controls.type.value === 'temporary' ||
                                                        jobDetailsForm.controls.type.value === 'fixed_term' ||
                                                        jobDetailsForm.controls.type.value === 'freelance')
                                                "
                                            >
                                                Contract Duration is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col-separator"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-content-section" formGroupName="salary">
                        <div class="form-content-holder">
                            <div>
                                <div class="form-block-info form-block-info-salary">
                                    <h3 class="form-block-info__title">Salary</h3>
                                    <p class="form-block-info__description">
                                        Entering a salary is required for benchmarking and reporting but you may choose
                                        to hide it from the candidate and hiring team view.
                                    </p>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="currencyOptions"
                                                    [newStyle]="true"
                                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                                    [control]="jobDetailsForm.controls.salary.controls.currency"
                                                    [placeholder]="'Currency'"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !jobDetailsForm.controls.salary.controls.currency.valid &&
                                                    jobDetailsForm.controls.salary.controls.currency.touched
                                                "
                                            >
                                                Currency required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="salaryOptions"
                                                    [newStyle]="true"
                                                    [control]="jobDetailsForm.controls.salary.controls.period"
                                                    [placeholder]="'Frequency'"
                                                >
                                                </app-auto-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <div class="input-block">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label *ngIf="!job?.salary.single">From</mat-label>
                                                        <mat-label *ngIf="job?.salary.single">Salary</mat-label>
                                                        <input
                                                            type="text"
                                                            currencyMask
                                                            [options]="{
                                                                prefix: '',
                                                                thousands: ',',
                                                                precision: 0,
                                                                align: 'left'
                                                            }"
                                                            placeholder="0"
                                                            formControlName="from"
                                                            matInput
                                                        />
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="error-message"
                                                    *ngIf="
                                                        !jobDetailsForm.controls.salary.controls.from.valid &&
                                                        jobDetailsForm.controls.salary.controls.from.touched
                                                    "
                                                >
                                                    Salary required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator" *ngIf="!job?.salary.single"></div>
                                        <div class="form-block-col" *ngIf="!job?.salary.single">
                                            <div class="input-block">
                                                <div class="input-block">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>To</mat-label>
                                                        <input
                                                            type="text"
                                                            [options]="{
                                                                prefix: '',
                                                                thousands: ',',
                                                                precision: 0,
                                                                align: 'left'
                                                            }"
                                                            currencyMask
                                                            placeholder="0"
                                                            formControlName="to"
                                                            maxlength="20"
                                                            matInput
                                                        />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    jobDetailsForm.controls.salary.controls.to.errors?.WrongSalary &&
                                                    jobDetailsForm.controls.salary.controls.to.touched
                                                "
                                            >
                                                Cannot be less than "From"
                                            </div>
                                        </div>
                                    </div>
                                    <p
                                        class="form-tip danger"
                                        (click)="toggleSalaryField()"
                                        *ngIf="!job?.salary.single"
                                    >
                                        Use Single Salary
                                    </p>
                                    <p class="form-tip danger" (click)="toggleSalaryField()" *ngIf="job?.salary.single">
                                        Use Salary Range
                                    </p>
                                </div>
                                <div class="form-block mb25">
                                    <p-checkbox
                                        name="hide_salary"
                                        binary="true"
                                        label="Hide this salary from the job posting"
                                        formControlName="hide"
                                        inputId="hide_salary"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-content-section" *ngIf="job?.status === 'draft' || job?.status === 'BUILD'">
                        <div class="form-content-holder">
                            <div class="form-block-info form-block-info-salary">
                                <h3 class="form-block-info__title">Hiring Team</h3>
                            </div>
                            <div class="form-block">
                                <div class="form-block-columns form-block-hiring">
                                    <div
                                        class="form-block-col"
                                        formArrayName="hiring_managers"
                                        *ngFor="
                                            let item of jobDetailsForm.get('hiring_managers')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="hiringManagerOptions"
                                                [control]="item"
                                                placeholder="Hiring Manager"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                    <div
                                        class="form-block-col"
                                        formArrayName="hr_business_partners"
                                        *ngFor="
                                            let item of jobDetailsForm.get('hr_business_partners')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="hrPartnerOptions"
                                                [control]="item"
                                                placeholder="HR Partner"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-block">
                                <div class="form-block-columns form-block-hiring">
                                    <div
                                        class="form-block-col"
                                        formArrayName="recruiters"
                                        *ngFor="
                                            let item of jobDetailsForm.get('recruiters')['controls'];
                                            let i = index;
                                            let first = first
                                        "
                                    >
                                        <div class="input-block" [formGroupName]="i">
                                            <app-auto-dropdown
                                                [options]="recruiterManagerOptions"
                                                [control]="item"
                                                placeholder="Recruiters"
                                                [ngClass]="{
                                                    invalid: !item.valid && item.touched
                                                }"
                                            >
                                            </app-auto-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="details" *ngIf="mode === 'new' || (mode === 'edit' && !job.jdAiCompleted)">
                        <!-- Skills  -->
                        <ng-container *ngIf="jobDescriptionBuilderSteps.titleCompleted">
                            <div class="form-content-section skills-section">
                                <app-loader *ngIf="skillsLoading" class="posa"></app-loader>
                                <div class="form-content-holder">
                                    <div class="form-block-info mt30">
                                        <div class="form-block-info-title-block fdr">
                                            <h3 class="form-block-info__title">Skills</h3>
                                            <button class="btn btn-simple mla" (click)="onMoreSkills()">
                                                <img src="/assets/images/plus-icon.svg" alt="" />
                                                <span class="text">&nbsp;&nbsp;Suggest More</span>
                                            </button>
                                        </div>
                                        <p class="form-block-info__description">
                                            Based on your job title, we have identified the following skills that may be
                                            appropriate to your job listing. Please select all those that are relevant
                                            below. Click Suggest More should you not find the skills you’re looking for
                                            below. You can add additional skills that may not have been listed in the
                                            input field below.
                                        </p>
                                    </div>
                                    <div class="skills-holder">
                                        <div
                                            class="skill-item"
                                            *ngFor="let skill of skillsList"
                                            [ngClass]="{ selected: skill.selected }"
                                            (click)="onSkillClick(skill)"
                                            title="{{ skill.name }}"
                                        >
                                            {{ skill.name }}
                                        </div>
                                    </div>
                                    <div class="error-message" *ngIf="skillsCompletionError">
                                        Select a minimum of 4 and a maximum of 8 skills
                                    </div>
                                    <div class="skills-input">
                                        <mat-form-field class="example-chip-list" appearance="outline">
                                            <mat-chip-list #chipList aria-label="Additional Skills">
                                                <mat-chip
                                                    *ngFor="let skill of additionalSkills"
                                                    [selectable]="selectableAdditionalSkill"
                                                    [removable]="removableAdditionalSkill"
                                                    (removed)="removeAdditionalSkill(skill)"
                                                >
                                                    {{ skill.name }}
                                                    <button matChipRemove *ngIf="removableAdditionalSkill">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </mat-chip>
                                                <input
                                                    placeholder="Additional Skills"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlurAdditionalSkill"
                                                    (matChipInputTokenEnd)="addAdditionalSkill($event)"
                                                />
                                            </mat-chip-list>
                                        </mat-form-field>
                                        <span class="note">Please seperate skills with a ","</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- /Skills  -->
                    </div>
                    <div
                        class="form-block"
                        *ngIf="(job.approval && job.approval.url) || (job.specification && job.specification.url)"
                    >
                        <h3 class="mt50 form-block-info__title">Attachments</h3>
                        <div class="attachments-block">
                            <a
                                target="_blank"
                                [href]="job.approval.url"
                                [title]="job.approval.name"
                                *ngIf="job?.approval?.url"
                            >
                                <span class="file-icon">
                                    <img
                                        *ngIf="job.approval.name"
                                        src="/assets/images/icons/files-icon-{{
                                            utilities.getExtension(job.approval.name)
                                        }}.svg"
                                        alt=""
                                    />
                                </span>
                                <div class="file-block-holder">
                                    <span class="title">{{ job.approval.name }}</span>
                                    <span class="label">Approval</span>
                                </div>
                            </a>
                            <a
                                download
                                target="_blank"
                                [href]="job.specification.url"
                                [title]="job.specification.name"
                                *ngIf="job?.specification?.url"
                            >
                                <span class="file-icon">
                                    <img
                                        *ngIf="job.specification.name"
                                        src="/assets/images/icons/files-icon-{{
                                            utilities.getExtension(job.specification.name)
                                        }}.svg"
                                        alt=""
                                    />
                                </span>
                                <div class="file-block-holder">
                                    <span class="title">{{ job.specification.name }}</span>
                                    <span class="label">Job Description</span>
                                </div>
                            </a>
                            <a
                                target="_blank"
                                [href]="job.job_description.url"
                                [title]="job.job_description.name"
                                *ngIf="job?.job_description?.url"
                            >
                                <span class="file-icon">
                                    <img
                                        *ngIf="job.job_description.name"
                                        src="/assets/images/icons/files-icon-{{
                                            utilities.getExtension(job.job_description.name)
                                        }}.svg"
                                        alt=""
                                    />
                                </span>
                                <div class="file-block-holder">
                                    <span class="title">{{ job.job_description.name }}</span>
                                    <span class="label">Job Description</span>
                                </div>
                            </a>
                            <a
                                target="_blank"
                                [href]="job.job_brief.url"
                                [title]="job.job_brief.name"
                                *ngIf="job?.job_brief?.url"
                            >
                                <span class="file-icon">
                                    <img
                                        *ngIf="job.job_brief.name"
                                        src="/assets/images/icons/files-icon-{{
                                            utilities.getExtension(job.job_brief.name)
                                        }}.svg"
                                        alt=""
                                    />
                                </span>
                                <div class="file-block-holder">
                                    <span class="title">{{ job.job_brief.name }}</span>
                                    <span class="label">Job Brief</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- <div *ngIf="mode === 'edit' && job.jdAiCompleted">
                        <br />
                        <br />
                        <div class="form-block">
                            <div class="editors-container">
                                <div class="editors-holder">
                                    <div
                                        class="error-message"
                                        *ngIf="description.errors?.required && description.touched"
                                    >
                                        Job description is required
                                    </div>
                                    <app-editor formControlName="description">
                                        <p-header>
                                            <div>
                                                <span class="ql-formats">
                                                    <button class="ql-bold" aria-label="Bold"></button>
                                                    <button class="ql-italic" aria-label="Italic"></button>
                                                    <button class="ql-underline" aria-label="Underline"></button>
                                                </span>
                                                <span class="ql-formats">
                                                    <button class="ql-list" value="ordered" type="button"></button>
                                                    <button class="ql-list" value="bullet" type="button"></button>
                                                </span>
                                                <span class="ql-formats">
                                                    <button
                                                        aria-label="Remove Styles"
                                                        class="ql-clean"
                                                        type="button"
                                                    ></button>
                                                </span>
                                            </div>
                                        </p-header>
                                    </app-editor>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </form>
            </div>
            <div class="content-section" id="job-details" *ngIf="activeSection === 'details'">
                <form [formGroup]="jobDetailsForm">
                    <div formGroupName="details">
                        <!-- Requirements -->
                        <div class="form-content-section">
                            <div class="form-content-holder">
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Requirements</h3>
                                    <p
                                        class="form-block-info__description grey"
                                        *ngIf="!jobDescriptionBuilderSteps.requirementsCompleted"
                                    >
                                        Select a minimum of 4 and a maximum of 8 job requirements
                                    </p>
                                    <div class="form-block-info__description">
                                        <app-editor
                                            formControlName="requirements"
                                            *ngIf="jobDescriptionBuilderSteps.requirementsCompleted"
                                            hideToolbar="true"
                                            class="editor"
                                        >
                                        </app-editor>
                                        <button
                                            class="btn btn-simple"
                                            (click)="onGenerateRequirements()"
                                            [disabled]="!jobDetailsForm.get('title').value.length"
                                        >
                                            <img src="/assets/images/plus-icon.svg" alt="" />
                                            <span class="text"
                                                >&nbsp;&nbsp;{{
                                                    jobDescriptionBuilderSteps.requirementsCompleted
                                                        ? 'Regenerate Requirements'
                                                        : 'Generate Requirements'
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Requirements -->
                        <!-- Qualifications -->
                        <div class="form-content-section" *ngIf="jobDescriptionBuilderSteps.requirementsCompleted">
                            <div class="form-content-holder">
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Qualifications</h3>
                                    <p
                                        class="form-block-info__description grey"
                                        *ngIf="!jobDescriptionBuilderSteps.qualificationsCompleted"
                                    >
                                        Select a minimum of 4 and a maximum of 8 job qualifications
                                    </p>
                                    <div class="form-block-info__description">
                                        <app-editor
                                            formControlName="qualifications"
                                            *ngIf="jobDescriptionBuilderSteps.qualificationsCompleted"
                                            hideToolbar="true"
                                            class="editor"
                                        >
                                        </app-editor>
                                        <button
                                            class="btn btn-simple"
                                            (click)="onGenerateQualifications()"
                                            [disabled]="!jobDetailsForm.get('title').value.length"
                                        >
                                            <img src="/assets/images/plus-icon.svg" alt="" />
                                            <span class="text"
                                                >&nbsp;&nbsp;{{
                                                    jobDescriptionBuilderSteps.qualificationsCompleted
                                                        ? 'Regenerate Qualifications'
                                                        : 'Generate Qualifications'
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Qualifications -->
                        <!-- Role Summary -->
                        <div class="form-content-section" *ngIf="jobDescriptionBuilderSteps.qualificationsCompleted">
                            <div class="form-content-holder">
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Role Summary</h3>
                                    <div class="form-block-info__description">
                                        <app-editor
                                            formControlName="roleSummary"
                                            *ngIf="jobDescription.roleSummary.length"
                                            hideToolbar="true"
                                            class="editor"
                                        >
                                        </app-editor>
                                        <button
                                            class="btn btn-simple"
                                            (click)="onGenerateRoleSummary()"
                                            [disabled]="!jobDetailsForm.get('title').value.length"
                                        >
                                            <img src="/assets/images/plus-icon.svg" alt="" />
                                            <span class="text"
                                                >&nbsp;&nbsp;{{
                                                    jobDescriptionBuilderSteps.roleSummaryCompleted
                                                        ? 'Regenerate Role Summary'
                                                        : 'Generate Role Summary'
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Role Summary -->
                        <!-- Current Career Pain Points -->
                        <div class="form-content-section" *ngIf="jobDescriptionBuilderSteps.roleSummaryCompleted">
                            <div class="form-content-holder">
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Current Career Pain Points</h3>
                                    <p class="form-block-info__description grey">
                                        Select a list of pain points that the candidate may currently be facing in their
                                        job. Something that your job may fix for them.
                                    </p>
                                    <div class="skills-holder">
                                        <div
                                            class="skill-item"
                                            *ngFor="let point of painPoints"
                                            (click)="onPainPointClick(point)"
                                            [ngClass]="{ selected: point.selected }"
                                        >
                                            {{ point.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Current Career Pain Points -->
                        <!-- Sell The Job -->
                        <div class="form-content-section" *ngIf="jobDescriptionBuilderSteps.painCompleted">
                            <div class="form-content-holder">
                                <div class="form-block-info">
                                    <h3 class="form-block-info__title">Sell The Job</h3>
                                    <div class="form-block-info__description">
                                        <app-editor
                                            formControlName="sellTheJob"
                                            *ngIf="jobDescription.sellTheJob.length"
                                            hideToolbar="true"
                                            class="editor"
                                        >
                                        </app-editor>

                                        <button
                                            class="btn btn-simple"
                                            (click)="onGenerateSellTheJob()"
                                            [disabled]="!jobDetailsForm.get('title').value.length"
                                        >
                                            <img src="/assets/images/plus-icon.svg" alt="" />
                                            <span class="text"
                                                >&nbsp;&nbsp;{{
                                                    jobDescriptionBuilderSteps.qualificationsCompleted
                                                        ? 'Regenerate Role Intro'
                                                        : 'Generate Role Intro'
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="content-section" id="job-details" *ngIf="activeSection === 'description'">
                <form [formGroup]="jobDetailsForm">
                    <div class="form-content-section">
                        <div class="form-content-holder">
                            <div class="description-warning" *ngIf="descriptionWarning.show">
                                <div class="status-icon warning">
                                    <div class="holder">
                                        <div class="bar1"></div>
                                        <div class="bar2"></div>
                                        <div class="bar3"></div>
                                        <div class="fill"></div>
                                        <div class="mark"></div>
                                    </div>
                                </div>
                                This job is at risk of not being found due to the length of the job description. Job
                                description should be a minimum of 250 words. Please consider using the job description
                                builder.
                            </div>
                            <div class="form-block">
                                <div class="editors-container">
                                    <div class="editors-holder">
                                        <div
                                            class="error-message"
                                            *ngIf="description.errors?.required && description.touched"
                                        >
                                            Job description is required
                                        </div>
                                        <app-editor formControlName="description">
                                            <p-header>
                                                <div>
                                                    <span class="ql-formats">
                                                        <button class="ql-bold" aria-label="Bold"></button>
                                                        <button class="ql-italic" aria-label="Italic"></button>
                                                        <button class="ql-underline" aria-label="Underline"></button>
                                                    </span>
                                                    <span class="ql-formats">
                                                        <button class="ql-list" value="ordered" type="button"></button>
                                                        <button class="ql-list" value="bullet" type="button"></button>
                                                    </span>
                                                    <span class="ql-formats">
                                                        <button
                                                            aria-label="Remove Styles"
                                                            class="ql-clean"
                                                            type="button"
                                                        ></button>
                                                    </span>
                                                </div>
                                            </p-header>
                                        </app-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
        <div class="content-section" id="applications" *ngIf="activeSection === 'applications'">
            <form [formGroup]="applicationsForm">
                <div class="form-content-section">
                    <div class="form-content-holder">
                        <div>
                            <div class="form-block-info">
                                <h3 class="form-block-info__title">Job Visibility</h3>
                                <p class="form-block-info__description">
                                    Choose how you would like your job to be discovered. The Boost network is a
                                    selection of job boards that your job will be posted to at no extra cost. We’ll also
                                    post to any linked social media accounts.
                                </p>
                            </div>
                            <div class="form-block">
                                <div class="job-options-block ">
                                    <p-dropdown
                                        (onChange)="onChangeJobStatus($event)"
                                        styleClass="statuses-dropdown"
                                        class="statuses-dropdown"
                                        [options]="jobStatusOptions"
                                        scrollHeight="360px"
                                        dropdownIcon="pi pi-caret-down"
                                        formControlName="status"
                                    >
                                        <ng-template let-item pTemplate="selectedItem">
                                            <div>
                                                <div class="ui-helper-label">
                                                    <svg-icon
                                                        class="ui-helper-img"
                                                        src="assets/images/job-icon-statuses/{{ item.img }}.svg"
                                                        style="width:12px;"
                                                    ></svg-icon>
                                                    <span>{{ item.label }}</span>
                                                </div>
                                                <div class="ui-helper-footer">{{ item.text }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-status pTemplate="item">
                                            <div class="ui-helper-clearfix">
                                                <svg-icon
                                                    class="ui-helper-img"
                                                    src="assets/images/job-icon-statuses/{{ status.img }}.svg"
                                                ></svg-icon>
                                                <div class="ui-helper-label">{{ status.label }}</div>
                                                <div class="ui-helper-footer">{{ status.text }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="form-block-info" *ngIf="applicationsForm.get('status').value === 'internal'">
                                <p class="form-block-info__description">
                                    <p-checkbox
                                        name="upload_resume_required"
                                        binary="true"
                                        label="Candidate is required to upload a Resume"
                                        formControlName="upload_resume_required"
                                        inputId="upload_resume_required"
                                    >
                                    </p-checkbox>
                                </p>
                            </div>
                            <!-- <div *ngIf="isShowAdvertisingFields">
                                <div class="form-block-info">
                                    <p class="form-block-info__description">
                                        The following additional information is required in order for us to publish to
                                        Google for Jobs and the Boost Network.
                                    </p>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="targetIndustryList"
                                                    [newStyle]="true"
                                                    [control]="applicationsForm.controls.industry"
                                                    [placeholder]="'Job Industry'"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !applicationsForm.controls.industry.valid &&
                                                    applicationsForm.controls.industry.touched
                                                "
                                            >
                                                Industry is required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="targetJobCategoryList"
                                                    [newStyle]="true"
                                                    [control]="applicationsForm.controls.vonq_category"
                                                    [placeholder]="'Job Category'"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !applicationsForm.controls.vonq_category.valid &&
                                                    applicationsForm.controls.vonq_category.touched
                                                "
                                            >
                                                Category is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="targetSeniorityList"
                                                    [newStyle]="true"
                                                    [control]="applicationsForm.controls.seniority"
                                                    [placeholder]="'Seniority'"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !applicationsForm.controls.seniority.valid &&
                                                    applicationsForm.controls.seniority.touched
                                                "
                                            >
                                                Seniority is required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="educationOptions"
                                                    [newStyle]="true"
                                                    [control]="applicationsForm.controls.education"
                                                    [placeholder]="'Education Level'"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !applicationsForm.controls.education.valid &&
                                                    applicationsForm.controls.education.touched
                                                "
                                            >
                                                Education is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-block">
                                    <div class="form-block-columns">
                                        <div class="form-block-col">
                                            <div class="input-block">
                                                <app-auto-dropdown
                                                    [options]="yearsOfExperienceOptions"
                                                    [newStyle]="true"
                                                    [control]="applicationsForm.controls.years_experience"
                                                    [placeholder]="'Years Of Experience'"
                                                ></app-auto-dropdown>
                                            </div>
                                            <div
                                                class="error-message"
                                                *ngIf="
                                                    !applicationsForm.controls.years_experience.valid &&
                                                    applicationsForm.controls.years_experience.touched
                                                "
                                            >
                                                Years of experience is required
                                            </div>
                                        </div>
                                        <div class="form-block-col-separator"></div>
                                        <div class="form-block-col"></div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="form-content-section">
                    <div
                        class="form-content-holder"
                        *ngIf="!job.status || job?.status === 'draft' || job?.status === 'BUILD'"
                    >
                        <div>
                            <div class="form-block-info">
                                <h3 class="form-block-info__title">Application Pipeline</h3>
                                <p class="form-block-info__description">
                                    The Application Pipeline determines the stages that candidates will progress through
                                    for this position.
                                </p>
                            </div>
                            <div class="form-block">
                                <div class="input-block">
                                    <p-dropdown
                                        (onChange)="onChangePipeline($event)"
                                        [disabled]="job.applications > 0"
                                        [options]="pipelineOptions"
                                        formControlName="pipeline"
                                        dropdownIcon="pi pi-caret-down"
                                        class="clear-drop pipeline-dropdown"
                                    >
                                        <ng-template let-item pTemplate="selectedItem">
                                            <div>
                                                <p class="ui-helper-title">{{ item.title }}</p>
                                                <p class="ui-helper-label">{{ item.label }}</p>
                                            </div>
                                        </ng-template>
                                        <ng-template let-status pTemplate="item">
                                            <div class="ui-helper-clearfix">
                                                <p class="ui-helper-title" *ngIf="status.title">{{ status?.title }}</p>
                                                <div class="ui-helper-label">{{ status.label }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        !applicationsForm.get('pipeline').valid &&
                                        applicationsForm.get('pipeline').touched
                                    "
                                >
                                    Application pipeline is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-content-section">
                    <div class="form-content-holder">
                        <div>
                            <div class="form-block-info">
                                <h3 class="form-block-info__title">Resume Matching</h3>
                                <p class="form-block-info__description">
                                    Control the two-way matching threshold for this job. This will enable you to more
                                    easily identify the right candidates for this positon.
                                </p>
                            </div>
                            <div class="form-block">
                                <div class="input-block slider-block">
                                    <div class="hc-slider-block">
                                        <p-slider
                                            styleClass="hc-slider"
                                            formControlName="resume_matching_threshold"
                                            [animate]="true"
                                            [min]="0"
                                            [max]="100"
                                            [step]="1"
                                            [disabled]="applicationsForm.get('do_not_match').value"
                                            (onChange)="onHcSliderChange()"
                                            #hcSlider
                                        ></p-slider>
                                        <div class="slider-values flex-row">
                                            <span>0</span> <span class="mla">100</span>
                                        </div>
                                    </div>
                                    <div class="vertical-separator mr20 ml20"></div>
                                    <p-checkbox
                                        name="do_not_match"
                                        binary="true"
                                        label="Do not AI Match"
                                        (onChange)="onChangeDoNotMatch($event)"
                                        formControlName="do_not_match"
                                        inputId="do_not_match"
                                    >
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- deprecated -->
        <div class="content-section" id="hiring-team" *ngIf="activeSection === 'hiring-team'">
            <!-- deprecated -->
            <p-messages [(value)]="msgs"></p-messages>
            <form [formGroup]="hiringForm">
                <div class="form-content-section" *ngIf="isJobOwner">
                    <div class="form-content-holder">
                        <div>
                            <label for="" class="form-block-label">Owner</label>
                            <app-auto-dropdown
                                [options]="accountOwners"
                                [control]="hiringForm.controls.owner"
                                [disabled]="user.role === 'recruiter'"
                                placeholder="Add to this position"
                                (onChange)="onChangeUser($event)"
                            ></app-auto-dropdown>
                        </div>
                    </div>
                </div>
                <div class="section-separator mb20 mt20" *ngIf="isJobOwner"></div>
                <div class="form-content-section">
                    <div class="form-content-holder">
                        <div>
                            <label for="" class="form-block-label">Recruiters</label>
                            <app-multi-select
                                userType="recruiter"
                                [owner]="hiringForm.get('owner').value"
                                [allowedChange]="allowJobChange"
                                [inviteUsers]="true"
                                formControlName="recruiters"
                            ></app-multi-select>
                        </div>
                    </div>
                </div>
                <div class="section-separator mb20 mt20"></div>
                <div class="form-content-section">
                    <div class="form-content-holder">
                        <div>
                            <label for="" class="form-block-label">Hiring Managers</label>
                            <app-multi-select
                                userType="hiring_manager"
                                [inviteUsers]="true"
                                [allowedChange]="allowJobChange"
                                formControlName="hiring_managers"
                            ></app-multi-select>
                        </div>
                    </div>
                </div>
                <div class="section-separator mb20 mt20"></div>
                <div class="form-content-section">
                    <div class="form-content-holder">
                        <div>
                            <label for="" class="form-block-label">Recruitment Agencies</label>
                            <app-multi-select
                                userType="recruitment_agency"
                                [inviteUsers]="true"
                                [allowedChange]="allowJobChange"
                                formControlName="recruitment_agencies"
                            ></app-multi-select>
                        </div>
                    </div>
                </div>
                <!-- <div class="section-separator mb20 mt20"></div>
                <div class="form-content-section">
                    <div class="description">
                        <strong class="title">Default Email</strong>
                        <p>Who should automated emails triggered by the system for this job be sent from?</p>
                    </div>
                    <div class="form-content-holder">
                        <div class="form-content-box">
                            <div class="form-block">
                                <div class="input-block">
                                    <label for="" class="form-block-label">Default Email Name</label>
                                    <input type="text" class="form-input" formControlName="default_email_name" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </form>
        </div>
        <!-- deprecated -->
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>

<div class="overlay" *ngIf="createNewLocation" (click)="closeNewLocationForm()"></div>
<app-new-location
    *ngIf="timeZones"
    [timeZones]="timeZones"
    [locations]="locations"
    action="create"
    [ngClass]="{ active: createNewLocation }"
    (close)="closeNewLocationForm()"
    (createLocation)="onCreateLocation($event)"
>
</app-new-location>

<div class="overlay" *ngIf="createNewPipeline" (click)="closeNewPipelineForm()"></div>
<div
    class="expandable-sidebar-block pipeline-form"
    [formGroup]="pipelineForm"
    [ngClass]="{ active: createNewPipeline }"
>
    <div class="form-content-section">
        <div class="form-content-box">
            <div class="form-block">
                <div class="header-block">
                    <label for="" class="form-block-label-title">
                        New Pipeline
                    </label>
                    <button class="btn btn-close" (click)="closeNewPipelineForm()"></button>
                </div>
                <div class="form-block-columns" style="margin-bottom: 27px;">
                    <div class="form-block-col">
                        <label class="form-block-label">Pipeline Name</label>
                        <div class="input-block">
                            <input
                                class="form-input"
                                type="text"
                                placeholder="Enter pipeline name"
                                formControlName="name"
                            />
                            <div
                                class="error-message"
                                *ngIf="!pipelineForm.controls.name.valid && pipelineForm.controls.name.touched"
                            >
                                Pipeline Name is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block-columns">
                    <div class="form-block-col stages-block">
                        <label class="form-block-label">Pipeline Stages</label>
                        <div
                            formArrayName="stages"
                            *ngFor="let stage of stages['controls']; let i = index"
                            class="stage"
                        >
                            <div class="stage-item" [formGroupName]="i">
                                <div class="drag-handler-block"></div>
                                <div class="form-block-col" style="flex: 0 0 346px;">
                                    <div class="input-block">
                                        <input
                                            placeholder="Enter stage name"
                                            class="form-input"
                                            type="text"
                                            [readonly]="i === 0 || i === stages.controls.length - 1 || hasCandidates"
                                            formControlName="stage_name"
                                        />
                                        <svg
                                            *ngIf="i !== 0 && i !== stages.controls.length - 1"
                                            class="delete-stage"
                                            (click)="onDeleteStage(i)"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                        >
                                            <path
                                                fill="#000"
                                                fill-rule="nonzero"
                                                d="M.75.043L.043.75l.355.352L5.293 6l-5.25 5.25.707.707L6 6.707l4.895 4.898.355.352.707-.707-.352-.355L6.707 6l5.25-5.25-.707-.707L6 5.293 1.102.398z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="separator" style="flex: 0 0 12px;"></div>
                                <div class="form-block-col" style="flex: 0 0 73px;">
                                    <p-dropdown
                                        [ngClass]="{ disabled: i === 0 || i === stages.controls.length - 1 }"
                                        [readonly]="i === 0 || i === stages.controls.length - 1"
                                        [options]="iconTypeOptions"
                                        formControlName="icon"
                                    >
                                        <ng-template let-object pTemplate="item">
                                            {{ object.label }}
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-add-green" (click)="onAddStage()"><span></span> Add a stage</button>
                    </div>
                </div>
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <div class="input-block">
                            <p-checkbox
                                name="default_pipeline"
                                binary="true"
                                label="This is our default pipeline"
                                formControlName="default_pipeline"
                                inputId="default_pipeline"
                            >
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-block-columns">
                    <button class="btn btn-primary btn-save" (click)="onSavePipeline()">
                        Save Pipeline
                    </button>
                </div>
                <div class="form-block-columns">
                    <p-messages [(value)]="msgs"></p-messages>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog
    [(visible)]="isShowTakeOwnershipModal"
    (onHide)="showTakeOwnershipConfirmModal(false)"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal decline-modal-new"
    [formGroup]="takeOwnershipConfirm"
>
    <div class="p-header p-header-new">
        <p>Take ownership</p>
        <button class="close-btn" (click)="showTakeOwnershipConfirmModal(false)">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new">
        <div class="form-block">
            <div class="form-block-columns">
                <div class="form-block-col">
                    <div class="input-block">
                        <p-checkbox
                            binary="true"
                            label="Assign candidate to another recruiter"
                            formControlName="assign_to_another"
                        ></p-checkbox>
                    </div>
                </div>
            </div>
            <div
                *ngIf="this.takeOwnershipConfirm.get('assign_to_another').value === true"
                class="form-block-columns mt30"
            >
                <div class="form-block-col">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="takeOwnershipRecruiters"
                            [newStyle]="true"
                            [control]="takeOwnershipConfirm.controls.recruiter"
                            [placeholder]="'Recruiter'"
                            [ngClass]="{
                                invalid:
                                    !takeOwnershipConfirm.controls.recruiter.valid &&
                                    takeOwnershipConfirm.controls.recruiter.touched
                            }"
                        ></app-auto-dropdown>
                    </div>
                    <div
                        class="error-message"
                        *ngIf="
                            takeOwnershipConfirm.get('assign_to_another').value === true &&
                            takeOwnershipConfirm.get('recruiter').invalid &&
                            takeOwnershipConfirm.get('recruiter').touched
                        "
                    >
                        Recruiter is required
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-footer p-footer-new">
        <div class="action">
            <button class="btn" (click)="onTakeOwnershipConfirm()">
                Confirm
            </button>
        </div>
    </div>
</p-dialog>
<app-job-requirements-modal
    *ngIf="showRequirementsModal"
    [title]="jobDetailsForm.get('title').value"
    [skills]="jobDescription.skills"
    [additionalSkills]="jobDescription.additionalSkills"
    (closeModal)="onCloseJobRequirementsModal($event)"
></app-job-requirements-modal>
<app-job-qualifications-modal
    *ngIf="showQualificationsModal"
    [title]="jobDetailsForm.get('title').value"
    (closeModal)="onCloseJobQualificationsModal($event)"
></app-job-qualifications-modal>
