<div class="content">
    <div class="header">
        <ng-container *ngIf="view === 'default' || view === 'details'">
            <h3>S197 Termination</h3>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <h3>Review {{ form.value?.full_name }}’s Termination Letter</h3>
        </ng-container>
        <button class="close-btn btn" *ngIf="view !== 'preview'" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="form-content-section" [formGroup]="form">
        <ng-container *ngIf="view === 'details'">
            <div class="form-content-holder">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Candidate Full Name</mat-label>
                                    <input type="text" autocomplete="off" formControlName="full_name" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.full_name.valid && form.controls.full_name.touched"
                                    >
                                        Full Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="HR Business Partner"
                                    [control]="form.controls.hr_business_partner"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hr_business_partner.valid &&
                                            form.controls.hr_business_partner.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        form.controls.hr_business_partner.touched &&
                                        !form.controls.hr_business_partner.valid
                                    "
                                >
                                    HR Business Partner is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>Dates</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                    <mat-label>S197 Meeting Date</mat-label>
                                    <input
                                        formControlName="meeting_date"
                                        matInput
                                        [matDatepicker]="picker1"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error
                                        *ngIf="!form.controls.meeting_date.valid && form.controls.meeting_date.touched"
                                    >
                                        Meeting Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker2.open()">
                                    <mat-label>Previous Company Original Start Date</mat-label>
                                    <input
                                        formControlName="previous_start_date"
                                        matInput
                                        [matDatepicker]="picker2"
                                        [min]="minEndDate"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error
                                        *ngIf="
                                            !form.controls.previous_start_date.valid &&
                                            form.controls.previous_start_date.touched
                                        "
                                    >
                                        Previous Company Original Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker3.open()">
                                    <mat-label>Original Start Date</mat-label>
                                    <input
                                        formControlName="start_date"
                                        matInput
                                        [matDatepicker]="picker3"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker3></mat-datepicker>
                                    <mat-error
                                        *ngIf="!form.controls.start_date.valid && form.controls.start_date.touched"
                                    >
                                        Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker4.open()">
                                    <mat-label>Last Working Day </mat-label>
                                    <input
                                        formControlName="last_working_day"
                                        matInput
                                        [matDatepicker]="picker4"
                                        [min]="minEndDate"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker4></mat-datepicker>
                                    <mat-error
                                        *ngIf="
                                            !form.controls.last_working_day.valid &&
                                            form.controls.last_working_day.touched
                                        "
                                    >
                                        Last Working Day is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker5.open()">
                                    <mat-label>New Start Date</mat-label>
                                    <input
                                        formControlName="new_start_date"
                                        matInput
                                        [matDatepicker]="picker5"
                                        autocomplete="off"
                                    />
                                    <mat-icon matSuffix>
                                        <svg-icon
                                            class="calendar-mat-icon"
                                            src="/assets/images/icons/calendar.svg"
                                        ></svg-icon>
                                    </mat-icon>
                                    <mat-datepicker #picker5></mat-datepicker>
                                    <mat-error
                                        *ngIf="
                                            !form.controls.new_start_date.valid && form.controls.new_start_date.touched
                                        "
                                    >
                                        New Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="fiscalTypeOptions"
                                    [newStyle]="true"
                                    placeholder="Current Fiscal Year"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="form.controls.current_fiscal_year"
                                    [ngClass]="{
                                        invalid:
                                            form.controls.current_fiscal_year.touched &&
                                            !form.controls.current_fiscal_year.valid
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        form.controls.current_fiscal_year.touched &&
                                        !form.controls.current_fiscal_year.valid
                                    "
                                >
                                    Current Fiscal Year is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>New Company</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Company</mat-label>
                                    <input type="text" autocomplete="off" formControlName="company" matInput />
                                    <mat-error *ngIf="!form.controls.company.valid && form.controls.company.touched">
                                        Company is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>New Provident Fund Name</mat-label>
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        formControlName="provident_fund_name"
                                        matInput
                                    />
                                    <mat-error
                                        *ngIf="
                                            !form.controls.provident_fund_name.valid &&
                                            form.controls.provident_fund_name.touched
                                        "
                                    >
                                        New Provident Fund Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <div class="preview-holder">
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Personal Details</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Full Name</div>
                            <span>{{ form.value.full_name }}</span>
                        </div>
                        <div class="preview-item">
                            <div>HR Business Partner</div>
                            <span>{{ getUserName(form.value.hr_business_partner) }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Dates</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>S197 Meeting Date</div>
                            <span>{{ form.value.meeting_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Previous Company Original Start Date</div>
                            <span>{{ form.value.previous_start_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Original Start Date</div>
                            <span>{{ form.value.start_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Last Working Day</div>
                            <span>{{ form.value.last_working_day | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>New Start Date</div>
                            <span>{{ form.value.new_start_date | amDateFormat: 'DD MMM YYYY' }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Current Fiscal Year</div>
                            <span>{{ form.value.current_fiscal_year }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-allowances">
                    <div class="preview-title">
                        <strong>New Company</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Company</div>
                            <span>{{ form.value.company }}</span>
                        </div>
                        <div class="preview-item">
                            <div>New Provident Fund Name</div>
                            <span>{{ form.value.provident_fund_name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'default'">
            <div class="form-details-section">
                <p>This candidate will receive a <strong>system generated termination letter</strong></p>
                <mat-radio-group formControlName="generate_termination_letter">
                    <mat-radio-button [value]="true">
                        <p>Yes, they will</p>
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        <p>No, they will not</p>
                    </mat-radio-button>
                </mat-radio-group>
                <ng-container *ngIf="form.value.generate_termination_letter">
                    <p class="text">Which <strong>annual leave transfer</strong> clause should be added?</p>
                    <mat-radio-group formControlName="annual_leave_transfer">
                        <mat-radio-button [value]="10">
                            <p>Maximum 10 days</p>
                        </mat-radio-button>
                        <mat-radio-button [value]="15">
                            <p>Maximum 15 days</p>
                        </mat-radio-button>
                        <mat-radio-button [value]="'all'">
                            <p>All leave transferred</p>
                        </mat-radio-button>
                    </mat-radio-group>
                    <p class="text">Which <strong>severance pay clause</strong> should be added?</p>
                    <mat-radio-group formControlName="severance_pay">
                        <mat-radio-button value="NTT DATA">
                            <p>NTT DATA is responsible for severance pay</p>
                        </mat-radio-button>
                        <mat-radio-button value="New company">
                            <p>New company responsible for severance pay</p>
                        </mat-radio-button>
                        <mat-radio-button value="Shared">
                            <p>Shared responsibility for severance pay</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <ng-container *ngIf="view === 'details'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary" (click)="onPreview()">
                Preview
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <button class="btn btn-primary mb10" (click)="onPreviewContract()">
                Preview Contract
            </button>
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn btn-primary" (click)="onSend()">
                Send for Approval
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'default'">
            <button *ngIf="!form.value.generate_termination_letter" class="btn btn-primary w72" (click)="onFinish()">
                Finish
            </button>
            <button *ngIf="form.value.generate_termination_letter" class="btn btn-primary w72" (click)="onAddDetails()">
                Details
            </button>
        </ng-container>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideModal()"></div>

<div class="offer-preview-modal" *ngIf="previewContractLink">
    <div class="overlay"></div>
    <button class="close-btn btn" (click)="onHideOfferPreview()">
        <img src="/assets/images/icons/icons-8-delete.svg" />
    </button>
    <pdf-viewer
        *ngIf="previewContractLink"
        [src]="previewContractLink"
        [render-text]="true"
        [original-size]="true"
        [autoresize]="true"
        [zoom]="1"
        [render-text]="true"
        [show-borders]="false"
        style="display: block;max-width: 800px;"
    >
    </pdf-viewer>
</div>
