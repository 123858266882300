<ng-container *ngIf="form">
    <form [formGroup]="form">
        <div class="professional_summary">
            <h4 class="mt60">Professional Summary</h4>

            <div class="form-content-section">
                <div class="form-content-holder">
                    <div class="form-block">
                        <div class="editors-container">
                            <div class="editors-holder">
                                <app-editor formControlName="employment_summary">
                                    <p-header>
                                        <div>
                                            <span class="ql-formats">
                                                <button class="ql-bold" aria-label="Bold"></button>
                                                <button class="ql-italic" aria-label="Italic"></button>
                                                <button class="ql-underline" aria-label="Underline"></button>
                                            </span>
                                            <span class="ql-formats">
                                                <button class="ql-list" value="ordered" type="button"></button>
                                                <button class="ql-list" value="bullet" type="button"></button>
                                            </span>
                                            <span class="ql-formats">
                                                <button
                                                    aria-label="Remove Styles"
                                                    class="ql-clean"
                                                    type="button"
                                                ></button>
                                            </span>
                                        </div>
                                    </p-header>
                                </app-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="mt60">Work Experience</h4>

        <div class="content-block">
            <app-work-experience [form]="form" [formData]="candidate.employment_history"></app-work-experience>
        </div>

        <h4 class="mt60">Education</h4>

        <div class="content-block">
            <app-candidate-education [form]="form" [formData]="candidate.education"></app-candidate-education>
        </div>

        <h4 class="mt60">Certifications and Licenses</h4>

        <div class="content-block">
            <app-candidate-certificates [form]="form" [formData]="candidate.certificates"></app-candidate-certificates>
        </div>

        <h4 *ngIf="!hideLanguagesBlock" class="mt60">Languages</h4>

        <div class="content-block mb30" *ngIf="!hideLanguagesBlock">
            <app-candidate-languages [form]="form" [formData]="candidate.languages"></app-candidate-languages>
        </div>
    </form>
</ng-container>
