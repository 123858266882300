import { HttpResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import * as fromStoreSelectors from '@app/products/hire/modules/jobs/store/selectors/jobs.selector';
import * as fromStoreStagesSelectors from '@app/products/hire/modules/jobs/store/selectors/stagesData.selector';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UIChart } from 'primeng/chart';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Stage, User } from '../../../../../core/models';
import * as fromJobsStore from '../store';
import { Candidate } from './../../../../../core/models/candidate';
import { SdkJob } from './../../../../../core/models/job';
import { CandidateService, JobService, OffersService, UtilitiesService } from './../../../../../core/services';
import * as fromStore from './../../../../../store';

interface ISelect {
    label?: string;
    value: number | string;
}

@Component({
    selector: 'app-candidate-item',
    templateUrl: './candidate-item.component.html',
    styleUrls: ['./candidate-item.component.scss']
})
export class CandidateItemComponent implements OnInit, OnChanges, OnDestroy {
    @Input() jobId: string;
    @Input() selectedIdx: string;
    @Input() initialJob;
    @Input() candidateId: string;
    @Input() fromPeople: string;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    @Output() changeJobOnCandidate: EventEmitter<any> = new EventEmitter();
    @Output() changeRating: EventEmitter<any> = new EventEmitter();
    @Output() candidateUpdated: EventEmitter<any> = new EventEmitter();
    @Output() ownerUpdated: EventEmitter<any> = new EventEmitter();
    unsubscribe: Subject<void> = new Subject<void>();
    math = Math;
    sections: string[] = ['overview', 'details', 'questions', 'interviews', 'history'];
    activeSection = 'overview';
    activeInteractivity = 'chat';
    job: SdkJob;
    stages: Stage[] = [];
    user: User;
    users: User[] = [];
    candidate: Candidate;
    matchingMap = {
        EDUCATION: 'Education',
        JOB_TITLES: 'Job Titles',
        SKILLS: 'Skills',
        INDUSTRIES: 'Industries',
        LANGUAGES: 'Languages',
        CERTIFICATIONS: 'Certifications',
        EXECUTIVE_TYPE: 'Executive Type',
        MANAGEMENT_LEVEL: 'Management Level'
    };
    personalityProfileScores = [
        { title: 'Extroversion', value: 0, average: 60, scoreText: '' },
        { title: 'Agreeableness', value: 0, average: 60, scoreText: '' },
        { title: 'Openness', value: 0, average: 60, scoreText: '' },
        { title: 'Conscientiousness', value: 0, average: 60, scoreText: '' },
        { title: 'Neuroticism', value: 0, average: 60, scoreText: '' }
    ];
    radar_chart_data: any;
    radar_chart_options: any;
    showVideoScore = false;
    stars: any[] = [{ index: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

    loadCounter: LoadCounter = new LoadCounter();
    contentLoading = true;
    uploadQueue: any[] = [];
    uploadError: string;
    supportedFileTypes: string[];
    showFeedback = false;
    jobOwner = false;
    questions: any[];
    questionsAnswers: any = {};
    questionnaire: any = null;
    questionsList: any[];

    personality_assessment: any = null;
    available_assessment = {};
    assignments = [];

    stageId: string = '';
    videos: any[] = [];
    logicTest;
    devskillerTest: any[] = [];
    criminalCheckAss: any;
    @ViewChild('chart') chart: UIChart;
    @ViewChild('appliedContent') appliedContent;
    baseUrl: string;

    declineModalVisible: boolean = false;
    undeclineModalVisible: boolean = false;
    declineModalForm: FormGroup;
    declineForm: FormGroup;
    modalSubmission: object = {};
    emailModalVisible: boolean = false;

    candidateSubscription: Subscription;
    userSubscription: Subscription;
    usersSubscription: Subscription;
    jobSubscription: Subscription;
    generalSubscription: Subscription;
    locationSubscription: Subscription;
    resumeThreshold: number;
    offerAssessment = null;
    offerAudit = null;
    questionnaires: any[] = [];
    stagesData: any[] = [];
    showRelocate: boolean = false;
    showIdVerified: boolean = false;
    showCriminalCheck: boolean = false;
    appliedJobs = [];
    appliedJobsId;
    appliedProgress;
    showRatingDropdown = false;

    potentialOwners;
    currentOwner;
    currentAgencyOwner;
    changedJobId = null;
    currentRating = null;
    candidateInterviews;
    changeOwnershipAllowed = false;

    editCandidateProfileMode: boolean = false;
    isCanChangeCandidateProfile: boolean = false;

    isSelfDeleted = false;
    isDeleted = false;
    underReviewOnAnotherJob = false;
    acceptedAnotherOffer = false;
    documents: any[] = [];
    documentsLoading = false;
    queryParametr = {
        'open-scorecard': false,
        'open-document': false
    };
    dataChanged = false;
    tagChanged = false;
    showQuickActionsDropdown = false;
    showCriminalCheckModal = false;

    selectedJobOption;
    candidateScore;
    candidateScoreDefined = false;
    documentsTypeOptions: any[] = [];
    viewJobOfferModal = {
        show: false,
        url: null
    };
    addCandidateToRoleModal = {
        show: false,
        candidate: null
    };
    locations: any[] = [];
    showDoNotHireModal = {
        show: false,
        candidate: null
    };
    constructor(
        private jobService: JobService,
        private fb: FormBuilder,
        private candidateService: CandidateService,
        private utilities: UtilitiesService,
        private store: Store<fromStore.State>,
        private jobsStore: Store<fromJobsStore.JobsState>,
        private router: Router,
        private route: ActivatedRoute,
        private workflowService: WorkflowService,
        private toastr: ToastrService,
        private employeeService: EmployeesService,
        private offerService: OffersService
    ) {
        this.baseUrl = this.utilities.getHireBaseUrl();
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
        this.radar_chart_data = this.utilities.getRadarChartData();
        this.radar_chart_options = this.utilities.getRadarChartOptions();
        this.loadCounter.count = 1;
    }

    ngOnInit() {
        // this.countStatuses();
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const action = this.route.snapshot.queryParamMap.get('action');
            if (action === 'open-scorecard') {
                this.queryParametr['open-scorecard'] = true;
            } else if (action === 'open-document') {
                this.queryParametr['open-document'] = true;
            }
        });
        this.declineModalForm = this.fb.group({});
        this.declineForm = this.fb.group({
            reason: [''],
            send_email: [false]
        });

        this.locationSubscription = this.jobsStore
            .pipe(select(fromStoreStagesSelectors.getLocationsStagesData))
            .subscribe((locations: any) => {
                this.locations = locations || [];
                if (this.initialJob) {
                    this.initialJob.location = this.replaceLocationToShort(this.initialJob.location);
                }
            });
        this.store
            .pipe(
                select(fromStore.getDocumentTypesData),
                filter((res) => !!res)
            )
            .subscribe(
                (res: any) => {
                    this.documentsTypeOptions = res;
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
    }

    replaceLocationToShort(location) {
        if (location && location.length) {
            if (location.length === 1) {
                const loc = this.locations.find((l) => l.location === location[0]);
                if (loc) {
                    location = [loc.location_short];
                }
            } else {
                location = ['Multiple Locations'];
            }
        }
        return location;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.candidateId) {
            if (!this.jobId) {
                const jobIds = await this.candidateService.getCandidateJobs(this.candidateId).toPromise();
                if (jobIds && jobIds.length) {
                    this.jobId = jobIds[0];
                }
            }
            this.loadCandidate(this.jobId);
        }
    }

    async countStatuses(jobId = this.jobId) {
        // this.updateCandidate(this.jobId);
        // this.loadCandidate();
        // this.onChangeSection('interviews');
        if (!jobId) {
            return;
        }
        this.offerAssessment = null;
        this.underReviewOnAnotherJob = false;
        this.acceptedAnotherOffer = false;
        if (this.candidate.stage[jobId] === 'hired') {
            const assignment = this.candidate.assignments.find((ass) => ass.stageId === 'hired');
            if (assignment && !assignment.offer_accepted) {
                let [
                    isDeleted,
                    isSelfDeleted,
                    underReviewOnAnotherJob,
                    acceptedAnotherOffer
                ] = await this.getOffersStatuses(this.candidate, jobId);

                this.isSelfDeleted = isSelfDeleted;
                this.isDeleted = isDeleted;
                this.underReviewOnAnotherJob = underReviewOnAnotherJob;
                this.acceptedAnotherOffer = acceptedAnotherOffer;
            }
        } else {
            let [
                isDeleted,
                isSelfDeleted,
                underReviewOnAnotherJob,
                acceptedAnotherOffer
            ] = await this.getOffersStatuses(this.candidate, jobId);
            this.isSelfDeleted = isSelfDeleted;
            this.isDeleted = isDeleted;
            this.underReviewOnAnotherJob = underReviewOnAnotherJob;
            this.acceptedAnotherOffer = acceptedAnotherOffer;
        }

        // console.log('STATUSES:', this.isSelfDeleted, this.underReviewOnAnotherJob, this.acceptedAnotherOffer);
    }

    async loadCandidate(jobId) {
        try {
            this.unsubscribeSubscriptions();
            this.userSubscription = this.store.pipe(select(fromStore.getUserEntity)).subscribe((user: User) => {
                this.user = user;
                if (this.user.role === 'recruitment_agency' || this.user.role === 'agency_user') {
                    this.sections = this.sections.filter((s) => s !== 'history');
                }
            });
            this.usersSubscription = this.store.pipe(select(fromStore.getUsersEntities)).subscribe((users: User[]) => {
                this.users = users;
            });
            const candidate: Candidate = await this.candidateService.getJobCandidate(this.jobId, this.candidateId);
            this.getCandidateInfo(candidate, jobId);

            if (jobId) {
                const read = candidate.read || [];
                if (read.findIndex((jId) => jId === jobId) === -1) {
                    try {
                        const readData = [...read, this.jobId];
                        candidate.read = readData;
                        await this.candidateService.updateDbCandidate(this.candidateId, { read: readData });
                    } catch (error) {
                        console.error(error);
                    }
                }
                setTimeout(() => {
                    this.candidateService
                        .updateJobCandidateStatus(candidate.id, jobId)
                        .catch((error) => console.error(error));
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    }

    getJob(jobId: string): Observable<SdkJob> {
        const observable = new Observable<SdkJob>((subscriber) => {
            if (!jobId) {
                subscriber.next(null);
                subscriber.complete();
                return;
            }
            this.jobService.getJob(jobId).subscribe(
                async (job: SdkJob) => {
                    job.stages = [];
                    const stages = await this.jobService
                        .getStages(jobId)
                        .pipe(take(1))
                        .toPromise();
                    job.stages = stages;
                    subscriber.next(job);
                    subscriber.complete();
                },
                (error) => subscriber.error(error)
            );
        });
        return observable;
    }

    onRatingClick(e) {
        e.stopPropagation();
        this.showRatingDropdown = !this.showRatingDropdown;
    }

    get candidateNameOrEmail() {
        if (!this.candidate) return '';
        const { first_name, last_name, email } = this.candidate;
        return { first_name, last_name, email };
    }

    setCandidateFields(jobId) {
        if (!this.candidate || !jobId) {
            return;
        }
        if (!this.candidate.assignments) {
            this.candidate.assignments = [];
        }
        if (this.candidate.profile_image && !this.candidate.profile_image_link) {
            if (this.candidate.profile_image.includes('https://')) {
                this.candidate.profile_image_link = this.candidate.profile_image;
            } else {
                this.candidateService.getProfileImageLink(`${this.candidate.profile_image}&collection=Users`).subscribe(
                    (response: string) => (this.candidate.profile_image_link = response),
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    completeCandidateWithData(data: any) {
        // console.log('GOT candidate data:', data);
        if (data.assignments) {
            this.candidate.assignments = data.assignments;
        }
        if (data.education && data.education.languages) {
            this.candidate.languages = data.education.languages;
        }
        if (data.education && data.education.education_history) {
            this.candidate.education_history = data.education.education_history;
        }
        if (data.skills) {
            this.candidate.skills = data.skills;
        }
        if (data.summary && !this.candidate.summary) {
            this.candidate.summary = data.summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_summary) {
            this.candidate.employment_summary = data['candidate-resume'].employment_summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_history) {
            this.candidate.employment_history = data['candidate-resume'].employment_history;
            this.findLastPosition(this.candidate.employment_history);
        }
        if (data.sex && !this.candidate.gender) {
            this.candidate.gender = data.sex;
        }
        if (data.ethnicity && !this.candidate.ethnicity) {
            this.candidate.ethnicity = data.ethnicity;
        }
        if (data.management_story && !this.candidate.management_story) {
            this.candidate.management_story = data.management_story;
        }
        if (data.avg_months_per_employer) {
            this.candidate.avg_months_per_employer = this.utilities.convertMonthsToYears(
                parseInt(data.avg_months_per_employer, 10)
            );
        }
        // if (data.candidate_matches && data.candidate_matches) {
        //     this.candidate.candidate_matches = data.candidate_matches;
        //     // this.transformSkills(data.candidate_matches);
        // } else {
        //     this.candidate.candidate_skills = [];
        // }

        this.jobService.populateSkills(this.jobId, this.candidateId).then((skills: any[]) => {
            this.candidate.candidate_skills = skills;
        });

        this.checkShowSections();
    }

    findLastPosition(employment_history) {
        const currentJob = employment_history.find((h) => h.end_date === 'Current');
        if (currentJob) {
            this.candidate.last_position = `${currentJob.title} @ ${currentJob.company}`;
        } else {
            const history = employment_history.sort((a, b) => {
                const dateA = moment(a.end_date, 'MM/YYYY').unix();
                const dateB = moment(b.end_date, 'MM/YYYY').unix();
                return dateB - dateA;
            });
            this.candidate.last_position = `${history[0].title} @ ${history[0].company}`;
        }
    }

    checkShowSections() {
        if (!this.candidate.resume_file && !this.candidate.resume_file_new) {
            this.sections = this.sections.filter((section) => section !== 'details');
        }
    }

    isKnockout(section): string {
        if (section === 'questions' && this.questionsAnswers) {
            let ass = this.available_assessment;
            let questionFailed = '';
            let questionExpired = '';
            let questionNotCompleted = '';
            let questionCompleted = false;
            if (this.questionsList) {
                questionFailed = this.questionsList.find(
                    (q) => q.type === 'questions' && !q.questionSuccess && q.completed
                );
                questionExpired = this.questionsList.find((q) => q.expired && !q.completed);
                questionNotCompleted = this.questionsList.find((q) => !q.expired && !q.completed);
                questionCompleted = this.questionsList.every((q) => q.completed);
            }
            if (questionFailed) {
                return 'danger';
            } else if (questionCompleted) {
                return;
            } else if (questionExpired) {
                return 'danger';
            } else if (questionNotCompleted) {
                return 'warning';
            } else if (ass['questions']) {
                return 'warning';
            }
        } else if (section === 'assessments' && this.available_assessment) {
            //need to optimize
            let ass = this.available_assessment;
            if (ass['personality'] && !this.personality_assessment && ass['personality'].expired) {
                return 'danger';
            }
            if (ass['logic-test'] && ass['logic-test'].expired && !this.logicTest) {
                return 'danger';
            } else if (ass['logic-test'] && this.logicTest && this.logicTest.score <= 5) {
                return 'danger';
            } else if (
                ass['devskiller'] &&
                this.devskillerTest &&
                this.devskillerTest.some((c) => !c.completed && !c.candidate && !c.results)
            ) {
                return 'danger';
            } else if (this.devskillerTest.length && this.devskillerTest.some((c) => c.expired && !c.completed)) {
                return 'danger';
            } else if (this.devskillerTest.length && this.devskillerTest.some((c) => c.result <= 39)) {
                return 'danger';
            } else if (ass['logic-test'] && !this.logicTest) {
                return 'warning';
            } else if (ass['personality'] && !this.personality_assessment) {
                return 'warning';
            } else if (ass['devskiller'] && this.devskillerTest && !this.devskillerTest.some((c) => c.completed)) {
                return 'warning';
            }
        }
    }

    allowShowFeedback() {
        if (this.job && this.candidate && this.user) {
            this.showFeedback = this.job.owner === this.user.id ? true : false;
        }
    }

    getExtension(filename) {
        const allowedExtensions = ['pdf'];
        const newFilename = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        if (allowedExtensions.indexOf(newFilename) !== -1) {
            return newFilename;
        } else {
            return 'docx';
        }
    }

    checkResumeFile() {
        // console.log('🗃 checkResumeFile');
        // Attachments
        return new Promise(async (resolve, reject) => {
            if (
                !this.candidate.resume_file &&
                !this.candidate.resume_file_new &&
                this.candidate.source !== 'application'
            ) {
                this.activeSection = 'overview';
            }
            if (this.candidate.resume_file && this.candidate.resume_file.length) {
                this.candidateService.getResumeLink(this.candidate.resume_file).subscribe(
                    (response: string) => {
                        this.candidate.resume_link = response;
                        if (
                            this.documents &&
                            this.documents.find((d) => d.originalName === this.candidate.resume_file)
                        ) {
                            const doc = this.documents.find((d) => d.originalName === this.candidate.resume_file);
                            doc.link = response;
                        }
                        return resolve(null);
                    },
                    (errorResponse) => {
                        console.error(errorResponse);
                        return resolve(null);
                    }
                );
                this.candidate.resume_file_type = this.getExtension(this.candidate.resume_file);
            } else if (this.candidate.resume_file_new && this.candidate.resume_file_new.length) {
                this.candidateService.getResumeLink(this.candidate.resume_file_new).subscribe(
                    (response: string) => {
                        console.log('Clevateam resume', response);
                        this.candidate.resume_link_new = response;
                        if (
                            this.documents &&
                            this.documents.find((d) => d.originalName === this.candidate.resume_file_new)
                        ) {
                            const doc = this.documents.find((d) => d.originalName === this.candidate.resume_file_new);
                            doc.link = response;
                        }
                        if (this.documents.length === 0) {
                            this.documents.push({
                                name: 'Resume.pdf',
                                originalName: 'Resume.pdf',
                                link: this.candidate.resume_link_new,
                                documentType: 'resume',
                                extension: 'pdf',
                                size: 120000,
                                uploadedAt: this.candidate.created_at * 1000
                            });
                        }
                        return resolve(null);
                    },
                    (errorResponse) => console.error(errorResponse)
                );

                this.candidate.resume_file_new_type = this.getExtension(this.candidate.resume_file_new);
            } else {
                return resolve(null);
            }
        });
    }

    checkOtherFiles() {
        if (this.candidate.uploaded && this.candidate.uploaded.length) {
            for (let i = 0; i <= this.candidate.uploaded.length - 1; i++) {
                if (this.candidate.uploaded[i] && this.candidate.uploaded[i].option === 'offer') {
                    continue;
                }
                this.candidateService.getResumeLink(this.candidate.uploaded[i].name).subscribe(
                    (response: string) => {
                        if (this.candidate && this.candidate.uploaded && this.candidate.uploaded[i]) {
                            this.candidate.uploaded[i]['link'] = response;
                            this.candidate.uploaded[i]['type'] = this.getExtension(this.candidate.uploaded[i].name);
                        }
                    },
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    prepareQuestionsAnswers() {
        this.questionsList.forEach((ass) => {
            if (ass.type === 'questions') {
                const questionsAnswers: any = [];
                let isKnockout = false;
                let candidateQuestions = ass.data;
                if (ass.data && ass.questions && ass.questions.questions_col) {
                    ass.questions.questions_col.forEach((q) => {
                        const obj = {
                            text: q.text,
                            answers: [],
                            isKnockout: ''
                        };

                        function applyKnockout(answer) {
                            // console.log('applyKnockout')
                            // console.log(answer);
                            if (answer.is_knockout !== undefined && this.isKnockout !== 'knockout wrong') {
                                this.isKnockout = !answer.is_knockout ? 'knockout' : 'knockout wrong';
                                if (!isKnockout && answer.is_knockout) isKnockout = true;
                            }
                        }

                        if (q.answers && q.type !== 'paragraph') {
                            if (
                                candidateQuestions[q.id] &&
                                candidateQuestions[q.id].selectedItems &&
                                Array.isArray(candidateQuestions[q.id].selectedItems)
                            ) {
                                candidateQuestions[q.id].selectedItems.forEach((qa) => {
                                    const answer = q.answers.find((a) => a.id === qa);
                                    if (answer) {
                                        if (q.answers.some((a) => a.is_knockout)) {
                                            ass.hasKnockoutQuestion = true;
                                            applyKnockout.call(obj, answer);
                                        }
                                        obj.answers.push(answer.text);
                                    }
                                });
                            } else {
                                const qa = ass.data[q.id];
                                const answer = q.answers.find((a) => a.id === qa);
                                if (qa && qa.selectedItems) {
                                    qa.selectedItems.forEach((c) => {
                                        let answer = q.answers.find((b) => b.id === c);
                                        if (answer) {
                                            obj.answers.push(answer.text);
                                        }
                                    });
                                }
                                if (answer) {
                                    if (q.answers.some((a) => a.is_knockout)) {
                                        ass.hasKnockoutQuestion = true;
                                        applyKnockout.call(obj, answer);
                                    }
                                    obj.answers.push(answer.text);
                                }
                            }
                        } else {
                            obj.answers.push(candidateQuestions[q.id]);
                        }

                        questionsAnswers.push(obj);
                    });
                    let correctQuestions = questionsAnswers
                        ? questionsAnswers.filter(
                              ({ isKnockout, answers }) => !isKnockout.includes('wrong') && answers.length
                          ).length
                        : 0;
                    ass.questionsAnswers = {
                        isKnockout,
                        questions: questionsAnswers.slice(0)
                    };
                    ass.correctQuestions = correctQuestions;

                    ass.questionSuccess = correctQuestions === (questionsAnswers || []).length;
                }
            } else if (ass.type === 'video-interview') {
                if (ass.data && ass.data.links && ass.questions && ass.questions.questions_col) {
                    let videos = [];
                    for (let key in ass.data.links) {
                        let video = ass.data.links[key];
                        let questions = ass.questions.questions_col.find((q) => q.id === key);
                        videos.push(video);
                        video.id = questions.id;
                        video.order = questions.order;
                        video.question = questions.text;
                        video.stageId = ass.stageId;
                        if (video.rating) {
                            video.ratings = [];
                            for (let i = 0; i < 5; i++) {
                                i < video.rating ? video.ratings.push(video.rating) : video.ratings.push(0);
                            }
                        }
                        this.videos.push(video);
                    }
                    videos.sort((a, b) => {
                        return a.order - b.order;
                    });
                    ass.videos = videos;
                }
            }
        });
    }

    get correctQuestions() {
        return this.questionsAnswers
            ? this.questionsAnswers.questions.filter(
                  ({ isKnockout, answers }) => !isKnockout.includes('wrong') && answers.length
              ).length
            : 0;
    }

    get questionSuccess() {
        return this.correctQuestions === (this.questionsAnswers.questions || []).length;
    }

    dateFormat(date) {
        return moment.unix(date).format('DD MMMM YYYY');
    }

    onChangeSection(section: string) {
        this.activeSection = section;
        if (this.chart) {
            this.chart.refresh();
        }
    }

    onChangeInteractivity(section: string) {
        this.activeInteractivity = section;
    }

    onBackClick() {
        this.closeModal.emit();
    }

    processAssessments() {
        this.stages = this.job.stages.sort((a, b) => a.order - b.order);
        const jobAssessment = this.stages
            .reduce((a, c) => a.concat(c), [])
            .reduce((a, c) => a.concat(c.assessment), [])
            .filter((ass) => ass);
        // console.log('PROCESS ASSIGNMENTS', this.candidate.assignments);
        if (this.candidate && this.candidate.assignments && this.candidate.assignments) {
            this.candidate.assignments.forEach((ass) => {
                const jobAss = jobAssessment.find(({ type }) => type === ass.type);
                if (ass.type === 'questions' || ass.type === 'video-interview') {
                    const expired_at =
                        ass.expired_at ||
                        moment
                            .unix(ass.added_at)
                            .add((jobAss && jobAss.deadline) || 5, 'days')
                            .unix();
                    this.available_assessment[ass.type] = {
                        invitationSent: moment.unix(ass.added_at).format('DD MMMM YYYY'),
                        assessmentExpired: moment.unix(expired_at).format('DD MMMM YYYY'),
                        expired: expired_at < moment().unix()
                    };
                    ass.invitationSent = moment.unix(ass.added_at).format('DD MMMM YYYY');
                    ass.assessmentExpired = moment.unix(expired_at).format('DD MMMM YYYY');
                    ass.expired = expired_at < moment().unix();
                } else if (ass.type === 'criminal-check') {
                    ass = this.candidateService.processCriminalCheckAss(this.candidate.id, ass);
                } else {
                    if (jobAss) {
                        // const expired_at =
                        //     ass.expired_at ||
                        //     moment
                        //         .unix(ass.added_at)
                        //         .add(jobAss.deadline || 5, 'days')
                        //         .unix();
                        // this.available_assessment[ass.type] = {
                        //     invitationSent: moment.unix(ass.added_at).format('DD MMMM YYYY'),
                        //     assessmentExpired: moment.unix(expired_at).format('DD MMMM YYYY'),
                        //     expired: expired_at < moment().unix()
                        // };
                        if (ass.type === 'devskiller') {
                            if (ass.candidate || ass.added_at) {
                                ass.invitationCode = ass.candidate ? ass.candidate.examUrl.split('?')[1] : 'Unknown';
                                ass.invitationSent = moment.unix(ass.added_at).format('DD MMMM YYYY');
                                if (ass.candidate && ass.candidate.testFinishDate) {
                                    ass.assessmentComplete = moment(ass.candidate.testFinishDate).format(
                                        'DD MMMM YYYY'
                                    );
                                } else {
                                    ass.completed = false;
                                }
                            }
                            if (ass.results) {
                                ass.result = (ass.results.scoredPoints / ass.results.maxPoints) * 100;
                            }
                            this.devskillerTest.push(ass);
                            this.setStageForAss(ass, ass);
                        }
                        if (ass.type === 'logic-test') {
                            if (ass.completed) {
                                this.logicTest = ass.data;
                                this.logicTest.completed = moment
                                    .unix(this.logicTest.completed_at)
                                    .format('DD MMMM  YYYY');
                                this.logicTest.invited_at = ass ? ass.added_at : 0;
                                if (this.logicTest.score && this.logicTest.score > 10) {
                                    this.logicTest.score = 10;
                                }
                                this.logicTest.invited = this.logicTest.invited_at
                                    ? moment.unix(this.logicTest.invited_at).format('DD MMMM YYYY')
                                    : '';
                            }
                            this.setStageForAss(ass, this.logicTest);
                        }

                        if (ass.type === 'personality') {
                            if (ass.data) {
                                let stageData = ass.data;
                                const assessment = stageData;
                                this.personality_assessment = stageData;
                                this.personalityProfileScores[0].value = assessment[1].score;
                                this.personalityProfileScores[1].value = assessment[3].score;
                                this.personalityProfileScores[2].value = assessment[2].score;
                                this.personalityProfileScores[3].value = assessment[4].score;
                                this.personalityProfileScores[4].value = assessment[0].score;
                                this.personalityProfileScores[0].scoreText = assessment[1].scoreText;
                                this.personalityProfileScores[1].scoreText = assessment[3].scoreText;
                                this.personalityProfileScores[2].scoreText = assessment[2].scoreText;
                                this.personalityProfileScores[3].scoreText = assessment[4].scoreText;
                                this.personalityProfileScores[4].scoreText = assessment[0].scoreText;

                                this.radar_chart_data.datasets[0].data = [
                                    assessment[1].score,
                                    assessment[3].score,
                                    assessment[4].score,
                                    assessment[0].score,
                                    assessment[2].score
                                ];
                                if (this.chart) {
                                    this.chart.refresh();
                                }
                            }
                            this.setStageForAss(ass, this.personality_assessment);
                        }

                        if (ass.type === 'ikm') {
                            // console.log(ass);
                            if (ass.ikmResult) {
                                const result = ass.ikmResult.test_result_percentile;
                                if (result <= 59) {
                                    ass.result = 'weak';
                                } else if (result > 59 && result < 80) {
                                    ass.result = 'proficient';
                                } else if (result >= 80) {
                                    ass.result = 'strong';
                                }
                            }
                            this.setStageForAss(ass, ass);
                        }
                    }
                }
            });
        }
    }

    setStageForAss(ass, result) {
        const jobAssessment = this.stages
            .reduce((a, c) => a.concat(c), [])
            .reduce((a, c) => a.concat(c.assessment), [])
            .filter((ass) => ass);
        const jobAss = jobAssessment.find(({ type }) => type === ass.type);
        const expired_at =
            ass.expired_at ||
            moment
                .unix(ass.added_at)
                .add(jobAss.deadline || 5, 'days')
                .unix();

        if (ass.type === 'devskiller') {
            ass.expired = !ass.completed ? expired_at < moment().unix() : false;
        }
        let stage = this.stages.find((s) => s.id === ass.stageId);
        let index = this.stagesData.findIndex((s) => s.id === stage.id);

        this.available_assessment[ass.type] = {
            invitationSent: moment.unix(ass.added_at).format('DD MMMM YYYY'),
            assessmentExpired: moment.unix(expired_at).format('DD MMMM YYYY'),
            expired: expired_at < moment().unix()
        };

        if (index > -1) {
            this.stagesData[index].assessments.push({
                type: ass.type,
                invitationSent: moment.unix(ass.added_at).format('DD MMMM YYYY'),
                assessmentExpired: moment.unix(expired_at).format('DD MMMM YYYY'),
                expired: !ass.completed ? expired_at < moment().unix() : false,
                completed: ass.completed,
                data: result
            });
        } else {
            if (stage) {
                this.stagesData.push({
                    id: stage.id,
                    icon: stage.icon,
                    order: stage.order,
                    title: stage.title,
                    assessments: [
                        {
                            type: ass.type,
                            invitationSent: moment.unix(ass.added_at).format('DD MMMM YYYY'),
                            assessmentExpired: moment.unix(expired_at).format('DD MMMM YYYY'),
                            expired: !ass.completed ? expired_at < moment().unix() : false,
                            completed: ass.completed,
                            data: result
                        }
                    ]
                });
            }
        }
        // console.log('this.stagesData', this.stagesData);
    }

    onExtendDeadline(type, id = null, ass) {
        console.log('onExtendDeadline', type, id, ass);
        if (ass.loading) return;
        ass.loading = true;
        this.contentLoading = true;
        ass.expired = false;
        ass.expired_at = null;

        this.candidateService.extendAssessmentDeadline(this.job.id, this.candidateId, { type, id }).subscribe(
            async (assignment: any) => {
                this.contentLoading = false;
                if (type === 'logic-test' || type === 'personality') {
                    const ass = this.stagesData[0].assessments.find((a) => a.type === type);
                    if (ass) {
                        ass.expired = false;
                        ass.expired_at = null;
                        ass.added_at = Math.floor(Date.now() / 1000);
                    }
                } else if (type === 'criminal-check') {
                    console.log('Extend deadline for criminal check', ass);
                    ass.expired = false;
                    ass.expired_at = null;
                    ass.added_at = Math.floor(Date.now() / 1000);
                    ass = this.candidateService.processCriminalCheckAss(this.candidateId, ass);
                } else {
                    const ass = this.questionnaires[0].questions.find((a) => a.id === id);
                    if (ass) {
                        ass.expired = false;
                    }

                    const stageId =
                        this.candidate.stage && this.candidate.stage[this.job.id]
                            ? this.candidate.stage[this.job.id]
                            : 'applied';
                    const response: any = await this.jobService.getCandidateStageClass(
                        this.job,
                        this.candidateId,
                        stageId
                    );
                    const { stageClass, complianceRateClass, order } = response;
                    const data = {
                        stageClass,
                        complianceRateClass,
                        order
                    };
                    await this.candidateService.updateJobCandidate(this.candidateId, this.job.id, data);
                    this.candidateService.updateCandidate(this.candidateId, data);
                }
            },
            (errorResponse) => {
                console.error(errorResponse);
                this.available_assessment[type].loading = false;
            }
        );
    }

    changeExtendDeadline(data: any) {
        const [type, id, ass] = data;
        this.onExtendDeadline(type, id, ass);
    }

    uploadFile(item) {
        this.utilities
            .readFile(item.file)
            .then((fileValue) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);

                this.jobService
                    .addDocumentsToCandidate(this.job.id, this.candidateId, {
                        document: fileValue,
                        documentType: item.type,
                        name: item.text
                    })
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            item.progress = 100;
                            item.uploadFinished = true;
                            item.success = true;
                            item.fadeout = true;
                            clearInterval(uploadProgressInterval);

                            const resp: any = response;
                            this.candidate.uploaded = [...resp.candidate.uploaded];
                            console.log(this.candidate.uploaded);
                            for (const file of this.candidate.uploaded) {
                                if (!this.documents.find((d) => d.name === file.name)) {
                                    this.documents.push({
                                        id: file.id,
                                        name: file.name,
                                        originalName: file.original_name || file.name,
                                        link: file.link,
                                        documentType: this.getDocumentType(file.type || 'other'),
                                        extension: this.getExtension(file.name),
                                        size: file.size,
                                        uploadedAt: file.uploaded_at
                                    });
                                }

                                if (file.type === 'resume') {
                                    if (this.candidate.resume_file) {
                                        this.loadCandidate(this.jobId);
                                    }
                                    if (file.link) {
                                        this.candidate.resume_file = file.name;
                                    }

                                    if (resp.candidate.skills) {
                                        this.candidate.skills = resp.candidate.skills;
                                    }
                                    if (resp.candidate.matching) {
                                        this.candidate.matching = resp.candidate.matching;
                                    }

                                    this.store.dispatch(
                                        new fromJobsStore.UpdateJobCandidate({
                                            jobId: this.jobId,
                                            candidateId: this.candidateId,
                                            data: this.candidate
                                        })
                                    );
                                }
                            }
                            console.log(this.documents);
                            this.checkOtherFiles();

                            if (
                                (!this.candidate.employment_history || !this.candidate.employment_history.length) &&
                                resp.candidate.employment_history
                            ) {
                                this.candidate.employment_history = resp.candidate.employment_history;
                            }
                        },
                        (error) => {
                            console.error(error);
                            item.text =
                                error && error.error && error.error.message ? error.error.error.message : 'Error';
                            item.progress = 100;
                            item.uploadFinished = true;
                            clearInterval(uploadProgressInterval);
                        }
                    );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    onDecline() {
        const reason = this.declineForm.get('reason');
        const stage =
            this.candidate.stage && this.candidate.stage[this.job.id] ? this.candidate.stage[this.job.id] : '';
        if (stage && stage !== 'applied' && !reason.value) {
            reason.markAsTouched();
            reason.setValidators([Validators.required]);
            reason.updateValueAndValidity();
            return false;
        }

        if (this.declineModalForm.valid) {
            const {
                job: { id: jobId },
                candidate: { id: candidateId }
            } = this;

            this.contentLoading = true;
            this.jobService.deleteCandidate(jobId, candidateId, this.declineForm.value).subscribe(
                () => {
                    console.log(`Candidate <${candidateId}> was declined`);
                    if (this.candidateSubscription) {
                        this.candidateSubscription.unsubscribe();
                    }
                    this.candidateService
                        .updateJobCandidate(candidateId, jobId, { stageClass: 'deleted' })
                        .catch((error) => console.error(error));
                    this.candidateService.updateCandidate(candidateId, { stageClass: 'deleted' });
                    this.jobsStore.dispatch(new fromJobsStore.DeleteJobCandidate({ jobId, candidateId }));
                    this.contentLoading = true;
                    this.onBackClick();
                    const {
                        user: { id: userId }
                    } = this;
                    // this.candidateService
                    //     .addToAudit(jobId, candidateId, {
                    //         type: 'delete',
                    //         deleted_by: 'user',
                    //         user_id: userId,
                    //         created_at: new Date().getTime()
                    //     })
                    //     .subscribe(
                    //         (response) => console.log(response),
                    //         (errorResponse) => console.error(errorResponse)
                    //     );
                    this.declineForm.reset();
                },
                (error) => {
                    this.contentLoading = false;
                    console.error(error);
                }
            );
        } else {
            this.modalSubmission['declineModalForm'] = true;
        }
    }

    onSilentDecline() {
        const jobId = this.job.id;
        const candidateId = this.candidate.id;

        this.contentLoading = true;
        this.jobService
            .deleteCandidate(jobId, candidateId, {
                reason: 'Auto-matched declined',
                send_email: false
            })
            .subscribe(
                () => {
                    console.log(`Candidate <${candidateId}> was declined`);
                    this.candidateService.updateCandidate(this.candidateId, {
                        jobId,
                        reason: 'auto_matched_declined'
                    });
                    if (this.candidateSubscription) {
                        this.candidateSubscription.unsubscribe();
                    }
                    this.jobsStore.dispatch(new fromJobsStore.DeleteJobCandidate({ jobId, candidateId }));
                    this.onBackClick();
                },
                (error) => {
                    this.contentLoading = false;
                    console.error(error);
                }
            );
    }

    onShowModal(visible = true) {
        if (visible) {
            this.declineModalForm.reset();
            delete this.modalSubmission['declineModalForm'];
        }
        this.declineModalVisible = visible;
        if (visible) {
            const stage =
                this.candidate.stage && this.candidate.stage[this.job.id] ? this.candidate.stage[this.job.id] : '';
            if (!stage || stage === 'applied') {
                this.declineForm.get('send_email').patchValue(true);
            } else {
                this.declineForm.get('send_email').patchValue(false);
            }
        }
    }

    onHideUndeclineModal() {
        this.undeclineModalVisible = false;
    }

    get isProgressable() {
        if (this.job && this.candidate) {
            const {
                job: { id: jobId },
                stages,
                candidate: { stage }
            } = this;

            if (stage) {
                const stageId = stage[jobId];
                const columnIndex = stages.findIndex(({ id }) => id === stageId);
                return columnIndex + 1 < stages.length;
            } else {
                return stages.length > 0;
            }
        } else {
            return false;
        }
    }

    get candidateIsActive() {
        if (this.job.id && this.candidate.opportunities && this.candidate.opportunities.length) {
            let opportunities = this.candidate.opportunities.find((c) => c.jobId === this.job.id);
            if (opportunities && !opportunities.approved) {
                return false;
            } else {
                return true;
            }
        } else if (
            this.candidate.hasUser &&
            (this.candidate.hasUserReviewed || this.candidate.matching || this.candidate.matchingScores)
        ) {
            return true;
        } else {
            return false;
        }
    }

    async onProgress() {
        console.log('🎭 onProgress');
        this.showQuickActionsDropdown = false;
        const {
            job: { id: jobId },
            stages,
            user: { id: userId },
            candidate: { id: candidateId, stage = {} }
        } = this;
        const stageId = stage[jobId];
        console.log('🎭 ', stageId);

        const columnIndex = stages.findIndex(({ id }) => id === stageId);
        const { id: fromId = 'applied' } = columnIndex === -1 ? { id: 'applied' } : this.stages[columnIndex];
        const { id: toId, title } = stages[columnIndex + 1];
        if (toId === 'hired') {
            const candidate = this.candidate;
            const acceptedOfferModal: {
                show: boolean;
                data: any;
                workflows: any[];
                hired_workflow: string;
            } = {
                show: false,
                data: null,
                workflows: [],
                hired_workflow: null
            };
            const workflows = await this.workflowService.getManualWorkflows().toPromise();
            acceptedOfferModal.data = candidate;
            acceptedOfferModal.workflows = workflows;
            this.candidateService.progressToHiredStage(acceptedOfferModal);
            this.closeModal.emit();
            return;
        }
        this.contentLoading = true;

        const response: any = await this.candidateService.updateStage(this.candidate, this.job, toId);
        console.log(response);
        const auditRecord = {
            type: 'stages_progress',
            user_id: this.user.id,
            stage_from_id: fromId,
            stage_to_id: toId,
            created_at: new Date().getTime()
        };
        this.candidateService
            .addToAudit(this.job.id, this.candidate.id, auditRecord)
            .catch((error) => console.error(error));
        this.contentLoading = false;
        this.candidateService.updateCandidate(this.candidate.id, {
            reason: 'candidate_progressed',
            stage: toId,
            order: 10
        });

        if (!this.job.moved_out_applied_stage_at && fromId === 'applied') {
            this.jobService.updateJob(this.job.id, {
                moved_out_applied_stage_at: Math.floor(Date.now() / 1000),
                moved_out_applied_stage_at_formatted: moment().format('DD-MM-YYYY HH:mm')
            });
            this.job.moved_out_applied_stage_at = Math.floor(Date.now() / 1000);
        }

        // this.jobService.updateCandidateStage(jobId, candidateId, { stageId: toId }).subscribe(async (response: any) => {
        //     if (response.assignments) {
        //         this.store.dispatch(
        //             new fromJobsStore.UpdateJobCandidate({
        //                 jobId,
        //                 candidateId,
        //                 data: { assignments: response.assignments }
        //             })
        //         );
        //         console.log('RESPONSE ASSIGNMENTS:', response.assignments);
        //     }
        //     console.log(`Candidate <${candidateId}> was progressed to - ${title}`);
        //     this.contentLoading = false;

        //     const data = { ...response, stage: toId };
        //     this.candidateService.updateCandidate(this.candidateId, data);
        //     await this.candidateService.addToAudit(jobId, candidateId, {
        //         type: 'stages_progress',
        //         user_id: userId,
        //         stage_from_id: fromId,
        //         stage_to_id: toId,
        //         created_at: new Date().getTime()
        //     });
        // });
    }

    showUndeclineModal() {
        this.showQuickActionsDropdown = false;
        this.undeclineModalVisible = true;
    }

    undeclineCandidate() {
        this.contentLoading = true;
        const {
            job: { id: jobId },
            candidate: { id: candidateId }
        } = this;
        const declinedJobIndex = this.candidate.declinedJobs.findIndex((j) => j === jobId);
        this.candidate.declinedJobs.splice(declinedJobIndex, 1);
        this.candidate.job_id.push(jobId);
        this.jobService.undeclineCandidate(jobId, candidateId).subscribe(async (response: any) => {
            const data: any = {
                declinedJobs: this.candidate.declinedJobs,
                job_id: this.candidate.job_id
            };
            if (response.opportunities && response.opportunities.length) {
                const opportunity = response.opportunities.find((opp) => opp.jobId === jobId);
                if (opportunity) {
                    data.opportunities = [opportunity];
                }
            }
            // this.store.dispatch(
            //     new fromJobsStore.UpdateJobCandidate({
            //         jobId,
            //         candidateId,
            //         data
            //     })
            // );
            const stageId =
                this.candidate.stage && this.candidate.stage[this.job.id]
                    ? this.candidate.stage[this.job.id]
                    : 'applied';
            const res: any = await this.jobService.getCandidateStageClass(this.job, this.candidateId, stageId);
            const { stageClass, complianceRateClass, order } = res;
            const dataClass = {
                stageClass,
                complianceRateClass,
                order
            };
            await this.candidateService.updateJobCandidate(this.candidateId, this.job.id, dataClass);
            this.candidateService.updateCandidate(this.candidateId, dataClass);

            this.contentLoading = false;
            this.undeclineModalVisible = false;
            console.log('Candidata was added to job again', response, this.candidate);
        });
    }

    onQuickActionsClick(e) {
        e.stopPropagation();
        this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
    }

    onCriminalCheckClick() {
        console.log('onCriminalCheckClick');
        this.showQuickActionsDropdown = false;
        this.showCriminalCheckModal = true;
    }

    onAddCandidateToJob(candidate) {
        console.log('🍡 [onAddCandidateToJob]', candidate);
        this.showQuickActionsDropdown = false;
        this.contentLoading = true;
        this.candidateService.addCandidateToJob(this.job.id, this.candidateId).subscribe(
            async (response: any) => {
                console.log('🦾 [addCandidateToJob] RESPONSE:', response);
                const stageId =
                    this.candidate.stage && this.candidate.stage[this.job.id]
                        ? this.candidate.stage[this.job.id]
                        : 'applied';
                const res: any = await this.jobService.getCandidateStageClass(this.job, this.candidateId, stageId);
                const { stageClass, complianceRateClass, order } = res;
                console.log('🖐🏻 Candidate classes after adding to the job:', res);
                const data = {
                    stageClass,
                    complianceRateClass,
                    order
                };
                await this.candidateService.updateJobCandidate(this.candidateId, this.job.id, data);
                this.candidateService.updateCandidate(this.candidateId, data);
                candidate.stageClass = res.stageClass;
                candidate.complianceRateClass = res.complianceRateClass;
                candidate.opportunities = response.opportunities;
                this.store.dispatch(
                    new fromJobsStore.UpdateJobCandidate({
                        jobId: this.job.id,
                        candidateId: this.candidateId,
                        data: { opportunities: response.opportunities }
                    })
                );
                this.contentLoading = false;
            },
            (err) => {
                console.log(err);
                this.contentLoading = false;
            }
        );
    }

    onShowEmailModal(visible = true) {
        this.emailModalVisible = visible;
    }

    onOfferAccepted(e) {
        const offerAssessment = { ...this.offerAssessment };
        offerAssessment.offer_accepted = e;
        this.contentLoading = true;
        this.candidateService.offerAccepted(this.job.id, this.candidateId, offerAssessment).subscribe(
            async (response: any) => {
                let assignments = this.candidate.assignments;
                let assignmentIdx = assignments.findIndex((a) => a.type === 'offer');

                if (assignmentIdx !== -1) {
                    assignments[assignmentIdx] = response.assignment;
                    this.store.dispatch(
                        new fromJobsStore.UpdateJobCandidate({
                            jobId: this.job.id,
                            candidateId: this.candidate.id,
                            data: { assignments }
                        })
                    );
                }

                const comment = {
                    id: this.utilities.generateUID(10).toLowerCase(),
                    status: e,
                    created_at: new Date().getTime(),
                    user_id: this.user.id,
                    type: 'offer'
                };
                this.candidateService
                    .addToAudit(this.job.id, this.candidate.id, comment)
                    .then((res: any) => {
                        // this.offerAudit = res.audit[this.job.id];
                        this.contentLoading = !this.loadCounter.minusLoad();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.contentLoading = !this.loadCounter.minusLoad();
                    });
            },
            (err) => {
                console.log(err);
                this.contentLoading = !this.loadCounter.minusLoad();
            }
        );
    }

    onResendOffer(job_id, candidate_id) {
        console.log(job_id, candidate_id);
        this.contentLoading = true;
        this.candidateService.resendOffer(job_id, candidate_id).subscribe(
            (res) => {
                console.log('res', res);
                this.contentLoading = false;
            },
            (err) => {
                console.error(err);
                this.contentLoading = false;
            }
        );
    }

    _getYearsFromMonths(months) {
        if (!months || months === 0) {
            return 0;
        }

        const years = Math.floor(months / 12);
        return years;
    }

    checkIfLocationMatch() {
        if (!this.job) {
            return;
        }

        if (this.job.is_remote) {
            this.showRelocate = false;
        } else if (!this.job.is_remote && this.candidate.location) {
            this.candidateService.isLocationMatch(this.job.id, this.candidate.id).subscribe((isMatch) => {
                if (!isMatch) {
                    this.showRelocate = true;
                }
            });
        }
    }

    updateCandidateFromSelect(jobId) {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.candidateSubscription) {
            this.candidateSubscription.unsubscribe();
        }
        this.getCandidateInfo(this.candidate, jobId);
        this.userSubscription = this.store.pipe(select(fromStore.getUserEntity)).subscribe((user: User) => {
            this.user = user;
        });

        this.declineModalForm = this.fb.group({});
        this.declineForm = this.fb.group({
            reason: [''],
            send_email: [false]
        });
        this.activeSection = 'overview';
    }

    getTagStyle(color: string) {
        function getTextColor(color: string): string {
            return color === '#f6f8f9' ? 'black' : 'white';
        }
        return {
            background: color,
            color: getTextColor(color)
        };
    }

    titleCase(str: string) {
        return str
            .replace(/#/g, '')
            .split(/[-|_]/g)
            .join(' ')
            .replace(/\w*\S/g, (t) => t[0].toUpperCase() + t.substr(1));
    }

    getAppliedJobsIds() {
        this.getAppliedJobsStore(this.candidate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (jobs: any) => {
                    const checkValue = this.changedJobId ? this.changedJobId : this.jobId;
                    this.appliedJobs = jobs;
                    const currentJobExist = this.appliedJobs.find((item) => item.value.id === this.jobId);
                    this.getAppliedJobsFunctionality(checkValue, currentJobExist);
                },
                (error) => {
                    this.candidateService.getAppliedJobs(this.candidateId).subscribe(
                        (jobs: any) => {
                            const checkValue = this.changedJobId ? this.changedJobId : this.jobId;
                            this.appliedJobs = jobs;
                            const currentJobExist = this.appliedJobs.find((item) => item.value.id === this.jobId);
                            this.getAppliedJobsFunctionality(checkValue, currentJobExist);
                        },
                        (error) => {
                            this.appliedJobs.unshift({
                                label: this.job.title,
                                value: {
                                    location: this.job.is_remote
                                        ? 'Remote'
                                        : this.job.location && this.job.location.length
                                        ? this.job.location[0]
                                        : '',
                                    stage: this.job.stages[0].id,
                                    stage_label: this.job.stages[0].id.split('_')[0],
                                    id: this.jobId
                                }
                            });
                            const checkValue = this.changedJobId ? this.changedJobId : this.jobId;
                            // const index = this.appliedJobs.findIndex((job) => job.value.id === checkValue);
                            // const element = { ...this.appliedJobs.find((job) => job.value.id === checkValue) };
                            // this.appliedJobs.splice(index, 1);
                            // this.appliedJobs = this.appliedJobs.filter((item) => item.value.id !== checkValue);
                            // this.appliedJobs.unshift(element);
                            this.appliedJobsId = this.appliedJobs.findIndex((job) => job.value.id === checkValue);
                            this.recountAppliedProgress(checkValue);
                        }
                    );
                }
            );
    }

    getAppliedJobsFunctionality(checkValue, currentJobExist) {
        this.selectedJobOption = currentJobExist;
        if (!this.initialJob || !this.initialJob.stages || !this.initialJob.stages.length) {
            return;
        }
        if (!currentJobExist && checkValue === this.jobId && this.initialJob) {
            this.appliedJobs.unshift({
                label: this.initialJob.title,
                value: {
                    location: this.initialJob.is_remote
                        ? 'Remote'
                        : this.initialJob.location && this.initialJob.location.length
                        ? this.initialJob.location[0]
                        : '',
                    stage: this.initialJob.stages[0].id,
                    stage_label: this.initialJob.stages[0].id.split('_')[0],
                    id: this.jobId
                }
            });
        } else if (!currentJobExist && checkValue !== this.jobId && this.initialJob) {
            this.appliedJobs.push({
                label: this.initialJob.title,
                value: {
                    location: this.initialJob.is_remote
                        ? 'Remote'
                        : this.initialJob.location && this.initialJob.location.length
                        ? this.initialJob.location[0]
                        : '',
                    stage: this.initialJob.stages[0].id,
                    stage_label: this.initialJob.stages[0].id.split('_')[0],
                    id: this.jobId
                }
            });
            const index = this.appliedJobs.findIndex((job) => job.value.id === checkValue);
            const element = { ...this.appliedJobs.find((job) => job.value.id === checkValue) };
            this.appliedJobs.splice(index, 1);
            this.appliedJobs = this.appliedJobs.filter((item) => item.value.id !== checkValue);
            this.appliedJobs.unshift(element);
        } else {
            const index = this.appliedJobs.findIndex((job) => job.value.id === checkValue);
            const element = { ...this.appliedJobs.find((job) => job.value.id === checkValue) };
            this.appliedJobs.splice(index, 1);
            this.appliedJobs = this.appliedJobs.filter((item) => item.value.id !== checkValue);
            this.appliedJobs.unshift(element);
        }
        this.appliedJobsId = this.appliedJobs.findIndex((job) => job.value.id === checkValue);
        this.recountAppliedProgress(checkValue);
    }

    selectAppliedJob(data) {
        console.log('🕹 selectAppliedJob', data);
        if (!data.value) {
            return;
        }
        this.changedJobId = data.value.id;
        this.appliedProgress = [];
        this.contentLoading = true;
        const updatedJobId = data.value.id;
        this.updateCandidateFromSelect(updatedJobId);
    }

    getAppliedJobsStore(candidate: Candidate) {
        return new Observable((subscriber) => {
            let jobs = candidate.job_id;
            let appliedJobs = [];
            let stages = jobs.map((id) => {
                let stage = 'applied';
                if (candidate.stage[id]) {
                    stage = candidate.stage[id];
                }
                return stage;
            });
            this.store
                .pipe(select(fromStoreSelectors.getAllJobs))
                .pipe(take(1))
                .subscribe(
                    (jobs_res: SdkJob[]) => {
                        if (!jobs_res.length) {
                            subscriber.error({ success: false });
                        }
                        for (let index = 0; index < jobs.length; index++) {
                            const id = jobs[index];
                            const job = jobs_res.find((job_in) => job_in.id === id);
                            if (!job) continue;
                            if (
                                (this.user.role === 'recruitment_agency' || this.user.role === 'agency_user') &&
                                this.candidate.agency_owner &&
                                this.candidate.agency_owner[id] !== this.user.id &&
                                this.candidate.agency_owner[id] !== this.user.agency
                            ) {
                                continue;
                            }
                            let transformedjob: any;
                            transformedjob = {
                                label: job.title,
                                value: {
                                    location: job.is_remote
                                        ? 'Remote'
                                        : job.location && job.location.length
                                        ? this.replaceLocationToShort(job.location)
                                        : '',
                                    stage: stages[index],
                                    stage_label: stages[index].split('_')[0],
                                    id
                                }
                            };
                            appliedJobs.push(transformedjob);
                        }
                        subscriber.next(appliedJobs);
                    },
                    (error) => subscriber.error(error)
                );
        });
    }

    recountAppliedProgress(jobId = this.job.id) {
        this.getJob(jobId).subscribe(
            (job: SdkJob) => {
                let count = null;
                if (job && job.stages) {
                    const stageSelectedCandidate = this.appliedJobs[this.appliedJobsId].value.stage;
                    const foundStage = job.stages.find((job) => job.id === stageSelectedCandidate);
                    const count_stages = job.stages && job.stages.length ? job.stages.length : 5;

                    if (foundStage) {
                        count = foundStage.order + 1;

                        this.appliedProgress = [];
                        for (let i = 0; i < count_stages; i++) {
                            this.appliedProgress.push({
                                active: false
                            });
                        }

                        if (this.appliedJobs && this.appliedJobs.length) {
                            this.appliedProgress.map((dot, idx) => {
                                if (count > idx) {
                                    dot.active = true;
                                }

                                if (count === idx + 1) {
                                    dot.last = true;
                                }

                                return dot;
                            });
                        }
                    }
                }
            },
            (error) => console.error(error)
        );
    }

    getAgencyOwner(jobId) {
        const agencyOwnerId =
            this.candidate.agency_owner && this.candidate.agency_owner[jobId] ? this.candidate.agency_owner[jobId] : '';
        const agencyOwner = this.users.find((u) => u.id === agencyOwnerId);
        if (agencyOwner) {
            // console.log('OWNER ROLE:', agencyOwner.role, 'Current user role:', this.user.role);
            let agency;
            if (
                agencyOwner.role === 'agency_user' &&
                agencyOwner.agency &&
                this.users.find((u) => u.id === agencyOwner.agency)
            ) {
                agency = this.users.find((u) => u.id === agencyOwner.agency);
            }
            this.currentAgencyOwner = agency
                ? {
                      label: `${agency.first_name} ${agency.last_name}`,
                      value: { id: agencyOwnerId }
                  }
                : {
                      label: `${agencyOwner.first_name} ${agencyOwner.last_name}`,
                      value: { id: agencyOwnerId }
                  };
        } else {
            this.currentAgencyOwner = {
                label: null,
                value: null
            };
        }
        this.tagChanged = true;
        setTimeout(() => {
            this.tagChanged = false;
        }, 200);
        // console.log(this.currentAgencyOwner);
    }

    getOwner() {
        // console.log('getOwner', this.candidate.owner);
        const ownerId = this.candidate.owner ? this.candidate.owner : '';

        this.currentOwner = null;
        this.potentialOwners = this.users
            .filter(
                (u) =>
                    u.id !== ownerId &&
                    u.role &&
                    ['recruitment_agency', 'null', 'agency_user', 'employee'].indexOf(u.role) === -1
            )
            .map((user) => {
                if (user.role === 'agency_user' && user.agency && this.users.find((u) => u.id === user.agency)) {
                    const agency = this.users.find((u) => u.id === user.agency);
                    return {
                        label: agency.first_name + ' ' + agency.last_name,
                        value: { id: user.id, color: this.randomColor() }
                    };
                } else {
                    return {
                        label: user.first_name + ' ' + user.last_name,
                        value: { id: user.id, color: this.randomColor() }
                    };
                }
            });

        this.alphabeticalSort(this.potentialOwners);

        const owner = this.users.find((u) => u.id === ownerId);
        if (owner) {
            // console.log('OWNER ROLE:', owner.role, 'Current user role:', this.user.role);
            let agency;
            if (owner.role === 'agency_user' && owner.agency && this.users.find((u) => u.id === owner.agency)) {
                agency = this.users.find((u) => u.id === owner.agency);
            }
            this.currentOwner = agency
                ? {
                      label: `${agency.first_name} ${agency.last_name}`,
                      value: { id: ownerId }
                  }
                : {
                      label: `${owner.first_name} ${owner.last_name}`,
                      value: { id: ownerId }
                  };
            this.potentialOwners.unshift(this.currentOwner);

            if (owner.role === 'recruitment_agency') {
                this.changeOwnershipAllowed =
                    this.user.role === 'account_owner' || this.user.role === 'admin' ? true : false;
            } else {
                this.changeOwnershipAllowed =
                    this.user.role === 'account_owner' || this.user.role === 'admin' || this.user.id === ownerId
                        ? true
                        : false;
            }
        } else {
            if (this.job && this.job.owner) {
                this.changeOwnershipAllowed =
                    this.user.role === 'account_owner' || this.user.role === 'admin' || this.user.id === this.job.owner
                        ? true
                        : false;
            }
        }
        // console.log('changeOwnershipAllowed', this.changeOwnershipAllowed);
        this.contentLoading = !this.loadCounter.minusLoad();
    }

    private alphabeticalSort(list: any[]) {
        list.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    }

    setCandidateProfileMode() {
        this.editCandidateProfileMode = true;
    }

    onCandidateProfileClosed(candidate) {
        if (candidate) {
            console.log('Send to server: ', this.candidateId, candidate);
            const audit = {
                type: 'contact-details',
                user_id: this.user.id,
                created_at: new Date().getTime(),
                updated: [],
                created: []
            };
            if (!candidate.address) {
                delete candidate.address;
            }
            this.candidate.fullName = `${this.candidate.first_name} ${this.candidate.last_name}`;
            for (var key in candidate) {
                const value = this.candidate[key];
                // console.log(value, key, candidate[key])
                if (!value && candidate[key] && key !== 'fullName') {
                    audit.created.push(key);
                } else if (value && value !== candidate[key]) {
                    audit.updated.push(key);
                }
            }
            Object.assign(this.candidate, candidate);
            this.candidateService
                .updateCandidateData(this.candidateId, { ...candidate, jobId: this.jobId })
                .subscribe((response) => {
                    console.log(response);
                    this.candidateService
                        .addToAudit(this.job.id, this.candidate.id, audit)
                        .catch((error) => console.error(error));
                    this.candidate.audit[this.job.id].push(audit);
                    this.dataChanged = true;
                    setTimeout(() => {
                        this.dataChanged = false;
                    }, 200);
                });
        }
        this.editCandidateProfileMode = false;
    }

    changeOwner(event) {
        const newOwnerId = event.value.id;
        this.candidateService.updateOwnerById(this.candidateId, this.job.id, newOwnerId).subscribe((data: any) => {
            // console.log(data);
            this.ownerUpdated.emit([this.candidateId, this.jobId, data.data]);
        });
    }

    randomColor() {
        let hex = Math.floor(Math.random() * 0xffffff);
        let color = '#' + hex.toString(16);

        return color;
    }

    async getCandidateInfo(candidate: Candidate, jobId: string) {
        // console.log('▶️ !!!GET CANDIDATE INFO', { ...candidate });
        // 1. Load job
        const job: SdkJob = await this.getJob(jobId).toPromise();
        this.job = job;

        this.questionnaires = [];
        if (!candidate && this.candidate) {
            console.log('Probably candidate was deleted and we need to close the modal');
        }
        if (!candidate) {
            return this.router.navigateByUrl('/not-found');
        }
        this.candidate = candidate;

        // console.log('candidate at this point:', { ...candidate });
        this.showRelocate = false;
        this.setCandidateFields(jobId);

        // 2. Load resume data
        // deprecated
        // console.time('-getCandidateInfo');
        // console.timeEnd('-getCandidateInfo');

        this.showIdVerified = typeof candidate.idVerified !== 'undefined';
        this.showCriminalCheck = typeof candidate.criminalCheck !== 'undefined';

        this.calculateOwnerRating(job);
        // 3. Load matchind data
        if (jobId) {
            // this.jobService.getCandidatesMatches(jobId, candidate.id).then((candidate_matches: any) => {
            //     if (candidate_matches) {
            //         this.candidate.candidate_matches = candidate_matches;
            //         // this.transformSkills(candidate_matches);
            //     }
            // });
            this.jobService.populateSkills(this.jobId, this.candidateId).then((skills: any[]) => {
                this.candidate.candidate_skills = skills;
            });
        }

        // 4. Load assignment
        if (jobId) {
            this.jobService
                .getAssignments(candidate.id, jobId)
                .then(async (assignments: any[]) => {
                    if (!assignments) {
                        assignments = [];
                    }
                    const criminalAss: any = await this.candidateService.getCriminalCheckAss(candidate.id);
                    if (criminalAss && !assignments.find((ass) => ass.id === criminalAss.id)) {
                        assignments.push(criminalAss);
                    }

                    this.candidate.assignments = assignments;
                    this.countStatuses(jobId);
                    this.processAssessments();
                    if (this.candidate.assignments && this.candidate.assignments) {
                        let assignments = this.candidate.assignments;
                        let assignment = assignments.find((ass) => ass.type === 'offer');
                        if (assignment) {
                            this.offerAssessment = assignment;
                        }

                        let criminal_check_ass = assignments.find((ass) => ass.type === 'criminal-check');
                        if (criminal_check_ass && criminal_check_ass.data && criminal_check_ass.data.id) {
                            this.candidate.id_number = criminal_check_ass.data.id;
                            this.candidate.obfuscated_id_number = this.obfuscatedIDnumber(criminal_check_ass.data.id);
                        }
                    }
                    // 5. Load questions
                    this.loadQuestions();
                })
                .catch((error) => console.error(error));

            this.candidateService
                .getCriminalCheckAss(candidate.id)
                .then((ass: any) => {
                    this.criminalCheckAss = ass && ass.length ? ass[0] : null;
                    if (this.criminalCheckAss) {
                        this.criminalCheckAss.added_at_formatted = this.criminalCheckAss.added_at
                            ? moment.unix(this.criminalCheckAss.added_at).format('DD MMMM YYYY')
                            : null;
                        this.criminalCheckAss.completed_at_formatted = this.criminalCheckAss.completed_at
                            ? moment.unix(this.criminalCheckAss.completed_at).format('DD MMMM YYYY')
                            : null;
                        // console.log('====criminalCheckAssignment', this.criminalCheckAss);
                    }
                })
                .catch((error) => console.error(error));

            // if (this.offerService && this.offerService.getOfferApprovalDetails) {
            this.offerService.getOfferApprovalDetails2(this.candidate.id, this.jobId).subscribe((response) => {
                // console.log('offerApprovalDetails', response);
                if (response) {
                    this.candidate['offer-approval'] = response;
                }
            });
            // }
        } else {
            const criminalAss: any = await this.candidateService.getCriminalCheckAss(candidate.id);
            if (!this.candidate.assignments) {
                this.candidate.assignments = [];
            }
            if (criminalAss && !this.candidate.assignments.find((ass) => ass.id === criminalAss.id)) {
                this.candidate.assignments.push(criminalAss);
                this.criminalCheckAss = criminalAss;
            }
        }

        // 6. Load Audit
        this.loadAudit();

        this.checkIfLocationMatch();
        this.allowShowFeedback();

        this.checkOtherFiles();
        this.stagesData = [];
        this.getAppliedJobsIds();
        this.getOwner();
        this.getAgencyOwner(jobId);
        // this.getAllAudit();

        this.jobOwner =
            (this.job && this.job.owner && this.job.owner === this.user.id) || this.user.role === 'recruiter'
                ? true
                : false;

        // Load Interviews
        this.loadInterviews();
        // Load documents
        this.checkResumeFile().then((res) => {
            this.loadDocuments();
        });
        // if (this.candidate.matching && this.candidate.matching[jobId]) {
        //     this.candidateScore = this.candidate.matching[jobId];
        // }
        if (this.candidate.matchingScores && typeof this.candidate.matchingScores[jobId] !== 'undefined') {
            this.candidateScoreDefined = true;
            this.candidateScore = this.candidate.matchingScores[jobId];
        }

        if (this.candidate.employment_history) {
            this.candidate.employment_history = this.candidate.employment_history.sort((a, b) => {
                return moment(b.start_date, 'MM / YYYY').unix() - moment(a.start_date, 'MM / YYYY').unix();
            });
        }
        if (this.candidate.avg_months_per_employer) {
            this.candidate.avg_months_per_employer = this.utilities.convertMonthsToYears(
                parseInt(this.candidate.avg_months_per_employer, 10)
            );
        }
        if (this.candidate.employment_history && this.candidate.employment_history.length) {
            this.findLastPosition(this.candidate.employment_history);
        }
        this.contentLoading = false;
    }

    loadQuestions() {
        this.jobService
            .getJobQuestionsList(this.job)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (list: any[]) => {
                    this.job.questions_list = list;
                    this.resumeThreshold = this.job.resume_matching_threshold || 0;
                    if (this.candidate.stage && this.candidate.stage[this.job.id]) {
                        this.stageId = this.candidate.stage[this.job.id];
                    }
                    if (!this.job.tags) this.job.tags = [];

                    this.stages = this.job.stages.sort((a, b) => a.order - b.order);

                    this.questionsList = this.candidate.assignments.filter(
                        (c) => c.type === 'questions' || c.type === 'video-interview'
                    );
                    if (this.questionsList.length) {
                        if (this.job.questions_list) {
                            this.questionnaires = [];
                            this.questionsList.forEach((q) => {
                                q.questions = this.job.questions_list.find((l) => l.id === q.option);
                                let stage = this.job.stages.find((s) => s.id === q.stageId);
                                let index = this.questionnaires.findIndex((s) => s.id === stage.id);
                                if (index > -1) {
                                    this.questionnaires[index].questions.push(q);
                                } else {
                                    this.questionnaires.push({
                                        id: stage.id,
                                        stage: stage.title || stage.id,
                                        icon: stage.icon,
                                        questions: [q]
                                    });
                                }
                            });
                        }
                        this.prepareQuestionsAnswers();
                        if (!this.sections.includes('questions')) {
                            this.sections.splice(2, 0, 'questions');
                        }
                    }
                },
                (error) => console.error(error)
            );
    }

    loadAudit() {
        this.jobService
            .getCandidateAudit(this.candidate.id)
            .then((audit: any) => {
                if (!audit) {
                    audit = {};
                }
                // console.log('candidate audit:', audit);
                this.candidate.audit = audit;
            })
            .catch((error) => console.error(error));
    }

    loadDocuments() {
        this.documentsLoading = true;
        this.candidateService.getDocuments(this.candidate.id).subscribe(
            (documents: any[]) => {
                if (!this.candidate.uploaded) {
                    this.candidate.uploaded = [];
                }
                this.candidate.uploaded = [...this.candidate.uploaded, ...documents];
                for (const file of this.candidate.uploaded) {
                    if (!this.documents.find((d) => d.name === file.name)) {
                        this.documents.push({
                            id: file.id,
                            name: file.name,
                            size: file.size,
                            uploadedAt: file.uploaded_at,
                            originalName: file.original_name || file.name,
                            link: file.link,
                            documentType: this.getDocumentType(file.type || 'other'),
                            extension: this.getExtension(file.name)
                        });
                    }
                }
                if (this.candidate.resume_file && this.candidate.resume_file.length) {
                    if (!this.documents.find((d) => d.name === this.candidate.resume_file)) {
                        if (this.candidate.resume_link) {
                            this.documents.push({
                                id: null,
                                size: 10000,
                                uploadedAt: this.candidate.created_at * 1000,
                                name: this.candidate.resume_file,
                                originalName: this.candidate.resume_file,
                                link: this.candidate.resume_link,
                                documentType: this.getDocumentType('resume'),
                                extension: this.getExtension(this.candidate.resume_file)
                            });
                        }
                    }
                }

                const resume = this.documents.find((d) => d.documentType === 'Resume');
                if (resume && (!resume.link || !resume.link.length) && this.candidate.resume_file) {
                    resume.name = resume.originalName = this.candidate.resume_file;
                    resume.link = this.candidate.resume_link;
                    resume.extension = this.getExtension(resume.name);
                    resume.size = null;
                } else if (resume && (!resume.link || !resume.link.length) && this.candidate.resume_file_new) {
                    resume.name = resume.originalName = this.candidate.resume_file_new;
                    resume.link = `${environment.apiUrl}/tenants/${this.utilities.getTenant()}/resume-link?file=${
                        this.candidate.resume_file_new
                    }`;
                    resume.extension = this.getExtension(resume.name);
                    resume.size = null;
                }

                this.documents = this.filterDocuments(this.documents.slice());
                // console.log('Documents', this.documents);
                this.documentsLoading = false;
                if (this.queryParametr['open-scorecard'] && this.candidateInterviews) {
                    this.onChangeSection('interviews');
                }
            },
            (errorResponse) => {
                console.error(errorResponse);
                this.documentsLoading = false;
            }
        );
    }

    loadInterviews() {
        if (this.job) {
            this.candidateService
                .getDbCandidateInterviews(this.candidateId, this.job.id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (interviews) => {
                        this.candidateInterviews = interviews;
                        if (
                            this.queryParametr['open-scorecard'] &&
                            this.candidate.uploaded &&
                            this.candidate.uploaded.length
                        ) {
                            this.onChangeSection('interviews');
                        }
                    },
                    (errorResponse) => console.error(errorResponse)
                );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    unsubscribeSubscriptions() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.candidateSubscription) {
            this.candidateSubscription.unsubscribe();
        }
        if (this.jobSubscription) {
            this.jobSubscription.unsubscribe();
        }
        if (this.generalSubscription) {
            this.generalSubscription.unsubscribe();
        }
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
    }

    calculateOwnerRating(job) {
        if (!job) {
            return;
        }
        this.currentRating = null;
        if (!this.candidate.feedback) {
            return;
        }
        const feedbackOfThisJob = this.candidate.feedback[job.id];
        if (!feedbackOfThisJob) {
            return;
        }
        if (feedbackOfThisJob.job_rating) {
            this.currentRating = feedbackOfThisJob.job_rating;
        } else {
            const rateOfOwner = feedbackOfThisJob.rating.find((rate) => {
                return rate.user_id === job.owner;
            });
            if (rateOfOwner) {
                this.currentRating = rateOfOwner.value;
            }
        }
    }

    recalculateRating(feedback, job) {
        this.showRatingDropdown = false;
        if (feedback) {
            this.candidate.feedback = feedback;
        }
        if (!this.candidate.feedback) {
            return;
        }
        const feedbackOfThisJob = this.candidate.feedback[job.id];
        if (!feedbackOfThisJob) {
            return;
        }

        if (feedbackOfThisJob.job_rating) {
            this.currentRating = feedbackOfThisJob.job_rating;
        } else {
            const rateOfOwner = feedbackOfThisJob.rating.find((rate) => rate.user_id === job.owner);
            if (rateOfOwner) {
                this.currentRating = rateOfOwner.value;
            }
        }
        if (job.id === this.jobId) {
            this.changeRating.emit([this.candidate.id, this.jobId, this.currentRating]);
        }

        if (!this.candidate.audit[this.job.id]) {
            this.candidate.audit[this.job.id] = [];
        }
        this.candidate.audit[this.job.id].push({
            type: 'rating',
            value: this.currentRating,
            user_id: this.user.id,
            created_at: new Date().getTime()
        });
        this.dataChanged = true;
        setTimeout(() => {
            this.dataChanged = false;
        }, 200);
    }

    async getOffersStatuses(candidate, jobId) {
        let acceptedAnotherOffer = false;
        let underReviewOnAnotherJob = false;
        const isSelfDeleted =
            candidate.selfDeclinedJobs &&
            candidate.selfDeclinedJobs.length &&
            candidate.selfDeclinedJobs.indexOf(jobId) !== -1 &&
            candidate.job_id.indexOf(jobId) === -1;
        const isDeleted =
            candidate.declinedJobs &&
            candidate.declinedJobs.length &&
            candidate.declinedJobs.indexOf(jobId) !== -1 &&
            candidate.job_id &&
            candidate.job_id.indexOf(jobId) === -1;
        if (candidate.stage) {
            for (const key in candidate.stage) {
                if (candidate.stage.hasOwnProperty(key)) {
                    const st = candidate.stage[key];
                    if (st === 'hired' && key !== jobId) {
                        const candidateDeclined = candidate.declinedJobs ? [...candidate.declinedJobs] : [];
                        const isDeclinedOnHiredJob = candidateDeclined.find((id) => id === key);

                        if (isDeclinedOnHiredJob) {
                            continue;
                        }
                        //  const assignment = candidate.assignments.find((ass) => ass.stageId === 'hired');
                        const assignments: any[] = await this.jobService.getHiredAssignments(candidate.id);
                        if (assignments && assignments[0]) {
                            if (!candidate.job_id) {
                                candidate.job_id = [];
                            }
                            let hiredAssIndex = candidate.job_id.findIndex((j) => j === assignments[0].jobId);
                            let activeJobIndex = candidate.job_id.findIndex((j) => j === jobId);
                            if (assignments[0].offer_accepted && activeJobIndex > hiredAssIndex) {
                                continue;
                            } else if (typeof assignments[0].offer_accepted !== 'undefined') {
                                if (assignments[0].offer_accepted) {
                                    acceptedAnotherOffer = true;
                                    // console.log(key, assignments[0]);
                                } else {
                                    underReviewOnAnotherJob = true;
                                }
                            } else {
                                underReviewOnAnotherJob = true;
                            }
                        } else {
                            underReviewOnAnotherJob = true;
                        }
                    }
                }
            }
        }

        return [isDeleted, isSelfDeleted, underReviewOnAnotherJob, acceptedAnotherOffer];
    }

    setCandidateFieldsFromPeople() {
        if (!this.candidate) {
            return;
        }
        if (this.candidate.profile_image && !this.candidate.profile_image_link) {
            if (this.candidate.profile_image.includes('https://')) {
                this.candidate.profile_image_link = this.candidate.profile_image;
            } else {
                this.candidateService.getProfileImageLink(`${this.candidate.profile_image}&collection=Users`).subscribe(
                    (response: string) => (this.candidate.profile_image_link = response),
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    getDocumentType(type: string) {
        const documentType = this.documentsTypeOptions.find((d) => d.value.toLowerCase() === type.toLowerCase());
        return documentType ? documentType.value : '';
        // switch (type) {
        //     case 'resume':
        //         return 'Resume';
        //     case 'letter':
        //         return 'Cover Letter';
        //     case 'portfolio':
        //         return 'Portfolio';
        //     case 'id':
        //         return 'ID / Passport';
        //     case 'other':
        //         return 'Other';
        //     default:
        //         return 'Other';
        // }
    }

    tagUpdated(event) {
        this.dataChanged = true;
        setTimeout(() => {
            this.dataChanged = false;
        }, 200);
    }

    filterDocuments(documents: any[]) {
        const resumes = documents
            .filter((d) => d.documentType === 'Resume')
            .sort((a, b) => (a.uploadedAt > b.uploadedAt ? -1 : 1));
        const otherFiles = documents
            .filter((d) => d.documentType !== 'Resume')
            .sort((a, b) => (a.uploadedAt > b.uploadedAt ? -1 : 1));
        const resultArr = [];
        if (resumes && resumes.length) {
            resultArr.push(resumes[0]);
        }
        if (otherFiles && otherFiles.length) {
            resultArr.push(...otherFiles);
        }
        return resultArr;
    }

    transformSkills(matches) {
        const skills = [];
        if (matches.EnrichedScoreData && matches.EnrichedScoreData.Skills && matches.EnrichedScoreData.Skills.Found) {
            matches.EnrichedScoreData.Skills.Found.forEach((s) => {
                if (s.IsCurrent) {
                    skills.unshift({ skill: s.Skill, type: 'current' });
                } else {
                    skills.push({ skill: s.Skill, type: 'not-current' });
                }
            });
        }
        if (
            matches.EnrichedScoreData &&
            matches.EnrichedScoreData.Skills &&
            matches.EnrichedScoreData.Skills.NotFound
        ) {
            matches.EnrichedScoreData.Skills.NotFound.forEach((s) => {
                skills.push({ skill: s, type: 'not-exist' });
            });
        }
        this.candidate.candidate_skills = skills;
    }

    onCriminalCheckModalClose(event) {
        this.showCriminalCheckModal = false;
    }

    onCriminalCheckAssAdded(obj: any) {
        this.candidate.criminalCheckStatus = 'pending';
        this.candidate.assignments.push(this.candidateService.processCriminalCheckAss(this.candidate.id, obj));
    }

    async onViewJobOffer() {
        console.log('onViewJobOffer', this.candidate, this.job);
        this.contentLoading = true;
        const file: any = await this.candidateService
            .getHellosignFile(this.candidate.id)
            .toPromise()
            .catch(() => {
                console.log('error');
                this.contentLoading = false;
            });
        this.contentLoading = false;
        this.viewJobOfferModal = {
            show: true,
            url: file.url
        };
    }

    onNavigateToEmployeeProfile() {
        this.router.navigateByUrl(
            `/tenant/${this.utilities.getTenant()}/hire/employees?employee_id=${this.candidateId}`
        );
    }

    obfuscatedIDnumber(number) {
        let new_id_number = '';
        const length = number.length;
        for (var i = 0; i < number.length; i++) {
            if (i < 4) {
                new_id_number += number.charAt(i);
            } else if (length - i < 4) {
                new_id_number += number.charAt(i);
            } else {
                new_id_number += 'X';
            }
        }
        return new_id_number;
    }

    onAddCandidateToRole(candidate) {
        this.addCandidateToRoleModal = {
            show: true,
            candidate
        };
        this.showQuickActionsDropdown = false;
    }

    onCloseRoleModal() {
        this.addCandidateToRoleModal = {
            show: false,
            candidate: null
        };
    }

    onGenerateResume() {
        this.contentLoading = true;
        this.candidateService.generateBrandedResume(this.candidate.id).subscribe(
            (response: any) => {
                console.log('generateBrandedResume response', response);
                this.contentLoading = false;
                const url = response.link;
                let name = `${this.candidate.first_name} ${this.candidate.last_name}_Resume_${moment
                    .unix(this.candidate.created_at)
                    .format('YYYYMMDD')}`;
                this.employeeService.downloadFile(url, name).subscribe();
                this.showQuickActionsDropdown = false;
            },
            (err) => {
                console.log(err);
                this.contentLoading = false;
                this.toastr.error('Error');
            }
        );
    }

    onShowDoNotHireModal() {
        this.showQuickActionsDropdown = false;
        this.showDoNotHireModal = {
            show: true,
            candidate: this.candidate
        };
    }

    onCloseDoNotHireModal() {
        this.showDoNotHireModal = {
            show: false,
            candidate: null
        };
    }

    onMarkAsDoNotHire(audit) {
        this.onCloseDoNotHireModal();
        this.candidate.audit[this.job.id].push(audit);
        this.dataChanged = true;
        setTimeout(() => {
            this.dataChanged = false;
        }, 200);
    }

    onCareerPortalProfile() {
        this.contentLoading = true;
        this.candidateService.getCareerPortalProfile(this.candidateId).subscribe((response: any) => {
            // console.log('response', response);
            this.contentLoading = false;
            window.open(response.url, '_blank');
            this.showQuickActionsDropdown = false;
        });
    }
}

class LoadCounter {
    count: number = 0;

    plusLoad() {
        this.count++;
        return this.isLoaded();
    }

    minusLoad() {
        this.count--;
        if (this.count < 0) this.count = 0;

        return this.isLoaded();
    }

    isLoaded() {
        return !this.count;
    }
}
