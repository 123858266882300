<div class="description">
    <h3>Sync Your Calendar</h3>
    <p>
        Before we get started we need to sync your calendar’s free / busy information. Please click below to connect
        your calendar to &Team.
    </p>
</div>

<div class="select-service">
    <div class="services-list">
        <button
            class="service-item"
            *ngFor="let service of servicesList"
            [disabled]="!service.enabled"
            [ngClass]="{ active: activeService === service.title }"
            (click)="selectScheduleService(service.id)"
        >
            <img [src]="service.icon" [alt]="service.title" />
            <span>{{ service.title }}</span>
        </button>
    </div>

    <!-- <div class="next-step flex-row jcc">
        <button class="btn btn-primary" [disabled]="!nextEnabled" (click)="goToNextStep()">
            Next
        </button>
    </div> -->
</div>
