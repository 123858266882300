import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PreferencesService, UtilitiesService } from '@app/core/services';
import { Message } from 'primeng/api';

@Component({
    selector: 'app-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
    @Input() config;
    @Input() themeForm: FormGroup;

    contentLoading = true;
    cpWidth = '250px';
    msgs: Message[] = [];
    colors: string[] = [
        '#44DB5E',
        '#5AC8FA',
        '#FFCC00',
        '#FF9500',
        '#FF2D55',
        '#4CD964',
        '#FF3B30',
        '#BD10E0',
        '#43a547',
        '#c7d732',
        '#e3b505',
        '#ff8d02',
        '#6e4b3f',
        '#949394',
        '#557280'
    ];
    uploadQueue: any[] = [];
    uploadError: any = {};
    supportedFileTypes: string[] = ['image/jpeg', 'image/png', 'image/gif']; // 'image/svg+xml'
    supportedFaviconFileTypes: string[] = ['image/png', 'image/vnd.microsoft.icon'];
    uploadLogo: any;
    uploadHeader: any;
    uploadFavicon: any;
    previousColorLength: number;
    previousButtonColorLength: number;

    constructor(
        private fb: FormBuilder,
        private preferencesService: PreferencesService,
        private utilities: UtilitiesService
    ) {}

    ngOnInit() {}

    get color() {
        return this.themeForm.get('color').value;
    }

    set color(value: string) {
        if (value.length === 7 && this.previousColorLength !== 3 && this.previousColorLength !== 5) {
            this.themeForm.controls.color.setValue(value);
        }
        this.previousColorLength = 0;
    }

    get buttonColor() {
        return this.themeForm.get('button_color').value;
    }

    set buttonColor(value: string) {
        if (value.length === 7 && this.previousColorLength !== 3 && this.previousButtonColorLength !== 5) {
            this.themeForm.controls.button_color.setValue(value);
        }
        this.previousColorLength = 0;
    }

    get logoUrl() {
        return this.themeForm.get('logo_url').value;
    }

    set logoUrl(value: string) {
        this.themeForm.get('logo_url').setValue(value);
    }

    get headerUrl() {
        return this.themeForm.get('header_url').value;
    }

    set headerUrl(value: string) {
        this.themeForm.get('header_url').setValue(value);
    }

    get faviconUrl() {
        return this.themeForm.get('favicon_url').value;
    }

    set faviconUrl(value: string) {
        this.themeForm.get('favicon_url').setValue(value);
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    private validateFaviconFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    inputColor(color) {
        this.previousColorLength = color.length;
    }

    inputButtonColor(color) {
        this.previousButtonColorLength = color.length;
    }

    selectColor(color) {
        this.color = color;
    }

    onDropFile(files, type) {
        files = files.target ? files.target.files : files;
        if (files && files[0]) {
            const file = files[0];
            console.log('📥 onDropFile', file, type);
            if (type === 'favicon') {
                if (this.validateFaviconFileType(file, this.supportedFaviconFileTypes)) {
                    this.uploadFavicon = {
                        file,
                        uploadStarted: false,
                        uploadFinished: false,
                        progress: 0,
                        success: false
                    };
                    this.uploadFaviconFile(this.uploadFavicon, type);
                } else {
                    this.uploadError = {
                        error: 'Only supported formats are: png, ico',
                        type
                    };
                    setTimeout(() => (this.uploadError = null), 10000);
                }
            } else if (this.validateFileType(file, this.supportedFileTypes)) {
                console.log('Need to upload');
                if (type === 'logo') {
                    this.uploadLogo = {
                        file,
                        uploadStarted: false,
                        uploadFinished: false,
                        progress: 0,
                        success: false
                    };
                    this.uploadFile(this.uploadLogo, type);
                } else {
                    this.uploadHeader = {
                        file,
                        uploadStarted: false,
                        uploadFinished: false,
                        progress: 0,
                        success: false
                    };
                    this.uploadFile(this.uploadHeader, type);
                }
            } else {
                this.uploadError = {
                    error: 'Only supported formats are: png, jpg, gif',
                    type
                };
                console.log('dd', this.uploadError);
                setTimeout(() => (this.uploadError = null), 10000);
            }
        }
    }

    uploadFaviconFile(item, type: string) {
        this.uploadError = null;
        this.utilities
            .readAndResizeImage(item.file, 32, 32)
            .then((fileValue: any) => {
                console.log('ddd', fileValue);
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : 90;
                }, 400);
                const reqData = { [type]: fileValue };
                console.log(reqData);
                this.uploadImage(reqData, item, uploadProgressInterval, fileValue, type);
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    uploadFile(item, type: string) {
        this.uploadError = null;
        this.utilities
            .readAndResizeImage(item.file, 800, 800)
            .then((fileValue: any) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : 90;
                }, 400);
                const reqData = type === 'logo' ? { logo: fileValue } : { header: fileValue };
                this.uploadImage(reqData, item, uploadProgressInterval, fileValue, type);
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    uploadImage(reqData, item, uploadProgressInterval, fileValue, type) {
        this.preferencesService.uploadImage(reqData).subscribe(
            (response: HttpResponse<any>) => {
                console.log('📬 Uploaded:', response, this.themeForm, type);
                item.progress = 100;
                item.uploadFinished = true;
                item.success = true;
                clearInterval(uploadProgressInterval);
                setTimeout(() => {
                    item.fadeout = true;
                }, 2000);

                // Remove from upload queue
                setTimeout(() => {
                    this.resetUpload(response.url, type);
                }, 500);
            },
            (error) => {
                console.error(error);
                this.uploadError.error =
                    error && error.error && error.error.message ? error.error.error.message : 'Error';
                this.uploadError.type = type;
                setTimeout(() => (this.uploadError = null), 10000);
                item.progress = 100;
                item.uploadFinished = true;
                this.resetUpload(fileValue, type);
                clearInterval(uploadProgressInterval);
            }
        );
    }

    private resetUpload(url, type: string) {
        if (type === 'logo') {
            this.uploadLogo = null;
            this.logoUrl = url;
        } else if (type === 'header') {
            this.uploadHeader = null;
            this.headerUrl = url;
        } else {
            this.uploadFavicon = null;
            this.faviconUrl = url;
        }
    }
}
