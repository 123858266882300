import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/core/models';
import { CandidateService, InterviewsService } from '@app/core/services';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as fromJobsStore from '../../store';
import { SdkJob } from './../../../../../../core/models/job';
import { MsService } from './../../../../../../core/services/ms.service';
import { TenantService } from './../../../../../../core/services/tenant.service';
import * as fromStore from './../../../../../../store';
import * as fromSelectors from './../../../../../../store/selectors';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'dddd, DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@Component({
    selector: 'app-interview-create',
    templateUrl: './interview-create.component.html',
    styleUrls: ['./interview-create.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class InterviewCreateComponent implements OnInit, OnDestroy {
    @Input() service;
    @Input() job;
    @Input() candidate;
    @Input() set meetingTime(v) {
        // console.log('v', v, this.meetingTimeSuggestions);
        if (v && !this.meetingTimeSuggestions.length) {
            this.findMeetingTime();
        } else if (v && this.meetingTimeSuggestions.length) {
            this.setMeetingTime();
        }
    }
    @Input() edit;
    @Input() interview;
    @Input() user;
    @Output() close = new EventEmitter<any>();
    @Output() addedSchedule = new EventEmitter<any>();
    contentLoading = false;
    interviewSettingsForm: FormGroup;
    attendeeForm: FormGroup;
    days = [];
    schedule;

    locations: any = [];

    tenantLocationOptions: any[] = [];
    editingDisabled = false;

    dates = [
        { value: 'test_0', label: 'Data 0' },
        { value: 'test_1', label: 'Data 1' },
        { value: 'test_2', label: 'Data 2' }
    ];
    interviewTypeOptions = [
        { value: 'person', label: 'In Person' },
        { value: 'online', label: 'Online' }
    ];
    scoreCardsTypeOptions = [];
    durationTypeOptions = [
        { value: 15, label: '15 mins' },
        { value: 20, label: '20 mins' },
        { value: 25, label: '25 mins' },
        { value: 30, label: '30 mins' },
        { value: 35, label: '35 mins' },
        { value: 40, label: '40 mins' },
        { value: 45, label: '45 mins' },
        { value: 50, label: '50 mins' },
        { value: 55, label: '55 mins' },
        { value: 60, label: '60 mins' }
    ];
    startTimeTypeOptions = [
        { value: '08:00', label: '08:00' },
        { value: '09:00', label: '09:00' },
        { value: '10:00', label: '10:00' },
        { value: '11:00', label: '11:00' },
        { value: '12:00', label: '12:00' },
        { value: '13:00', label: '13:00' },
        { value: '14:00', label: '14:00' },
        { value: '15:00', label: '15:00' },
        { value: '16:00', label: '16:00' }
    ];
    availableDaysTypeOptions = [
        { value: [0, 1, 2, 3, 4, 5, 6], label: 'Everyday (Mon to Sun)' },
        { value: [1, 2, 3, 4, 5], label: 'Weekdays Only (Mon to Fri)' },
        { value: [0, 6], label: 'Weekend Only (Sat to Sun)' },
        { value: [1], label: 'Monday' },
        { value: [2], label: 'Tuesday' },
        { value: [3], label: 'Wednesday' },
        { value: [4], label: 'Thursday' },
        { value: [5], label: 'Friday' },
        { value: [6], label: 'Saturday' },
        { value: [0], label: 'Sunday' }
    ];
    availableRangeTypeOptions = [];
    availableBufferTypeOptions = [
        { value: 5, label: '5 mins before and after' },
        { value: 10, label: '10 mins before and after' },
        { value: 15, label: '15 mins before and after' },
        { value: 20, label: '20 mins before and after' },
        { value: 25, label: '25 mins before and after' },
        { value: 30, label: '30 mins before and after' }
    ];
    today = new Date();
    providerTypeOptions = [{ value: 'teamsForBusiness', label: 'Microsoft Teams' }];
    startDateTypeOptions = [];
    attendees = [];
    usersSubscription: Subscription;
    jobSubscription: Subscription;
    meetingTimeSuggestions: any[] = [];
    activeSuggestions: number = 0;
    jobOwner: User;

    setSuggestions = false;

    organizerName: string = '';
    organizerEmail: string = '';

    time = [
        { label: '08', value: '08:00' },
        { label: '09', value: '09:00' },
        { label: 10, value: '10:00' },
        { label: 11, value: '11:00' },
        { label: 12, value: '12:00' },
        { label: 13, value: '13:00' },
        { label: 14, value: '14:00' },
        { label: 15, value: '15:00' },
        { label: 16, value: '16:00' }
    ];
    limitedSlotsModal: { show: boolean; slots: number; warning?: boolean } = {
        show: false,
        slots: 0,
        warning: false
    };
    @HostListener('document:keydown.enter')
    clickout() {
        this.submitForm();
    }

    constructor(
        private route: ActivatedRoute,
        private formHelper: FormHelperService,
        private store: Store<fromStore.State>,
        private jobsStore: Store<fromJobsStore.JobsState>,
        private fb: FormBuilder,
        private msalService: MsService,
        private interviewsService: InterviewsService,
        private tenantService: TenantService,
        private toastr: ToastrService
    ) {
        this.tenantService.init();
        for (let i = 1; i < 30; i++) {
            this.availableRangeTypeOptions.push({
                value: i,
                label: `${i} days`
            });
        }
    }

    ngOnInit(): void {
        // Load locations
        this.tenantService.locations().subscribe((locations: any[]) => {
            if (locations) {
                this.locations = locations.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                this.locations.forEach((l) => {
                    this.tenantLocationOptions.push({
                        value: l.location,
                        label: l.name || l.location,
                        short: l.location_short || l.location
                    });
                });

                setTimeout(() => {
                    if (this.interview && this.interview.type === 'person') {
                        this.interviewSettingsForm.patchValue({
                            location: this.interview.location
                        });
                    }
                }, 100);
            }
        });

        this.initForms();
        if (this.service === 'office') {
            this.contentLoading = true;
            this.msalService.getCalendar().subscribe(
                (calendar: any) => {
                    console.log('📆 Calendar:', calendar);
                    this.organizerName = calendar.owner.name;
                    this.organizerEmail = calendar.owner.address;
                    this.populateProviderTypeOptions(calendar.allowedOnlineMeetingProviders);
                    this.loadAttendees();
                    this.contentLoading = false;

                    const defaultProvider = calendar.defaultOnlineMeetingProvider;
                    if (defaultProvider) {
                        this.interviewSettingsForm.patchValue({ provider: defaultProvider });
                    }
                },
                (errorResponse) => {
                    console.error(errorResponse);
                    this.close.emit();
                }
            );
        }

        this.interviewsService.getScorecards().subscribe((cards: any) => {
            if (cards && cards.length) {
                cards.forEach((c) => {
                    this.scoreCardsTypeOptions.push({ value: c.id, label: c.name });
                });
            }
        });

        this.populateStartDateTypeOptions();
        this.createListOfDays();

        this.interviewSettingsForm.valueChanges.subscribe((form) => {
            if (form.interview_date && form.start_time && form.duration) {
                this.hoverCells();
            }
        });

        this.interviewSettingsForm.get('type').valueChanges.subscribe((value) => {
            if (value) {
                if (value === 'person') {
                    this.interviewSettingsForm.get('provider').clearValidators();
                    this.interviewSettingsForm.get('provider').updateValueAndValidity();
                    this.interviewSettingsForm.get('location').setValidators([Validators.required]);
                    this.interviewSettingsForm.get('location').updateValueAndValidity();
                } else if (value === 'online') {
                    this.interviewSettingsForm.get('location').clearValidators();
                    this.interviewSettingsForm.get('location').updateValueAndValidity();
                    this.interviewSettingsForm.get('provider').setValidators([Validators.required]);
                    this.interviewSettingsForm.get('provider').updateValueAndValidity();
                }
            }
        });

        this.populateForms();
    }

    populateForms() {
        if (this.edit) {
            console.log('EDITING INTERVIEW', this.interview);
            this.interviewSettingsForm.patchValue({
                interview_date: this.interview.interview_date,
                start_time: this.interview.start_time,
                duration: this.interview.duration,
                type: this.interview.type,
                scorecard_id: this.interview.scorecard_id,
                location: this.interview.location,
                specify_date: 'team'
            });

            if (this.interview.criteria_votes || (this.interview.overall_votes && this.interview.overall_votes)) {
                this.editingDisabled = true;
            }

            if (this.interview.type === 'online') {
                this.interviewSettingsForm.patchValue({
                    provider: this.interview.provider
                });
            }
        }
    }

    initForms() {
        this.interviewSettingsForm = this.fb.group({
            interview_date: ['', Validators.required],
            start_time: ['', Validators.required],
            duration: [45, Validators.required],
            type: ['online', Validators.required],
            provider: ['', Validators.required],
            location: [''],
            scorecard_id: ['', Validators.required],
            specify_date: ['team']
        });
        this.attendeeForm = this.fb.group({
            name: ['', Validators.required]
        });
    }

    disableWeekend(d: Date): boolean {
        const dayOfWeek = moment(d).day();
        return dayOfWeek !== 0 && dayOfWeek !== 6;
    }

    hoverCells() {
        this.interviewSettingsForm.value.interview_date = moment(
            this.interviewSettingsForm.value.interview_date
        ).format('YYYY-MM-DD');
        const day = moment(this.interviewSettingsForm.value.interview_date, 'YYYY-MM-DD');
        const time = this.interviewSettingsForm.value.start_time;
        const div = document.querySelector('.schedule-col');
        let daysIndex = this.days.findIndex((d) => {
            if (day.isSame(moment(d.value, 'YYYY-MM-DD'), 'day')) {
                return true;
            }
        });
        this.days.forEach((d) => {
            d.time.forEach((t) => delete t.hover);
        });
        if (daysIndex > -1) {
            let timeIndex = this.days[daysIndex].time.findIndex((d) => {
                const hour = Number(moment(time, 'HH:mm').format('H'));
                if (hour === moment(d.value, 'HH:mm').hour()) {
                    return true;
                }
            });
            if (timeIndex > -1) {
                this.days[daysIndex].time[timeIndex].hover = true;
                daysIndex > 0 ? (div.scrollLeft = daysIndex * 9 * 40) : (div.scrollLeft = 0);
            }
        }
    }

    getLeftPosition() {
        const startTime = this.interviewSettingsForm.get('start_time').value;
        const minutes = Number(moment(startTime, 'HH:mm').format('m'));
        const fullWidth = 40;
        const part = fullWidth / 12;
        const startMargin = 6;
        let val = 0;
        switch (minutes) {
            case 0:
                val = 0;
                break;
            case 5:
                val = 1;
                break;
            case 10:
                val = 2;
                break;
            case 15:
                val = 3;
                break;
            case 20:
                val = 4;
                break;
            case 25:
                val = 5;
                break;
            case 30:
                val = 6;
                break;
            case 35:
                val = 7;
                break;
            case 40:
                val = 8;
                break;
            case 45:
                val = 9;
                break;
            case 50:
                val = 10;
                break;
            case 55:
                val = 11;
                break;
            case 60:
                val = 12;
                break;
        }
        return `${startMargin + part * val}px`;
    }

    loadAttendees() {
        const jobId = this.job.id;
        // console.log('jobid', this.job.id, this.candidate);
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            const job: SdkJob = this.job;
            const jobOwner = users.find((u) => u.id === job.owner);
            this.jobOwner = jobOwner;
            this.attendees.push({
                name: this.candidate.full_name || this.candidate.first_name,
                email: this.candidate.email,
                type: 'required',
                text: 'Candidate',
                checked: true
            });
            if (jobOwner) {
                this.attendees.push({
                    name: this.organizerName || jobOwner.full_name,
                    email: this.organizerEmail || jobOwner.email,
                    type: 'required',
                    text: 'me',
                    checked: true
                });
            }

            if (job.recruiters && job.recruiters.length) {
                job.recruiters.forEach((r) => {
                    const recruiters = users.find((u) => u.id === r);
                    if (recruiters) {
                        this.attendees.push({
                            name: recruiters.full_name,
                            email: recruiters.email,
                            type: 'required',
                            checked: true
                        });
                    }
                });
            }

            if (job.hiring_managers && job.hiring_managers.length) {
                job.hiring_managers.forEach((r) => {
                    const hiring_manager = users.find((u) => u.id === r);
                    if (hiring_manager) {
                        this.attendees.push({
                            name: hiring_manager.full_name,
                            email: hiring_manager.email,
                            type: 'required',
                            checked: true
                        });
                    }
                });
            }

            if (job.hr_business_partners && job.hr_business_partners.length) {
                job.hr_business_partners.forEach((r) => {
                    const hr_business_partner = users.find((u) => u.id === r);
                    if (hr_business_partner) {
                        this.attendees.push({
                            name: hr_business_partner.full_name,
                            email: hr_business_partner.email,
                            type: 'required',
                            checked: true
                        });
                    }
                });
            }

            if (job.hiring_panels && job.hiring_panels.length) {
                job.hiring_panels.forEach((r) => {
                    const hiring_panel = users.find((u) => u.id === r);
                    if (hiring_panel) {
                        this.attendees.push({
                            name: hiring_panel.full_name,
                            email: hiring_panel.email,
                            type: 'required',
                            checked: true
                        });
                    }
                });
            }

            this.attendees.forEach((a) => {
                // console.log('Attendee', a);
                a.days = [];
                for (let i = 0; i < 14; i++) {
                    const dayOfWeek = moment()
                        .add(i, 'days')
                        .day();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        a.days.push({
                            label: moment()
                                .add(i, 'days')
                                .format('dddd, DD MMM YYYY'),
                            value: moment()
                                .add(i, 'days')
                                .format('YYYY-MM-DD HH:mm:ss'),
                            time: this.time.map((t) => {
                                return { ...t };
                            })
                        });
                    }
                }

                if (this.edit) {
                    const intAt = this.interview.attendees.find(
                        (att) => att.emailAddress && att.emailAddress.address === a.email
                    );
                    if (intAt) {
                        a.type = intAt.type;
                    }
                }
            });

            const emails = this.attendees.map((a) => a.email);
            const data = {
                schedules: emails,
                startTime: {
                    dateTime: moment()
                        .startOf('day')
                        .hour(6)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    timeZone: 'South Africa Standard Time'
                },
                endTime: {
                    dateTime: moment()
                        .add(14, 'days')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    timeZone: 'South Africa Standard Time'
                },
                availabilityViewInterval: 60
            };
            console.log('🙋🏻‍♀️ Attendees loaded:', this.attendees);
            console.log('📅 getSchedule data:', data);
            this.msalService.getSchedule(data).subscribe((r) => {
                console.log('📆 getSchedule response: ', r);
                this.schedule = r;
                this.setTimeLineForAttendees();
            });
        });
    }

    get f() {
        return this.interviewSettingsForm.controls;
    }

    submitForm() {
        this.interviewSettingsForm.markAllAsTouched();
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }

    dpvalueChanged(e) {
        // this.interviewSettingsForm.value.interview_date = moment(
        //     this.interviewSettingsForm.value.interview_date
        // ).format('YYYY-MM-DD');
    }

    async onSaveInterview() {
        // console.log(this.interviewSettingsForm);
        const formValue = this.interviewSettingsForm.getRawValue();
        console.log('👨🏻‍💻 onSaveInterview', formValue);
        if (!this.interviewSettingsForm.valid) {
            this.formHelper.markFormGroupTouched(this.interviewSettingsForm);
            return false;
        }
        this.contentLoading = true;
        const type = formValue.type;

        //Prepare dates
        formValue.interview_date = moment(formValue.interview_date).format('YYYY-MM-DD');
        const hour = moment(formValue.start_time, 'HH:mm').format('H');
        const minutes = moment(formValue.start_time, 'HH:mm').format('m');
        const duration = formValue.duration;
        const startDate = moment(formValue.interview_date, 'YYYY-MM-DD')
            .add(hour, 'hour')
            .add(minutes, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss')
            .add(duration, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
        this.contentLoading = true;
        const subject = `Interview for ${this.job.title} at ${this.job.company}`;
        let location = '';
        if (type === 'online') {
            location = formValue.provider + ' Meeting';
        } else if (type === 'person') {
            location = formValue.location;
        }
        let interview_time = moment(formValue.interview_date, 'YYYY-MM-DD')
            .add(hour, 'hour')
            .add(minutes, 'minutes')
            .format('HH:mm');
        interview_time += ' to ';
        interview_time += moment(startDate, 'YYYY-MM-DD HH:mm:ss')
            .add(duration, 'minutes')
            .format('HH:mm');

        const event = {
            subject,
            body: {
                contentType: 'HTML',
                content: this.invitationBody(formValue.interview_date, interview_time, location)
            },
            start: {
                dateTime: startDate,
                timeZone: 'South Africa Standard Time'
            },
            end: {
                dateTime: endDate,
                timeZone: 'South Africa Standard Time'
            },
            location: {
                displayName: location
            },
            attendees: [],
            allowNewTimeProposals: false,
            isOnlineMeeting: type === 'online' ? true : false,
            onlineMeetingProvider: type === 'online' ? formValue.provider : null
        };
        formValue.attendees = [];
        this.attendees.forEach((a) => {
            const eventAttendee = {
                emailAddress: {
                    address: a.email,
                    name: a.name
                },
                type: a.type
            };
            if (eventAttendee.type === 'required' || eventAttendee.type === 'optional') {
                event.attendees.push(eventAttendee);
                if (a.email !== this.candidate.email) {
                    if (!formValue.interview_panel) {
                        formValue.interview_panel = `${a.name}`;
                    } else {
                        formValue.interview_panel += `, ${a.name}`;
                    }
                }
            }
            formValue.attendees.push(eventAttendee);
        });
        if (this.edit) {
            console.log('🗓 updateEvent:', event);
            try {
                const updatedEvent: any = await this.msalService.updateEvent(this.interview.event_id, event);
                console.log('MSAL Updated EvENT:', updatedEvent);

                const data = {
                    ...formValue,
                    event_id: updatedEvent.id,
                    job_owner_id: this.job.owner,
                    created_at: new Date().getTime(),
                    join_url: updatedEvent?.onlineMeeting?.joinUrl || null,
                    interview_time
                };

                this.interviewsService
                    .updateCandidateInterview(this.job.id, this.candidate.id, this.interview.id, data)
                    .subscribe((dbEvents: any) => {
                        data.id = this.interview.id;
                        console.log('🗓 updateCandidateInterview response:', dbEvents);
                        const item = dbEvents.find((ev) => ev.id === this.interview.id);
                        this.addedSchedule.emit(item ? item : data);
                        this.interviewSettingsForm.reset();
                        this.contentLoading = false;
                        this.close.emit();
                    });
            } catch (errorResponse) {
                console.error(errorResponse);
                this.contentLoading = false;
                this.close.emit();
                alert('Error! Please contact support!');
            }
        } else {
            console.log('🗓 createEvent:', event);
            this.msalService.createEvent(event).subscribe(
                (createdEvent: any) => {
                    console.log('MSAL CREATED EvENT:', createdEvent);

                    const data = {
                        ...formValue,
                        event_id: createdEvent.id,
                        job_owner_id: this.job.owner,
                        created_at: new Date().getTime(),
                        join_url: createdEvent?.onlineMeeting?.joinUrl || null,
                        interview_time
                    };
                    if (type === 'person') {
                        data.location_short = this.tenantLocationOptions.find(
                            (l) => l.value === formValue.location
                        ).short;
                    }

                    this.interviewsService
                        .createCandidateInterview(this.job.id, this.candidate.id, data)
                        .subscribe((dbEvent: any) => {
                            console.log('🗓 createCandidateInterview response:', dbEvent);
                            this.addedSchedule.emit(data);
                            this.interviewSettingsForm.reset();
                            this.contentLoading = false;
                            this.close.emit();
                        });
                },
                (errorResponse) => {
                    console.error(errorResponse);
                    this.contentLoading = false;
                    this.close.emit();
                    alert('Error! Please contact support!');
                }
            );
        }
    }

    createListOfDays() {
        for (let i = 0; i < 14; i++) {
            const dayOfWeek = moment()
                .add(i, 'days')
                .day();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                this.days.push({
                    label: moment()
                        .add(i, 'days')
                        .format('dddd, DD MMM YYYY'),
                    value: moment()
                        .add(i, 'days')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    time: this.time.map((t) => {
                        return { ...t };
                    })
                });
            }
        }
    }

    setTimeLineForAttendees() {
        console.log('🕤 setTimeLineForAttendees', 'Schedule:', this.schedule);
        this.schedule.value.forEach((s) => {
            const employee = this.attendees.find((e) => e.email === s.scheduleId);
            if (s && s.scheduleItems && employee) {
                s.scheduleItems.forEach((i) => {
                    const startTime = i.start.dateTime;
                    const endTime = i.end.dateTime;
                    const hours = moment
                        .duration(moment(endTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(startTime, 'YYYY-MM-DD HH:mm:ss')))
                        .asHours();
                    const busyDate = [];
                    for (let i = 0; i < hours; i++) {
                        busyDate.push({
                            date: moment(startTime)
                                .add(i, 'hours')
                                .format('YYYY-MM-DD HH:mm:ss'),
                            duration: hours - i < 1 ? hours - i : 1
                        });
                    }
                    busyDate.forEach((d) => {
                        const day = moment(d.date, 'YYYY-MM-DD HH:mm:ss');
                        let daysIndex = employee.days.findIndex((d) => {
                            if (day.isSame(moment(d.value, 'YYYY-MM-DD HH:mm:ss'), 'day')) {
                                return true;
                            }
                        });
                        if (daysIndex > -1) {
                            let timeIndex = employee.days[daysIndex].time.findIndex((d) => {
                                if (day.hour() === moment(d.value, 'HH:mm').hour()) {
                                    return true;
                                }
                            });
                            if (timeIndex > -1) {
                                // console.log('Bingo', daysIndex, timeIndex);
                                const square = employee.days[daysIndex].time[timeIndex];
                                if (!square.timelines) {
                                    square.timelines = [];
                                }
                                square.timelines.push({
                                    status: 'busy',
                                    duration: d.duration,
                                    start_time: Number(moment(d.date, 'YYYY-MM-DD HH:mm:ss').format('m'))
                                });
                            }
                        }
                    });
                });
            } else if (s.error && s.error.message) {
                employee.unable = true;
            }
        });
        // console.log(this.attendees);
    }

    onAddAttendee() {
        // this.attendees.push()
        const name = this.attendeeForm.get('name').value;
        if (!this.attendeeForm.valid) {
            return false;
        }
        const days: any = [];
        for (let i = 0; i < 14; i++) {
            const dayOfWeek = moment()
                .add(i, 'days')
                .day();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                days.push({
                    label: moment()
                        .add(i, 'days')
                        .format('dddd, DD MMM YYYY'),
                    value: moment()
                        .add(i, 'days')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    time: this.time.map((t) => {
                        return { ...t };
                    })
                });
            }
        }
        this.attendees.push({ name, email: name, days, type: 'optional', checked: false, unable: true });
        this.attendeeForm.reset();
    }

    onChangeType(event, attendee) {
        if (attendee.email === this.candidate.email || attendee.email === this.organizerEmail) {
            return false;
        }
        switch (attendee.type) {
            case 'required':
                attendee.type = 'optional';
                break;
            case 'optional':
                attendee.type = 'not-required';
                break;
            case 'not-required':
                attendee.type = 'required';
                break;
        }
        // console.log(`👨🏼‍💼 Attendee type (${attendee.email}):`, attendee.type);
    }

    onFindMeetingTime() {
        if (!this.meetingTimeSuggestions.length) {
            this.findMeetingTime();
        } else if (this.meetingTimeSuggestions.length) {
            this.setMeetingTime();
        }
    }

    findMeetingTime() {
        const formDuration = this.interviewSettingsForm.get('duration').value;
        const meetingDuration = ((duration) => {
            switch (duration) {
                case 15:
                    return 'PT15M';
                case 30:
                    return 'PT30M';
                case 45:
                    return 'PT45M';
                case 60:
                    return 'PT1H';
                default:
                    return 'PT1H';
            }
        })(formDuration);

        const data = {
            attendees: [
                // {
                //     type: 'required',
                //     emailAddress: {
                //         name: 'Nick Romanenko',
                //         address: 'nick@clevateamio.onmicrosoft.com'
                //     }
                // },
                // {
                //     type: 'required',
                //     emailAddress: {
                //         name: 'Greg Kockott',
                //         address: 'greg@clevateamio.onmicrosoft.com'
                //     }
                // }
            ],
            timeConstraint: {
                activityDomain: 'work',
                timeSlots: [
                    {
                        start: {
                            dateTime: moment()
                                .add(1, 'hours')
                                .format('YYYY-MM-DD HH:mm:ss'),
                            timeZone: 'South Africa Standard Time'
                        },
                        end: {
                            dateTime: moment()
                                .add(13, 'days')
                                .format('YYYY-MM-DD HH:mm:ss'),
                            timeZone: 'South Africa Standard Time'
                        }
                    }
                ]
            },
            isOrganizerOptional: 'false',
            meetingDuration,
            returnSuggestionReasons: 'true',
            minimumAttendeePercentage: '100'
        };
        this.attendees
            .filter((a) => !a.unable)
            .forEach((a) => {
                data.attendees.push({
                    type: a.type,
                    emailAddress: {
                        name: a.name,
                        address: a.email
                    }
                });
            });

        // console.log('🕵🏻‍♂️ findMeetingTime', data);
        this.msalService.findMeetingTimes(data).subscribe((r: any) => {
            // console.log('𝌮 findMeetingTime response', r);
            this.startDateTypeOptions = [];
            if (r && r.meetingTimeSuggestions && r.meetingTimeSuggestions.length) {
                // 2020-05-28 // 06:00
                r.meetingTimeSuggestions = r.meetingTimeSuggestions
                    .filter((m) => {
                        let startTime = Number(
                            moment(m.meetingTimeSlot.start.dateTime, 'YYYY-MM-DD HH:mm:sss').format('H')
                        );
                        let endTime = Number(
                            moment(m.meetingTimeSlot.end.dateTime, 'YYYY-MM-DD HH:mm:sss').format('H')
                        );
                        if (startTime >= 8 && endTime <= 16) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .sort((a, b) => {
                        let after = moment(a.meetingTimeSlot.start.dateTime, 'YYYY-MM-DD HH:mm:sss').isAfter(
                            moment(b.meetingTimeSlot.end.dateTime, 'YYYY-MM-DD HH:mm:sss')
                        );
                        return after ? 1 : -1;
                    });

                let date = r.meetingTimeSuggestions[0].meetingTimeSlot.start.dateTime;
                this.interviewSettingsForm
                    .get('interview_date')
                    .patchValue(moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
                this.interviewSettingsForm
                    .get('start_time')
                    .patchValue(moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));

                // console.log('FORM VALUE AFTER PATCH');
                console.log('filtered', r.meetingTimeSuggestions);
                this.meetingTimeSuggestions = r.meetingTimeSuggestions;
                this.meetingTimeSuggestions.length - 1 === this.activeSuggestions
                    ? (this.activeSuggestions = 0)
                    : (this.activeSuggestions = 1);
            }
        });
    }

    setMeetingTime() {
        let date = this.meetingTimeSuggestions[this.activeSuggestions].meetingTimeSlot.start.dateTime;
        this.interviewSettingsForm
            .get('interview_date')
            .patchValue(moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
        this.interviewSettingsForm.get('start_time').patchValue(moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
        this.activeSuggestions += 1;
        if (this.meetingTimeSuggestions.length - 1 < this.activeSuggestions) {
            this.activeSuggestions = 0;
        }
    }

    isWorkingDay(day) {
        const dayOfWeek = moment(day, 'YYYY-MM-DD HH:mm:ss').day();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return false;
        } else {
            return true;
        }
    }

    populateStartDateTypeOptions() {
        for (let i = 0; i < 14; i++) {
            const dayOfWeek = moment()
                .add(i, 'days')
                .day();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                this.startDateTypeOptions.push({
                    value: moment()
                        .add(i, 'days')
                        .format('YYYY-MM-DD'),
                    label: moment()
                        .add(i, 'days')
                        .format('dddd, DD MMM YYYY')
                });
            }
        }
    }

    populateProviderTypeOptions(providers) {
        // Populate providerTypeOptions
        if (providers && providers.length) {
            providers.forEach((p) => {
                if (p === 'teamsForBusiness') {
                    const existing = this.providerTypeOptions.find((option) => option.value === p);
                    if (!existing) {
                        this.providerTypeOptions.push({ value: 'teamsForBusiness', label: 'Microsoft Teams' });
                    }
                }
                if (p === 'skypeForBusiness') {
                    const existing = this.providerTypeOptions.find((option) => option.value === p);
                    if (!existing) {
                        this.providerTypeOptions.push({ value: 'skypeForBusiness', label: 'Skype for business' });
                    }
                }
            });
        }
    }

    invitationBody(startDate = '', startTime, location) {
        const candidateName = `${this.candidate.first_name} ${this.candidate.last_name}`;
        const tenantName = this.job.company;
        const jobTitle = this.job.title;

        const panelMembers = this.attendees
            .slice()
            .filter((a) => a.email !== this.candidate.email)
            .map((a) => a.name);

        const panel = panelMembers.join(', ');
        const jobOwnerName = this.jobOwner ? this.jobOwner.full_name : '';
        const jobOwnerEmail = this.jobOwner ? this.jobOwner.email : '';

        const text = `<p style="margin:0;">Dear ${candidateName}</p><br>
        <p style="margin:0;">We're excited to let you know that you have being selected to interview for the ${jobTitle} role at ${tenantName}.</p><br>
        <p style="margin:0;">You will receive a calendar invitation with interview details in a seperate email. In the interim, please take note of the important information related to your interview below:</p><br>
        <p style="margin:0;">Date: ${moment(startDate, 'YYYY-MM-DD').format('DD MMMM YYYY')}</p>
        <p style="margin:0;">Time: ${startTime}</p>
        <p style="margin:0;">Interview panel: ${panel}</p>
        <p style="margin:0;">Location: ${location}</p><br>
        <p style="margin:0;">All the best with your interview!</p><br>
        <p style="margin:0;">Kind regards</p>
        <p style="margin:0;">${jobOwnerName}</p>
        <p style="margin:0;">${jobOwnerEmail}</p>`;
        return text;
    }

    onChangeTypeSchedule(type) {
        this.interviewSettingsForm.get('specify_date').patchValue(type);
        if (type === 'candidate') {
            this.interviewSettingsForm.addControl(
                'availability',
                this.fb.group({
                    days: ['', Validators.required],
                    from: ['08:00', Validators.required],
                    to: ['16:00', Validators.required],
                    range: ['', Validators.required],
                    buffer: [0, Validators.required]
                })
            );
            this.interviewSettingsForm.get('availability').statusChanges.subscribe(async (result) => {
                if (result === 'VALID') {
                    let slots = await this.findNumberFreeSlots();
                    console.log('FreeSlots', slots);
                    this.limitedSlotsModal = {
                        show: true,
                        slots,
                        warning: false
                    };
                }
            });
        } else {
            this.interviewSettingsForm.removeControl('availability');
        }
    }

    async onSendRequest() {
        console.log('onSendRequest', this.interviewSettingsForm, this.interviewSettingsForm.value, this.attendees);
        if (!this.interviewSettingsForm.get('availability').valid) {
            this.formHelper.markFormGroupTouched(this.interviewSettingsForm.get('availability') as FormGroup);
            return false;
        }
        if (!this.interviewSettingsForm.get('scorecard_id').valid) {
            this.interviewSettingsForm.get('scorecard_id').markAsTouched();
            return false;
        }
        this.contentLoading = true;
        const data = {
            user_id: this.user.id,
            availability: this.interviewSettingsForm.value.availability,
            scorecard_id: this.interviewSettingsForm.value.scorecard_id,
            duration: this.interviewSettingsForm.value.duration,
            attendees: []
        };
        this.attendees.forEach((a) => {
            data.attendees.push({
                type: a.type,
                emailAddress: {
                    name: a.name,
                    address: a.email
                },
                unable: a.unable || false
            });
        });
        this.contentLoading = true;
        console.log('DATA', data);
        this.interviewsService.scheduleCandidateInterview(this.job.id, this.candidate.id, data).subscribe(
            (response) => {
                console.log('Response', response);
                this.contentLoading = false;
                this.limitedSlotsModal = {
                    show: false,
                    slots: 0,
                    warning: false
                };
                this.close.emit();
            },
            (err) => {
                console.log(err);
                this.toastr.error('Error');
                this.contentLoading = false;
            }
        );
    }

    async findNumberFreeSlots() {
        const formDuration = this.interviewSettingsForm.get('duration').value;
        const buffer = this.interviewSettingsForm.get('availability').get('buffer').value;
        const endDate = this.interviewSettingsForm.get('availability').get('range').value;
        const availableDays = this.interviewSettingsForm.get('availability').get('days').value;
        const from = this.interviewSettingsForm.get('availability').get('from').value;
        const to = this.interviewSettingsForm.get('availability').get('to').value;
        const startHour = moment(from, 'HH:mm').format('H');
        const startMinutes = moment(from, 'HH:mm').format('m');
        const endHour = moment(to, 'HH:mm').format('H');
        const endMinutes = moment(to, 'HH:mm').format('m');
        const meetingDuration = `PT${formDuration + (buffer || 0)}M`;
        const timeSlots = [];
        for (let i = 1; i < endDate + 1; i++) {
            const dayOfWeek = moment()
                .add(i, 'days')
                .day();
            if (availableDays.indexOf(dayOfWeek) > -1) {
                timeSlots.push({
                    start: {
                        dateTime: moment()
                            .add(i, 'days')
                            .startOf('day')
                            .hour(parseInt(startHour))
                            .minutes(parseInt(startMinutes))
                            .format('YYYY-MM-DD HH:mm:ss'),
                        timeZone: 'South Africa Standard Time'
                    },
                    end: {
                        dateTime: moment()
                            .add(i, 'days')
                            .startOf('day')
                            .hour(parseInt(endHour))
                            .minutes(parseInt(endMinutes))
                            .format('YYYY-MM-DD HH:mm:ss'),
                        timeZone: 'South Africa Standard Time'
                    }
                });
            }
        }
        // console.log('timeSlots', timeSlots);
        if (timeSlots.length === 0) {
            return 0;
        }
        const data = {
            attendees: [],
            timeConstraint: {
                activityDomain: 'work',
                timeSlots
            },
            isOrganizerOptional: false,
            meetingDuration,
            returnSuggestionReasons: true,
            minimumAttendeePercentage: '100',
            maxCandidates: 10
        };
        this.attendees
            .filter((a) => !a.unable)
            .forEach((a) => {
                data.attendees.push({
                    type: a.type,
                    emailAddress: {
                        name: a.name,
                        address: a.email
                    }
                });
            });
        console.log('🕵🏻‍♂️ findMeetingTime', data);
        this.contentLoading = true;
        const response: any = await this.msalService.findMeetingTimes(data).toPromise();
        console.log('𝌮 findMeetingTime response', response);
        this.contentLoading = false;
        return response.meetingTimeSuggestions.length || 0;
    }
}
