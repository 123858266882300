<div class="interview-settings">
    <button
        *ngIf="interviewSettingsForm.get('specify_date').value === 'team'"
        type="button"
        class="btn-primary btn mla schedule-btn"
        (click)="onSaveInterview()"
    >
        Schedule Interview
    </button>
    <button
        *ngIf="interviewSettingsForm.get('specify_date').value === 'candidate'"
        type="button"
        class="btn-primary btn mla schedule-btn"
        (click)="onSendRequest()"
        [disabled]="limitedSlotsModal.show && limitedSlotsModal.slots < 2"
    >
        Send Request
    </button>
    <form [formGroup]="interviewSettingsForm" (ngSubmit)="submitForm()">
        <div
            class="form-row mb40"
            *ngIf="limitedSlotsModal.show && limitedSlotsModal.slots > 1 && limitedSlotsModal.slots < 6"
        >
            <div class="slots-available-block limited">
                <svg-icon
                    src="/assets/images/icons/icons-8-error.svg"
                    alt="Error"
                    [svgStyle]="{ fill: '#ffc107' }"
                ></svg-icon>
                <p>
                    <strong>Limited time slots available</strong> - At this stage the candidate has 5 or less time slots
                    available to them. Consider updating your parameters
                </p>
            </div>
        </div>
        <div class="form-row mb40" *ngIf="limitedSlotsModal.show && limitedSlotsModal.slots < 2">
            <div class="slots-available-block no-time">
                <svg-icon
                    src="/assets/images/icons/icons-8-error.svg"
                    alt="Error"
                    [svgStyle]="{ fill: '#ff3b30' }"
                ></svg-icon>
                <p>
                    <strong>No time slots available</strong> - At this stage the candidate has less than 1 time slot
                    available to them. This request can not be sent. Please update your parameters
                </p>
            </div>
        </div>
        <div class="form-row mb10">
            <p>
                Would you like to specify a date and time for the interview or allow the candidate to schedule their own
                date and time based on team availability?
            </p>
        </div>
        <div class="form-row mb40">
            <div
                class="interview-type-schedule"
                [class.active]="interviewSettingsForm.get('specify_date').value === 'team'"
                (click)="onChangeTypeSchedule('team')"
            >
                Specify Date and Time
            </div>
            <div
                *ngIf="!edit"
                class="interview-type-schedule"
                [class.active]="interviewSettingsForm.get('specify_date').value === 'candidate'"
                (click)="onChangeTypeSchedule('candidate')"
            >
                Allow Candidate to Schedule
            </div>
        </div>
        <ng-container *ngIf="interviewSettingsForm.get('specify_date').value === 'team'">
            <div class="form-row aic mb25">
                <h4>Interview Details</h4>
                <button type="button" class="btn btn-add" (click)="onFindMeetingTime()">Find meeting time</button>
            </div>
            <div class="form-row">
                <div class="datepicker-style form-control half">
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Select an Interview Date</mat-label>
                        <mat-select formControlName="interview_date">
                            <mat-option *ngFor="let data of startDateTypeOptions" [value]="data.value">
                                {{ data.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.interview_date.errors?.required">Field required!</mat-error>
                    </mat-form-field> -->
                    <mat-form-field class="example-full-width" (click)="picker.open()">
                        <input
                            [matDatepickerFilter]="disableWeekend"
                            [min]="today"
                            formControlName="interview_date"
                            matInput
                            [matDatepicker]="picker"
                            placeholder="Interview Date"
                        />
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="form-row form-control half">
                    <div class="form-control quarter">
                        <input
                            class="form-input"
                            formControlName="start_time"
                            [ngxTimepicker]="timepicker"
                            [format]="24"
                            min="08:00"
                            max="16:00"
                        />
                        <label
                            [ngClass]="{ hide: interviewSettingsForm.get('start_time').value }"
                            class="timepicker-label"
                            for=""
                            >Start Time</label
                        >
                        <ngx-material-timepicker #timepicker [minutesGap]="5"></ngx-material-timepicker>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Start Time</mat-label>
                            <mat-select formControlName="start_time">
                                <mat-option *ngFor="let data of startTimeTypeOptions" [value]="data.value">
                                    {{ data.viewValue }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.start_time.errors?.required">Field required!</mat-error>
                        </mat-form-field> -->
                    </div>
                    <div class="form-control quarter">
                        <app-auto-dropdown
                            [options]="durationTypeOptions"
                            [newStyle]="true"
                            [control]="interviewSettingsForm.get('duration')"
                            placeholder="Duration"
                        ></app-auto-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row ">
                <div class="form-control half">
                    <app-auto-dropdown
                        [options]="interviewTypeOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('type')"
                        placeholder="Interview Type"
                    ></app-auto-dropdown>
                </div>

                <!-- Online providers -->
                <div class="form-control half" *ngIf="interviewSettingsForm.get('type').value === 'online'">
                    <app-auto-dropdown
                        *ngIf="providerTypeOptions && !contentLoading"
                        [options]="providerTypeOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('provider')"
                        placeholder="Online Meeting Provider"
                    ></app-auto-dropdown>
                </div>

                <!-- Locations -->
                <div class="form-control half" *ngIf="interviewSettingsForm.get('type').value === 'person'">
                    <app-auto-dropdown
                        *ngIf="tenantLocationOptions && !contentLoading"
                        [options]="tenantLocationOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('location')"
                        placeholder="Location"
                    ></app-auto-dropdown>
                    <!-- <app-ac-chips-material
                        *ngIf="tenantLocationOptions.length"
                        formControlName="location"
                        [suggestions]="tenantLocationOptions"
                        [setSuggestions]="setSuggestions"
                        title="Location"
                        key="location"
                        [ngClass]="{
                            invalid:
                                !interviewSettingsForm.controls.location.valid &&
                                interviewSettingsForm.controls.location.touched,
                            fullwidth: true
                        }"
                    ></app-ac-chips-material> -->
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="interviewSettingsForm.get('specify_date').value === 'candidate'"
            formGroupName="availability"
        >
            <div class="form-row aic mb20">
                <h4>Choose your Availability</h4>
            </div>
            <div class="form-row mb20">
                <p>
                    You can select which days the candidate can book. You can also select timeslots, required duration
                    and a buffer. The buffer allows you pad the meeting with free time at the beginning and end.
                </p>
            </div>
            <div class="form-row">
                <div class="form-control half">
                    <app-auto-dropdown
                        [options]="availableDaysTypeOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('availability').get('days')"
                        placeholder="Available Days"
                        [ngClass]="{
                            invalid:
                                !interviewSettingsForm.get('availability').get('days').valid &&
                                interviewSettingsForm.get('availability').get('days').touched
                        }"
                    ></app-auto-dropdown>
                </div>
                <div class="form-row form-control half">
                    <div class="form-control quarter">
                        <input
                            class="form-input"
                            formControlName="from"
                            [ngxTimepicker]="timepicker2"
                            [format]="24"
                            min="08:00"
                            max="16:00"
                        />
                        <label
                            [ngClass]="{ hide: interviewSettingsForm.get('availability').get('from').value }"
                            class="timepicker-label"
                            for=""
                            >From</label
                        >
                        <ngx-material-timepicker #timepicker2 [minutesGap]="5"></ngx-material-timepicker>
                    </div>

                    <div class="form-control quarter">
                        <input
                            class="form-input"
                            formControlName="to"
                            [ngxTimepicker]="timepicker3"
                            [format]="24"
                            min="08:00"
                            max="16:00"
                        />
                        <label
                            [ngClass]="{ hide: interviewSettingsForm.get('availability').get('to').value }"
                            class="timepicker-label"
                            for=""
                            >To</label
                        >
                        <ngx-material-timepicker #timepicker3 [minutesGap]="5"></ngx-material-timepicker>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-control half">
                    <app-auto-dropdown
                        [options]="availableRangeTypeOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('availability').get('range')"
                        placeholder="Availability Range"
                        [ngClass]="{
                            invalid:
                                !interviewSettingsForm.get('availability').get('range').valid &&
                                interviewSettingsForm.get('availability').get('range').touched
                        }"
                    ></app-auto-dropdown>
                </div>
                <div class="form-control half">
                    <app-auto-dropdown
                        [options]="availableBufferTypeOptions"
                        [newStyle]="true"
                        [control]="interviewSettingsForm.get('availability').get('buffer')"
                        placeholder="Buffer"
                        [ngClass]="{
                            invalid:
                                !interviewSettingsForm.get('availability').get('buffer').valid &&
                                interviewSettingsForm.get('availability').get('buffer').touched
                        }"
                    ></app-auto-dropdown>
                </div>
            </div>
        </ng-container>
        <div class="form-row">
            <div class="form-control">
                <app-auto-dropdown
                    *ngIf="scoreCardsTypeOptions && scoreCardsTypeOptions.length"
                    [options]="scoreCardsTypeOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('scorecard_id')"
                    [disabled]="editingDisabled"
                    placeholder="Scorecard"
                    [ngClass]="{
                        invalid:
                            !interviewSettingsForm.controls.scorecard_id.valid &&
                            interviewSettingsForm.controls.scorecard_id.touched
                    }"
                ></app-auto-dropdown>
            </div>
        </div>
    </form>
    <div
        class="scheduler-table"
        [ngClass]="{ 'allow-candidate': interviewSettingsForm.get('specify_date').value === 'candidate' }"
    >
        <div class="scheduler-table-header">
            <h4>
                {{
                    interviewSettingsForm.get('specify_date').value === 'team'
                        ? 'Availability'
                        : 'Hiring Team Availability'
                }}
            </h4>
            <div class="availability-types">
                <div class="availability-type">
                    <div class="interview-checkbox required">
                        <span class="pi pi-check"></span>
                    </div>
                    Required
                </div>
                <div class="availability-type">
                    <div class="interview-checkbox optional">
                        <span class="pi pi-check"></span>
                    </div>
                    Optional
                </div>
                <div class="availability-type">
                    <div class="interview-checkbox not-required">
                        <span class="pi pi-check"></span>
                    </div>
                    Not Required
                </div>
            </div>
        </div>
        <div class="attendees-col">
            <div class="attendee" *ngFor="let attendee of attendees">
                <div class="interview-checkbox" (click)="onChangeType($event, attendee)" [ngClass]="attendee.type">
                    <span class="pi pi-check"></span>
                </div>
                {{ attendee.name }}
                <ng-container *ngIf="attendee.text">
                    {{ '(' + attendee.text + ')' }}
                </ng-container>
            </div>
            <!-- <ng-container [formGroup]="attendeeForm">
                <input
                    type="text"
                    formControlName="name"
                    placeholder="Enter a name or email address"
                    class="form-input"
                />
            </ng-container> -->
        </div>
        <div class="schedule-col">
            <div class="schedule-header">
                <div class="day" *ngFor="let day of days">
                    {{ day.label }}
                    <div class="wrap">
                        <div class="time" *ngFor="let item of day.time">
                            {{ item.label }}
                            <div
                                [ngStyle]="{
                                    'height.px': attendees?.length * 40 - 6,
                                    width: (interviewSettingsForm.get('duration').value / 60) * 100 + '%',
                                    left: getLeftPosition()
                                }"
                                *ngIf="item.hover"
                                class="hover-line"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="schedule-holder" *ngFor="let attendee of attendees" [ngClass]="{ unable: attendee.unable }">
                <div class="day" *ngFor="let day of attendee.days">
                    <div class="time" *ngFor="let item of day.time">
                        <ng-container *ngFor="let timeline of item?.timelines">
                            <div
                                class="attendee-timeline"
                                [ngStyle]="{
                                    width: timeline?.duration * 100 + '%',
                                    left: (timeline?.start_time / 60) * 100 + '%'
                                }"
                                *ngIf="timeline?.status === 'busy'"
                            ></div>
                        </ng-container>
                        <div class="inactive"></div>
                        <!-- <div class="unchecked-label"></div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="schedule-holder unable">
                <div class="day" *ngFor="let day of days">
                    <div class="time" *ngFor="let item of day.time">
                        <div class="inactive"></div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="footer-scheduler">
        <!-- <button type="button" class="btn btn-add" (click)="onAddAttendee()">Add Attendee</button> -->
        <p class="mla">(GMT+02:00) Johannesburg, - SAST</p>
    </div>
</div>

<app-loader *ngIf="contentLoading"></app-loader>
