import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SdkJob } from '@app/core/models';
import { CandidateService, JobService, OffersService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
    selector: 'app-candidate-offer-tracking',
    templateUrl: './candidate-offer-tracking.component.html',
    styleUrls: ['./candidate-offer-tracking.component.scss']
})
export class CandidateOfferTrackingComponent implements OnInit, OnDestroy {
    @Input() job;
    @Input() candidate;
    @Input() user;
    contentLoading = false;
    offerTrackingModal = {
        show: false
    };
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflows: any[];
        delete_workflow_id?: null;
    } = {
        show: false,
        data: null,
        workflows: []
    };
    tracking: any;

    offerAssessment;
    declineByCandidate = false;
    someoneSigned = false;
    retractAvailable = true;
    reOffer = {
        available: false,
        workflow_id: null
    };
    candidateSigned = false;
    offerSigns: any;
    offerApproval: any;
    subscription: Subscription = new Subscription();
    notHaveTracking = false;
    constructor(
        private candidateService: CandidateService,
        private employeeService: EmployeesService,
        private offerService: OffersService,
        private jobService: JobService,
        private workflowService: WorkflowService,
        private toaster: ToastrService
    ) {}

    async ngOnInit() {
        this.subscription = combineLatest([
            this.offerService.getOfferTracking(this.candidate.id, this.job.id),
            this.offerService.getJobOfferSigns(this.candidate.id, this.job.id),
            this.offerService.getOfferApprovalDetails2(this.candidate.id, this.job.id)
        ]).subscribe((response) => {
            console.log('getOfferTracking and getJobOfferSigns', response);
            if (!response[0]) {
                this.notHaveTracking = true;
                return;
            }
            this.tracking = response[0];
            this.offerApproval = response[2];
            this.tracking.signers.forEach((t, index) => {
                t.expanded = false;
                t.resend = false;
                if (t.label === 'Approver') {
                    if (this.offerApproval && this.offerApproval.approve_at) {
                        t.approved_at = this.offerApproval.approve_at;
                        t.signed = true;
                    }
                }
                this.offerSigns = response[1];
                const item = this.offerSigns.find((o) => o.id === t.id);
                if (item && t.label !== 'Approver') {
                    t.signed = item.signed;
                    t.approved_at = item.signed_at;
                }
            });
            const currentSigner = this.tracking.signers.find((s: any) => !s.approved_at);
            if (currentSigner) {
                currentSigner.resend = true;
                currentSigner.expanded = true;
            }

            if (this.job['offer_signs']) {
                const currentSigner = this.offerSigns.find((o) => !o.signed && o.label !== 'Approver');
                this.someoneSigned =
                    this.offerSigns.some((o) => o.signed) || (currentSigner && currentSigner.label === 'Candidate');
                if (currentSigner && currentSigner.label === 'Candidate') {
                    this.retractAvailable = false;
                }
                if (
                    this.offerSigns.length > 1 &&
                    currentSigner &&
                    currentSigner.label === 'Candidate' &&
                    this.candidate.workflows &&
                    this.candidate.workflows.indexOf(currentSigner.workflow_id) > -1
                ) {
                    this.reOffer = {
                        available: true,
                        workflow_id: currentSigner.workflow_id
                    };
                }
            }
        });
        this.offerAssessment = this.candidate.assignments.find((a) => {
            return a.stageId === 'hired';
        });
        console.log('offerAssessment', this.offerAssessment, this.candidate);
    }

    onOverviewProgress() {
        if (this.notHaveTracking) {
            return;
        }
        this.offerTrackingModal = {
            show: true
        };
    }

    onHideOfferTrackingModal() {
        this.offerTrackingModal = {
            show: false
        };
    }

    onResendNotification(item) {
        // console.log('resend notification', item);
        if (item.label === 'Approver') {
            this.contentLoading = true;
            this.candidateService
                .resendOfferApprovalNotification(this.job.id, this.candidate.id, item.id)
                .subscribe((response) => {
                    console.log('Success', response);
                    this.toaster.success('Email Sent');
                    this.contentLoading = false;
                });
        } else {
            this.contentLoading = true;
            this.candidateService.resendOfferNotification(this.job.id, this.candidate.id).subscribe((response) => {
                console.log('Success', response);
                this.contentLoading = false;
            });
        }
    }

    async onRetractOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        const currentSigner = this.job['offer_signs'][this.candidate.id].find((o) => !o.signed);
        if (
            (currentSigner && currentSigner.label === 'Candidate') ||
            (job &&
                job.offer_signs &&
                job.offer_signs[this.candidate.id] &&
                job.offer_signs[this.candidate.id].every((o) => o.signed))
        ) {
            this.toaster.error('Could you please refresh page.');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
        this.onHideOfferTrackingModal();
    }

    async onEditOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        if (
            job &&
            job.offer_signs &&
            job.offer_signs[this.candidate.id] &&
            job.offer_signs[this.candidate.id].some((o) => o.signed)
        ) {
            this.toaster.error('Contract has just signed');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
        this.onHideOfferTrackingModal();
    }

    async onReOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        if (
            job &&
            job.offer_signs &&
            job.offer_signs[this.candidate.id] &&
            job.offer_signs[this.candidate.id].every((o) => o.signed)
        ) {
            this.toaster.error('Contract has just signed');
            return;
        }
        const workflows = await this.workflowService.getManualWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows,
            delete_workflow_id: this.reOffer.workflow_id
        };
        this.reOffer = {
            available: false,
            workflow_id: null
        };
        this.onHideOfferTrackingModal();
    }

    async onSentOffer(event) {
        console.log('onSentOffer', event, this.acceptedOfferModal);
        this.acceptedOfferModal.show = false;
        if (this.offerAssessment) {
            delete this.offerAssessment.retracted;
            delete this.offerAssessment.offer_accepted;
        }
        const data = {
            stageClass: 'offer-approval',
            complianceRateClass: 'red',
            order: 3
        };
        await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
        this.candidateService.updateCandidate(this.candidate.id, data);
        if (this.acceptedOfferModal.delete_workflow_id) {
            this.employeeService
                .deleteWorkflow(this.candidate.id, this.acceptedOfferModal.delete_workflow_id)
                .subscribe(() => {});
            this.acceptedOfferModal.delete_workflow_id = null;
        }
    }

    refreshOfferAssessment() {
        return new Promise(async (resolve, reject) => {
            const assessment = await this.offerService.getOfferAssessment(this.candidate.id, this.offerAssessment.id);
            const equal = isEqual(assessment, this.offerAssessment);
            resolve(equal);
        });
    }

    async onCandidateDeclineOffer() {
        const equal = await this.refreshOfferAssessment();
        if (!equal) {
            this.toaster.error('Could you please refresh page');
        } else {
            // const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
            // if (
            //     job &&
            //     job.offer_signs &&
            //     job.offer_signs[this.candidate.id] &&
            //     job.offer_signs[this.candidate.id].some((o) => o.signed)
            // ) {
            //     this.toaster.error('Contract has just signed');
            //     return;
            // }
            this.contentLoading = true;
            await this.offerService.declineOffer(this.candidate.id, this.offerAssessment.id);
            this.contentLoading = false;
            this.offerAssessment.offer_accepted = false;
            this.offerAssessment.completed = true;
            const data = {
                stageClass: 'offer_declined'
            };
            await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
            this.candidateService.updateCandidate(this.candidate.id, data);

            const auditRecord = {
                type: 'delete',
                deleted_by: 'user',
                user_id: this.user.id || '',
                created_at: Math.floor(Date.now() / 1000),
                reason: 'Candidate decline offer',
                decline_email_sent: false
            };
            this.candidateService
                .addToAudit(this.job.id, this.candidate.id, auditRecord)
                .catch((error) => console.error(error));
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
