import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-employee-kpi',
    templateUrl: './employee-kpi.component.html',
    styleUrls: ['./employee-kpi.component.scss']
})
export class EmployeeKpiComponent implements OnInit {
    @Input() employee;
    constructor() {}

    ngOnInit(): void {
        console.log('employee', this.employee);
        this.employee.kpi.forEach((k) => {
            if (k.manager_rating) {
                k.manager_ratings = [];
                for (let i = 0; i < k.manager_rating; i++) {
                    k.manager_ratings.push(i);
                }
            }
            if (k.employee_rating) {
                k.employee_ratings = [];
                for (let i = 0; i < k.employee_rating; i++) {
                    k.employee_ratings.push(i);
                }
            }
        });
    }
}
