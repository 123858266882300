import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {
    transform(value: any): any {
        let initials;
        if (value.first_name) {
            initials = value.first_name.charAt(0).toUpperCase();
            if (value.last_name) {
                initials += value.last_name.charAt(0).toUpperCase();
            }
        } else if (value.email) {
            initials = value.email.charAt(0).toUpperCase();
        } else if (value.full_name) {
            const first = value.full_name
                .split(' ')
                .slice(0, -1)
                .join(' ');
            const last = value.full_name
                .split(' ')
                .slice(-1)
                .join(' ');
            initials = first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
        }
        return initials;
    }
}
