export const environment = {
    production: false,
    env: 'development',
    googleClientId: '121873510988-mfehkrbf6quh3p72609lrf7b4flspvo3.apps.googleusercontent.com',
    apiUrl: 'https://us-central1-dev-hire-by-hellocrowd.cloudfunctions.net/api',
    geoipKey: '6a3ec34047804b3bb5c3dd43a89b8595',
    intercomAppId: 'ygeod2m5',
    jobsPortalUrl: 'https://dev.dimensiondatajobs.com',
    genericPortalUrl: 'https://careers.dev.andteam.com',
    msalClientId: '5d107785-cc71-436e-8172-9969d2f4a717',
    msalAuthority: 'https://login.microsoftonline.com/common',
    msalRedirectUrl: 'https://app.dev.andteam.com/',
    hellosignClientId: '705887cdb5a0ae08d8dbb94baa3822ce',
    recaptchaSiteKey: '6Leu39IZAAAAAIvFlrpbLzhFZxlZcpjYIe2mea45',
    recaptchaSiteSecret: '6Leu39IZAAAAAGht9m7h6mXftffcRpeHUsg1In6A',
    firebase: {
        apiKey: 'AIzaSyA4paFOh_4ha2U5F6foGIPT_FTIJbuYMXg',
        authDomain: 'dev-hire-by-hellocrowd.firebaseapp.com',
        databaseURL: 'https://dev-hire-by-hellocrowd.firebaseio.com',
        projectId: 'dev-hire-by-hellocrowd',
        storageBucket: 'dev-hire-by-hellocrowd.appspot.com',
        messagingSenderId: '121873510988',
        appId: '1:121873510988:web:522ab979d38ccc35e63ae9'
    },
    ALGOLIA_APP_ID: 'CCJPHPWWQN',
    ALGOLIA_API_KEY: '56aa7057a1b653ce53ce2e9def0228e3',
    googleKey: 'AIzaSyBNCKkTmxFmVMad39hk-58FcX05qBqRnTQ',
    fileStackApi: 'AhJ3CUkqIQFulPcYqXoNYz',
};